import React from 'react'
import { withRouter } from 'react-router-dom'
import {money, progress, truncate} from '../../../includes/functions'
import Payments from '../../components/Payments/'
import DonationGuidelines from '../../components/DonationGuidelines'

import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import Collapse from '@material-ui/core/Collapse'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import LinearProgress from '@material-ui/core/LinearProgress'
import Paper from '@material-ui/core/Paper'
import Slider from '@material-ui/core/Slider'
import Text from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import { withStyles } from '@material-ui/core/styles'

const PrettoSlider = withStyles({
  root: {
    //color: '#52af77',
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: -8,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider)

function Feature({history, loggedIn, boardId, featureImage, featureTitle, featureAbout, featureOwner, featureRaised, donationTarget, donationDefault}) {
	const [disableDonate, setDisableDonate] = React.useState(false)
	const [expandLoginRegister, setExpandLoginRegister] = React.useState(false)
	const [guidelines, showGuidelines] = React.useState(false)
	
	const [donationAmount, setDonationAmount] = React.useState(donationDefault)
	const [donorMessage, setDonorMessage] = React.useState('')
	const [checkedAnonymous, setCheckedAnonymous] = React.useState(false)
	const [checkedHideMessage, setCheckedHideMessage] = React.useState(false)
	const [checkedTos, setCheckedTos] = React.useState(false)
	const [errorMessage, setErrorMessage] = React.useState('')

	const handleToggleLoginRegister = () => {
		setDisableDonate(!disableDonate)
		setExpandLoginRegister(!expandLoginRegister)
	}
	const handleClickLink = (link) => {
   	let loginReturnLink= 'rURL=/board/' + boardId
      history.push({ pathname: link, search:loginReturnLink })
   }
   const handleClickDonate = () => {
   	if (!checkedTos) {
   		setErrorMessage('Please Review & Confirm Donation Guidelines')
   	}
   }
   const handleChangeDonation = (event, newValue) => {
    	setDonationAmount(newValue)
  	}
  	const handleChangeMessage = (e) => {
		setDonorMessage(e.target.value)
	}
	const showGuidelinesToggle = () => {
   	showGuidelines(!guidelines)
   }
	return (
		<div style={{maxWidth:650, margin:'0px auto', marginTop:15 }}>
			<Text variant='h5'>Featured Fundraiser</Text>
			<Paper elevation={1} style={{padding: 5}} >
				<div style={{padding:15}}>
				<img alt={featureTitle} src={featureImage} style={{display:'block',width:'100%', borderRadius:4}} />
				</div>
				<div style={{padding:10}}>
					<Text variant='h6'>{featureTitle}</Text>
					<Text variant='body2'>{truncate(featureAbout,250)}</Text>
					<div align='right' style={{marginTop:4, borderTop:'1px solid gray'}}><Text variant='caption'><i>Beneficiary: {featureOwner}</i></Text></div>
				
					<Text component='span' variant='body1' color='primary'><b>{money(featureRaised,0)} </b></Text>
	          	<Text component='span' variant='caption' color='textSecondary'> raised of </Text>
	          	<Text component='span' variant='body1' color='textSecondary' ><b>{money(donationTarget,0)} </b></Text>
	          	<Text component='span' variant='caption' color='textSecondary'> target. </Text>
	          	<LinearProgress variant="determinate" value={progress(featureRaised, donationTarget)} style={{marginBottom:20}}/>
	          									
	          	{(!loggedIn) ? (
	          		<div align='center' style={{marginTop:50, marginBottom:25}}>
	          			<Button onClick={handleToggleLoginRegister} disabled={disableDonate} variant='contained' size='large' color='secondary'>Make a Donation</Button><br/>
	          			<Collapse in={expandLoginRegister} timeout='auto' unmountOnExit>
	          				<div style={{padding:20}}>
									Please <b>Login</b> / <b>Register</b> to donate.<br/>
									This is required so we know where to send your Donation Receipt.<br/> 
									It takes <b>one minute</b> and you would be returned right here when complete.
									<div align='center'>
										<Button onClick={() => {handleClickLink('/login')}} variant='contained' color='secondary' style={{margin:10}}>Login</Button>
										<Button onClick={() => {handleClickLink('/email/register')}} variant='contained' color='secondary' style={{margin:10}}>Register</Button>
									</div>
								</div>
	          			</Collapse>
	          		</div>
	          	) : (
	          		<div align='center' style={{marginTop:40, marginBottom:25}}>
	          			<Text variant='h6'>Contribute to this fundraiser</Text>
	          			<div align='center'>
								<Text component='span' variant='h2' style={{color:'#d4d4d4'}}>$</Text>
								<Text component='span' variant='h2' color='primary'>{donationAmount}</Text>
								<Text component='span' variant='h4' style={{color:'#d4d4d4'}}> USD</Text>
							</div>
							<PrettoSlider 
								value={donationAmount} 
								onChange={handleChangeDonation} 
								step={5}
        						min={5}
        						max={500}
        					/>
        					<TextField
				          	fullWidth
				          	value={donorMessage}
				          	onChange={handleChangeMessage}
        						margin='dense'
				          	variant='outlined'
				          	label='Message to Beneficiary (optional)'
				          	id='donorMessage'
				          	multiline
				          	rows={4}
				          	style={{marginTop:10, marginBottom:10}}
				        	/>
				        	<div align='left'>
				        	<FormControlLabel
					        control={<Checkbox checked={checkedAnonymous} onChange={ ()=>{setCheckedAnonymous(!checkedAnonymous)} } />}
					        label='Make donation anonymously.'
					        style={{marginLeft:0}}
					      />
					      <FormControlLabel
					        control={<Checkbox checked={checkedHideMessage} onChange={ ()=>{setCheckedHideMessage(!checkedHideMessage)} } />}
					        label='Hide message from public.'
					        style={{marginLeft:0}}
					      />
					      <FormControlLabel
					        	control={<Checkbox checked={checkedTos} style={{ zIndex: 3 }} onChange={ ()=>{setCheckedTos(!checkedTos)} } />}
					      	style={{borderTop:'2px solid #f1f1f1', borderBottom:'2px solid #f1f1f1', marginLeft:0}}
					        	label={
					        		<label>
					        			Please Review & Confirm our <Button style={{padding:0}} onClick={() => { showGuidelines(!guidelines) }}><b>Donation Guidelines</b></Button>, then check this box to continue.
					        		</label>
					        	}
					      />
					      </div>
					      {(!checkedTos) ? (
					      	<div style={{ textAlign:'center', padding:5 }}>
					      		<Text variant='body1' style={{ color:'red'}}><b>{errorMessage}</b></Text>
									<div style={{paddingTop:5, margin:'0 auto'}}>
										<Button onClick={handleClickDonate} style={{padding:15}} fullWidth variant='contained' color='secondary'>make a donation</Button>
									</div>
								</div>
							) : ( null )}
					      <Collapse in={checkedTos} timeout='auto' unmountOnExit>
							   <div align='center' style={{backgroundColor:'#f1f1f1', marginTop:15, padding:10, borderRadius:5, paddingBottom:30}}>
									<Text variant='h6' color='secondary'>Select Payment Method</Text>
									<Payments 
										type='board' 
										id={boardId}
										amount={donationAmount}
										message={donorMessage}
										checkedAnonymous={checkedAnonymous}
										checkedHideMessage={checkedHideMessage}
									/>
								</div>
							</Collapse>
							<DonationGuidelines open={guidelines} showGuidelinesToggle={showGuidelinesToggle}/>
	          		</div>
	          	)}
				</div>
			</Paper>
		</div>
	)
}
export default withRouter(Feature)
