import { APP_LAST_UPDATED, GROUP_LOAD_CAMPAIGNS, GROUP_CREATE_EDIT_CAMPAIGN, GROUP_LOAD_BOARDS, GROUP_CREATE_EDIT_BOARD, GROUP_LOAD_SUPPORT_TICKETS, GROUP_UPDATE_SUPPORT_TICKET, GROUP_LOAD_TEAM } from '../types'
import { chkExpiredData, chkGroupId } from '../app/actions'

// [START]: SECTION - Group Add/Edit Campaign :::::::::::::::::::::::::::::::::::::::::::::::::::::::
// ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
	export function fetchCampaignInfo(id) {
		return (dispatch, getstate, firebase) => new Promise(async function (resolve, reject) {
	      // make sure group didnt change (for members with roles in multiple groups)
			const groupId = await dispatch(chkGroupId())	

			// fetch campaign info
	      const fetchInfo = firebase.functions().httpsCallable('groupFetchCampaignInfo')
	      fetchInfo({ groupId:groupId, campaignId:id })
	      .then((returned) => {
	      	// returned campaign info
				resolve(returned.data)
	      })
	      .catch((e) => {
	      	let error = {title:'Error',message:''}
	      	try { error = JSON.parse(e.message) } 
	      	catch (err) { error.message = (e.message === 'internal') ? 'Error connecting to server.' : e.message }
	      	reject(error)
	      }) 
		})
	}

	export function addEditFundraiser(fInfo) {
		return (dispatch, getstate, firebase) => new Promise(async function (resolve, reject) {
			// make sure group didnt change (for members with roles in multiple groups)
			const groupId = await dispatch(chkGroupId())	

			// add/edit campaign
			const create = firebase.functions().httpsCallable('groupCreateEditFundraiser')
	      create({ groupId:groupId, fundraiserInfo:fInfo })
	      .then((returned) => {
	      	let id = returned.data.id
	      	let info = returned.data.info
	         let payload = {
					timestamp: Math.floor(Date.now() / 1000),
					id: id,
					info: info
				}
				dispatch({type: GROUP_CREATE_EDIT_CAMPAIGN, payload: payload})
				resolve(returned.data.id)
	      })
	      .catch((e) => {
	      	let error = {title:'Error',message:''}
	      	try { error = JSON.parse(e.message) } 
	      	catch (err) { error.message = (e.message === 'internal') ? 'Error connecting to server.' : e.message }
	      	reject(error)
	      }) 
		})
	}	
// ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
// [END]: SECTION - Group Add/Edit Campaign :::::::::::::::::::::::::::::::::::::::::::::::::::::::::


// [START]: SECTION - Group Add/Edit Board ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
// ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
	export function fetchBoardInfo(id) {
		return (dispatch, getstate, firebase) => new Promise(async function (resolve, reject) {
	      // make sure group didnt change (for members with roles in multiple groups)
			const groupId = await dispatch(chkGroupId())	

			// fetch campaign info
	      const fetchInfo = firebase.functions().httpsCallable('groupFetchBoardInfo')
	      fetchInfo({ groupId:groupId, boardId:id })
	      .then((returned) => {
	      	// returned board info
				resolve(returned.data)
	      })
	      .catch((e) => {
	      	let error = {title:'Error',message:''}
	      	try { error = JSON.parse(e.message) } 
	      	catch (err) { error.message = (e.message === 'internal') ? 'Error connecting to server.' : e.message }
	      	reject(error)
	      }) 
		})
	}

	export function addEditBoard(bInfo) {
		return (dispatch, getstate, firebase) => new Promise(async function (resolve, reject) {
			// make sure group didnt change (for members with roles in multiple groups)
			const groupId = await dispatch(chkGroupId())	

			// add/edit campaign
			const create = firebase.functions().httpsCallable('groupCreateEditBoard')
	      create({ groupId:groupId, boardInfo:bInfo })
	      .then((returned) => {
	      	let id = returned.data.id
	      	let info = returned.data.info
	         let payload = {
					timestamp: Math.floor(Date.now() / 1000),
					id: id,
					info: info
				}
				dispatch({type: GROUP_CREATE_EDIT_BOARD, payload: payload})
				resolve(id)
	      })
	      .catch((e) => {
	      	let error = {title:'Error',message:''}
	      	try { error = JSON.parse(e.message) } 
	      	catch (err) { error.message = (e.message === 'internal') ? 'Error connecting to server.' : e.message }
	      	reject(error)
	      }) 
		})
	}	
// ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
// [END]: SECTION - Group Add/Edit Board ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::


// [START]: SECTION - Group Boards ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
// ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
	export function fetchDefaultCampaigns() {
		return (dispatch, getstate, firebase) => new Promise(async function (resolve, reject) {
			// fetch campaign info
	      const fetchCampaigns = firebase.functions().httpsCallable('groupFetchBoardsDefaultCampaigns')
	      fetchCampaigns()
	      .then((returned) => {
	      	// returns default campaigns
				resolve(returned.data)
	      })
	      .catch((e) => {
	      	console.log('error fetching default campaigns: ', e.message)
	      }) 
		})
	}

	export function fetchGroupBoards(force=false) {
		return (dispatch, getstate, firebase) => new Promise(async function (resolve, reject) {
			// make sure group didnt change (for members with roles in multiple groups)
			const groupId = await dispatch(chkGroupId())
		   
		   // check if current data expired (reduces server calls)
		   const chkIfExpired = dispatch(chkExpiredData(force, 'groupBoards'))
	     	chkIfExpired
	     	.then((currentTime) => {
	     	// ---------------------------------------------------------------------------    
		      const getCampaigns = firebase.functions().httpsCallable('groupFetchBoards')
		      getCampaigns({ groupId:groupId })
		      .then((returned) => {
		         let payload = {
						timestamp: currentTime,
						boards: returned.data
					}
					dispatch({type: GROUP_LOAD_BOARDS, payload: payload})
					dispatch({ type: APP_LAST_UPDATED, payload:{key:'groupBoards', value:currentTime} })
					resolve()
		      })
		      .catch((e) => {
		      	let error = {title:'Error',message:''}
		      	try { error = JSON.parse(e.message) } 
		      	catch (err) { error.message = (e.message === 'internal') ? 'Error connecting to server.' : e.message }
		      	reject(error)
		      })  
	     	// ---------------------------------------------------------------------------
	     	})
	     	.catch(() => {
	         // Current data still good so do nothing.
	         resolve()
	     	})
		})
	}

	export function updateDefaultCampaign(boardId, defaultId) {
		return (dispatch, getstate, firebase) => new Promise(async function (resolve, reject) {
			// make sure group didnt change (for members with roles in multiple groups)
			const groupId = await dispatch(chkGroupId())	

			// update default campaign
			const create = firebase.functions().httpsCallable('groupUpdateDefaultCampaign')
	      create({ groupId:groupId, boardId:boardId, defaultId:defaultId })
	      .then((returned) => {
	      	let id = returned.data.id
	      	let info = returned.data.info
	         let payload = {
					timestamp: Math.floor(Date.now() / 1000),
					id: id,
					info: info
				}
				dispatch({type: GROUP_CREATE_EDIT_BOARD, payload: payload})
				resolve()
	      })
	      .catch((e) => {
	      	let error = {title:'Error',message:''}
	      	try { error = JSON.parse(e.message) } 
	      	catch (err) { error.message = (e.message === 'internal') ? 'Error connecting to server.' : e.message }
	      	reject(error)
	      })

		})
	}		
// ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
// [END]: SECTION - Group Boards ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::


// [START]: SECTION - Group Campaigns ///////////////////////////////////////////////////////////
// //////////////////////////////////////////////////////////////////////////////////////////////
	export function fetchGroupCampaigns(force=false) {
		return (dispatch, getstate, firebase) => new Promise(async function (resolve, reject) {
			// make sure group didnt change (for members with roles in multiple groups)
			const groupId = await dispatch(chkGroupId())
		   
		   // check if current data expired (reduces server calls)
		   const chkIfExpired = dispatch(chkExpiredData(force, 'groupCampaigns'))
	     	chkIfExpired
	     	.then((currentTime) => {
	     	// ---------------------------------------------------------------------------    
		      const getCampaigns = firebase.functions().httpsCallable('groupFetchCampaigns')
		      getCampaigns({ groupId:groupId })
		      .then((returned) => {
		         let payload = {
						timestamp: currentTime,
						campaigns: returned.data
					}
					dispatch({type: GROUP_LOAD_CAMPAIGNS, payload: payload})
					dispatch({ type: APP_LAST_UPDATED, payload:{key:'groupCampaigns', value:currentTime} })
					resolve()
		      })
		      .catch((e) => {
		      	let error = {title:'Error',message:''}
		      	try { error = JSON.parse(e.message) } 
		      	catch (err) { error.message = (e.message === 'internal') ? 'Error connecting to server.' : e.message }
		      	reject(error)
		      })  
	     	// ---------------------------------------------------------------------------
	     	})
	     	.catch(() => {
	         // Current data still good so do nothing.
	         resolve()
	     	})
		})
	}

	export function fetchDonorDetails(id) {
		return (dispatch, getstate, firebase) => new Promise(async function (resolve, reject) {
         // make sure group didnt change (for members with roles in multiple groups)
			const groupId = await dispatch(chkGroupId())	

         const fetchDetails = firebase.functions().httpsCallable('groupFetchDonorDetails')
	      fetchDetails({ groupId:groupId, campaignId:id })
	      .then((returned) => {
	      	// returned donors info
				resolve(returned.data)
	      })
	      .catch((e) => {
	      	let error = {title:'Error',message:''}
	      	try { error = JSON.parse(e.message) } 
	      	catch (err) { error.message = (e.message === 'internal') ? 'Error connecting to server.' : e.message }
	      	reject(error)
	      })   
		})
	}
// //////////////////////////////////////////////////////////////////////////////////////////////
// [END]: SECTION - Group Campaigns /////////////////////////////////////////////////////////////


// [START]: SECTION - Group Team ////////////////////////////////////////////////////////////////
// //////////////////////////////////////////////////////////////////////////////////////////////
	export function loadTeam(force=false) {
		return (dispatch, getstate, firebase) => new Promise(async function (resolve, reject) {
		// make sure group didnt change (for members with roles in multiple groups)
			const groupId = await dispatch(chkGroupId())
		   
		   // check if current data expired (reduces server calls)
		   const chkIfExpired = dispatch(chkExpiredData(force, 'groupTeam'))
	     	chkIfExpired
	     	.then((currentTime) => {
	     	// ---------------------------------------------------------------------------    
				const teamMembers = firebase.functions().httpsCallable('groupTeamMembers')
		      teamMembers({ groupId:groupId })
		      .then((returned) => {
		      	let payload = {
						timestamp: currentTime,
						team: returned.data
					}
		      	dispatch({ type:GROUP_LOAD_TEAM, payload:payload })
		         dispatch({ type:APP_LAST_UPDATED, payload:{key:'groupTeam', value:currentTime} })
		         resolve()
		      })
		      .catch((e) => {
		      	let error = {title:'Error',message:''}
		      	try { error = JSON.parse(e.message) } 
		      	catch (err) { error.message = (e.message === 'internal') ? 'Error connecting to server.' : e.message }
		      	reject(error)
		      }) 
	      // ---------------------------------------------------------------------------
	     	})
	     	.catch(() => {
	         // Current data still good so do nothing.
	         resolve()
	     	})
		})
	}

// //////////////////////////////////////////////////////////////////////////////////////////////
// [START]: SECTION - Group Team ////////////////////////////////////////////////////////////////


// [START]: SECTIOM - Group Support :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
// ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
	export function loadTickets(force=false) {
		return (dispatch, getstate, firebase) => new Promise(async function (resolve, reject) {
			// make sure group didnt change (for members with roles in multiple groups)
			const groupId = await dispatch(chkGroupId())
		   
		   // check if current data expired (reduces server calls)
		   const chkIfExpired = dispatch(chkExpiredData(force, 'groupSupportTickets'))
	     	chkIfExpired
	     	.then((currentTime) => {
	     	// ---------------------------------------------------------------------------    
		      const getCampaigns = firebase.functions().httpsCallable('groupLoadSupport')
		      getCampaigns({ groupId:groupId })
		      .then((returned) => {
		         let payload = {
						timestamp: currentTime,
						tickets: returned.data.tickets,
						notes: returned.data.notes,
						team: returned.data.team
					}
					dispatch({type: GROUP_LOAD_SUPPORT_TICKETS, payload: payload})
					dispatch({ type: APP_LAST_UPDATED, payload:{key:'groupSupportTickets', value:currentTime} })
					resolve()
		      })
		      .catch((e) => {
		      	let error = {title:'Error',message:''}
		      	try { error = JSON.parse(e.message) } 
		      	catch (err) { error.message = (e.message === 'internal') ? 'Error connecting to server.' : e.message }
		      	reject(error)
		      })  
	     	// ---------------------------------------------------------------------------
	     	})
	     	.catch(() => {
	         // Current data still good so do nothing.
	         resolve()
	     	})
		})
	}

	export function assignTicket(ticketId, teamMember) {
		return (dispatch, getstate, firebase) => new Promise(async function (resolve, reject) {
			// make sure group didnt change (for members with roles in multiple groups)
			const groupId = await dispatch(chkGroupId())

			const assign = firebase.functions().httpsCallable('groupAssignTicket')
	      assign({ groupId:groupId, ticketId:ticketId, teamMember:teamMember })
	      .then((returned) => {
	      	let timestamp = Math.floor(Date.now() / 1000)
	      	let name = getstate().auth.userInfo.name
	      	let ticket = getstate().group.supportTickets[ticketId] || {}
	      	ticket.status = 'open'
	      	ticket.assignedToId = teamMember.memberId
            ticket.assignedToName = teamMember.memberName
            if (!ticket.notes) {
					ticket.notes = []
            }
            ticket.notes.push({ timestamp:timestamp, note: 'Ticket assigned to ' + teamMember.memberName + ' by ' + name })
            let payload = {
            	timestamp: timestamp,
            	id: ticketId,
            	info: ticket
            }
            dispatch({type: GROUP_UPDATE_SUPPORT_TICKET, payload: payload})	      	
	         resolve()
	      })
	      .catch((err) => {
	         reject(err.message)
	      })		
		})
	}

	export function updateTicket(ticketId, post) {
		return (dispatch, getstate, firebase) => new Promise(async function (resolve, reject) {
			// make sure group didnt change (for members with roles in multiple groups)
			const groupId = await dispatch(chkGroupId())

			const ticketUpdate = firebase.functions().httpsCallable('groupUpdateTicket')
	      ticketUpdate({ groupId:groupId, ticketId:ticketId, post:post })
	      .then((returned) => {
	      	let timestamp = returned.data
	      	let user = getstate().auth.userInfo
	      	let ticket = getstate().group.supportTickets[ticketId] || {}
            ticket.thread[timestamp] = {
            	timestamp: timestamp,
               comment: post,
               postedById: user.id,
               postedByName: user.name,
               postedByImage: user.image
            }
            let payload = {
            	timestamp: timestamp,
            	id: ticketId,
            	info: ticket
            }
            dispatch({type: GROUP_UPDATE_SUPPORT_TICKET, payload: payload})	      	
	         resolve()
	      })
	      .catch((err) => {
	         reject(err.message)
	      })
		})
	}

	export function addTicketNote(ticketId, note) {
		return (dispatch, getstate, firebase) => new Promise(async function (resolve, reject) {
			// make sure group didnt change (for members with roles in multiple groups)
			const groupId = await dispatch(chkGroupId())

			const ticketUpdate = firebase.functions().httpsCallable('groupUpdateTicketNotes')
	      ticketUpdate({ groupId:groupId, ticketId:ticketId, note:note })
	      .then((returned) => {
	      	let timestamp = Math.floor(Date.now() / 1000)
	      	let name = getstate().auth.userInfo.name
	      	let ticket = getstate().group.supportTickets[ticketId] || {}
            if (!ticket.notes) {
              	ticket.notes = []
            }            
				ticket.notes.push({ timestamp:timestamp, note: note + '\n\nposted by ' + name })
            let payload = {
            	timestamp: timestamp,
            	id: ticketId,
            	info: ticket
            }
            dispatch({type: GROUP_UPDATE_SUPPORT_TICKET, payload: payload})	      	
	         resolve()
	      })
	      .catch((err) => {
	         reject(err.message)
	      })
		})
	}

	export function closeTicket(ticketId) {
		return (dispatch, getstate, firebase) => new Promise(async function (resolve, reject) {
			// make sure group didnt change (for members with roles in multiple groups)
			const groupId = await dispatch(chkGroupId())

			const ticketClose = firebase.functions().httpsCallable('groupCloseTicket')
	      ticketClose({ groupId:groupId, ticketId:ticketId })
	      .then((returned) => {
	      	let timestamp = Math.floor(Date.now() / 1000)
	      	let user = getstate().auth.userInfo
	      	let ticket = getstate().group.supportTickets[ticketId] || {}
            ticket.status = 'closed'
				ticket.closed = true
				ticket.closedById = user.id
				ticket.closedByName = user.name
				ticket.closedTimestamp = timestamp
				ticket.notes.push({ timestamp:timestamp, note: 'Ticket Closed by ' + user.name })           
            let payload = {
            	timestamp: timestamp,
            	id: ticketId,
            	info: ticket
            }
            dispatch({type: GROUP_UPDATE_SUPPORT_TICKET, payload: payload})	      	
	         resolve()
	      })
	      .catch((err) => {
	         reject(err.message)
	      })
		})
	}

	export function archiveTicket(ticketId) {
		return (dispatch, getstate, firebase) => new Promise(async function (resolve, reject) {
			// make sure group didnt change (for members with roles in multiple groups)
			const groupId = await dispatch(chkGroupId())

			const ticketArchive = firebase.functions().httpsCallable('groupArchiveTicket')
	      ticketArchive({ groupId:groupId, ticketId:ticketId })
	      .then((returned) => {
	      	let timestamp = Math.floor(Date.now() / 1000)
	      	let user = getstate().auth.userInfo
	      	let ticket = getstate().group.supportTickets[ticketId] || {}
            ticket.status = 'archived'
				ticket.notes.push({ timestamp:timestamp, note: 'Ticket Archived by ' + user.name })           
            let payload = {
            	timestamp: timestamp,
            	id: ticketId,
            	info: ticket
            }
            dispatch({type: GROUP_UPDATE_SUPPORT_TICKET, payload: payload})	      	
	         resolve()
	      })
	      .catch((err) => {
	         reject(err.message)
	      })		
		})
	}
// ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
// [END]: SECTIOM - Group Support :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
