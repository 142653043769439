import React from 'react'
import { withRouter } from 'react-router-dom'
import countryList from '../../../includes/countryList'

import { green } from '@material-ui/core/colors'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemText from '@material-ui/core/ListItemText'
import Text from '@material-ui/core/Typography'

function Step1 ({history, loggedIn, userInfo, boardId, handleGoTo}) {
	let country = countryList.find(e => e.code === userInfo.location)
	let countryName = country.name

	const handleClickLink = (link) => {
   	let loginReturnLink= 'rURL=/stepbystep/' + boardId
      history.push({ pathname: link, search:loginReturnLink })
   }
	const handleEditProfile = () => {
   	let returnLink= 'nextURL=/stepbystep/' + boardId + '&noPw=1'
      history.push({ pathname:'/member/setup', search:returnLink })
   }
   const handleNext = () => {
   	handleGoTo(1)
   }
	return (
		<div>
			{(!loggedIn) ? (
       		<div align='center' style={{ margin:'0px auto', marginBottom:25, padding:20, maxWidth:600 }}>
    				<Text variant='body1'>
    					You must be a Registered Member of LetsFundIt to Join this Feature Board.
						It takes <b>one minute</b> and you would be returned right here when complete.
					</Text> 
					<div align='center'>
						<Button onClick={() => {handleClickLink('/login')}} variant='contained' color='secondary' style={{margin:10}}>Login</Button>
						<Button onClick={() => {handleClickLink('/email/register')}} variant='contained' color='secondary' style={{margin:10}}>Register</Button>
					</div>
       		</div>
       	) : (
       		<div>
       			<div style={{ maxWidth:600, margin:'0px auto'}}>
						<div style={{padding:10}}>
							<Text variant='body1'>Before we get started lets make sure your profile details are correct.</Text>
						</div>	

						<div style={{ backgroundColor:'#f1f1f1'}}>
							<List>
								<ListItem>
									<ListItemAvatar>
										<Avatar alt={userInfo.name} src={userInfo.image} style={{borderRadius:5, width:80, height:80, margin:5}} />
									</ListItemAvatar>
									<ListItemText>
										<Text style={{lineHeight:.5}} variant='caption' display='block'>
											Campaign by:
										</Text>
										<Text variant='h6'>{userInfo.name}</Text>
										<Text style={{lineHeight:.5}} variant='caption' display='block'>
											{countryName}
										</Text>
									</ListItemText>
								</ListItem>
							</List>
						</div>

						<Text variant='body2' color='textSecondary'>
						<b>NOTE:</b> As noted in our Terms of Service, funds raised under a false identity may be forfeited.
						Please ensure your name and image are correctly displayed.  
						</Text> 
						<Button onClick={handleEditProfile} size='small' color='secondary' style={{padding:0, marginLeft:5}}> Edit Profile Here</Button>
					</div>

					<div style={{ marginTop: 50, borderBottom: '2px solid #ddd' }} />
				  	<div align='right' style={{marginTop:20}}>
					 	<Button variant='contained' style={{backgroundColor:green[500], color:'#fff'}} onClick={handleNext}>
							Lets Get Started
					 	</Button>
					</div>
       		</div>
       	)}
		</div>
	)
}
export default withRouter(Step1)
