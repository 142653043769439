import moment from 'moment-timezone'

export function payWithFac(type, id, amount, message, checkedHideMessage, checkedAnonymous) {
	return (dispatch, getstate, firebase) => new Promise(async function (resolve, reject) {
		// setup return link
      let hostname = window.location.hostname
		let returnUrl = 'https://'+hostname+'/payment/card'
      if (hostname.includes('localhost')) {
         returnUrl = 'http://localhost:3000/payment/card'
      }
      // get payment link
		const payment = firebase.functions().httpsCallable('facPayment')
      payment({
      	type:type, 
      	id:id, 
      	amount:amount, 
      	message:message, 
      	privateMessage:checkedHideMessage, 
      	privateDonor:checkedAnonymous,
      	returnUrl:returnUrl
      })
      .then((returned) => {
         // return URL is returned.
			resolve(returned.data)
      })
      .catch((e) => {
         let error = {title:'Error',message:''}
         try { error = JSON.parse(e.message) } 
         catch (err) { error.message = (e.message === 'internal') ? 'Error connecting to server.' : e.message }
         reject(error)
      }) 
	})
}

export function processFacPayment(tokenId) {
   return (dispatch, getState, firebase) => new Promise(function (resolve, reject) {
      let hostname = window.location.hostname
      let mode = 'live'
      if (hostname.includes('dev') || hostname.includes('localhost')) {
         mode = 'stage'
      }
      const facResponse = firebase.functions().httpsCallable('facResponse')
      facResponse({tokenId:tokenId, mode:mode})
      .then((response) => {
      	// returns receipt.
         resolve(response.data)
      })
      .catch((e) => {
         let error = {title:'Error',message:''}
         try { error = JSON.parse(e.message) } 
         catch (err) { error.message = (e.message === 'internal') ? 'Error connecting to server.' : e.message }
         reject(error)
      }) 
   })
}

export function payWithCredits(type, id, amount, message, checkedHideMessage, checkedAnonymous) {
   return (dispatch, getstate, firebase) => new Promise(async function (resolve, reject) {
      // setup return link
      let hostname = window.location.hostname
      let returnUrl = 'https://'+hostname+'/payment/credits/'
      if (hostname.includes('localhost')) {
         returnUrl = 'http://localhost:3000/payment/credits/'
      }
      // get payment link
      const payment = firebase.functions().httpsCallable('creditsPayment')
      payment({
         type:type, 
         id:id, 
         amount:amount, 
         message:message, 
         privateMessage:checkedHideMessage, 
         privateDonor:checkedAnonymous,
         returnUrl:returnUrl
      })
      .then((returned) => {
         // return URL is returned. 
         resolve(returned.data)
      })
      .catch((e) => {
         let error = {title:'Error',message:''}
         try { error = JSON.parse(e.message) } 
         catch (err) { error.message = (e.message === 'internal') ? 'Error connecting to server.' : e.message }
         reject(error)
      }) 
   })
}

export function processCreditsPayment(id, token) {
   return (dispatch, getState, firebase) => new Promise(function (resolve, reject) {
      const creditsResponse = firebase.functions().httpsCallable('creditsResponse')
      creditsResponse({id:id, token:token})
      .then((response) => {
         // returns receipt.
         resolve(response.data)
      })
      .catch((e) => {
         let error = {title:'Error',message:''}
         try { error = JSON.parse(e.message) } 
         catch (err) { error.message = (e.message === 'internal') ? 'Error connecting to server.' : e.message }
         reject(error)
      })  
   })
}

export function transferGetProfile(email) {
   return (dispatch, getState, firebase) => new Promise(function (resolve, reject) {
      const getProfile = firebase.functions().httpsCallable('transferGetProfile')
      getProfile({ email:email })
      .then((response) => {
         // returns user info.
         resolve(response.data)
      })
      .catch((e) => {
         let error = {title:'Error',message:''}
         try { error = JSON.parse(e.message) } 
         catch (err) { error.message = (e.message === 'internal') ? 'Error connecting to server.' : e.message }
         reject(error)
      })      
   })
}

export function transferSubmit(email, amount) {
   return (dispatch, getState, firebase) => new Promise(function (resolve, reject) {
      let dateTimezone = moment.tz.guess()
      let hostname = window.location.hostname
      let mode = 'live'
      if (hostname.includes('dev') || hostname.includes('localhost')) {
         mode = 'stage'
      }
      const submitAmount = firebase.functions().httpsCallable('transferSubmit')
      submitAmount({ mode:mode, email:email, amount:amount, dateTimezone:dateTimezone })
      .then((response) => {
         // returns 'complete'.
         resolve()
      })
      .catch((e) => {
         let error = {title:'Error',message:''}
         try { error = JSON.parse(e.message) } 
         catch (err) { error.message = (e.message === 'internal') ? 'Error connecting to server.' : e.message }
         reject(error)
      }) 
   })
}

export function transferConfirm(transferId, tokenId) {
   return (dispatch, getState, firebase) => new Promise(function (resolve, reject) {
      const transfer = firebase.functions().httpsCallable('transferConfirm')
      transfer({ transferId:transferId, tokenId:tokenId })
      .then((response) => {
         // returns {name, email, amount}.
         resolve(response.data)
      })
      .catch((e) => {
         let error = {title:'Error',message:''}
         try { error = JSON.parse(e.message) } 
         catch (err) { error.message = (e.message === 'internal') ? 'Error connecting to server.' : e.message }
         reject(error)
      })
   })
}
