import React from 'react'
import { withRouter } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import Drawer from '@material-ui/core/SwipeableDrawer'
import IconAccount from '@material-ui/icons/AccountBalance'
//import IconAffilate from '@material-ui/icons/PeopleOutline'
import IconBoards from '@material-ui/icons/DeveloperBoard'
import IconButton from '@material-ui/core/IconButton'
import IconCampaign from '@material-ui/icons/EmojiPeople'
import IconCharity from '@material-ui/icons/HowToVote'
import IconChevronLeft from '@material-ui/icons/ChevronLeft'
import IconDashboard from '@material-ui/icons/Dashboard'
import IconLogin from '@material-ui/icons/VpnKey'
import IconFundraiser from '@material-ui/icons/Speed'
import IconLiveHelp from '@material-ui/icons/LiveHelp'
import IconMoney from '@material-ui/icons/MonetizationOn'
import IconNotifications from '@material-ui/icons/NotificationsNone'
import IconPortrait from '@material-ui/icons/Portrait'
import IconSettings from '@material-ui/icons/Tune'
import IconTeam from '@material-ui/icons/Group'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ListSubheader from '@material-ui/core/ListSubheader'

const useStyles = makeStyles(theme => ({
	list: {
		width: 300
	},
	active: {
		backgroundColor: '#E8E8E8'
	},
	inactive: {
		backgroundColor: '#ffffff'
	},
	toolbar: {
    	display: 'flex',
    	alignItems: 'center',
    	justifyContent: 'flex-end',
    	padding: theme.spacing(0, 1),
    	// necessary for content to be below app bar
    	//...theme.mixins.toolbar,
   }
}))

const SideNav = (props) => {
	const style = useStyles()
	
	const clickLink = (link) => {
		props.history.push({ pathname: link })
		props.sideNavToggle()
	}

	return (
		<Drawer open={props.sideNavOpen} onClose={props.sideNavToggle} onOpen={ props.sideNavToggle } >
			<div className={style.toolbar}>
          	<IconButton onClick={() => { props.sideNavToggle() }}>
            	<IconChevronLeft />
          	</IconButton>
        	</div>
        	<Divider />
        	{(props.publicRoute) ? (
				<div className={style.list}>
					<List>
						<ListItem button onClick={() => { clickLink('/campaigns') }}
							className={ (props.location.pathname === '/campaigns') ? style.active : style.inactive }
						>
							<ListItemIcon>
								<IconCampaign />
							</ListItemIcon>
							<ListItemText primary='Campaigns' />
						</ListItem>

						<ListItem button onClick={() => { clickLink('/charities') }}
							className={ (props.location.pathname === '/charities') ? style.active : style.inactive }
						>
							<ListItemIcon>
								<IconCharity />
							</ListItemIcon>
							<ListItemText primary='Charities' />
						</ListItem>

						<ListItem button onClick={() => { clickLink('/boards') }}
							className={ (props.location.pathname === '/boards') ? style.active : style.inactive }
						>
							<ListItemIcon>
								<IconBoards />
							</ListItemIcon>
							<ListItemText primary='Feature Boards' />
						</ListItem>

						<ListItem button onClick={() => { clickLink('/login') }}
							className={ (props.location.pathname === '/login') ? style.active : style.inactive }
						>
							<ListItemIcon>
								<IconLogin />
							</ListItemIcon>
							<ListItemText primary='Login | Register' />
						</ListItem>

						<ListItem button onClick={() => { clickLink('/start') }}
							className={ (props.location.pathname === '/start') ? style.active : style.inactive }
						>
							<Button fullWidth size='small' variant='contained' color='secondary' style={{textTransform:'none'}}>Start a LetsFundIt</Button>
						</ListItem>

					{/*
						<ListItem button onClick={() => { clickLink('/affilates') }}
							className={ (props.location.pathname === '/affilates') ? style.active : style.inactive }
						>
							<ListItemIcon>
								<IconAffilate />
							</ListItemIcon>
							<ListItemText primary='Create Affilate Account' />
						</ListItem>
					*/}
					</List>
				</div>
        	):(
        		<div className={style.list}>
        			{(props.selectedOption.login === 'group') ? (
        				<React.Fragment>
        					<div align='center'><img src={ props.selectedOption.thumb } style={{ width: '75%', borderRadius: 20, border: '1.5px solid #ddd', margin: '10px auto' }} alt='' /></div>
							<List component="nav"
								subheader={
								<ListSubheader component="div">
								  { props.selectedOption.name.toUpperCase() } MENU
								</ListSubheader>
							  }
							>
								<ListItem button onClick={() => { clickLink('/group/dashboard') }}
									className={ (props.location.pathname.includes('/group/dashboard')) ? style.active : style.inactive }
								>
									<ListItemIcon><IconDashboard /></ListItemIcon>
									<ListItemText primary='Dashboard' />
								</ListItem>

								<ListItem button onClick={() => { clickLink('/group/campaigns') }}
									className={ (props.location.pathname.includes('/group/campaigns')) ? style.active : style.inactive }
								>
									<ListItemIcon><IconFundraiser /></ListItemIcon>
									<ListItemText primary='Campaigns' />
								</ListItem>

								<ListItem button onClick={() => { clickLink('/group/boards') }}
									className={ (props.location.pathname.includes('/group/boards')) ? style.active : style.inactive }
								>
									<ListItemIcon><IconBoards /></ListItemIcon>
									<ListItemText primary='Boards' />
								</ListItem>

								<ListItem button onClick={() => { clickLink('/group/accounts') }}
									className={ (props.location.pathname.includes('/group/accounts')) ? style.active : style.inactive }
								>
									<ListItemIcon><IconAccount /></ListItemIcon>
									<ListItemText primary='Accounts' />
								</ListItem>
								<ListItem button onClick={() => { clickLink('/group/team') }}
									className={ (props.location.pathname.includes('/group/team')) ? style.active : style.inactive }
								>
									<ListItemIcon><IconTeam /></ListItemIcon>
									<ListItemText primary='Team' />
								</ListItem>
								<ListItem button onClick={() => { clickLink('/group/support') }}
									className={ (props.location.pathname.includes('/group/support')) ? style.active : style.inactive }
								>
									<ListItemIcon><IconLiveHelp /></ListItemIcon>
									<ListItemText primary='Support Desk' />
								</ListItem>
								<ListItem button onClick={() => { clickLink('/group/settings') }}
									className={ (props.location.pathname.includes('/group/settings')) ? style.active : style.inactive }
								>
									<ListItemIcon><IconSettings /></ListItemIcon>
									<ListItemText primary='Group Settings' />
								</ListItem>
							</List>
        				</React.Fragment>
        			):(
        				<React.Fragment>
        					<div align='center'><img src={ props.selectedOption.thumb } style={{ width: '75%', borderRadius: 20, border: '1.5px solid #ddd', margin: '10px auto' }} alt='' /></div>
							<List>
								<ListItem button onClick={() => { clickLink('/member') }}
									className={ (props.location.pathname.includes('/member/account')) ? style.active : style.inactive }
								>
									<ListItemIcon>
									<IconDashboard />
									</ListItemIcon>
									<ListItemText primary='My Account' />
								</ListItem>
								
								<ListItem button onClick={() => { clickLink('/member/campaigns') }}
									className={ (props.location.pathname.includes('/member/campaigns')) ? style.active : style.inactive }
								>
									<ListItemIcon><IconFundraiser /></ListItemIcon>
									<ListItemText primary='Campaigns' />
								</ListItem>

								<ListItem button onClick={() => { clickLink('/member/donations') }}
									className={ (props.location.pathname.includes('/member/donations')) ? style.active : style.inactive }
								>
									<ListItemIcon><IconMoney /></ListItemIcon>
									<ListItemText primary='Donations' />
								</ListItem>
								
								<ListItem button onClick={() => { clickLink('/member/notifications') }}
									className={ (props.location.pathname.includes('/member/notifications')) ? style.active : style.inactive }
								>
									<ListItemIcon>
									<IconNotifications />
									</ListItemIcon>
									<ListItemText primary='Notifications' />
								</ListItem>
								<ListItem button onClick={() => { clickLink('/member/support') }}
									className={ (props.location.pathname.includes('/member/support')) ? style.active : style.inactive }
								>
									<ListItemIcon>
									<IconLiveHelp />
									</ListItemIcon>
									<ListItemText primary='Support Desk' />
								</ListItem>
								<ListItem button onClick={() => { clickLink('/member/profile') }}
									className={ (props.location.pathname.includes('/member/profile')) ? style.active : style.inactive }
								>
									<ListItemIcon>
									<IconPortrait />
									</ListItemIcon>
									<ListItemText primary='Profile Settings' />
								</ListItem>
							</List>
        				</React.Fragment>
        			)}
        		</div>
        	)}
		</Drawer>
	)
}
export default withRouter(SideNav)
