import React from 'react'
import { withRouter } from 'react-router-dom'

import AboutFeatureBoards from '../../components/AboutFeatureBoards'
import DonationGuidelines from '../../components/DonationGuidelines'
import ServiceAndPolicies from '../../components/ServiceAndPolicies'

import { green } from '@material-ui/core/colors'
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import OpenInNew from '@material-ui/icons/OpenInNew'
import Text from '@material-ui/core/Typography'

function Step2 ({history, loggedIn, handleGoTo}) {
	const [howItWorks, showHowItWorks] = React.useState(false)
	const [guidelines, showGuidelines] = React.useState(false)
	const [checkedHow, setCheckedHow] = React.useState(false)
	const [checkedGuidelines, setCheckedGuidelines] = React.useState(false)
	const [checkedTos, setCheckedTos] = React.useState(false)
	const [message, setMessage] = React.useState('')

   const showHowItWorksToggle = () => {
   	showHowItWorks(!howItWorks)
   }
   const showGuidelinesToggle = () => {
   	showGuidelines(!guidelines)
   }
   const handleNext = () => {
   	if (!checkedHow || !checkedGuidelines || !checkedTos) {
   		return setMessage('Please check each box to Acknowledge.')
   	}
   	handleGoTo(2)
   }

   if (!loggedIn) return null
	return (
		<div>

    		<div>
       		<div style={{ margin:'0px auto', padding:20, maxWidth:600 }}>
       			<Text variant='body1'>
       			Before we get started, it's important for you to understand <b>How Feature Boards Work</b>,
       			our <b>Donation Guidelines</b> and <b>Terms of Service & Fees</b>. Please review each of these and once you
       			agree we can proceed.	
       			</Text>
       			<div style={{height:20}} />
					<FormControlLabel
			      	style={{margin:0, padding:0}}
			        	control={<Checkbox checked={checkedHow} style={{ zIndex: 3 }} onChange={ ()=>{setCheckedHow(!checkedHow)} } />}
			        	label={
			        		<label>
			        			<Button style={{textTransform:'none', margin:2, padding:0, color:'#777'}} onClick={showHowItWorksToggle}><b>How Feature Boards Work</b> <OpenInNew style={{marginLeft:5, fontSize: 16}}/></Button>
			        		</label>
			        	}
			      /><br/>
       			<FormControlLabel
			      	style={{margin:0, padding:0}}
			        	control={<Checkbox checked={checkedGuidelines} style={{ zIndex: 3 }} onChange={ ()=>{setCheckedGuidelines(!checkedGuidelines)} } />}
			        	label={
			        		<label>
			        			<Button style={{textTransform:'none', margin:2, padding:0, color:'#777'}} onClick={showGuidelinesToggle}><b>Donation Guidelines </b> <OpenInNew style={{marginLeft:5, fontSize: 16}}/></Button>
			        		</label>
			        	}
			      /><br/>
			      <FormControlLabel
			      	style={{margin:0, padding:0}}
			        	control={<Checkbox checked={checkedTos} style={{ zIndex: 3 }} onChange={ ()=>{setCheckedTos(!checkedTos)} } />}
			        	label={
			        		<label>				        			
			        			<ServiceAndPolicies tab={4} size='medium' stepbystep={true} />
			        		</label>
			        	}
			      />
    			</div>

				<div align='center' style={{ minHeight: 50, padding: 10, borderBottom: '2px solid #ddd' }}>
	            <Text variant='h6' style={{ color: 'red' }}>{message}</Text>
	         </div>
			  	<div align='right' style={{marginTop:20}}>
				 	<Button variant='contained' style={{backgroundColor:green[500], color:'#fff'}} onClick={handleNext}>
						I Acknowledge & Agree
				 	</Button>
				</div>
				<DonationGuidelines open={guidelines} showGuidelinesToggle={showGuidelinesToggle}/>	
				<AboutFeatureBoards open={howItWorks} showAboutToggle={showHowItWorksToggle}/>	
    		</div>

		</div>
		)
}
export default withRouter(Step2)
