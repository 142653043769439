import React from 'react'
import swal from 'sweetalert2'
import {  withRouter } from 'react-router-dom'

import Loader from '../../components/Loader'
import Step1 from './Step1'
import Step2 from './Step2'
import Step3 from './Step3'
import Step4 from './Step4'
import Step5 from './Step5'

import Paper from '@material-ui/core/Paper'
import Step from '@material-ui/core/Step'
import StepContent from '@material-ui/core/StepContent'
import StepLabel from '@material-ui/core/StepLabel'
import Stepper from '@material-ui/core/Stepper'
import Text from '@material-ui/core/Typography'

class Index extends React.Component {
	state = { 
		loading: false,
		activeStep: 0,
		group: {
			name: '',
			summary: '',
			type: 'AGENCY',
			headerImage: null,
			profileImage: null
		}, 
		headerImages: [ // ImageSelector variable,
			'/images/groups/groupHeader.jpg'
		],
		profileImages: [ // ImageSelector variable,
			'/images/groups/groupProfile.jpg'
		]
	}
	
	// Step 1 .................................... 
	updateGroupInfo = (name, summary, type) => {
		let group = this.state.group
		group.name = name
		group.summary = summary
		group.type = type
		this.setState({ group:group })
		this.handleNext()
	}

	// Step 2 & 3 ................................
	updateImage = (img, src) => {
		let group = this.state.group
		group[img] = src
		  
		let images = []
		if (img === 'headerImage') {
			images = this.state.headerImages
		} else if (img === 'profileImage') {
			images = this.state.profileImages
		}
		if (!images.includes(src)) {
			images.push(src)
		}
		let name = img + 's'
		  
		this.setState({ group:group, [name]: images })
		this.handleNext()
	}

	// Step 4 .....................................
	handleCreateGroup = (cb) => {
		this.setState({loading:true})
	   this.props.createGroup(this.state.group)
		.then((groupId) => {
			this.setState({loading:false})
			swal.fire({
				position: 'top-end',
				type: 'success',
				title: 'Your group was created',
				showConfirmButton: true
		  	})
			this.handleNext()
		})
		.catch((error) => {
			this.setState({loading:false})
			swal.fire({
				position: 'top-end',
				type: 'error',
				title: error.title,
				text: error.message,
				showConfirmButton: true
		  	})
			cb()
		})
	}
  
	handleNext = () => { 
	   this.setState({activeStep: this.state.activeStep + 1 })
	}
	 
	handleBack = () => {
	   this.setState({activeStep: this.state.activeStep - 1 })
	}

	render() {
		const { loading, activeStep, group, headerImages, profileImages } = this.state
		return (
			<React.Fragment>
				<Loader loading={loading} />
			   <Paper square  style={{marginTop:20}}>
				   <div style={{padding:20}}>
				   	<Text>Welcome, lets get you started. Tell us about your Organization.</Text>
				   </div>
				   <Stepper activeStep={activeStep} orientation='vertical'>
						{/* [START]: STEP 1 - Details ::::::::::::::::::::: */}    
							<Step key={1}>
							<StepLabel>Organization Details</StepLabel>
							<StepContent>
								<Step1 
								   group={group}
									updateInfo={this.updateGroupInfo}
								/> 
							</StepContent>
							</Step>
						{/* [END]: STEP 1 - Details ::::::::::::::::::::::: */}

					   {/* [START]: STEP 2 - Header Image ::::::::::::::::::::: */}    
							<Step key={2}>
							<StepLabel>Header Image</StepLabel>
							<StepContent>
								<Step2 
								 	currentImage={group.headerImage}
								 	headerImages ={headerImages} 
								 	updateImage={this.updateImage}
								 	handleBack={this.handleBack}
								/>
							</StepContent>
							</Step>
					   {/* [END]: STEP 2 - Header Image ::::::::::::::::::::::: */}

						{/* [START]: STEP 3 - Profile Image ::::::::::::::::::::: */}    
						  <Step key={3}>
								<StepLabel>Profile Image</StepLabel>
								<StepContent>
									<Step3 
									 	currentImage={group.profileImage}
									 	profileImages ={profileImages} 
									 	updateImage={this.updateImage}
									 	handleBack={this.handleBack}
									/>
								</StepContent>
							</Step>
						{/* [END]: STEP 3 - Profile Image ::::::::::::::::::::::: */}

					   {/* [START]: STEP 4 - Review Info ::::::::::::::::::::: */}    
							<Step key={4}>
								<StepLabel>Review & Save</StepLabel>
								<StepContent>
								 	<Step4 
								 		group={group}
								 		handleBack={ this.handleBack }
								 		handleCreateGroup={ this.handleCreateGroup }
								 	/>
								</StepContent>
							</Step>
					   {/* [END]: STEP 4 - Review Info ::::::::::::::::::::::: */}

					   {/* [START]: STEP 5 - Next Step ::::::::::::::::::::: */}    
							<Step key={5}>
								<StepLabel>Next Steps</StepLabel>
								<StepContent>
								 	<Step5 />
								</StepContent>
							</Step>
					   {/* [END]: STEP 5 - Next Step ::::::::::::::::::::::: */}
					</Stepper>
				</Paper>
			</React.Fragment>
		)
	}
}
export default withRouter(Index)
