import React from 'react'
import Swal from 'sweetalert2'
import AddMethod from './AddMethod'
import Loader from '../../../components/Loader'

import Button from '@material-ui/core/Button'
import Collapse from '@material-ui/core/Collapse'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Paper from '@material-ui/core/Paper'
import Text from '@material-ui/core/Typography'

function PayoutMethods({userInfo, payOutMethods, getPayOutMethods, addPayOutMethod, deletePayOutMethod}){
	const [loading, setLoading] = React.useState(false)
	const [addMethod, setAddMethod] = React.useState(false)

	const handleDeletePayoutMethod = (id) => {
		Swal.fire({
			title: 'Delete Payout Method',
			text: 'Are you sure you want to delete this payout method?',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Yes, delete it!'
		}).then((result) => {
			if (result.value) {
				setLoading(true)
				deletePayOutMethod(id)
				.then(() => {
					setLoading(false)
				})
				.catch((error) => {
					setLoading(false)
					Swal.fire({
						title: 'Delete Payout Method',
						text: 'Sorry, there was an error deleting this payout method.',
						confirmButtonColor: '#3085d6',
						confirmButtonText: 'Ok'
					})
				})
			}
		})
	}
	return (
		<div style={{marginTop:30}}>
			<Loader loading={loading} />
			<Text variant='h5' color='textSecondary'>Payout Methods</Text>
			{(!payOutMethods || !Object.keys(payOutMethods).length) ? (
				<div align='center' style={{margin:0, padding:50, backgroundColor:'#f1f1f1'}}><Text variant='body1' color='textSecondary'>No Payout Methods Listed</Text></div>
			) : (
				<Paper>
					<List>
					{Object.keys(payOutMethods).map((i,key) => {
						return (
							<ListItem key={key}>
								<Grid container>
					     			<Grid item xs={12} sm={3}>
					     				<Text variant='caption' color='textSecondary'>{payOutMethods[i].methodType}</Text>
					     				<Text variant='body1'><b>{payOutMethods[i].methodName}</b></Text>
					     			</Grid>
					     			<Grid item xs={12} sm={6}>
					     				<Text variant='body1' color='primary'><b>{payOutMethods[i].accountName}</b></Text>
					     				<Text variant='body2' color='textSecondary'>{payOutMethods[i].accountEmail}</Text>
					     				{(payOutMethods[i].branchNumber) ? (
					     					<Text component='span' variant='body2' color='textSecondary'>{payOutMethods[i].branchNumber + ' - '}</Text>
					     				):( null )}
					     				<Text component='span' variant='body2' color='textSecondary'>{payOutMethods[i].accountNumber}</Text>
					     				{(payOutMethods[i].accountType) ? (
					     					<Text component='span' variant='body2' color='textSecondary'>{ ' (' + payOutMethods[i].accountType + ')'}</Text>
					     				):( null )}
					     			</Grid>
					     			<Grid item xs={12} sm={3} style={{textAlign:'right'}}>
					     				<Button onClick={() => { handleDeletePayoutMethod(i) }} size='small'>Delete This method</Button>
					     			</Grid>
					     			<Grid item xs={12}><Divider style={{margin:5}}/></Grid>
								</Grid>										
							</ListItem>
						)
					})}
					</List>
				</Paper>
			)}
			<div align='right'><Button size='small' color='secondary' onClick={() => {setAddMethod(!addMethod)}}>Add Payout Method</Button></div>
			<Collapse in={addMethod} timeout='auto' unmountOnExit>
				<div align='center' style={{backgroundColor:'#fafafa', padding:10, borderRadius:3}}>
					<AddMethod 
						userInfo={userInfo} 
						getPayOutMethods={getPayOutMethods} 
						addPayOutMethod={addPayOutMethod}
					/>
				</div>
			</Collapse>		
		</div>
	)
}
export default PayoutMethods
