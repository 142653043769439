import React from 'react'
import { Route, Redirect, Switch } from 'react-router-dom'
 
import AboutUs from './views-public/AboutUs'						
import Affiliates from './views-public/Affiliates'
import Blog from './views-public/Blog'				
import Board from './views-public/board/_Container'
import Boards from './views-public/Boards'	
import Campaigns from './views-public/Campaigns'	
import Charities from './views-public/Charities'						
import ContactUs from './views-public/ContactUs'				
import Email from './views-public/Email'							
import EmailConfirm from './views-public/EmailConfirm'		
import Faqs from './views-public/Faqs'									
import Fees from './views-public/Fees'								
import GetStarted from './views-public/GetStarted'				
import Help from './views-public/Help'
import Home from './views-public/Home'								
import HowItWorks from './views-public/HowItWorks'				
import Login from './views-public/Login'							
import LoginRedirect from './views-public/LoginRedirect'
import New from './views-public/New'		
import PageNotFound from './views-public/PageNotFound'		
import PaymentCard from './views-public/payments/Card'
import PaymentCredits from './views-public/payments/Credits'
import Press from './views-public/Press'
import Privacy from './views-public/Privacy'						
import Resources from './views-public/Resources'				
import Search from './views-public/Search'
import StepByStep from './views-public/stepbystep/_Container'						
import TermsOfService from './views-public/TermsOfService'	
import Templates from './views-public/templates/'	
import Tips from './views-public/Tips'	
import View from './views-public/View'								

function PublicRoutes({location, loggedIn}) {
	return (
		<Switch>
			<Route path='/login' render={(props) => loggedIn === true
				? <Redirect to={'/login-redirect' + location.search} /> 
				: <Login {...props} />
			}/>
			<Route path='/email/confirm/:type?' render={(props) => loggedIn === true
				? <Redirect to={'/login-redirect' + location.search} /> 
				: <EmailConfirm {...props} />
			}/>
			<Route path='/email/:type' render={(props) => loggedIn === true
				? <Redirect to={'/login-redirect' + location.search} /> 
				: <Email {...props} />
			}/>
			<Route path='/affiliates' render={(props) => <Affiliates {...props}
					loggedIn={loggedIn}
				/>}	
			/>
			<Route path='/start' render={(props) => <GetStarted {...props}
					loggedIn={loggedIn}
				/>}	
			/>
			<Route path='/' exact component={Home}/>
			<Route path='/board/:id?' component={Board}/>
			<Route path='/boards' component={Boards}/>
			<Route path='/aboutus' component={AboutUs}/>
			<Route path='/blog' component={Blog}/>
			<Route path='/campaigns/:type?/:filter?' component={Campaigns}/>
			<Route path='/charities' component={Charities}/>
			<Route path='/contactus' component={ContactUs}/>
			<Route path='/faqs' component={Faqs}/>
			<Route path='/fees' component={Fees}/>
			<Route path='/help' component={Help}/>
			<Route path='/howitworks' component={HowItWorks}/>
			<Route path='/login-redirect' component={LoginRedirect}/>
			<Route path='/payment/card' component={PaymentCard}/>
			<Route path='/payment/credits/:id?/:token?' component={PaymentCredits}/>
			<Route path='/press' component={Press}/>
			<Route path='/privacy' component={Privacy}/>
			<Route path='/resources' component={Resources}/>
			<Route path='/search/:value?' component={Search}/>
			<Route path='/termsofservice' component={TermsOfService}/>
			<Route path='/templates' component={Templates}/>
			<Route path='/tips' component={Tips}/>
			<Route path='/view/:id?' component={View}/>
			<Route path='/new/:id?' component={New}/>
			<Route path='/stepbystep/:id?' component={StepByStep}/>
			<Route path='/' component={PageNotFound}/>
		</Switch>
	)
}
export default PublicRoutes
