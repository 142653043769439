import React from 'react'
import moment from 'moment'
import Swal from 'sweetalert2'
import Loader from '../../components/Loader'

import { green } from '@material-ui/core/colors'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import IconArrowBack from '@material-ui/icons/ArrowBackIos'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import Paper from '@material-ui/core/Paper'
import Text from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'

function SupportDetails({ticketId, ticket, openTicketToggle, updateTicket, closeTicket, goToTab}) {
	const [loading, setLoading] = React.useState(false)
	const [post, setPost] = React.useState('')
	const [message, setMessage] = React.useState('')
	let pendingResponse = true

	const handleChange = (e) => {
		setMessage('')
		setPost(e.target.value)
	}
	const handleUpdateTicket = () => {
		if(post === ''){
			return setMessage('Please enter your post.')
		}
		setLoading(true)
		updateTicket(ticketId, post)
		.then(() => {
			setLoading(false)
			setMessage('')
			setPost('')
		})
		.catch((error) => {
			setLoading(false)
			setMessage(error)
		})
	}
	const handleCloseTicket = () => {
		Swal.fire({
			title: 'Close Ticket',
			text: 'Are you sure you want to close this ticket?',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Yes, close it!'
		}).then((result) => {
			if (result.value) {
				setLoading(true)
				closeTicket(ticketId)
				.then(() => {
					goToTab(0)
				})
				.catch((error) => {
					setLoading(false)
					setMessage(error.message)
				})
			}
		})
	}

	return (
		<Paper style={{ padding: 20, minHeight:800 }} >
			<Loader loading={loading} />
			<div style={{maxWidth:700, margin:'0px auto'}}>
				<div align='right' >
					<Button onClick={ () => {openTicketToggle('') }}>
						<IconArrowBack /> Back to List
					</Button>
				</div>

				<div style={{marginTop:5, padding: 5, border:'1px solid #ccc'}} >
					<List>
						<ListItem>
							<ListItemAvatar>
								<Avatar alt={ticket.groupName} src={ticket.groupImage} style={{borderRadius:5, width:70, height:70}} />
							</ListItemAvatar>
							<ListItemText style={{marginLeft:10, paddingTop:0, marginTop:0}}>
								<Text variant='body1'><b>{ticket.groupName}</b></Text>
								<Text style={{lineHeight:.8}} variant='caption' display='block' color='textSecondary'>
									{moment.unix(ticket.timestamp).format('MMM Do YYYY - h:mm a')}
								</Text>
								<Text variant='h6' style={{marginTop:5, lineHeight:1, color:'#546e7a'}}><b>{ticket.subject}</b></Text>
							</ListItemText>
							<ListItemSecondaryAction>
								{(ticket.closed) ? (
									<Text style={{lineHeight:1, color:'red'}}><b>CLOSED</b></Text>
								):(
									<Text style={{lineHeight:1, color:green[500]}}><b>OPEN</b></Text>
								)}
							</ListItemSecondaryAction>
						</ListItem>
					</List>
				</div>

				<div style={{marginTop:5, padding: 5, border:'1px solid #ccc'}} >

					<List>
					{Object.keys(ticket.thread).map((i, key) => {
						let t = ticket.thread[i]
						pendingResponse = (ticket.memberId === t.postedById) ? true : false
						return (
							<div key={key}>
								<ListItem alignItems="flex-start" style={{paddingBottom:50}}>
									<ListItemAvatar>
										<Avatar alt={t.postedByName} src={t.postedByImage} style={{borderRadius:5}} />
									</ListItemAvatar>
									<ListItemText>
										{t.postedByName}
										<Text style={{lineHeight:.8}} variant='caption' display='block' color='textSecondary'>
											{moment.unix(t.timestamp).format('MMM Do YYYY - h:mm a')}
										</Text>
										<Text style={{marginTop:15, lineHeight:1, color:'#333'}}>{t.comment}</Text>
									</ListItemText>
								</ListItem>
								<Divider/>
							</div>
						)
					})}
					</List>

					{(!ticket.closed && pendingResponse) ? (
						<div align="center" style={{padding:20}}>
							<Text variant="h6" style={{color:'red'}}><i>Pending Response</i></Text>
						</div>
					):( null )}

					{(!ticket.closed) ? (
					<div style={{padding:20, textAlign:'center'}}>
						<TextField
							style={{width:'100%', margin:'20px auto'}}
							id="post"
							label="Post"
							multiline
							rows={4}
							variant="outlined"
							value={post}
                		onChange={handleChange}
						/>
						<div style={{textAlign:'center'}}><Text variant="overline" style={{color:'red'}}>{message}</Text></div>
						<Button onClick={handleUpdateTicket} variant="contained" color="secondary" style={{marginBottom:20}}>Submit</Button>
						<Divider/>
					</div>
					):( 
						<div align="center" style={{padding:20}}>
							<Text variant="h6" color="textSecondary"><i>This ticket was closed by {ticket.closedByName}</i></Text>
							<Text style={{lineHeight:.8}} variant='caption' display='block' color='textSecondary'><i>
								{(ticket.closedTimestamp) ? moment.unix(ticket.closedTimestamp).format('MMM Do YYYY - h:mm a') : null}
							</i></Text>
						</div>
					)}

					<div style={{margin:5,textAlign:'right'}}>
						{(ticket.closed) ? (
							<Text style={{margin:10, color:'red'}}><b>CLOSED</b></Text>
						):(
							<Button onClick={handleCloseTicket}>Close this Ticket</Button>
						)}
					</div>

				</div>
			</div>
		</Paper>
	)
}
export default SupportDetails
