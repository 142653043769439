import React from 'react'
import { withRouter } from 'react-router-dom'

import Button from '@material-ui/core/Button'
import Text from '@material-ui/core/Typography'

function Step5 ({history}) {
		return (
			<div>
				<Text>Your Board was created and is now pending Admin approval.</Text>
				<Text>This should be complete within the next 24-48 hours.</Text>
				<Button onClick={()=> { history.push({ pathname: '/group/boards' }) }} variant='outlined' color='secondary' style={{marginTop:10}}>view group boards</Button>
			</div>
		)
}
export default withRouter(Step5)
