import React from 'react'

import Paper from '@material-ui/core/Paper'
import Text from '@material-ui/core/Typography'

function Donation() {
	return (
		<Paper style={{ padding: 20, minHeight:800 }} >
			<div align='center' style={{marginTop:50}}><Text variant='overline'>New Donation</Text></div>
		</Paper>
	)
}
export default Donation
