import React from 'react'
import queryString from 'query-string'
import { connect } from 'react-redux'
import { updateMemberInfo, updateMemberPassword } from '../../../redux/member/actions'

import SubNav from '../../components/SubNav'
import Password from './Password'
import Profile from './Profile'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'

class SetupContainer extends React.Component {
	qs = queryString.parse(this.props.location.search)
	state = {
		rURL: (this.qs.nextURL) ? this.qs.nextURL : '/',
		showPwBox: (this.qs.noPw) ? false : true
	}
	skip = () => {
		this.props.history.push({ pathname: this.state.rURL })
	}
	setPassword = (status) => {
		this.setState({ showPwBox:status })
	}
	render() { 
		const {showPwBox} = this.state
		const {memberInfo, updateMemberInfo, updateMemberPassword} = this.props
		if (!memberInfo) return null
		return (
			<React.Fragment>
				<Password showPwBox={showPwBox} setPassword={this.setPassword} updateMemberPassword={updateMemberPassword}/>
				<Container maxWidth='lg' style={{ padding: 10, marginBottom: 50 }}>
					<Grid container justify='center' spacing={2}>
						<Grid item xs={12} sm={12} md={10} >
							<SubNav text='Setup Your Profile' />
							<Profile skip={this.skip} setPassword={this.setPassword} memberInfo={memberInfo} updateMemberInfo={updateMemberInfo}/>
						</Grid>
					</Grid>
				</Container>
			</React.Fragment>
		)
	}
}
const mapStateToProps = (state) => ({
	memberInfo: state.auth.userInfo
})
const mapDispatchToProps = {
	updateMemberInfo,
	updateMemberPassword
}
export default connect(mapStateToProps, mapDispatchToProps)(SetupContainer)
