import React from 'react'
import moment from 'moment'
import swal from 'sweetalert2'
import { withRouter } from 'react-router-dom'

import Loader from '../../components/Loader'
import Step1 from './FundraiserStep1'
import Step2 from './FundraiserStep2'
import Step3 from './FundraiserStep3'
import Step4 from './FundraiserStep4'

import Paper from '@material-ui/core/Paper'
import Step from '@material-ui/core/Step'
import StepContent from '@material-ui/core/StepContent'
import StepLabel from '@material-ui/core/StepLabel'
import Stepper from '@material-ui/core/Stepper'
import Text from '@material-ui/core/Typography'

class Fundraiser extends React.Component {
	mounted = true
	state = { 
		loading: false,	
		campaignId: (this.props.id) ? this.props.id : null,
		campaignInfo: {
			id: (this.props.id) ? this.props.id : null,
			ownerType: (this.props.info) ? this.props.info.ownerType : 'member',
			type: (this.props.info) ? this.props.info.type : 'fundraiser',
			listed: (this.props.info) ? this.props.info.listed : true,			
			category: (this.props.info) ? this.props.info.category :  0,
			target: (this.props.info) ? this.props.info.target : 5000,
			stopAtTarget: (this.props.info) ? this.props.info.stopAtTarget : false,
			donationType: (this.props.info) ? this.props.info.donationType : 'any',
			donationFixedAmount: (this.props.info) ? this.props.info.donationFixedAmount : 100,
			deadline: (this.props.info) ? this.props.info.deadline : false,
			dateStart: (this.props.info) ? this.props.info.dateStart : moment(Date.now()).format('X'), // current date in seconds
			dateEnd: (this.props.info) ? this.props.info.dateEnd : moment(Date.now()).add(180, 'd').format('X'), // current date plus 180 days in seconds
			title: (this.props.info) ? this.props.info.title : '',
			about: (this.props.info) ? this.props.info.about : '',
			promoImage: (this.props.info) ? this.props.info.promoImage : '/images/campaigns/default.jpg', //null,
			thankyouMessage: (this.props.info) ? this.props.info.thankyouMessage : 'Thankyou for supporting me and contributing to my campaign. Your kindness is greatly appreciated.',
			thankyouEmail: (this.props.info) ? this.props.info.thankyouEmail : 'Thankyou for supporting me and contributing to my campaign. Your kindness is greatly appreciated.',	
			live: (this.props.info) ? this.props.info.live : true,
			featured: (this.props.info) ? this.props.info.featured : false
		}, 
		promoImages: [ // ImageSelector variable,
			'/images/campaigns/default.jpg'
		],
		activeStep: 0
	}
	
	componentWillUnmount = () => {
		this.mounted = false
	}

// Step #1 - handleUpdateCampaignSetup ................................
	handleUpdateCampaignSetup = (i) => {
		let campaign = this.state.campaignInfo
		campaign.target = i.target
		campaign.category = i.category
		campaign.title = i.title
		campaign.about = i.about
		campaign.promoImage = i.selectedPromo
		this.setState({ campaignInfo:campaign, promoImages:i.promos, activeStep:2 })
	}

// Step #2 - handleUpdateCampaignSettings ................................
	handleUpdateCampaignSettings = (i) => {
		let campaign = this.state.campaignInfo
		campaign.listed = i.listed
		campaign.stopAtTarget = i.stopAtTarget
		campaign.deadline = i.deadline
		campaign.dateEnd = i.dateEnd
		campaign.thankyouMessage = i.thankyouMessage
		campaign.thankyouEmail = i.thankyouEmail
		this.setState({ campaignInfo:campaign, activeStep:2 })
	}

// Step #3 - handleSetCampaignInfo ....................................
	handleSetCampaignInfo = () => {
		let _this = this
		this.setState({loading:true})
	   this.props.addEditFundraiser(this.state.campaignInfo)
		.then((id) => {
			if (_this.mounted) {
				_this.setState({loading:false, campaignId:id})
				swal.fire({
					position: 'top-end',
					icon: 'success',
					title: 'Your fundraiser was updated.',
					showConfirmButton: true
			  	})
				_this.handleNext()
			}
		})
		.catch((error) => {
			if (_this.mounted) {
				_this.setState({loading:false})
				swal.fire({
					title: error.title,
					text: error.message,
					position: 'top-end',
					icon: 'error',
					showConfirmButton: true
			  	})
			}
		})
	}

// ...................................................................
	handleGoTo = (step) => {
	   this.setState({activeStep: step })
	}
	handleNext = () => { 
	   this.setState({activeStep: this.state.activeStep + 1 })
	}

	render() {
		const { loading, campaignId, campaignInfo, promoImages, activeStep } = this.state

		return (
			<React.Fragment>
				<Loader loading={loading} />
			   <Paper square  style={{ marginTop:20, padding:0, minHeight:800}}>
				   <div style={{padding:20}}>
				   	<Text>
				   		Let's get started on your new campaign.
				   	</Text>
				   </div>
				   <Stepper activeStep={activeStep} orientation='vertical'>
						{/* [START]: STEP 1 - Setup ::::::::::::::::::::: */}    
							<Step key={1}>
							<StepLabel>Setup</StepLabel>
							<StepContent>
								<Step1 
									campaignInfo={campaignInfo}
									promoImages={promoImages}
									updateCampaignSetup={this.handleUpdateCampaignSetup}
								/> 
							</StepContent>
							</Step>
						{/* [END]: STEP 1 - Setup ::::::::::::::::::::::: */}

					   {/* [START]: STEP 2 - Settings ::::::::::::::::::::: */}    
							<Step key={2}>
							<StepLabel>Settings</StepLabel>
							<StepContent>
								<Step2 
									campaignInfo={campaignInfo}
									handleUpdateCampaignSettings={this.handleUpdateCampaignSettings}
								/>
							</StepContent>
							</Step>
					   {/* [END]: STEP 2 - Settings ::::::::::::::::::::::: */}

						{/* [START]: STEP 3 - Review & Save ::::::::::::::::::::: */}    
						  <Step key={3}>
								<StepLabel>Review & Save</StepLabel>
								<StepContent>
									<Step3
									campaignInfo={campaignInfo}
								 	handleGoTo={this.handleGoTo}
								 	handleSetCampaignInfo={this.handleSetCampaignInfo}
								/>
								</StepContent>
							</Step>
						{/* [END]: STEP 3 - Review & Save ::::::::::::::::::::::: */}

						{/* [START]: STEP 4 - Thankyou ::::::::::::::::::::: */}    
						  <Step key={4}>
								<StepLabel>Thankyou</StepLabel>
								<StepContent>
									<Step4 campaignId={campaignId} />
								</StepContent>
							</Step>
						{/* [END]: STEP 4 - Thankyou ::::::::::::::::::::::: */}

					</Stepper>
				</Paper>
			</React.Fragment>
		)
	}
}
export default withRouter(Fundraiser)
