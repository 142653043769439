import React from 'react'
import { connect } from 'react-redux'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Divider from '@material-ui/core/Divider'
import Text from '@material-ui/core/Typography'

function SplashScreen({currentVersion, version, live, splashTitle, splashMessage, splashFooter, buttonShow, buttonText, buttonLink}) {
	const [open, setOpen] = React.useState(false)
	   
	React.useEffect(() => {
		setOpen(!live)
	},[live])	

	const handleUpdateApp = () => {
		window.localStorage.removeItem('mData')
		setTimeout(document.location.reload(true), 500)
	}
	const handleButtonClick = () => {
		setOpen(false)
		console.log('button clicked')
	}

  	if (currentVersion !== version) {
		return (
		   <Dialog open={true} disableBackdropClick={true}>
		        <DialogContent>
		          <div align='center'>
		          	<Text variant='h6'>New Version Ready</Text>
		          	<Divider />
		          	<div style={{padding:10}}>
		          		<Text variant='body1'>
		          			A new version of letsFundIt is ready.<br/> 
		          			We've made a few updates to impove your experience.<br/>
		          			Please click update to continue.
		          		</Text>
		          	</div>
		          	<div><Text variant='caption' color='textSecondary'>Current Version: {currentVersion} - New Version: {version}</Text></div>
		          </div>
		        </DialogContent>
		        <DialogActions>
		          <Button onClick={handleUpdateApp} variant='contained' color='primary'>update</Button>
		        </DialogActions>
		   </Dialog>
		)
  	}

  	return (
	   <Dialog open={open} disableBackdropClick={true}>
	        <DialogContent>
	          <div align='center'>
	          	<Text variant='h6'>{splashTitle}</Text>
	          	<Divider />
	          	<div style={{padding:10}}><Text variant='body1'>{splashMessage}</Text></div>
	          	<div><Text variant='caption' color='textSecondary'>{splashFooter}</Text></div>
	          </div>
	        </DialogContent>
	        {(buttonShow) ? (
	        <DialogActions>
	          <Button onClick={handleButtonClick} variant='contained' color='primary'>{buttonText}</Button>
	        </DialogActions>
	        ) :  ( null )}
	   </Dialog>
  	)

}
const mapStateToProps = (state) => ({
	currentVersion: state.app.currentVersion,
	version: state.app.version,
	live: state.app.live,
	splashTitle: state.app.splashTitle,
	splashMessage: state.app.splashMessage,
	splashFooter: state.app.splashFooter,
	buttonShow: state.app.buttonShow,
	buttonText: state.app.buttonText,
	buttonLink: state.app.buttonLink
})
const mapDispatchToProps = {
}
export default connect(mapStateToProps, mapDispatchToProps)(SplashScreen)
