import React from 'react'
import { connect } from 'react-redux'
import { fetchMemberDonationsAndBookmarks  } from '../../../redux/member/actions'

import PageMessage from '../../components/PageMessage'
import NextUpdate from '../../components/NextUpdate'
import SubNav from '../../components/SubNav'
import Donations from './Donations'
import Bookmarked from './Bookmarked'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'

function TabPanel(props) {
  	const { children, value, index, ...other } = props;
  	return (
    	<div role='tabpanel' hidden={value !== index} id={`nav-tabpanel-${index}`} {...other} >
      	{value === index && ( children )}
    	</div>
  	)
}

class MemberDonationsContainer extends React.Component {
	mounted = true
	state = {
	 	pageLoading: true,
		pageTitle: undefined,	//Not Found',
		pageText: undefined,	//The campaign you are looking for was not found',
		activeTab: 0
  	}
	componentDidMount = () => {
		this.handleLoadDonationsAndBookmarks(false)
	}
	componentWillUnmount = () => {
		this.mounted = false
	}
	handleLoadDonationsAndBookmarks = (force) => {
	 	return new Promise((resolve, reject) => {
			let _this = this
		 	this.props.fetchMemberDonationsAndBookmarks(force)
		 	.then(() => {
		 		if(_this.mounted) {
		 			_this.setState({ pageLoading:false })
		 		}
		 	})	
		 	.catch((e) => {
		 		if(_this.mounted) {
		 			_this.setState({ pageLoading:false, pageTitle:e.title, pageText:e.message })
		 		}
		 	})
		 	.finally(() => {
		 		resolve()
		 	})		
	 	})
	}
  	handleChange = (event, newValue) => {
  		this.setState({activeTab:newValue})
  	}
  	render() {
  		const {pageLoading, pageTitle, pageText, activeTab} = this.state
    	const {donations, bookmarks, lastUpdated} = this.props

    	if(pageLoading || pageTitle) {
			return (<PageMessage pageLoading={pageLoading} pageTitle={pageTitle} pageText={pageText} />)
		}

    	return (
   		<React.Fragment>
   		<Container maxWidth='lg' style={{ padding: 10, marginBottom: 50 }}>
				<Grid container justify='center' spacing={2}>
					<Grid item xs={12} sm={12} md={10} >
						<SubNav text='Donations'/>
					
						<div style={{borderBottom: '2px solid #eee'}}>  
						  	<Tabs
						    	value={activeTab}
						    	onChange={this.handleChange}
						    	variant='fullWidth'
						    	indicatorColor='primary'
						    	textColor='primary'							    
						  	>
						    	<Tab label='Donations' />
						    	<Tab label='Bookmarked' />
						  	</Tabs>
						</div>

						<TabPanel value={activeTab} index={0}>
				        	<Donations donations={donations} />
				      </TabPanel>
				      <TabPanel value={activeTab} index={1}>
				        	<Bookmarked bookmarks={bookmarks} />
				      </TabPanel>

					</Grid>
				</Grid>
				<NextUpdate lastUpdated={lastUpdated} updateNow={this.handleLoadDonationsAndBookmarks}/>
			</Container>
   		</React.Fragment>
    	)
  	}
}
const mapStateToProps = (state) => ({
	donations: state.member.donations,
	bookmarks: state.member.bookmarks,
	lastUpdated: state.app.lastUpdated.memberDonationsAndBookmarks
})
const mapDispatchToProps = {
	fetchMemberDonationsAndBookmarks
}
export default connect(mapStateToProps, mapDispatchToProps)(MemberDonationsContainer)
