import React from 'react'
import { connect } from 'react-redux'
import { fetchGroupBoards, fetchDefaultCampaigns, updateDefaultCampaign } from '../../../redux/group/actions'

import PageMessage from '../../components/PageMessage'
import NextUpdate from '../../components/NextUpdate'
import SubNav from '../../components/SubNav'
import Boards from './Boards'
import Entries from './Entries'
import Members from './Members'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'

function TabPanel(props) {
  	const { children, value, index, ...other } = props;
  	return (
    	<div role='tabpanel' hidden={value !== index} id={`nav-tabpanel-${index}`} {...other} >
      	{value === index && ( children )}
    	</div>
  	)
}
class GroupBoardsContainer extends React.Component {
	mounted = true
	state = {
  		activeTab: 0,
		pageLoading: true,
		pageTitle: undefined,	//Not Found',
		pageText: undefined,		//The campaign you are looking for was not found',
		boardId: undefined,
		defaultCampaigns: []
  	}
  	componentDidMount = () => {
  		this.props.fetchDefaultCampaigns()
  		.then((campaigns) => {
  			this.setState({ defaultCampaigns:campaigns })
  		})
		this.handleFetchGroupBoards(false)
	}
  	componentWillUnmount = () => {
		this.mounted = false
	}
	handleChange = (event, newValue) => {
  		this.setState({activeTab:newValue})
  	}
	handleFetchGroupBoards = (force) => {
	 	return new Promise((resolve, reject) => {
		 	let _this = this
		 	this.props.fetchGroupBoards(force)
		 	.then(() => {
		 		if(_this.mounted) {
		 			_this.setState({ pageLoading:false })
		 		}
		 	})	
		 	.catch((e) => {
		 		if(_this.mounted) {
		 			_this.setState({ pageLoading:false, pageTitle:e.title, pageText:e.message })
		 		}
		 	})	
		 	.finally(() => {
		 		resolve()
		 	})		
	 	})
	}
	//getBoardInfo = (boardId, activeTab) => {
	//	this.setState({boardId:boardId, activeTab:activeTab})
	//}

  	render() {
  		const {activeTab, pageLoading, pageTitle, pageText, boardId, defaultCampaigns} = this.state
  		const {lastUpdated, boards, updateDefaultCampaign} = this.props

  		if(pageLoading || pageTitle) {
			return (<PageMessage pageLoading={pageLoading} pageTitle={pageTitle} pageText={pageText} />)
		}

    	return (
   		<React.Fragment>
   		<Container maxWidth='lg' style={{ padding: 10, marginBottom: 20 }}>
				<Grid container justify='center' spacing={2}>
					<Grid item xs={12} sm={12} md={10} >
						<SubNav text='Boards' buttonText='New Board' buttonLink='/group/board/'/>
				      <div style={{borderBottom: '2px solid #eee'}}>  
						  	<Tabs value={activeTab} onChange={this.handleChange} variant='fullWidth' indicatorColor='primary' textColor='primary'>
						    	<Tab label='Boards' />
						    	<Tab label='Entries' />
						    	<Tab label='Members' />
						  	</Tabs>
						</div>
						<TabPanel value={activeTab} index={0}>
				        	<Boards boards={boards} defaultCampaigns={defaultCampaigns} updateDefaultCampaign={updateDefaultCampaign}/>
				      </TabPanel>
				      <TabPanel value={activeTab} index={1}>
				        	<Entries boardId={boardId} />
				      </TabPanel>
				      <TabPanel value={activeTab} index={2}>
				        	<Members boardId={boardId}/>
				      </TabPanel>					
					</Grid>
				</Grid>
				<NextUpdate lastUpdated={lastUpdated} updateNow={this.handleFetchGroupBoards}/>
			</Container>
   		</React.Fragment>
    	)
  	}
}
const mapStateToProps = (state) => ({
	lastUpdated: state.app.lastUpdated.groupBoards,
	boards: state.group.boards,
})
const mapDispatchToProps = {
	fetchGroupBoards,
	fetchDefaultCampaigns,
	updateDefaultCampaign
}
export default connect(mapStateToProps, mapDispatchToProps)(GroupBoardsContainer)
