import React from 'react'
import moment from 'moment'
import { withRouter } from 'react-router-dom'
import {money, formattedNumber} from '../../../includes/functions'

import Alert from '@material-ui/lab/Alert'
import AlertTitle from '@material-ui/lab/AlertTitle'
import Avatar from '@material-ui/core/Avatar'
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionActions from '@material-ui/core/AccordionActions'
import Button from '@material-ui/core/Button'
import Collapse from '@material-ui/core/Collapse'
import CircularProgress from '@material-ui/core/CircularProgress'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import IconExpandMore from '@material-ui/icons/ExpandMore'
import Paper from '@material-ui/core/Paper'
import Text from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'

import IconButton from '@material-ui/core/IconButton'
import IconClose from '@material-ui/icons/Close'
import IconMinus from '@material-ui/icons/Remove';
import IconPlus from '@material-ui/icons/Add';
import IconDot from '@material-ui/icons/FiberManualRecord'
import IconInfo from '@material-ui/icons/InfoOutlined';

function Spots({currentSpot, minSpot, maxSpot, list}) {
	let spotsList = []
	for (var i = minSpot; i <= maxSpot; i++) {
		if (list[i]) {
			spotsList.push(<IconDot key={i} style={{color:list[i].color}}/>)
		} else {
			if(i < currentSpot) {
				spotsList.push(<IconDot key={i} style={{color:'gray'}}/>)
			} else {
				spotsList.push(<IconDot key={i} style={{color:'lightGray'}}/>)
			}	
		}
	}	
	spotsList.push(<IconDot key='x' style={{color:'lightGray'}}/>)
	spotsList.push(<IconDot key='y' style={{color:'lightGray'}}/>)
	spotsList.push(<IconDot key='z' style={{color:'lightGray'}}/>)
	return spotsList
}


function Boards({history, boards, fetchBoardDetails}) {
	const mounted = React.useRef(true)
	const open = React.useRef(false)
	const [expanded, setExpanded] = React.useState(false)
	const [loadingBoard, setLoadingBoard] = React.useState(false)
	const [data, setData] = React.useState('')
	const [error, setError] = React.useState('')
	const [openLedger, setOpenLedger] = React.useState(false)
 	const [spotDetails, setSpotDetails] = React.useState(false)
 	const [showInfoBox, setShowInfoBox] = React.useState(true)

	const goTo = (path) => {
      history.push({ pathname: path })
	}
	const handleChange = (panel) => (event, isExpanded) => {
  		if (isExpanded) {
  			open.current = true
     		setExpanded(panel)
  			handleLoadBoardData(panel)
  		} else {
  			open.current = false
  			setExpanded(false)
  		}
  	}
  	const handleClose = () => {
  		open.current = false
  		setExpanded(false)
  	}
  	const handleLoadBoardData = (id) => {
  		setError('')
  		setLoadingBoard(true)
  		fetchBoardDetails(id)
  		.then((data) => {
  			if (mounted.current && open.current) {
  				setData(data)
  				setLoadingBoard(false)
  				console.log(data)
  			}
  		})
  		.catch((e) => {
  			if (mounted.current) {
  				setError(e)
  				setLoadingBoard(false)
  				console.log(e)
  			}
  		})
  		
  	}

	if (!boards || !Object.keys(boards).length) { 
		return (
			<Paper style={{ padding: 20, minHeight:800 }} >
				<div align='center' style={{marginTop:20}}>
					<Text variant='body1' color='textSecondary'>No Boards Listed</Text>
				</div>
			</Paper>
		) 
	}

	return (
		<Paper style={{ padding: 10, minHeight:800 }} >
			{Object.keys(boards).map((i, key) => {
				return (
					<Accordion key={key} expanded={expanded === i} onChange={handleChange(i)}>
			        	<AccordionSummary	expandIcon={<IconExpandMore />}>
			        		<Avatar alt={boards[i].boardName} src={boards[i].boardImage} style={{borderRadius:5, margin:5, marginRight:10}} />
			        		<Grid container>
			        			<Grid item xs={12} sm={6} md={6} >
									<Text variant='h6' style={{marginTop:5, lineHeight:1.1}}>{boards[i].boardName}</Text>
										<Text variant='caption' display='block' color='textSecondary'>
											By: {boards[i].boardOwner}
										</Text>
			        			</Grid>
			        			<Grid item xs={6} sm={3} md={3} style={{textAlign:'center'}} >
			        				<Text variant='overline'>Used Spots</Text><br/>
			        				<Text variant='body1' color='primary'><b>{boards[i].totalSpotsUsed}</b></Text>
			        			</Grid>
			        			<Grid item xs={6} sm={3} md={3} style={{textAlign:'center'}} >
			        				<Text variant='overline'>Raised</Text><br/>
			        				<Text variant='body1' color='primary'><b>{money(boards[i].totalReceived)}</b></Text>
			        			</Grid>
			        		</Grid>
			        	</AccordionSummary>
			        	<AccordionDetails>
			        		{(error) ? (
								<div align='center' style={{marginTop:10, marginBottom:20, width:'100%'}}>
									<Text variant='h6'>{error.title}</Text>
									<Text variant='subtitle1'>{error.message}</Text>
								</div>
			        		) : (
			        			(loadingBoard) ? (
				        			<div align='center' style={{marginTop:20, width:'100%'}}>
										<CircularProgress color='secondary' style={{ margin: '5px auto'}} />
										<Text variant='h6'>Loading Board Details</Text>
										<Text variant='subtitle1'>Checking server for up to date board details.</Text>
									</div>
				        		):(
				        			<div style={{width:'100%'}}>
				        				
										<Grid container spacing={2}>
							     			<Grid item xs={12} sm={4}>
												<div style={{backgroundColor:'#f1f1f1', padding:5, textAlign:'center'}}>
													<Text variant='h5' component='span' color='primary'><b>{ money(data.totalDonated,0) } </b></Text>
													<Text variant='caption' component='span' color='textSecondary'><b> USD</b></Text><br/>
													<Text variant='body1' color='primary'><b>TOTAL DONATED</b></Text>
							     				</div>
							     			</Grid>
							     			<Grid item xs={12} sm={4}>
												<div style={{backgroundColor:'#f1f1f1', padding:5, textAlign:'center'}}>
													<Text variant='h5' component='span' color='primary'><b>{ formattedNumber(data.totalSpotsUsed,0) } </b></Text>
													<Text variant='body1' color='primary'><b>USED SPOTS</b></Text>
							     				</div>
							     			</Grid>
							     			<Grid item xs={12} sm={4}>
												<div style={{backgroundColor:'#f1f1f1', padding:5, textAlign:'center'}}>
													<Text variant='h5' component='span' color='primary'><b>{ money(data.totalRaised,0) } </b></Text>
													<Text variant='caption' component='span' color='textSecondary'><b> USD</b></Text><br/>
													<Text variant='body1' color='primary'><b>TOTAL RAISED</b></Text>
							     				</div>
							     			</Grid>
							     		</Grid>
							     		<div align='right' style={{margin:5, borderTop:'2px solid #f1f1f1'}}><Button  onClick={() => {setOpenLedger(!openLedger)}} size='small' color='secondary'>{(openLedger) ? 'hide board history' : 'view board history'}</Button></div>

							     		<Collapse in={openLedger} timeout='auto' unmountOnExit style={{maxWidth:700, margin:'0px auto'}}>
											<div style={{marginTop:30, marginBottom:50, padding:5, backgroundColor:'#f1f1f1'}}>
											{data.ledger && data.ledger.map((rec, key) => {
												return (
													<div key={key}>
														<Grid container style={{padding:4, backgroundColor:'#fff'}}>
											     			<Grid item xs={12} sm={4}>
											     				<Text variant='body1'><i>{moment.unix(rec.date).format('MMM Do YYYY - h:mm a')}</i></Text>
											     			</Grid>
											     			<Grid item xs={12} sm={5}>
											     				<Text variant='body1' color='primary' style={{lineHeight:1}}><b>{rec.description}</b></Text>
											     				<Text variant='caption' color='textSecondary'><i>{rec.note}</i></Text>
											     			</Grid>
											     			<Grid item xs={12} sm={3} style={{textAlign:'right', paddingRight:5}}>
											     				{(rec.type === 'plus') ? (
											     					<IconPlus style={{color:'green', marginBottom:-5, marginRight:5}} fontSize='small'/>
											     				) : (
											     					<IconMinus style={{color:'red', marginBottom:-5, marginRight:5}} fontSize='small'/>
											     				)}
											     				<Text variant='body1' component='span'><b>{ money(rec.amount,0) } </b></Text>
																<Text variant='caption' component='span' color='textSecondary'><b> USD</b></Text>
											     				<Tooltip title={rec.info} placement='top-end'>
											     					<IconInfo style={{color: 'lightGray', marginBottom:-5, marginLeft:5}} fontSize='small'/>
											     				</Tooltip>
											     			</Grid>
														</Grid>
														<div style={{height:1, margin:2}} />
													</div>
												)
											})}
											</div>
										</Collapse>

										<Collapse in={showInfoBox}>
										<div style={{margin:'0px auto', marginTop:20, marginBottom:20, maxWidth:600}}>
											<Alert severity='info' action={ <IconButton color='inherit' size='small' onClick={() => { setShowInfoBox(false) }}><IconClose fontSize='inherit' /></IconButton> }>
												{(data.totalSpotsAvailable) ? (
													<div>
														<AlertTitle style={{marginBottom:0}}>You currently have {data.totalSpotsAvailable} available {(data.totalSpotsAvailable > 1) ? 'spots' : 'spot'}.</AlertTitle>
															Go to the Feature Board to Register {(data.totalSpotsAvailable > 1) ? 'them' : 'it'} now.<br/>
															<Button onClick={() => { goTo('/board/'+data.boardId) }} style={{padding:0}} color='inherit' size='small'>click here to go</Button>
													</div>
												) : (
													<div>
														<AlertTitle style={{marginBottom:0}}>You currently have 0 available spots.</AlertTitle>
														Go to the Feature Board and make a donation to qualify for a spot.<br/>
														<Button onClick={() => { goTo('/board/'+data.boardId) }} style={{padding:0}} color='inherit' size='small'>click here to go</Button>
													</div>
												)}
											</Alert>
										</div>
										</Collapse>

										<Collapse in={spotDetails} timeout='auto' unmountOnExit style={{maxWidth:500, margin:'0px auto'}}>
											<div style={{marginTop:20, marginBottom:5, padding:5, backgroundColor:'#f1f1f1'}}>
											{data.spots && Object.keys(data.spots).map((i, key) => {
												return (
													<div key={key}>
														<Grid container style={{padding:4, backgroundColor:'#fff'}}>
											     			<Grid item xs={6} style={{textAlign:'right'}}>
											     				<Text variant='body1'><b>{data.spots[i].type}</b></Text>
											     			</Grid>
											     			<Grid item xs={6}>
											     				<IconDot style={{color:data.spots[i].color, textSize:'500px', marginBottom:-6, marginLeft:10, marginRight:5}}/>
											     				<Text variant='body1' component='span'><b>{data.spots[i].number}</b></Text>
											     			</Grid>
											     		</Grid>
											     		<div style={{height:1, margin:2}} />
											     	</div>
												)
											})}
								     		</div>
								     		<div style={{marginBottom:20}}>
								     			<Text color='textSecondary' variant='body2'>This is the Feature Board Chart for {data.boardName}, it lists the boards' current spot and all your spots on the board.</Text>
								     		</div>
										</Collapse>

										<div style={{width:'100%'}}>
											<Grid alignItems='flex-end' container style={{padding:4, backgroundColor:'#fff'}}>
								     			<Grid item xs={6}>
								     				<Text variant='h4' color='secondary'>Spots</Text>
								     			</Grid>
								     			<Grid item xs={6} style={{textAlign:'right'}}>
								     				<Button  onClick={() => {setSpotDetails(!spotDetails)}} size='small' color='secondary'>{(spotDetails) ? 'hide spots details' : 'view spots details'}</Button>
								     			</Grid>
								     		</Grid>
										</div>
										<div>
											{(data.maxSpot) ? (
												<div>
													<Spots currentSpot={data.currentSpot} minSpot={data.minSpot} maxSpot={data.maxSpot} list={data.spots} />
												</div>
											) : (
												<div align='center' style={{marginTop:20}}>
													<Text variant='body1' color='textSecondary'>You currently have 0 spots on this board.</Text>
												</div>
											)}
										</div>
				        			</div>
				        		)
			        		)}			        		
			        	</AccordionDetails>
			        	<Divider />
			        	<AccordionActions>
			        		<Button onClick={() => { handleLoadBoardData(i) }} size="small" variant='outlined'>update board data</Button>
			        		<Button onClick={handleClose} size='small' variant='contained' color='secondary' style={{marginLeft:20}}>Close</Button>
			        	</AccordionActions>
			      </Accordion>
				)
			})}
		</Paper>
	)
}
export default withRouter(Boards)
