import React from 'react'

import AppBar from '@material-ui/core/AppBar'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import OpenInNew from '@material-ui/icons/OpenInNew'
import Slide from '@material-ui/core/Slide'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Text from '@material-ui/core/Typography'
import withMobileDialog from '@material-ui/core/withMobileDialog'

class ServiceAndPolicies extends React.Component {
	state = {
	  open: false,
	  value: 0
	}

	handleOpen = (tab) => {
		let val = (tab >= 3) ? 0 : tab;
		this.setState({  open: true, value: val })
	}
	handleClose = () => {
		this.setState({ open: false })
	}
	handleChange = (event, value) => {
		this.setState({ value })
	}

	render() {
		let tabName = ['Terms of Service','Privacy Policy','Fees','I agree with the Terms of Service', 'Fees & Terms of Service']
		return (
			<React.Fragment>
			{(this.props.stepbystep) ? (
				<Button 
					style={{textTransform:'none', margin:2, padding:0, color:'#777'}} 
					onClick={() => {this.handleOpen(this.props.tab)}}
				>
					<b>Terms of Service & Fees</b> <OpenInNew style={{marginLeft:5, fontSize: 16}}/>
				</Button>
			) : (
				<Button 
					style={{fontSize: 12, padding:0, margin:0}} 
					size={(this.props.size) ? this.props.size : 'small'} 
					color={(this.props.color) ? this.props.color : 'default'}
					onClick={() => {this.handleOpen(this.props.tab)}}
				>
					{tabName[this.props.tab]} <OpenInNew style={{fontSize: 16}}/>
				</Button>
			)}
			<Dialog
				fullScreen={this.props.fullScreen}
				open={this.state.open}
				TransitionComponent={Transition}
				keepMounted
				onClose={this.handleClose}
			>
				<DialogContent style={{ padding:0, margin: 0}}>
					<AppBar position="static" color="default">
						<Tabs
						variant="fullWidth" 
						value={this.state.value}
						onChange={this.handleChange}
						indicatorColor="primary"
						textColor="primary"
						>
						<Tab label="Terms of Service" />
						<Tab label="Privacy Policy" />
						<Tab label="Fees" />
						</Tabs>
					</AppBar>
					<div style={{padding: 10}}>
						{(this.state.value === 0) ? (
							<TOS />
						) : ( null )}
						{(this.state.value === 1) ? (
							<PrivacyPolicy />
						) : ( null )}
						{(this.state.value === 2) ? (
							<Fees />
						) : ( null )}
					</div>
				</DialogContent>
				<DialogActions>
					<Button onClick={this.handleClose} color='primary'>Close</Button>
				</DialogActions>
			</Dialog>
			</React.Fragment>
		)
	}
}
export default withMobileDialog()(ServiceAndPolicies)

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction='up' ref={ref} {...props} />
})

const TOS = () => {
  return (
		<div>
			<p>COVID-19 NOTICE- As LetsFundIt looks to help individuals and businesses impacted by COVID-19, it is partnering 
			with various third parties to help Users cross promote campaigns in order to drive more support. Such third parties 
			that provide or facilitate links, tools, widgets or other features allow You to access other sites, services and 
			resources provided by such third parties but also allow such third parties to access campaigns and distribute 
			campaigns created on LetsFundIt without obtaining Your consent. By starting a LetsFundIt and applying to be part 
			of the various COVID-19 initiatives, You consent to your Campaign being shared on each such third party site and 
			You agree to be bound by their respective privacy policies and terms of service.</p>

			<p>Online Solutions Ltd provides its services (described below) to you through its LetsFundIt website and mobile 
			applications (the "Platform") and related services (collectively, such services, including any new features and 
				applications, and the Platform, the "Services"), subject to the following Terms of Service (as amended from time 
				to time, the "Terms of Service"). If you are a campaign organizer (as defined below), regardless of the country 
				of your domicile, you will be contracting with Online Solutions Ltd For purposes of the following Terms of 
				Service, “LetsFundIt,” “we,” “us,” “our,” and other similar terms, shall refer to the party with whom you are 
				contracting.</p>
			 
			<p>ARBITRATION AND CLASS ACTION WAIVER: EXCEPT AS OTHERWISE DESCRIBED IN THE DISPUTES SECTION BELOW, BY USING THE 
			SERVICES, YOU AGREE THAT DISPUTES BETWEEN YOU AND US WILL BE RESOLVED BY BINDING, INDIVIDUAL ARBITRATION AND YOU 
			WAIVE YOUR RIGHT TO PARTICIPATE IN A CLASS ACTION LAWSUIT OR CLASS-WIDE ARBITRATION. PLEASE REVIEW THE DISPUTES 
			SECTION CAREFULLY; BY ENTERING THIS AGREEMENT, YOU EXPRESSLY ACKNOWLEDGE THAT YOU HAVE READ AND UNDERSTAND ALL 
			OF THE TERMS OF THIS AGREEMENT.</p>
			 
			<p>We reserve the right, at our sole discretion, to change or modify portions of these Terms of Service at any time. 
			If we do this, we will endeavour to notify you, either through the Services user interface, in an email notification 
			or through other reasonable means, and unless otherwise stated, we may communicate with you officially by any 
			reasonable means now known or later developed. These currently include email, regular mail, and postings on the 
			Platform. Any such changes will become effective no earlier than two (2) days after they are posted, except that 
			(i) changes addressing new functions of the Services or changes made for legal reasons will be effective immediately, 
			nd (ii) changes or modifications to the provisions of these Terms of Service governing the Disputes section shall 
			be made as specified therein.</p>

			<p>Your continued use of the Services after the date any such changes become effective constitutes your 
			acceptance of the new Terms of Service. To the extent allowed by law, the English version of these Terms of 
			Service is binding and other translations are for convenience only. If you do not wish to accept the new Terms of 
			Service, you may discontinue your use of the Services.</p>

			<p>In addition, when using certain services, you will be subject to any additional terms applicable to such services 
			that may be posted on the Services from time to time, including, without limitation, the Privacy Policy located at 
			https://www.letsfundit.org/privacy. All such terms are hereby incorporated by reference into these Terms of Service 
			(provided, however, that such other terms may be modified in accordance with their terms and, with respect to the 
				use of the Services, these Terms of Service will take precedence in the event of conflict). All resolutions are 
			decided by us in our sole discretion, and all of our determinations are final.</p>

			<p>If you are in Europe, the Privacy Policy does not form part of these Terms of Service.</p>
			 
			<p><b>ACCESS AND USE OF THE SERVICES</b></p>
			<p>Services Description: The Services are offered as a platform to users of the Services, which may include Campaign 
			Organizers and Donors (each defined herein), entrants to Promotions (defined below) and other registered and 
			unregistered users of the Services (which may include users who simply "like" or "heart" Campaigns or otherwise 
				interact with the Platform or Services). Among other features, the Services are designed to allow a user (a 
				"Campaign Organizer") to post a fundraising campaign ("Campaign") to the Platform to accept monetary donations 
				("Donations") from those registered users wishing to contribute funds to the Campaign ("Donors"). For purposes 
				hereof, the term "Campaign Organizer" may also be deemed to include any individual(s) designated as a beneficiary 
				of a Campaign if the particular Campaign Organizer has specifically communicated to us that the beneficiary should 
				e so classified. Although there are no fees to set up a Campaign, payment processing and settlement fees apply to 
				each Donation. </p>
			 
			<p>Charitable Giving: You understand and acknowledge that we are not a professional charitable organization, 
			notwithstanding any Campaign Organizers that may qualify as an organization approved as a Charitable Body by the 
			President of Trinidad and Tobago under Section 6 (1) (g) of the Corporation Tax Act Chap.75:02.  As used in this 
			Agreement, the term "Campaign" does not refer to a charity, and you acknowledge that contributions to Campaigns are 
			not deductible under this jurisdiction’s applicable tax laws and regulations at this time.</p>
			 
			<p>The Services are a Platform; We are not a Broker, Financial Institution, Creditor or Charitable Institution: The 
			Services are an administrative platform only. LetsFundIt facilitates the Donation transaction between Campaign 
			Organizers and Donors, but is not a party to any agreement between a Campaign Organizer and a Donor. LetsFundIt is 
			not a broker, agent, financial institution, creditor or insurer for any user. LetsFundIt has no control over the 
			conduct of, or any information provided by, a Campaign Organizeror any other user, and LetsFundIt hereby disclaims 
			all liability in this regard to the fullest extent permitted by applicable law.</p>

			<p>All information and content provided by LetsFundIt through the Services is for informational purposes only, and 
			LetsFundIt does not guarantee the accuracy, completeness, timeliness or reliability of any such information or 
			content. No content is intended to provide financial, legal, tax or other professional advice. Before making any 
			decisions regarding any Campaigns, Donations, Donors, users or any products, services, information or content 
			relating to the Services, you should consult your financial, legal, tax advisers or other professional advisor as 
			appropriate. You acknowledge that all information and content accessed by you using the Services is at your own 
			risk.</p>

			<p>LetsFundIt does not guarantee that a Campaign will obtain a certain amount of Donations or any Donations at all. 
			We do not personally endorse any Campaign or Campaign Organizer and we make no guarantee, explicit or implied, that 
			any information provided through the Services by a user is accurate. We expressly disclaim any liability or 
			responsibility for the success of any Campaign, or the outcome of any fundraising purpose. You, as a Donor, must 
			make the final determination as to the value and appropriateness of contributing to any Campaign or Campaign 
			Organizer.</p>

			<p>All Donations are at your own risk. When you make a Donation to a Campaign or otherwise through the Services, it 
			is your responsibility to understand how your money will be used. LetsFundIt is not responsible for any offers, 
			promises, rewards or promotions made or offered by Campaigns or Campaign Organizers. We do not and cannot verify the 
			information that Campaign Organizers supply, nor do we guarantee that the Donations will be used in accordance with 
			any fundraising purpose prescribed by a Campaign Organizer. We assume no responsibility to verify whether the 
			Donations are used in accordance with any applicable laws; such responsibility rests solely with the Campaign 
			Organizer, as applicable. While we have no obligation to verify that the use of any funds raised is in accordance 
			with applicable law and these Terms of Service, we take possible fraudulent activity and the misuse of funds raised 
			very seriously. If you have reason to believe that a Campaign Organizer is not raising or using the funds for their 
			stated purpose, please use the "Report" button on the Campaign to alert us of this potential issue and we will 
			investigate.</p>
			 
			<p>You, as a Campaign Organizer, represent, warrant, and covenant that (i) all information you provide in connection 
			with a Campaign is accurate, complete, and not otherwise designed to mislead, defraud, or deceive any user; (ii) all 
			Donations contributed to your Campaign will be used solely as described in the materials that you post or otherwise 
			provide; (iii) you will comply with all applicable laws and regulations when you solicit funds, particularly, but not 
			imited to, laws relating to your marketing and solicitation for your project; and (iv) to the extent you share with us 
			any personal data of any third party for any purpose, including the names, email addresses and phone numbers of your 
			personal contacts, you have the authority (including any necessary consents), as required under applicable law, to 
			provide us with such personal data and allow us to use such personal data for the purposes for which you shared it 
			with us. You authorize LetsFundIt, and LetsFundIt reserves the right to, provide information relating to your 
			Campaign to donors and beneficiaries of your Campaign or law enforcement or to assist in any investigation.</p>
			 
			<p>Your Registration Obligations: You may be required to register with LetsFundIt in order to access and use certain 
			features of the Services. If you choose to register for the Services, you agree to provide and maintain true, 
			accurate, current and complete information about yourself as prompted by the Services' registration form. Campaign 
			Organizers must register using their true identities, including their name and any image or video purporting to 
			depict the Campaign Organizer or the beneficiary of such campaign. Registration data and certain other information 
			about you are governed by our Privacy Policy. If you are under 18 years of age, you are not authorized to use the 
			Services, with or without registering. Certain aspects of our Services may also require you to register with, and 
			agree to the terms of, third-party service providers (e.g., payment processors) in order to utilize such Services. 
			You may not resell, hire, or on any other basis, allow third parties to use the payment services to enable such 
			third parties to be paid for their services. You may not use the payment services for any different purpose than as 
			registered with our application. If LetsFundIt at any time discovers that the information you provided about you or 
			the purpose of your campaign is incorrect or changed without informing us or if you violate any of these conditions, 
			the services may be suspended and/or terminated with immediate effect and fines may be applied by the credit card 
			schemes and/or the authorities for unregistered use of payment services which will in such case be payable by you. 
			While we may help facilitate such registration in some cases in connection with payment processors with which 
			LetsFundIt has partnered, we are not a party to any such relationships and disclaim any responsibility or liability 
			for the performance by such third parties. Our processors may, in their sole discretion, require underwriting on any 
				account, and you agree to cooperate with all underwriting requests. We may exchange information with such 
			third-party services in order to facilitate the provision of Services (and related third party services) as set out 
			in our Privacy Policy.</p>

			 
			<p>Taxes: It is your sole responsibility to determine what, if any, taxes apply to the Donations you receive through 
			your use of the Services. It is solely your responsibility to assess, collect, report or remit the correct tax, 
			if any, to the appropriate tax authority.</p>
			 
			<p>Member Account, Password and Security: You are responsible for maintaining the confidentiality of your password 
			and account, if any, and are fully responsible for any and all activities that occur under your password or account. 
			You agree to (a) immediately notify LetsFundIt of any unauthorized use of your password or account or any other 
			breach of security, and (b) ensure that you sign out from your account at the end of each session when accessing the 
			Services. LetsFundIt will not be liable for any loss or damage arising from your failure to comply with this Section.</p>
			 
			<p>Modifications to Services: LetsFundIt reserves the right to modify, suspend or discontinue, temporarily or 
			permanently, the Services (or any part thereof). We will endeavor to give you notice of any such modification, 
			suspension or discontinuance. You agree that, to the extent permitted by law, LetsFundIt will not be liable to you 
			for any modification, suspension or discontinuance of the Services in accordance with these Terms of Service.</p>
			 
			<p>Public Content; Public Display of Donations: Some of your activity on and through the Services is public, such 
			as content you post publicly on the website or Services. Additionally, user profile information, including your first 
			and last name, public email address, organization, personal biography, and other information you enter in connection 
			with your user profile may be displayed to other users to facilitate user interaction within the Services. 
			For example, as a Donor, you have the option to publicly display your Donation for all to see, including on search 
			engines (like Google and Yahoo). To keep the details of your Donation private, simply click the “Anonymous” checkbox 
			during the Donation process. Please remember that if you choose to provide information using certain public 
			features of the Services, then that information is governed by the privacy settings of those particular features 
			and may be publicly available. Individuals reading such information may use or disclose it to other individuals or 
			entities without our control and without your knowledge, and search engines may index that information. We therefore 
			urge you to think carefully about including any specific information you may deem private in content that you create 
			or information that you submit through the Services. Please see our Privacy Policy for information on the ways that 
			we may collect, use, and store certain information about you and your use of the Services.</p>
			 
			<p>Promotions: You are not permitted to offer any contest, competition giveaway, sweepstakes or similar activity 
			(each, a "Promotion") on or through the Services. LetsFundIt reserves the right to suspend, cancel, or discontinue 
			any Campaign conducted via the Services at any time if it determined in its sole discretion that such Campaign or 
			Campaign Organizer is explicitly or implicitly offering a Promotion in contravention of these Terms of Service. </p>
			 
			<p>General Practices Regarding Use and Storage: You acknowledge that LetsFundIt may establish general practices and 
			limits concerning use of the Services, including without limitation the maximum period of time that data or other 
			content will be retained by the Services and the maximum storage space that will be allotted on LetsFundIt's servers 
			on your behalf. You agree that LetsFundIt has no responsibility or liability for the deletion or failure to store 
			any data or other content maintained or uploaded by the Services. You acknowledge that LetsFundIt reserves the right 
			to terminate accounts or Campaigns that are inactive for an extended period of time, provided that LetsFundIt will 
			use its commercially reasonable efforts to provide a Campaign Organizer with reasonable notice before terminating an 
			inactive Campaign having a positive balance of Donations. You further acknowledge that LetsFundIt reserves the 
			right to change these general practices and limits at any time, in its sole discretion. We will endeavor to give 
			you notice of any such change where required by applicable law.</p>
			 
			<p>Mobile Services: The Services include certain services that are available via a mobile device, including (i) the 
			ability to upload content to the Platform via a mobile device, (ii) the ability to browse the Platform from a mobile 
			device and (iii) the ability to access certain features through an application downloaded and installed on a mobile 
			device (collectively, the "Mobile Services"). To the extent you access the Services through a mobile device, your 
			wireless service carrier's standard charges, data rates and other fees may apply. In addition, downloading, 
			installing, or using certain Mobile Services may be prohibited or restricted by your carrier, and not all Mobile 
			Services may work with all carriers or devices. By using the Mobile Services, you agree that we may communicate 
			with you regarding LetsFundIt and other entities by SMS, MMS, text message or other electronic means to your mobile 
			device and that certain information about your usage of the Mobile Services may be communicated to us. We shall 
			comply with any additional requirements that may apply under local laws and regulations before communicating with 
			you in this manner. In the event that you change or deactivate your mobile telephone number, you agree to promptly 
			update your LetsFundIt account information to ensure that your messages are not sent to the person that acquires your
			 old number.</p>
			 
			<p><b>CONDITIONS OF USE</b></p>
			<p>User Conduct: You are solely responsible for all Campaign descriptions, comments, videos, images, information, 
			data, text, software, music, sound, photographs, graphics, messages or other materials ("content") that you upload, 
			post, publish or display (hereinafter, "upload") or transmit or otherwise use via the Services. You agree to fully 
			cooperate with any request by LetsFundIt for evidence it deems in its sole discretion is appropriate to verify your 
			compliance with these Terms of Service.</p>

			<p>The following are examples of the kind of content and/or use that is illegal or prohibited by LetsFundIt. However, 
			this list is not exhaustive and LetsFundIt reserves the right to investigate anyone by engaging public and private 
			organizations, including, but not limited to collection agents, private investigators, and any local and/or applicable 
			international agencies, and take appropriate action against anyone who, in LetsFundIt's sole discretion, violates 
			any of the terms or spirit of these Terms of Service (or the principles described in our preamble hereto), including, 
			without limitation, removing the offending content, suspending or terminating the account of such violators, 
			stopping payments to any such Campaign, freezing or placing a hold on donated funds when LetsFundIt reasonably 
			believes it to be required by applicable law, and reporting you to law enforcement authorities or otherwise taking 
			appropriate legal action including seeking restitution on behalf of itself and/or its users. Without limiting the 
			foregoing, you agree to not use the Services to:</p>

			<p>in connection with any of the following activities or items, including by establishing or contributing to any 
			Campaign with the implicit or express purpose relating to any of the following:</p>

			<p>the raising of debt or equity funding for any incorporated for profit entity for which the raising of such funds 
			could be construed to be governed by the Securities Act, 2012, the Securities (Amendment) Act, 2014 and/or the 
			Securities (General) By-Laws, 2015;</p>

			<p>any activity that violates any law or governmental regulation industry requirements, or third-party guidelines or 
			agreements to which you are a party, including from payment card providers and payment processors that you utilize 
			in connection with the Services;</p>

			<p>content or campaigns that are fraudulent, misleading, inaccurate, dishonest, impossible or imitating any other 
			person or fundraising campaign (whether on the Platform or not);</p>

			<p>illegal drugs, narcotics, steroids, controlled substances, pharmaceuticals or other products that make health 
			claims that have not been approved or verified by the applicable local and/or national regulatory body, legal 
			substances that provide the same effect as an illegal drug or other products that present a risk to consumer safety 
			or any related equipment or paraphernalia;</p>

			<p>knives, explosives, ammunition, firearms, or other weaponry or accessories;</p>

			<p>annuities, investments, equity or lottery contracts, lay-away systems, off-shore banking or similar 
			transactions, money service businesses (including currency exchanges, check cashing or the like), pyramid schemes, 
			“get rich quick schemes” (i.e., Investment opportunities or other services that promise high rewards), network 
			marketing and referral marketing programs, debt collection or crypto-currencies;</p>

			<p>gambling, gaming and/or any other activity with an entry fee and a prize, including, but not limited to casino 
			games, sports betting, fantasy sports, horse or greyhound racing, lottery tickets, raffle tickets, auctions and 
			other ventures that facilitate gambling, games of skill or chance (whether or not it is legally defined as a 
				lottery) or sweepstakes;</p>

			<p>campaigns deemed by LetsFundIt, in its sole discretion, to be in support of, or for the legal defense of alleged 
			crimes associated with hate, violence, harassment, bullying, discrimination, terrorism, or intolerance of any kind 
			relating to race, ethnicity, national origin, religious affiliation, sexual orientation, sex, gender or gender 
			identity, or serious disabilities or diseases;</p>

			<p>activities with, in, or involving countries, regions, governments, persons, or entities that are subject to U.S. 
			and other economic sanctions under applicable law, unless such activities are expressly authorized by the 
			appropriate governmental authority;</p>

			<p>funding a ransom, human trafficking or exploitation, vigilantism, bribes or bounty;</p>

			<p>pornography or other sexual content;</p>

			<p>offensive, graphic, perverse or sensitive content;</p>

			<p>offering monetary rewards, including gift cards;</p>

			<p>transactions for the sale of items before the seller has control or possession of the item;</p>

			<p>collection of payments on behalf of merchants by payment processors or otherwise; including but not limited to 
			self-payments on campaigns or an attempt to bypass or otherwise circumvent the designated method of payment as 
			provided by LetsFundIt;</p>

			<p>credit repair or debt settlement services;</p>

			<p>to receive or grant cash advances or lines of credit to yourself or to another person for purposes other than 
			those purposes clearly stated in the Campaign;</p>

			<p>publication or removal of content (such as mug shots), where the primary purpose of posting such content is to 
			cause or raise concerns of reputational harm;</p>

			<p>sale or resale of a service without added benefit to the buyer; resale of government offerings without 
			authorization or added value;</p>

			<p>aggregation of funds owed to third parties, factoring, or other activities intended to obfuscate the origin of 
			funds;</p>

			<p>counterfeit music, movies, software, or other licensed materials without the appropriate authorization from the 
			rights holder;</p>

			<p>products or services that directly infringe or facilitate infringement upon the trademark, patent, copyright, 
			trade secrets, or proprietary or privacy rights of any third party;</p>

			<p>unauthorized sale or resale of brand name or designer products or services;</p>

			<p>sale of goods or services that are illegally imported or exported;</p>

			<p>processing where there is no bona fide donation accepted; card testing; evasion of card network chargeback 
			monitoring programs;</p>

			<p>collecting or providing funds for any purpose other than as described in a Campaign description; or
			any other activity that LetsFundIt may deem in its sole discretion to be unacceptable.</p>

			<p>to transmit or otherwise upload any content that (i) infringes any intellectual property or other proprietary 
			rights of any party; (ii) you do not have a right to upload under any law or under contractual or fiduciary 
			relationships; (iii) contains software viruses or any other computer code, files or programs designed to interrupt, 
			destroy or limit the functionality of any computer software or hardware or telecommunications equipment; (iv) 
			poses or creates a privacy or security risk to any person; (v) constitutes unsolicited or unauthorized advertising, 
			promotional materials, commercial activities and/or sales, "junk mail," "spam," "chain letters," "pyramid schemes," 
			"contests," "sweepstakes," or any other form of solicitation; or (vi) in the sole judgment of LetsFundIt, is 
			objectionable or which restricts or inhibits any other person from using or enjoying the Services, or which may 
			expose LetsFundIt or its users to any harm or liability of any type;</p> 

			<p>or to interfere with or disrupt the Services or servers or networks connected to the Services, or disobey any 
			requirements, procedures, policies or regulations of networks connected to the Services;</p> 

			<p>or to harvest or collect email addresses or other contact information of other users from the Services by 
			electronic or other means.</p>

			<p>to raise funds for a minor without the express permission of the minor’s guardian unless the funds are transferred
			 into a trust account for the sole benefit of the minor.</p>

			<p>Additionally, with respect to all Donations you make or accept through the Services, you agree:</p>

			<p>not to make or accept any donations that you know or suspect to be erroneous, suspicious or fraudulent;</p>

			<p>not to use the Services in or for the benefit of a country, organization, entity, or person embargoed or blocked 
			by any government, including those on sanctions lists identified by the United States Office of Foreign Asset 
			Control (OFAC);</p>

			<p>to maintain reasonable and standard security measures to protect any information transmitted and received through 
			the Services, including without limitation by adhering to any security procedures and controls required by LetsFundIt 
			from time to time;</p>

			<p>to maintain a copy of all electronic and other records related to Campaigns and Donations as necessary for 
			LetsFundIt to verify compliance with these Terms of Service, and make such records available to LetsFundIt upon 
			our request. For clarity, the foregoing does not affect or limit your obligations to maintain documentation as 
			required by applicable laws, rules, regulations, or governmental authority;</p> 

			<p>and at LetsFundIt’s request, including without limitation in case of investigations by LetsFundIt, a payment 
			processing partner, or a regulatory or governmental authority, fully cooperate in the auditing of such records, 
			investigation of the relevant circumstances and remedy of any uncovered violation or wrongdoing.</p>

			<p>LetsFundIt reserves the right to refuse, condition, or suspend any Donations or other transactions that we 
			believe in our sole discretion may violate the Terms of Service or harm the interests of our users, business 
			partners, the public, or LetsFundIt, or that expose you, LetsFundIt, or others to risks unacceptable to us. We may 
			share any information related to your use of the Services with the appropriate financial institution, regulatory 
			authority, or law enforcement agency consistent with our legal obligations. This information may include information 
			bout you, your account, your Donors, your Donations, and transactions made through or in connection with your use of 
			the Services.</p>
			 
			<p>Donations: In order to contribute to a Campaign, a Donor will be required to provide LetsFundIt information 
			regarding its credit card or other payment instruments, including cash, (“Payment Instrument”) that is linked to the 
			onor’s account on the Services (a “Billing Account”). You, as a Donor, represent and warrant to LetsFundIt that such 
			nformation is true and that you are authorized to use the applicable Payment Instrument. You agree that a certain 
			minimum Donation amount may apply and that all Donation payments are final and will not be refunded unless 
			LetsFundIt, in its sole discretion, agrees to issue a refund. LetsFundIt uses third-party payment processing 
			partners to bill you through your Payment Instrument and/or Billing Account for any Donations made, and Donors 
			acknowledge that by contributing a Donation to a Campaign, the Donor is agreeing to any and all applicable terms 
			set forth by our payment partners (currently, Stripe and PayPal), in addition to these Terms of Service, including 
			Stripe's terms of service and PayPal’s terms of service. Note that for all debit and credit card payments, LetsFundIt 
			uses another third-party provider, Online Solutions Ltd ("BGI"), as an agent for card acquiring and all payments 
			made using this payment channel will reflect on a Donor's card statement as Online Solutions Ltd</p>
			 
			<p>Account Holds: From time to time, LetsFundIt may place a hold on a Campaign account (a "Hold"), restricting 
			Withdrawals (defined herein) by a Campaign Organizer. Some of the reasons that we may place a Hold on a Campaign 
			Account include the following: (i) if we have reason to believe (in our sole discretion) that information provided 
			by a Campaign Organizer is false, misleading, or fraudulent, or that funds are being used in a prohibited manner, 
			(ii) if the funds available should be provided directly to a person other than the Campaign Organizer (such as a 
				legal beneficiary or person entitled by law to act on behalf of a Campaign Organizer), (iii) if we have reason 
			to believe that a Campaign or Campaign Organizer has violated these Terms of Service, or (iv) if LetsFundIt 
			determines that the Campaign Organizer is colluding with donors to engage in fraudulent activity or (v) if 
			required in order to comply with a court order, subpoena, writ, injunction, or as otherwise required under 
			applicable laws and regulations. If you have questions about a Hold we may have placed on your Campaign account, 
			or need information about how to resolve the Hold, please contact us at admin@letsfundit.org.</p>
			 
			<p>Rewards: Reward Levels are not intended to be items available for purchase and LetsFundIt cannot guarantee the 
			delivery of Reward Levels in any way.</p>
			 
			<p>Withdrawing Donations from a Campaign: You, as a Campaign Organizer (or, as applicable, the beneficiary designated by 
				the Campaign), may withdraw Donations to your Campaign at any time up to the full amount of all Donations credited 
			to your Campaign, less any applicable fees and any previously withdrawn amounts ("Withdrawals") and subject to any 
			Holds that we or our payment partners may place on your Campaign account. A Campaign Organizer may withdraw 
			Donations by sending an email request to admin@letsfundit.org following which the Campaign Organizer will recieve 
			email confirmation of its request and advice on the estimated timeframe for payment and the information required 
			to receive payout, which will be via Automated Clearing House ("ACH") direct credit payment. While LetsFundIt 
			strives to make Withdrawals available to you promptly, you acknowledge and agree that Withdrawals may not be 
			available to you for use immediately, and LetsFundIt does not guarantee that Withdrawals will be available to 
			you within any specific time frame, and LetsFundIt expressly disclaims any and all responsibility for any delay 
			or inability to access and use Withdrawals at any specified time, and any consequences arising from such delay 
			or inability. You, as a Campaign Organizer, are responsible for ensuring that the information you provide to 
			LetsFundIt in order to process a Withdrawal, is accurate and up to date. LetsFundIt may, at any time, for any 
			reason, and in its sole discretion, offer or issue a refund of donation(s) with or without consulting with you, 
			which may comprise the entire amount donated to your campaign. LetsFundIt is not responsible for any consequences 
			caused by LetsFundIt issuing refunds, including, but not limited to transaction or overdraft fees. You also 
			acknowledge that LetsFundIt is not liable to compensate you in any way for any loss.</p>
			 
			<p>Special Notice for International Use; Export Controls: Software (defined below) available in connection with 
			the Services and the transmission of applicable data, if any, may be subject to export controls and economic 
			sanctions laws of the United States or other jurisdictions. No Software may be downloaded from the Services or 
			otherwise exported or re-exported in violation of such export control and economic sanctions laws. Downloading 
			or using the Software is at your sole risk. Recognizing the global nature of the Internet, you agree to comply 
			with all local rules and laws regarding your use of the Services, including as it concerns online conduct and 
			acceptable content.</p>
			 
			<p><b>INTELLECTUAL PROPERTY RIGHTS</b></p>

			<p>Services Content, Software and Trademarks: You acknowledge and agree that the Services may contain content or 
			features ("Services Content") that are protected by copyright, patent, trademark, trade secret or other proprietary 
			rights and laws. Except as expressly authorized by LetsFundIt, you agree not to modify, copy, frame, scrape, rent, 
			lease, loan, sell, distribute or create derivative works based on the Services or the Services Content, in whole or 
			in part, except that the foregoing does not apply to your own User Content (as defined below) that you legally 
			upload to the Services. In connection with your use of the Services you will not engage in or use any data mining, 
			robots, scraping or similar data gathering or extraction methods. If you are blocked by LetsFundIt from accessing 
			the Services (including by blocking your IP address), you agree not to implement any measures to circumvent such 
			blocking (e.g., by masking your IP address or using a proxy IP address). Any use of the Services or the Services 
			Content other than as specifically authorized herein is strictly prohibited. The technology and software underlying 
			the Services or distributed in connection therewith are the property of LetsFundIt, our affiliates and our partners 
			(the "Software"). You agree not to copy, modify, create a derivative work of, reverse engineer, reverse assemble 
			or otherwise attempt to discover any source code, sell, assign, sublicense, or otherwise transfer any right in 
			the Software. Any rights not expressly granted herein are reserved by LetsFundIt.</p>

			<p>The LetsFundIt name and logos are trademarks and service marks of LetsFundIt (collectively the "LetsFundIt 
				Trademarks"). Other company, product, and service names and logos used and displayed via the Services may be 
			trademarks or service marks of their respective owners, who may or may not endorse or be affiliated with or 
			connected to LetsFundIt. Nothing in these Terms of Service or the Services should be construed as granting, by 
			implication, estoppel, or otherwise, any license or right to use any of LetsFundIt Trademarks displayed on the 
			Services, without our prior written permission in each instance. All goodwill generated from the use of LetsFundIt 
			Trademarks will inure to our exclusive benefit.</p>
			 
			<p>Third-Party Material: Under no circumstances will LetsFundIt be liable in any way for any content or materials 
			of any third parties (including users and Campaign Organizers) or any User Content (defined below, including, but 
				not limited to, for any errors or omissions in any content, or for any loss or damage of any kind incurred as a 
				result of the use of any such content. You acknowledge that LetsFundIt does not pre-screen content, but that 
				LetsFundIt and its designees will have the right (but not the obligation) in their sole discretion to refuse, 
				remove, or allow any content that is available via the Services. Without limiting the foregoing, LetsFundIt and 
				its designees will have the right to remove any content that violates these Terms of Service or is deemed by 
				LetsFundIt, in its sole discretion, to be otherwise objectionable.</p>
			 
			<p>User Content Transmitted Through the Services: With respect to the content, photos, videos, images, trademarks, 
			logos, brands or other materials you upload or post through the Services or share with other users or recipients 
			(collectively, "User Content"), you represent and warrant that you own all right, title and interest in and to, 
			or otherwise have all necessary rights and consents to (and to allow others to) fully exploit, such User Content, 
			including, without limitation, as it concerns all copyrights, trademark rights and rights of publicity or privacy 
			related thereto. By uploading, sharing, providing, or otherwise making available any User Content, or any portion 
			thereof, in connection with the Services, you hereby grant and will grant LetsFundIt and its affiliated companies 
			a nonexclusive, worldwide, royalty free, fully paid up, transferable, sublicensable, perpetual, irrevocable license 
			to copy, display, upload, perform, distribute, store, modify and otherwise use your User Content in connection with 
			the operation of the Services or the promotion, advertising or marketing thereof, in any form, medium or technology 
			now known or later developed. Without limiting the foregoing, if any User Content contains your name, image or 
			likeness, you hereby release and hold harmless LetsFundIt and its contractors and employees, from (i) all claims 
			for invasion of privacy, publicity or libel, (ii) any liability or other claims by virtue of any blurring, 
				distortion, alteration, optical illusion, or other use or exploitation of your name, image or likeness, 
			and (iii) any liability for claims made by you (or any successor to any claim you might bring) in connection with 
			your User Content, name, image or likeness. You waive any right to inspect or approve any intermediary version(s) 
			or finished version(s) of the results of the use of your User Content (including your name, image or likeness). 
			Further, if any person (other than you) appears in your User Content, you represent and warrant that you have secured 
			all necessary licenses, waivers and releases from such person(s) for the benefit of LetsFundIt in a manner fully 
			consistent with the licenses, waivers and releases set forth above. You further acknowledge that your participation 
			in the Services and submission of User Content is voluntary and that you will not receive financial compensation of 
			any type associated with the licenses, waivers, and releases set forth herein (or LetsFundIt's exploitation thereof), 
			and that the sole consideration for subject matter of this agreement is the opportunity to use the Services.</p>

			<p>We do not guarantee that any Services Content will be made available through the Services. We reserve the right 
			to, but do not have any obligation to, (i) remove, edit or modify any Services Content or User Content, in our sole 
			discretion, at any time, without notice to you and for any reason (including, but not limited to, upon receipt of 
				claims or allegations from third parties or authorities relating to such Services Content or User Content, or 
				if we are concerned that you may have violated these Terms of Service), or for no reason at all and (ii) to remove 
			or block any Services Content or User Content from the Services.</p>

			<p>You acknowledge and agree that any questions, comments, suggestions, ideas, feedback or other information about 
			the Services ("Submissions"), provided by you to LetsFundIt are non-confidential and LetsFundIt will be entitled to 
			the unrestricted use and dissemination of these Submissions for any purpose, commercial or otherwise, without 
			acknowledgment or compensation to you.</p>

			<p>You acknowledge and agree that LetsFundIt may preserve content and may also disclose content if required to do 
			so by law or in the good-faith belief that such preservation or disclosure is reasonably necessary to (a) comply 
			with legal process, applicable laws or government requests; (b) enforce these Terms of Service; (c) respond to 
			claims that any content violates the rights of third parties; or (d) protect the rights, property, or personal 
			safety of LetsFundIt, its users or the public.</p>
			 
			<p>Payment Card Industry Data Security Standard: The Payment Card Industry Data Security Standard (PCI DSS) is a set 
			of industry-mandated requirements for any business that handles, processes, or stores credit cards. The primary 
			purpose of the standards is to maintain controls around cardholder data to reduce credit card fraud. As a service 
			provider, LetsFundIt, through its payment processing partner(s), is PCI DSS compliant and expects its payment 
			processing partner(s) will maintain all applicable PCI DSS requirements to the extent that its payment processing 
			partner(s) possess or otherwise store, process, or transmit cardholder data on behalf of you, or to the extent that 
			we can in any way impact the security of your cardholder data environment.</p>
			 
			<p>Copyright Complaints: LetsFundIt respects the intellectual property of others, and we ask our users to do the 
			same. If you believe that your work has been copied in a way that constitutes copyright infringement, or that your 
			intellectual property rights have been otherwise violated, you should notify LetsFundIt of your infringement 
			claim in accordance with the procedure set forth below.</p>

			<p>LetsFundIt will process and investigate notices of alleged infringement and will take appropriate actions under 
			the applicable intellectual property laws with respect to any alleged or actual infringement. A notification of 
			claimed copyright infringement should be emailed to LetsFundIt at admin@letsfundit.org (Subject line: "IP Takedown 
				Request"). You may also contact us by mail at:</p>

			<p>LetsFundIt:<br/>
			Petra Street<br/>
			Woodbrook,<br/>
			Trinidad, W.I.</p>
			 
			<p>To be effective, the notification must be in writing and contain the following information:</p>

			<p>a physical signature of the person authorized to act on behalf of the owner of the copyright or other 
			intellectual property interest;</p>

			<p>a description of the copyrighted work or other intellectual property that you claim has been infringed;</p>

			<p>a description of where the material that you claim is infringing is located on the Services, with enough detail 
			that we may find it on the Services;</p>

			<p>your address, telephone number, and email address;</p>

			<p>a statement by you that you have a good faith belief that the disputed use is not authorized by the copyright or 
			intellectual property owner, its agent, or the law;</p> 

			<p>and a statement by you, made under penalty of perjury, that the above information in your notice is accurate and 
			that you are the copyright or intellectual property owner or authorized to act on the copyright or intellectual 
			property owner's behalf.</p>
			 
			<p>Counter-Notice: If you believe that your User Content that was removed (or to which access was disabled) is not 
			infringing, or that you have the authorization from the copyright owner, the copyright owner's agent, or pursuant 
			to the law, to upload and use the content in your User Content, you may send a written counter-notice containing 
			the following information to LetsFundIt at the above-listed contact details:</p>

			<p>your physical signature;</p>

			<p>identification of the content that has been removed or to which access has been disabled and the location at 
			which the content appeared before it was removed or disabled;</p>

			<p>a statement that you have a good-faith belief that the content was removed or disabled as a result of mistake or 
			a misidentification of the content; and</p>

			<p>your name, address, telephone number, and email address, and a statement that you will accept service of process 
			from the person who provided notification of the alleged infringement.</p>

			<p>If a counter-notice is received by LetsFundIt, we will send a copy of the counter-notice to the original 
			complaining party, informing that person that it may replace the removed content or cease disabling it in 10 
			business days. Unless the copyright owner files an action seeking a court order against the content provider, 
			member or user, the removed content may be replaced, or access to it restored, in 10 to 15 business days or more 
			after receipt of the counter-notice, at our sole discretion.</p>
			 
			<p>Repeat Infringer Policy: In accordance with applicable intellectual property laws, LetsFundIt has adopted a 
			policy of terminating, in appropriate circumstances and at LetsFundIt's sole discretion, users who are deemed to be 
			repeat infringers. LetsFundIt may also at its sole discretion limit access to the Services and/or terminate the 
			memberships of any users who infringe any intellectual property rights of others, whether or not there is any repeat 
			infringement.</p>
			 
			<p><b>THIRD-PARTY WEBSITES/SERVICES</b></p>

			<p>The Services or third parties may provide or facilitate links, tools, widgets or other features that allow you 
			to access other sites, services and resources provided by third parties (collectively, “Third Party Resources”). 
			LetsFundIt has no control over such Third Party Resources or any products, services or content made available 
			through or by such Third Party Resources, or the business practices of the third parties providing such Third Party 
			Resources, and LetsFundIt is not responsible for and does not endorse such Third Party Resources or the products, 
			services or content made available thereby. You acknowledge that LetsFundIt is not responsible or liable for the 
			content, functions, accuracy, legality, appropriateness or any other aspect of such Third Party Resources. 
			You further acknowledge and agree that LetsFundIt will not be responsible or liable, directly or indirectly, for any 
			damage or loss caused or alleged to be caused by or in connection with use of or reliance on any content, events, 
			goods or services available on or through any such Third Party Resources. Any dealings you have with third parties 
			found while using the Services are between you and the third party and may be subject to additional terms provided 
			by the third party, which you agree to by using such Third Party Resources, and you agree that LetsFundIt is not 
			liable for any loss or claim that you may have against any such third party.</p>

			<p><b>INDEMNITY AND RELEASE</b></p>

			<p>You agree to release, indemnify on demand and hold LetsFundIt and its affiliates and their officers, employees, 
			directors and agents harmless from any from any and all losses, damages, expenses, including reasonable attorneys' 
			fees, costs, awards, fines, damages, rights, claims, actions of any kind and injury (including death) arising out 
			of or relating to your use of the Services, any Donation or Campaign, any User Content, your connection to the 
			Services, your violation of these Terms of Service or your violation of any rights of another. Regardless of your 
			jurisdiction you waive any doctrine which expressly or implicitly indicates that a general release does not extend 
			to claims which a creditor does not know or suspect to exist in his favor at the time of executing the release, 
			which if known by him must have materially affected his settlement with a debtor.</p>
			 
			<p><b>DISCLAIMER OF WARRANTIES</b></p>

			<p>YOUR USE OF THE SERVICES IS AT YOUR SOLE RISK. THE SERVICES ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS. 
			LetsFundIt AND ITS AFFILIATES EXPRESSLY DISCLAIM AND EXCLUDE, TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, ALL 
			ARRANTIES, CONDITIONS AND REPRESENTATIONS OF ANY KIND, WHETHER EXPRESS, IMPLIED OR STATUTORY, INCLUDING, BUT NOT 
			LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT.</p>

			<p>LetsFundIt AND ITS AFFILIATES MAKE NO WARRANTY OR CONDITION THAT (I) THE SERVICE WILL MEET YOUR REQUIREMENTS, 
			(II) THE SERVICE WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE, (III) THE RESULTS THAT MAY BE OBTAINED FROM 
			THE USE OF THE SERVICE WILL BE ACCURATE OR RELIABLE, OR (IV) THE QUALITY OF ANY PRODUCTS, SERVICES, INFORMATION, OR 
			OTHER MATERIAL PURCHASED OR OBTAINED BY YOU THROUGH THE SERVICE WILL MEET YOUR EXPECTATIONS.</p>
			 
			<p><b>LIMITATION OF LIABILITY</b></p>
			<p>YOU EXPRESSLY UNDERSTAND AND AGREE THAT, TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, NEITHER LetsFundIt 
			NOR ITS AFFILIATES WILL BE LIABLE FOR ANY (A) INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, PUNITIVE OR EXEMPLARY 
			DAMAGES, (B) DAMAGES FOR LOSS OF PROFITS, (C) DAMAGES FOR LOSS OF GOODWILL, (D) DAMAGES FOR LOSS OF USE, (E) LOSS 
			OR CORRUPTION OF DATA, OR (F) OTHER INTANGIBLE LOSSES (EVEN IF LetsFundIt HAS BEEN ADVISED OF THE POSSIBILITY OF 
				SUCH DAMAGES), WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE, RESULTING FROM (I) 
			THE USE OR THE INABILITY TO USE THE SERVICES; (II) THE COST OF PROCUREMENT OF SUBSTITUTE GOODS AND SERVICES RESULTING 
			FROM ANY GOODS, DATA, INFORMATION OR SERVICES PURCHASED OR OBTAINED OR MESSAGES RECEIVED OR TRANSACTIONS ENTERED 
			INTO THROUGH OR FROM THE SERVICES; (III) ANY PROMOTIONS AND RELATED PRIZES OR REWARDS MADE AVAILABLE THROUGH THE 
			SERVICES; (IV) UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR TRANSMISSIONS OR DATA; (V) STATEMENTS OR CONDUCT OF 
			ANY THIRD PARTY ON THE SERVICES; OR (VI) ANY OTHER MATTER RELATING TO THE SERVICES. TO THE FULLEST EXTENT PERMITTED 
			BY APPLICABLE LAW, IN NO EVENT WILL LetsFundIt'S TOTAL LIABILITY TO YOU FOR ALL DAMAGES, LOSSES (INCLUDING CONTRACT, 
				EGLIGENCE, STATUTORY LIABILITY OR OTHERWISE) OR CAUSES OF ACTION EXCEED THE AMOUNT YOU HAVE PAID LetsFundIt IN THE 
			LAST SIX (6) MONTHS, OR, IF GREATER, ONE HUNDRED TRINIDAD AND TOBAGO DOLLARS (TT$100.00).</p>

			<p>SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES OR THE LIMITATION OR EXCLUSION OF LIABILITY 
			FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES. ACCORDINGLY, SOME OF THE LIMITATIONS SET FORTH ABOVE MAY NOT APPLY TO YOU. 
			IF YOU ARE DISSATISFIED WITH ANY PORTION OF THE SERVICE OR WITH THESE TERMS OF SERVICE, YOUR SOLE AND EXCLUSIVE 
			REMEDY IS TO DISCONTINUE USE OF THE SERVICES.</p>
			 
			<p><b>DISPUTES</b></p>

			<p>ARBITRATION CLAUSE & CLASS ACTION WAIVER – IMPORTANT – PLEASE REVIEW AS THIS AFFECTS YOUR LEGAL RIGHTS
			ARBITRATION; WAIVER OF TRIAL BY JURY: YOU AGREE THAT ALL DISPUTES BETWEEN YOU AND US OR ANY OF OUR OFFICERS, 
			DIRECTORS OR EMPLOYEES ACTING IN THEIR CAPACITY AS SUCH (WHETHER OR NOT SUCH DISPUTE INVOLVES A THIRD PARTY) WITH 
			REGARD TO YOUR RELATIONSHIP WITH US, INCLUDING WITHOUT LIMITATION DISPUTES RELATED TO THESE TERMS OF SERVICE, YOUR 
			USE OF THE SERVICES, AND/OR RIGHTS OF PRIVACY AND/OR PUBLICITY, WILL BE RESOLVED BY BINDING, INDIVIDUAL ARBITRATION, 
			ND YOU AND WE HEREBY EXPRESSLY WAIVE TRIAL BY JURY; PROVIDED, HOWEVER, THAT WE OR YOU MAY SEEK INJUNCTIVE OR OTHER 
			EQUITABLE RELIEF IN ANY COURT HAVING JURISDICTION TO GRANT IT IN THE EVENT OF AN ACTUAL OR THREATENED INFRINGEMENT 
			OR MISAPPROPRIATION OF INTELLECTUAL PROPERTY RIGHTS. DISCOVERY AND RIGHTS TO APPEAL IN ARBITRATION ARE GENERALLY MORE 
			LIMITED THAN IN A LAWSUIT, AND OTHER RIGHTS THAT YOU AND WE WOULD HAVE IN COURT MAY NOT BE AVAILABLE IN ARBITRATION.</p>

			<p>Procedures: Any dispute arising out of the use of the Services, which cannot be amicably settled by informal 
			negotiation or discussion after twenty (20) business days from the commencement of such informal negotiation or 
			discussion, will be referred to arbitration at the Dispute Resolution Centre Trinidad and Tobago.</p>
			 
		</div>
	)
}

const PrivacyPolicy = () => {
	return (
		<div>

			<p><b>LetsFundIt | Online Solutions Ltd</b></p>
			<p><b>COVID-19 NOTICE</b> - As LetsFundIt.org looks to help individuals and businesses impacted by COVID-19, it is 
			partnering with various third parties to help users cross promote campaigns in order to drive more support. Such third 
			parties that provide or facilitate links, tools, widgets or other features allow you to access other sites, services 
			and resources provided by such third parties but also allow such third parties to access campaigns and distribute 
			campaigns created on LetsFundIt.org without obtaining your consent. By starting a LetsFundIt campaign and applying to 
			be part of the various COVID-19 initiatives, you consent to your Campaign being shared on each such third party site 
			and you agree to be bound by their respective privacy policies and terms of service.</p>

			<p>Welcome to the web site or mobile application (as the case may be) (collectively, the "Platform") LetsFundIt.org 
			a service of Online Solutions Limited (including its subsidiaries, affiliates, agents, and service providers, 
			collectively, "LetsFundIt," "we," "us," and/or "our"). This Platform is operated as LetsFundIt and has been created 
			to provide information about our company, our online platform designed to permit users to post a fundraising campaigns 
			to accept monetary donations, and our related services (together with the Platform, the "Services") to our Services 
			users and visitors ("you," and/or "your"). Except as provided below, this Privacy Policy describes and governs how 
			we collect, use, share and protect data collected from users of or visitors to the Platform.</p>
			 
			<p>Before you use or submit any information through or in connection with the Services, please carefully review this 
			Privacy Policy. By using any part of the Services, you consent to the collection, use, and disclosure of your 
			information as further outlined in this Privacy Policy.</p>
			 
			<p><b>If you are in Europe:</b></p>
			 
			<p>for the purposes of the EU General Data Protection Regulation 2016/679 (the "GDPR"), the data controller is 
			LetsFundIt.org. and can be reached at the address set out at "Contacting LetsFundIt" below;
			"information" covers all personal data processed by us which means information that (either in isolation or in 
			combination with other information) enables you to be identified directly or indirectly; and
			you will not need to consent to this Privacy Policy, but do acknowledge you have read and understood its terms.</p>

			<p><b>Information We Collect</b></p>
			<p>As further described below, when you use or otherwise access the Services, we may collect information in multiple 
			ways, including when you provide information directly to us, and when we passively collect information from your 
			browser or device.</p>
			 
			<p><b>Information That You Provide Directly to Us:</b></p>
			 
			<p>We collect information from you (including, but not limited to, your name, email address, and location) when you 
			voluntarily provide such information, such as when you contact us with inquiries, respond to one of our surveys, 
			provide us with feedback, register for access to the Services, or use certain Services.</p>
			 
			<p>You may also provide us with information relating to third parties—including information stored in your email and 
			mobile device address books—such as, without limitation, names, email addresses, and phone numbers of your contacts 
			(collectively, “Third-Party Data”). The Services may request your permission to access such Third-Party Data.</p>
			 
			<p>You may also provide us with special categories of personal data under the GDPR (also known as sensitive data) 
			about yourself and/or third parties, such as health information. When you provide such information, we will seek your 
			explicit consent unless it is being published in relation to your campaign.</p>
			 
			<p>We collect this information for the purposes described below in the section entitled, Our Use of Information 
			Collected. While you are under no obligation to directly provide us with such information, should you choose to 
			withhold such information, we may not be able to provide you with some or all of the Services.</p>
			 
			<p>If you provide “personal data” (as defined under the Privacy Shield principles – See Privacy Shield section for 
			more information on how we treat information from users who reside in the European Union, European Economic Area, and 
			Switzerland) to the Services, you acknowledge and agree that such personal data may be transferred from your current 
			location to the offices and servers of LetsFundIt and the authorized third parties referred to herein located in the 
			United States.</p>
			 
			<p>Our Services may include online chats and message boards. Please remember that any information that is disclosed 
			in these areas becomes public information for both us and other users to use and share. Please be considerate and 
			respectful of others while using the community to share your opinions. We reserve the right, but do not have the 
			obligation, to review and monitor such posting or any other content on our Services, and to remove postings or 
			content that may be viewed as inappropriate or offensive to others.</p>
			 
			<p><b>Information That is Passively or Automatically Collected</b></p>
			 
			<p>Device & Usage Information:</p>
			 
			<p>When you interact with LetsFundIt through the Services, we automatically receive and store certain information 
			from devices that you use to access the Services. This information is collected passively using various technologies, 
			and includes the type of internet browser or mobile device you use, any website from which you have come to the 
			Services, your operating system, and may include location data through an IP address that identifies the city and 
			state where you logged into the Platform or your precise geo-location if you have permitted your mobile device to 
			provide that information to us. LetsFundIt may store such information itself or such information may be included in 
			databases owned and maintained by LetsFundIt affiliates, agents or service providers.</p>
			 
			<p>Aggregated Data:</p>
			 
			<p>In an ongoing effort to better understand and serve the users of the Services, LetsFundIt often conducts research 
			on its customer demographics, interests and behavior based on information we collect. We may de-identify and/or 
			aggregate the information collected through the Services for research purposes or any other purposes, and we may 
			share this data with our affiliates, agents, business partners, or other third parties.</p>
			 
			<p>Location Information:</p>
			 
			<p>When you use the Services to organize a fundraising campaign, the Services may request that you provide your city 
			or town and state or province of residence. Please keep in mind that other users of the Services may be able to view 
			your city or town and state or province of residence in connection with the campaign, if it is made available within 
			LetsFundIt's Public Search Directory. We may also use your location information in an aggregate way, as described 
			above in the "Aggregated Data" section.</p>
			 
			<p>Cookies and Other Electronic Technologies:</p>
			 
			<p>When you interact with the Services, we try to make that experience simple and meaningful. When you visit our 
			Platform, our web server sends a cookie to your computer or mobile device. Cookies are small pieces of information 
			that are issued to your computer or mobile device when you visit a website or access or use a mobile application, 
			and that store and sometimes track information about your use of the Platform. A number of cookies we use last only 
			for the duration of your web or application session and expire when you close your browser or exit the application. 
			Other cookies are used to remember you when you return to the Platform and will last longer.</p>
			 
			<p>Some of the cookies used by the Platform are set by us, and some are set by third parties who are delivering 
			services on our behalf.</p>
			 
			<p>We may use cookies, and similar technologies to cookies, to collect information about your access and use of the 
			Platform. For example, we may use cookies or other technologies that: (1) allow you to navigate and use all the 
			features provided by our Platform; (2) customize elements of the layout and/or content within the Platform and 
			remember that you have visited us before; (3) identify the number of unique visitors we receive; (4) allow us to 
			provide you with a customized experience; and (5) inform us how you use the Platform (including how long you spend on 
				the Platform) and where you have come to the Platform from, so that we can improve the Platform and learn which 
			functions of the Platform are most popular with users. As we adopt additional technologies, we may also gather 
			additional information through other methods.</p>
			 
			<p>Most web and mobile device browsers automatically accept cookies but, if you prefer, you can change your browser 
			to prevent that or to notify you each time a cookie is set. You can also learn more about cookies by visiting 
			www.allaboutcookies.org, which includes additional useful information on cookies and how to block cookies using 
			different types of browsers or mobile devices.</p>
			 
			<p>You may also consult the "Help" section of your browser for more information (e.g., Internet Explorer; Google 
				Chrome; Mozilla Firefox; or Apple Safari). You can also manage the use of Flash technologies, including cookies 
			and local storage objects with the Flash management tools available at Adobe's website. Please note that by blocking 
			any or all cookies, you may not have access to certain features or offerings of the Services.</p>
			 
			<p>If you are accessing our Services through a mobile device, you can also update your privacy settings on your device 
			by setting the “Limit Ad Tracking” and Diagnostics and Usage setting property located in the settings screen of your 
			Apple iPhone or iPad, or by resetting your Android ID through apps that are available in the Play Store. You can also 
			limit information collection by uninstalling the App on your device and you can use the standard uninstall process 
			available as part of your device for this purpose.</p>
			 
			<p><b>Information From Social Networking Services</b></p>
			 
			<p>One of the special features of the Services is that it may allow you to enable or log into the Services via various 
			social networking services like Facebook or Twitter ("Social Networking Service(s)"). By directly integrating these 
			services, we aim to make your online experiences richer and more personalized. To take advantage of this feature, 
			we will ask you to provide your credentials for the applicable Social Networking Service in order to log-in to our 
			Services. When you add a Social Networking Services account to the Services or log into the Services using your 
			Social Networking Services account, we will collect relevant information necessary to enable the Services to access 
			that Social Networking Service and your data contained within that Social Networking Service. As part of such 
			integration, the Social Networking Service will provide us with access to certain information (including, for example, 
				your name and photograph, as well as other information that you permit the Social Network Service to share with us) 
			that you have provided to the Social Networking Service, and we will use, store, and disclose such information in 
			accordance with this Privacy Policy. However, please remember that the manner in which Social Networking Services use, 
			store, and disclose your information is governed by the policies of such third parties, and LetsFundIt shall have no 
			liability or responsibility for the privacy practices or other actions of any Social Networking Services that may be 
			enabled within the Services.</p>
			 
			<p>You may also have the option of posting your Services activities to Social Networking Services when you access 
			content through the Services (for example, you may post to Facebook that you performed an activity on the Platform); 
			you acknowledge that if you choose to use this feature, your friends, followers, and subscribers on any Social 
			Networking Services you have enabled will be able to view such activity.</p>
			 
			<p>Please visit the privacy policy of the relevant Social Networking Service to find out more information about your 
			rights and choices.</p>
			 
			<p><b>Our Use of Information Collected</b></p>
			<p>LetsFundIt uses the information collected from the Services in a manner that is consistent with this Privacy 
			Policy. If you provide information for a certain reason, we may use the information in connection with the reason for 
			which it was provided. For instance, if you contact us by email, we will use the information you provide to answer 
			your question or resolve your problem. Also, if you provide information about yourself, or have provided 
			(or otherwise permitted the Services to access) any Third-Party Data, we will use your information and any such 
			Third-Party Data to provide you with access to such services and to monitor your use of such services. For the 
			purposes of the GDPR, we may use your information in the above ways as this is necessary for us to fulfill our 
			obligations under our contract with you or in order to take steps at your request prior to entering into a contract.</p>
			 
			<p>LetsFundIt may also use the information we collect through the Services to help us in providing the Services and 
			support services, processing transactions, fulfilling requests for information, receiving and sending communications, 
			updating marketing lists, analyzing data and research aimed at improving the accuracy, effectiveness, usability or 
			popularity of the Services, to personalize the content and advertising that you see on Services and to understand 
			how users interact with and user our advertisement and Services, to associate your activities, customize content or 
			tailor advertising across different device and browsers that you use to access the Services (for instance, computers, 
				tablets, mobile devices, applications and websites), to comply with state, local, federal or international laws, 
			to enforce our Terms of Service and this Privacy Policy, as otherwise described in this Privacy Policy, or in 
			assisting with other tasks from time to time. Please note that we may combine information that we collect from you 
			and about you (including automatically-collected information) with information we obtain about you from our 
			affiliates and/or non-affiliated third parties, and use such combined information in accordance with this Privacy 
			Policy. For the purposes of the GDPR, we may use your information in the above ways as this is necessary: (i) to 
			fulfill our obligations under our contract with you or in order to take steps at your request prior to entering 
			into a contract; (ii) for our legitimate interest to maintain our relationship with you or protect you and us against 
			raud; or (iv) to comply with legal obligations.</p>
			 
			<p>We may also use the information collected from the “Information We Collect” section, such as device identifiers 
			and MAC addresses, to associate users’ activities, customize content, or tailor advertising to those users across 
			different devices and browsers (for instance, tablets, mobile devices and applications, and websites). For the 
			purposes of the GDPR, we may use your information in the above ways as this is necessary: (i) to fulfil our 
			obligations under our contract with you or in order to take steps at your request prior to entering into a 
			contract; (ii) for our legitimate interest to maintain our relationship with you or protect you and us against 
			fraud; or (iv) to comply with legal obligations.</p>
			 
			<p>LetsFundIt may use the information collected through the Services to contact you about services we believe will 
			be of interest to you. In some cases, we will seek your permission. In each marketing communication we send you will 
			contain instructions permitting you to "opt-out" of receiving future marketing communications. In addition, if at any 
			time you wish not to receive any future marketing communications or you wish to have your name deleted from our 
			mailing lists, please contact us as indicated below. If you opt out of receiving marketing communications or other 
			information we think may interest you, as discussed below, we may still send you emails about your account or any 
			Services you have requested or received from us.</p>
			 
			<p>We may also use data collected through the Services to send communications relating to the Services, in accordance 
			with applicable laws. For example, we may send periodic emails to registered users of the Services relating to their 
			recent interactions with the Services, such as donating to a campaign or "hearting" a campaign. Also, if you use any 
			feature of the Services permitting you to communicate with third parties (such as to refer a third party to the 
				Services or to communicate with them regarding a campaign or a donation), either by submitting Third-Party Data to 
			the Services or otherwise permitting the Services to automatically access Third-Party Data in your possession, 
			you acknowledge and agree that you have the authority of the relevant third party for us to access and use the 
			relevant Third-Party Data and that you have notified these third parties and informed them how their information is 
			collected and used by LetsFundIt to provide the Services. We reserve the right to identify you as the person who has 
			made the referral in any messages that are sent to them. We may use Third-Party Data to (1) contact such third 
			party using the Third-Party Data provided, and/or (2) provide you with an editable template message designed to 
			facilitate communication between you and such third party through the Services. In addition to sending the foregoing 
			communications, we may also send reminders or related messages to you and to third parties on your behalf from time to 
			time where permitted by applicable law. In each case, any such communications sent to third parties using Third-Party 
			Data will provide a means to "opt-out" of receiving further communication of the same nature.</p>
			 
			<p><b>Mobile Services:</b></p> 
			<p>If you access the Services through a mobile device, you agree that we may communicate with you regarding 
			LetsFundIt by SMS, MMS, text message, or other electronic means to your mobile device. In the event you change or 
			deactivate your mobile telephone number, you agree to promptly update your LetsFundIt account information to ensure 
			that your messages are not misdirected. Please note that your wireless service carrier’s standard charges, data rates, 
			and other fees may apply where you access the Services through a mobile device. In addition, downloading, installing, 
			or using certain Services on a mobile device may be prohibited or restricted by your carrier, and not all Services 
			may work with all carriers or devices. By using the Services on a mobile device, you agree that we may communicate 
			with you regarding LetsFundIt by SMS, MMS, text message, or other electronic means to your mobile device. In the 
			event you change or deactivate your mobile telephone number, you agree to promptly update your LetsFundIt account 
			information to ensure that your messages are not misdirected.</p>
			 
			<p><b>Our Disclosure of Information Collected Through the Services</b></p>
			<p>There are certain circumstances in which we may share information collected through the Services with certain 
			third parties without further notice to you, as set forth below:</p>
			 
			<p><b>Business Transfers:</b></p>
			 
			<p>As we develop our business, we might sell or buy businesses or assets. In the event of a corporate sale, merger, 
			reorganization, dissolution, similar event, or steps taken in anticipation of such events (e.g., due diligence in a 
				transaction), user information may be part of the transferred assets.</p>
			 
			<p><b>Agents, Consultants, Vendors and Related Third Parties:</b></p>
			 
			<p>LetsFundIt, like many businesses, sometimes hires other companies to perform certain business-related functions 
			on its behalf, and we may provide access to or share your information with these companies so they can perform 
			services for us. Examples of such functions include marketing, mailing information, data storage, security, fraud 
			preventions, payment processing, legal services, and maintaining databases.</p>
			 
			<p><b>Legal Requirements:</b></p>
			 
			<p>LetsFundIt may access, disclose, transfer and preserve your information when we have a good faith belief that 
			doing so is necessary to: (i) comply with the law including with subpoenas, search warrants, court orders, and other 
			legal process; and respond to inquiries or requests from government, regulatory, law enforcement, public authorities, 
			or content protection organizations; (ii) protect and defend the legal rights, privacy, safety or property of 
			LetsFundIt, its affiliates, subsidiaries, employees, agents, contractors, or its users; (iii) permit us to 
			pursue available remedies, commence, participate in, or defend litigation, or limit the damages we may sustain; or 
			(iv) enforce this Privacy Policy or any applicable Terms of Service.</p>
			 
			<p><b>Campaign Organizers</b></p>
			 
			<p>If you donate to a fundraising campaign, we may share your name, email address and other information you’ve 
			provided in connection with your donation with the campaign organizer, who may contact you.</p>
			 
			<p><b>Your consent</b></p>
			 
			<p>We may share your information in the event that you consent to the sharing of your information.</p>
			 
			<p>Aggregate and/or De-Identified Information</p>
			 
			<p>We may aggregate and/or de-identify your information so that the information no longer relates to you 
			individually. Our use and disclosures of such aggregated or de-identified information is not subject to this Privacy 
			Policy, and may be disclosed to others without limitation and for any purpose.</p>
			 
			<p><b>Analytics</b></p>
			 
			<p>We may use third-party web analytics services on our Services, such as those of Google Analytics. These service 
			providers use the sort of technology previously described in the “Cookies and other electronic technologies” section 
			to help us analyze how users use the Services, including by noting the third-party website from which you arrive, 
			and provide certain features to you. The information (including your IP address) collected by the technology will be 
			disclosed to or collected directly by these service providers, who use the information to evaluate your use of the 
			Services. This information may be used to place interest-based advertisements on the Platform. This may result 
			in you seeing advertisements for our Platform when you visit other websites. To prevent Google Analytics from using 
			your information for analytics, you may install the Google Analytics Opt-out Browser Add-on by clicking here.</p>
			 
			<p><b>Tailored Advertising</b></p>
			<p>We may use third-party advertising technologies that allow for the delivery of relevant content and advertising on 
			ur Platform, as well as on other websites you visit. The ads may be based on various factors such as the content of 
			the page you are visiting, information you enter such as your age and gender, your searches, demographic data, 
			user-generated content, and other information we collect from you. These ads may be based on your current activity or 
			our activity over time and across other websites and online services and may be tailored to your interests.</p>
			 
			<p>Also, third parties whose products or services are accessible or advertised on our Platform may place cookies or 
			other tracking technologies on your computer, mobile phone, or other device to collect information about your use of 
			the website as discussed above. We also allow other third parties (e.g., ad networks and ad servers such as Google 
				Analytics, DoubleClick and others) to serve tailored ads to you on our website, other sites, and in other 
			applications, and to access their own cookies or other tracking technologies on your computer, mobile phone, or other 
			evice you use to access the website. We may provide our customer information (such as a hashed version of your email 
				addresses) to service providers, who may “match” this information in de-identified form to cookies (or mobile ad 
				identifiers) and other proprietary IDs, in order to target or “retarget” you with ads when you visit other websites 
				and mobile applications. (You may opt out of these many of these service providers as described in the next 
					paragraph.)</p>
			 
			<p>We neither have access to, nor does this Privacy Policy govern, the use of cookies or other tracking technologies 
			that may be placed on your computer, mobile phone, or other device you use to access our website by non-affiliated, 
			third-party ad technology, ad servers, ad networks or any other non-affiliated third parties. Those parties that use 
			these technologies may offer you a way to opt out of ad targeting as described below.</p>
			 
			<p>If you receive tailored advertising on your computer through a web browser, you can learn more about such tailored 
			rowser advertising and how you can generally control cookies from being put on your computer to deliver tailored 
			advertising, by visiting the European Interactive Digital Advertising Alliance Consumer Ad Choices link for EU 
			based advertising or for US based advertising either: (i) the Network Advertising Initiative’s Consumer Opt-Out link 
			or (ii) the Digital Advertising Alliance’s Consumer Opt-Out link to opt-out of receiving tailored advertising from 
			companies that participate in those programs. To opt out of Google Analytics for display advertising or customize 
			Google display network ads, you can visit the Google Ads Settings page.</p>
			 
			<p>Please note that to the extent advertising technology is integrated into our website or other websites that you 
			visit, you may still receive advertisements even if you opt-out. In that case, the advertising will not be tailored 
			to your interests. Also, we do not control any of the above opt-out links or whether any particular company chooses 
			to participate in these opt-out programs. We are not responsible for any choices you make using these mechanisms or 
			the continued availability or accuracy of these mechanisms.</p>
			 
			<p><b>Unsolicited Information</b></p>
			<p>Please be advised that some information you provide may be publicly accessible, such as information posted in 
			forums or comment sections. We may also collect information through customer support communications, your 
			communication to us of ideas for new products or modifications to existing products, and other unsolicited 
			submissions (collectively, with publicly-accessible information, "Unsolicited Information"). By sending us 
			Unsolicited Information, you further (a) agree that we are under no obligation of confidentiality, express or 
			implied, with respect to the Unsolicited Information, (b) acknowledge that we may have something similar to the 
			Unsolicited Information already under consideration or in development, (c) grant us an irrevocable, non-exclusive, 
			royalty-free, perpetual, worldwide license to use, modify, prepare derivative works, publish, distribute and 
			sublicense the Unsolicited Information, and (iv) irrevocably waive, and cause to be waived, against LetsFundIt and 
			its users any claims and assertions of any moral rights contained in such Unsolicited Information. This Unsolicited 
			Information section shall survive any termination of your account or the Services. This Privacy Policy otherwise does 
			not apply to any information collected by LetsFundIt other than information collected through the Services.</p>
			 
			<p><b>Children</b></p>
			<p>Our Services are not designed for use by individuals under the age of 13(or 16 in Europe). If you are under the 
			age of 13(or 16 in Europe), please do not use the Services and/or submit any information through the Services. If 
			you have reason to believe that a child under the age of 13(or 16 in Europe) has provided personal information to 
			LetsFundIt through the Services, please contact us support@LetsFundIt.com, and we will delete that information from 
			our databases to the extent required by law.</p>
			 
			<p><b>Links to Other Web Sites</b></p>
			<p>This Privacy Policy applies only to the Services. The Services may contain links to other web sites not 
			operated or controlled by LetsFundIt (the "Third-Party Sites"). The policies and procedures we described here do not 
			apply to the Third-Party Sites. The links from the Services do not imply that LetsFundIt endorses or has reviewed the 
			hird-Party Sites. We suggest contacting those sites directly for information on their respective privacy policies.</p>
			 
			<p><b>Security</b></p>
			<p>LetsFundIt has implemented technical and organizational security measures to protect the Personal Data provided 
			via the Services from loss, misuse, and unauthorized access, disclosure, alteration, or destruction. For example, 
			limiting access to information only to employees and authorized service providers who need to know such information 
			for the purposes of this Privacy Notice. However, no Internet or email transmission is ever fully secure or error 
				free. In particular, email sent to or from the Services may not be secure. Therefore, you should take special care 
			n deciding what information you send to us via email. Please keep this in mind when disclosing any information to 
			LetsFundIt via the Internet.</p>
			 
			<p><b>Do-Not-Track Signals</b></p>
			<p>Do Not Track (“DNT”) is a privacy preference that users can set in certain web browsers. We do not recognize or 
			respond to browser-initiated DNT signals, as the Internet industry is currently still working toward defining exactly 
			what DNT means, what it means to comply with DNT, and a common approach to responding to DNT. To learn more about Do 
			Not Track, you can do so here.</p>
			 
			<p><b>Retention of your information</b></p>
			<p>We will only retain the information for as long as necessary for the purpose for which that information was 
			collected and to the extent permitted or required by applicable laws. When we no longer need to use your information, 
			e will remove it from our systems and records and/or take step to anonymize it so that you can no longer be identified 
			om it in accordance with our internal document retention policies.</p>
			 
			<p><b>Access to Information</b></p>
			<p>You may access and correct certain information about you by visiting the “Account Settings” portion of our 
			Platform. You may also request in writing copies of personal information about you held by us. If that information is 
			naccurate, please let us know and we will endeavor to make the necessary amendments, erase, or block the relevant 
			information as you request. For more information on your rights if you are in Europe, please see the "Users in 
			Europe” section below.</p>
			 
			<p><b>Privacy Shield</b></p>
			<p>LetsFundIt has certified with the EU-U.S. and Swiss-U.S. Privacy Shield as set forth by the U.S. Department of 
			Commerce regarding the collection, use, and retention of “personal data” (as defined under the Privacy Shield 
				principles) from applicable European countries. LetsFundIt has certified that it adheres to the Privacy Shield 
			Principles of notice, choice, onward transfer, security, data integrity, access, and enforcement for such personal 
			data. To learn more about the Privacy Shield Framework, visit the Department of Commerce’s Privacy Shield list by 
			clicking here.</p>
			 
			<p>As required under the principles, when LetsFundIt receives information under the Privacy Shield and then transfers 
			t to a third-party service provider acting as an agent on LetsFundIt’s behalf, LetsFundIt has certain liability under 
			the Privacy Shield if both (i) the agent processes the information in a manner inconsistent with the Privacy Shield 
			and (ii) LetsFundIt is responsible for the event giving rise to the damage.</p>
			 
			<p>If you have any questions or complaints about LetsFundIt’s privacy practices, including questions related to the 
			Privacy Shield, you may contact us at the email address or mailing address set forth under “Contacting LetsFundIt.” 
			We will work with you to resolve your issue.</p>
			 
			<p>If you are a resident of the European Union or Switzerland and are dissatisfied with the manner in which we have 
			addressed your concerns about our privacy practices, you may seek further assistance, at no cost to you, from our 
			designated Privacy Shield independent recourse mechanism, the JAMS Privacy Shield Program, which you can learn more 
			about by visiting https://www.jamsadr.com/eu-us-privacy-shield.</p>
			 
			<p>Residents of the European Union or Switzerland may elect to arbitrate unresolved complaints but prior to initiating 
			such arbitration, you must: (1) contact LetsFundIt and afford us the opportunity to resolve the issue; (2) seek 
			assistance from LetsFundIt’s designated independent recourse mechanism above; and (3) contact the U.S. Department of 
			Commerce (either directly or through a European Data Protection Authority) and afford the Department of Commerce time 
			to attempt to resolve the issue. Each party shall be responsible for its own attorney’s fees. Please be advised that, 
			pursuant to the Privacy Shield, the arbitrator(s) may only impose individual-specific, non-monetary, equitable 
			relief necessary to remedy any violation of the Privacy Shield Principles with respect to the individual. LetsFundIt 
			is subject to the investigatory and enforcement powers of the U.S. Federal Trade Commission (FTC).</p>
			 
			<p><b>Users in Europe</b></p>

			<p><b>Access.</b></p> 
			<p>You have the right to request a copy of the information we are processing about you.</p>

			<p><b>Rectification.</b></p> 
			<p>You have the right to have incomplete or inaccurate information that we process about you rectified.</p>

			<p><b>Deletion.</b></p> 
			<p>You have the right to request that we delete information that we process about you, except we are not obliged 
			to do so if we need to retain such data in order to comply with a legal obligation or to establish, exercise or 
			defend legal claims.</p>

			<p><b>Restriction.</b></p> 
			<p>You have the right to restrict our processing of your information where you believe such data to be inaccurate; 
			our processing is unlawful; or that we no longer need to process such data for a particular purpose unless we are 
			not able to delete the data due to a legal or other obligation or because you do not wish for us to delete it.</p>

			<p><b>Portability.</b></p> 
			<p>You have the right to obtain information we hold about you in a structured, electronic format, and to transmit 
			such data to another data controller, where this is (a) information that you have provided to us, and (b) if we are 
			processing that data solely on the basis of your consent or to perform a contract with you.</p>

			<p><b>Objection.</b></p> 
			<p>Where the legal basis for processing your information is our legitimate interest, you have the right to object to 
			such processing on grounds relating to your particular situation. We will abide by your request unless we have 
			compelling legitimate grounds for the processing which override your interests, or if we need to continue to process 
			the data for the establishment, exercise or defence of a legal claim.</p>

			<p><b>Withdrawing consent.</b></p> 
			<p>If you have consented to our processing of your information, you have the right to withdraw your consent at any 
			time, free of charge. This includes where you wish to opt out from marketing messages.</p>

			<p>You can make a request to exercise any of these rights in relation to your information by sending the request to 
			us at the email address or mailing address set forth under "Contacting LetsFundIt". For your own privacy and security, 
			at our discretion, we may require you to provide your identity before providing the requested information. Please 
			note that LetsFundIt may take up to 30 days to fulfill such requests.</p>
			 
			<p>You also have the right to lodge a complaint with the local data protection authority if you believe that we have 
			not complied with applicable data protection laws. A list of local data protection authorities in European countries 
			is available here.</p>
			 
			<p><b>Other Terms and Conditions</b></p>
			<p>Your access to and use of the Services is subject to the Terms of Service 
			and such other terms, which may be made available to you in connection with your use of the Services.</p>
			 
			<p><b>Changes to LetsFundIt's Privacy Policy</b></p>
			<p>The Services and our business may change from time to time. As a result, at times it may be necessary for 
			LetsFundIt to make changes to this Privacy Policy. LetsFundIt reserves the right to update or modify this Privacy 
			Policy at any time and from time to time. If you disagree with our revisions to the Privacy Policy, you may 
			de-activate your account or discontinue the use of our Services. Please review this policy periodically, and 
			especially before you provide us with any information through the Services. This Privacy Policy was last updated on 
			the date indicated above. Your continued use of the Services after any changes or revisions to this Privacy Policy 
			shall indicate your agreement with the terms of such revised Privacy Policy.</p>
			 
			<p>If you are in Europe, you will not be required to agree to changes to this Privacy Policy, unless we specifically 
			seek your consent.</p>







		</div>
	)
}

const Fees = () => {
	return (
		<div>
			<Text variant='body2'>
			   Currently these are the fees associated with LetsFundIt services. 
			   We are constantly working on ways to lower all our fees for you to get the most out of our service.
		   </Text>

		   <Text variant='body1' style={{marginTop:15}}><b>DONATION FEES</b></Text>
		   <Text variant='body2'>
			   The donation fee is payed by the donor and varies depending on the payment method selected. When you select a payment method 
			   the fees and total charge would be displayed. Payment methods currently supported are Credit Card 
			   and LetsFundIt Credits.
		   </Text>

		   <Text variant='body1' style={{marginTop:15}}><b>LISTING FEE</b></Text>
		   <Text variant='body2'>
			   We charge a flat <b>5% listing fee</b> on all funds received. This fee is deducted as soon as a 
			   donation is made so your account always displays your available balance.
		   </Text>

			<Text variant='body1' style={{marginTop:15}}><b>FEATURE BOARD FEE</b></Text>
			<Text variant='body2'>
			   Feature Boards are owned and managed by our affiliates. They charge a <b>flat registrations fee + 10% of funds raised</b>.      
			</Text>
			<Text variant='body2' color='secondary'>
				<i>(Note: Feature Board Fee = registration fee + 5% listing fee + 10% affilate fee)</i>
			</Text>

			<Text variant='body1' style={{marginTop:15}}><b>WITHDRAWAL FEES</b></Text>
			<Text variant='body2'>
				 The withdrawal fee varies depending on the method selected. When you select a withdrawal method the fees 
				 and total charge would be displayed. We currently only support bank withdrawals.          
			</Text>
		
{/*
Worked Example - Online Donation (single donation):
Donation Amount : One online donation of TT$1,000.00
LetsFundIt Service Fee - 12% : $120.00
Online Payment Processing Fee - 4.90% + TT$2.00 = $49.00 + $2.00 : $51.00
Total fees : TT$120.00 + TT$51.00 = TTD$171.00
Net Payout to Campaign : TT$1000.00 - TT$171.00 = TT$829.00
 
Worked Example - Online Donation (single donation) - Expedited Payout:
Donation Amount : One online donation of TT$1,000.00
LetsFundIt Service Fee - 12% : $120.00
LetsFundIt Expedited Service Fee - 3% : $30.00
Online Payment Processing Fee - 4.90% + TT$2.00 = $49.00 + $2.00 : $51.00
Total fees : TT$120.00 + TT$30.00 + TT$51.00 = TTD$201.00
Net Payout to Campaign : TT$1000.00 - TT$201.00 = TT$799.00
 
Worked Example - Online Donation (multiple donations):
Donation Amount : Four online donations of TT$250.00 each = Total Donations of $1,000.00
LetsFundIt Service Fee - 12% : $120.00
Online Payment Processing Fee - 4.90% + TT$2.00 per donation = $49.00 + $8.00 : $57.00
Total fees : TT$120.00 + TT$57.00 = TTD$177.00
Net Payout to Campaign : TT$1000.00 - TT$177.00 = TT$823.00
 
Worked Example - Online Donation (multiple donations) - Expedited Payout:
Donation Amount : Four online donations of TT$250.00 each = Total Donations of $1,000.00
LetsFundIt Service Fee - 12% : $120.00
LetsFundIt Expedited Service Fee - 3% : $30.00
Online Payment Processing Fee - 4.90% + TT$2.00 per donation = $49.00 + $8.00 : $57.00
Total fees : TT$120.00 + TT$30.00  + TT$57.00 = TTD$207.00
Net Payout to Campaign : TT$1000.00 - TT$207.00 = TT$793.00

Note: These worked examples are for illustrative purposes only and are not meant to constitute an exhaustive 
list of the possible permutations and combinations of donations that my be made to a Campaign.
 
The Campaign Organizer's account dashboard, once created, shows the relevant information on each donation made 
and the current total payout amount per Campaign created exclusive of the Expedited Service Fee, which will be 
deducted separately.
 
Payment Dispute Fee: In the event that a Donor raises a dispute on a donation made by making a report to his 
or her card issuing bank to refund their payment to a campaign due to their belief that the payment was made 
in error or they suspect it is fraudulent, TT$175.00 will be withheld from the Campaign's Payout Value to in 
addition to the value of the original Donation. In the event that this Payment Dispute is raised after all 
proceeds of a Campaign have been paid out, the Campaign Organizer agrees that he or she will be legally liable to 
LetsFundIt for the full amount of the donation made in addition to the TT$175.00 Payment Dispute Fee and 
unconditionally bestows LetsFundIt will all possible legal rights and recourse to recover these monies from  him or 
her with any costs (legal or otherwise) associated with LetsFundIt recovering these monies. 
*/}

		</div>
	)
}
