import React from 'react'
import moment from 'moment'
import {  withRouter } from 'react-router-dom'

import Loader from '../../components/Loader'
import TicketAssignTo from './components/TicketAssignTo'
import TicketClose from './components/TicketClose'
import TicketNotes from './components/TicketNotes'
import TicketPose from './components/TicketPost'

import { green } from '@material-ui/core/colors'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import IconArrowBack from '@material-ui/icons/ArrowBackIos'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import Paper from '@material-ui/core/Paper'
import Text from '@material-ui/core/Typography'

function GroupSupportTicket({history, section, filter, ticketId, tickets, roleId, memberId, team, assignTicket, addTicketNote, updateTicket, closeTicket, archiveTicket}) {
	const statusColor = {'unassigned':'#999', 'open':green[500], 'closed':'red', 'archived':'#000'}
	const ticket = tickets[ticketId]
	const [loading, setLoading] = React.useState(false)

	const goToList = () => {
		let url = '/group/support/' + section + '/' + filter
		history.push({ pathname: url })
	}
	if(!ticket){ goToList() }

	const handleArchive = () => {
		if(ticket.status === 'closed'){
			setLoading(true)
			archiveTicket(ticketId)
			.then(() => {
				setLoading(false)
			})
			.catch((error) => {
				setLoading(false)
			})
		}
	}

	return (
		<React.Fragment>
			<Loader loading={loading} />
			<div align="right" style={{marginTop:5}} >
				<Button onClick={goToList}>
					<IconArrowBack /> Back to List
				</Button>
			</div>
			
			<Paper style={{marginTop:5, padding: 5}} >
				<List>
					<ListItem>
						<ListItemAvatar>
							<Avatar alt={ticket.memberName} src={ticket.memberImage} style={{borderRadius:5}} />
						</ListItemAvatar>
						<ListItemText>
							<Text variant='h6'>{ticket.memberName}</Text>
							<Text style={{lineHeight:.8}} variant='caption' display='block' color='textSecondary'>
								Submitted on {moment.unix(ticket.timestamp).format('MMM Do YYYY - h:mm a')}
							</Text>
							<Text style={{marginTop:5, lineHeight:1, color:'#546e7a'}}><b>{ticket.subject}</b></Text>
						</ListItemText>
						<ListItemSecondaryAction>
							<Text style={{lineHeight:1.5, color:statusColor[ticket.status]}}><b>{ticket.status.toUpperCase()}</b></Text>
						</ListItemSecondaryAction>
					</ListItem>
				</List>
			</Paper>

			<Paper style={{marginTop:20, padding: 5}} >
				<List>
					{Object.keys(ticket.thread).map((i, key) => {
						let t = ticket.thread[i]
						return (
							<div key={key}>
								<ListItem alignItems="flex-start" style={{paddingBottom:50}}>
									<ListItemAvatar>
										<Avatar alt={t.postedByName} src={t.postedByImage} style={{borderRadius:5}} />
									</ListItemAvatar>
									<ListItemText>
										{t.postedByName}
										<Text style={{lineHeight:.8}} variant='caption' display='block' color='textSecondary'>
											{moment.unix(t.timestamp).format('MMM Do YYYY - h:mm a')}
										</Text>
										<Text style={{marginTop:15, lineHeight:1.25, color:'#546e7a', whiteSpace:'pre-wrap'}}>{t.comment}</Text>
									</ListItemText>
								</ListItem>
								<Divider/>
							</div>
						)
					})}

					{(ticket.status === 'open' && ticket.assignedToId === memberId) ? (
						<div>
							<TicketPose ticketId={ticketId} updateTicket={updateTicket}/>
							<TicketClose ticketId={ticketId} closeTicket={closeTicket}/>
						</div>
					):( null )}
					
					{(ticket.status === 'closed' || ticket.status === 'archived') ? (
						<div align='center'style={{padding:10}}><Text variant='h6'>Ticket Closed by {ticket.closedByName}</Text></div>
					):( null )}
				</List>
			</Paper>

			{(ticket.notes) ? (
				<TicketNotes ticketId={ticketId} notes={ticket.notes} addTicketNote={addTicketNote} status={ticket.status}/>
			):( null )}	

			{(ticket.status === 'unassigned' || ticket.status === 'open') ? (
				<TicketAssignTo ticketId={ticketId} ticket={ticket} team={team} assignTicket={assignTicket}/>
			):( null )}	

			{(ticket.status === 'closed' && roleId <= 2) ? (
				<div align='center' style={{padding:10}}><Button onClick={handleArchive}>Archive this Ticket</Button></div>
			):( null )}
		</React.Fragment>
	)
}
export default withRouter(GroupSupportTicket)
