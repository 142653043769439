import React from 'react'
import {money} from '../../../includes/functions'
import ViewPayInsList from './ViewPayInsList'

import Alert from '@material-ui/lab/Alert'
import AppBar from '@material-ui/core/AppBar'
import Button from '@material-ui/core/Button'
import Collapse from '@material-ui/core/Collapse'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import IconClose from '@material-ui/icons/Close'
import IconEqualizer from '@material-ui/icons/Equalizer'
import Paper from '@material-ui/core/Paper'
import Slide from '@material-ui/core/Slide'
import Text from '@material-ui/core/Typography'
import Toolbar from '@material-ui/core/Toolbar'
import withMobileDialog from '@material-ui/core/withMobileDialog'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction='up' ref={ref} {...props} />
})

function Summary({fullScreen, credits, payIns, payOuts, payOutMethods, payInActivity}) {
	const [showAlert, setShowAlert] = React.useState(true)
	const [viewActivity, setViewActivity] = React.useState(false)

  	const toggleViewAllActivity = () => {
  		setViewActivity(!viewActivity)
  	}

	return (
		<React.Fragment>
			<Paper style={{ padding: 20, minHeight:800 }} >
				<Grid container spacing={2}>
	     			<Grid item xs={12} sm={4}>
						<div style={{backgroundColor:'#f1f1f1', padding:5, textAlign:'center'}}>
							<Text variant='h5' component='span' color='primary'><b>{ money(payIns,0) } </b></Text>
							<Text variant='caption' component='span' color='textSecondary'><b> USD</b></Text><br/>
							<Text variant='body1' color='primary'><b>PAYINS</b></Text>
	     				</div>
	     			</Grid>
	     			<Grid item xs={12} sm={4}>
						<div style={{backgroundColor:'#f1f1f1', padding:5, textAlign:'center'}}>
							<Text variant='h5' component='span' color='primary'><b>{ money(payOuts,0) } </b></Text>
							<Text variant='caption' component='span' color='textSecondary'><b> USD</b></Text><br/>
							<Text variant='body1' color='primary'><b>PAYOUTS</b></Text>
	     				</div>
	     			</Grid>
	     			<Grid item xs={12} sm={4}>
						<div style={{backgroundColor:'#f1f1f1', padding:5, textAlign:'center'}}>
							<Text variant='h5' component='span' color='primary'><b>{ money(credits,0) } </b></Text>
							<Text variant='caption' component='span' color='textSecondary'><b> USD</b></Text><br/>
							<Text variant='body1' color='primary'><b>CREDITS</b></Text>
	     				</div>
	     			</Grid>
	     		</Grid>

				{(!payOutMethods || !Object.keys(payOutMethods).length) ? (
						<Collapse in={showAlert}>
						<div align='center' style={{margin:'0px auto', marginTop:20, maxWidth:600}}>
							<Alert severity="error"
								action={
					            <IconButton color='inherit' size='small' onClick={() => { setShowAlert(false) }}>
					              <IconClose fontSize='inherit' />
					            </IconButton>
					          }

							>You need to setup a <b>Payout Method</b> before you can <b>Request A Payout</b>.<br/>
							Go to PAYOUTS to setup.
							</Alert>
						</div>
						</Collapse>
				) : (null)}


	     		<div align='center' style={{width:'100%', marginTop:20, marginBottom:20, paddingTop:100, paddingBottom:100, backgroundColor:'#f1f1f1'}}>
	     			<Text variant='body1' color='textSecondary'>Graph of PayIn Activity for last 30 days.<br/>(Coming Soon)</Text>
	     		</div>
				
				<div style={{marginTop:30}}>
					<Text variant='h5' color='textSecondary'>PayIn Activity</Text>
					<ViewPayInsList payIns={payInActivity} count={5}/>
					{(Object.keys(payInActivity).length) ? (
						<div align='right'><Button size='small' color='secondary' onClick={toggleViewAllActivity}>View All Activity</Button></div>
					) : ( null )}
				</div>
			</Paper>

			<Dialog keepMounted fullScreen={fullScreen} open={viewActivity} TransitionComponent={Transition} onClose={toggleViewAllActivity}>
				<AppBar style={{ position: 'relative' }} >
               <Toolbar>
                   <IconEqualizer style={{ marginRight: 5 }} />
                   <Text variant='subtitle2' color='inherit' style={{ flex: 1 }}>
                       PayIn Activity
                   </Text>
                   <IconButton color='inherit' onClick={toggleViewAllActivity}>
                       <IconClose />
                   </IconButton>
               </Toolbar>
           	</AppBar>
           	<DialogContent>
           		<ViewPayInsList payIns={payInActivity}/>
           	</DialogContent>
            <DialogActions>
               <Button onClick={toggleViewAllActivity} color='primary'>Close</Button>
            </DialogActions>
			</Dialog>
		</React.Fragment>
	)
}
export default withMobileDialog()(Summary)
