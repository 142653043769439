import React from 'react'
import {formattedNumber} from '../../../includes/functions'
import ImageSelector from '../../components/ImageSelector'

import Button from '@material-ui/core/Button'
import MenuItem from '@material-ui/core/MenuItem'
import Slider from '@material-ui/core/Slider'
import Text from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'

function Step1 ({campaignInfo, promoImages, updateCampaignSetup}) {
	const [target, setTarget] = React.useState(campaignInfo.target)
	const [message, setMessage] = React.useState('')
	const [selectedPromo, setSelectedPromo] = React.useState(campaignInfo.promoImage)
	const [category, setCategory] = React.useState(campaignInfo.category)
	const [title, setTitle] = React.useState(campaignInfo.title)
	const [about, setAbout] = React.useState(campaignInfo.about)
	let promos = promoImages

	const handleChangeDonation = (event, newValue) => {
		setMessage('')
		setTarget(newValue)
	}
	const handleSelectPromo = (src) => {
		setSelectedPromo(src)
		if (!promos.includes(src)) {
			promos.push(src)
		}
	}
	const handleChange = (f,value) => {
		setMessage('')
		f(value)
	}
	const handlePreview = () => {
		let i = {
			target: target,
			category: category,
			title: title,
			about: about,
			promos: promos,
			selectedPromo: selectedPromo
		}
		if (!category) {
			return setMessage('Sorry, please select a Category for your campaign')
		}
		if (title === '' || about === '') {
			return setMessage('Sorry, both Title and About are required')
		}
		updateCampaignSetup(i)
	}

	return (
		<div style={{maxWidth:500, margin:'0 auto'}}>
			
			<div style={{marginTop:20}}>
				<Text variant='subtitle2' color='primary'>How much would you like to raise?</Text>
				<div align='center' style={{marginTop:5}}>
					<Text component='span' variant='h2' style={{color:'#d4d4d4'}}>$</Text>
					<Text component='span' variant='h2' color='primary'>{formattedNumber(target,0)}</Text>
					<Text component='span' variant='h3' style={{color:'#d4d4d4'}}> USD</Text>
				</div>
				<Slider value={target} onChange={handleChangeDonation} step={100} min={100} max={10000} />
			</div>

			<div style={{marginTop:20}}>
				<Text variant='subtitle2' color='primary'>Select or Upload an Image for your campaign.</Text>
				{/** ImageSelector Starts Here */}
				<div align='center' style={{ maxWidth: 600, margin:'0 auto', marginTop:0}}>
					<ImageSelector 
						imgBorder='1px solid #ccc'
						imgBorderRadius={10}
						imgPadding={5}
						imgRatio='16:9'
						images={promos}
						currentImage={campaignInfo.promoImage}
						selectImage={handleSelectPromo}
					/>
				</div>
				{/** ImageSelector Ends Here */}
			</div>

			<div style={{marginTop:20}}>	
				<Text variant='subtitle2' color='primary'>Select a category for your fundraiser?</Text>
				<TextField select fullWidth onChange={(e) => { handleChange(setCategory, e.target.value) }} value={category} size='small' variant='outlined' id='category'>
				  	<MenuItem value='0' >Select Category</MenuItem>
				  	<MenuItem value='Charity'>Charity</MenuItem>
				  	<MenuItem value='Education'>Education</MenuItem>
				  	<MenuItem value='Family'>Family</MenuItem>
				  	<MenuItem value='Home Improvement'>Home Improvement</MenuItem>
				  	<MenuItem value='Repairs'>Repairs</MenuItem>
				</TextField>
			</div>

			<div style={{marginTop:20}}>
				<Text variant='subtitle2' color='primary'>Give your campaign a title</Text>
				<TextField 
					placeholder='Campaign Title'
					//style={{maxWidth:300}}
					fullWidth 
					onChange={(e) => { handleChange(setTitle, e.target.value) }} 
					value={title} 
					size='small' 
					variant='outlined' 
				/>
			</div>

			<div style={{marginTop:20}}>
				<Text variant='subtitle2' color='primary'>Tell us about this campaign</Text>
				<TextField 
					placeholder='About Campaign'
					multiline
	       		rows={4}
					fullWidth 
					onChange={(e) => { handleChange(setAbout, e.target.value) }} 
					value={about} 
					size='small' 
					variant='outlined'
				/>
			</div>

			<div align='center' style={{ height: 60, padding: 10, borderBottom: '2px solid #ddd' }}>
            <Text variant='body2' style={{ color: 'red' }}>{message}</Text>
         </div>

		  	<div align='right' style={{marginTop:20}}>
			 	<Button variant='contained' color='secondary' onClick={handlePreview}>
					preview
			 	</Button>
			</div>				
		</div>
	)
}
export default Step1
