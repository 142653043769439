import React from 'react'
import { connect } from 'react-redux'
import { loadAccountSummary, getPayOutMethods, addPayOutMethod, deletePayOutMethod, requestPayout, cancelPayoutRequest } from '../../../redux/member/actions'
import { selectorLoadMemberActivity } from '../../../redux/member/selectors'

import PageMessage from '../../components/PageMessage'
import NextUpdate from '../../components/NextUpdate'
import SubNav from '../../components/SubNav'
import Summary from './Summary'
import Funds from './Funds'
import PayOuts from './PayOuts'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'

function TabPanel(props) {
  	const { children, value, index, ...other } = props;
  	return (
    	<div role='tabpanel' hidden={value !== index} id={`nav-tabpanel-${index}`} {...other} >
      	{value === index && ( children )}
    	</div>
  	)
}

class MemberAccountContainer extends React.Component {
	mounted = true
	state = {
		pageLoading: true,
		pageTitle: undefined,	//Not Found',
		pageText: undefined,	//The campaign you are looking for was not found',
		activeTab: 0
  	}
  	componentDidMount = () => {
		this.handleLoadAccountSummary(false)
	}
	componentWillUnmount = () => {
		this.mounted = false
	}
	handleLoadAccountSummary = (force) => {
	 	return new Promise((resolve, reject) => {
			let _this = this
		 	this.props.loadAccountSummary(force)
		 	.then(() => {
		 		if(_this.mounted) {
		 			_this.setState({ pageLoading:false, pageTitle:undefined, pageText:undefined })
		 		}
		 	})	
		 	.catch((e) => {
		 		if(_this.mounted) {
		 			_this.setState({ pageLoading:false, pageTitle:e.title, pageText:e.message })
		 		}
		 	})
		 	.finally(() => {
		 		resolve()
		 	})		
	 	})
	}
  	handleTabChange = (event, newValue) => {
  		this.setState({activeTab:newValue})
  	}
  	render() {
  		const {pageLoading, pageTitle, pageText, activeTab} = this.state
  		const {userInfo, lastUpdated, credits, payIns, payOuts, payOutMethods, pendingRequests, allActivity, payInActivity, payOutActivity} = this.props
  		const {getPayOutMethods, addPayOutMethod, deletePayOutMethod, requestPayout, cancelPayoutRequest} = this.props

    	if(pageLoading || pageTitle) {
			return (<PageMessage pageLoading={pageLoading} pageTitle={pageTitle} pageText={pageText} />)
		}

    	return (
   		<React.Fragment>
   		<Container maxWidth='lg' style={{ padding: 10, marginBottom: 50 }}>
				<Grid container justify='center' spacing={2}>
					<Grid item xs={12} sm={12} md={10} >
						<SubNav text='My Account'/>
					
						<div style={{borderBottom: '2px solid #eee'}}>  
						  	<Tabs value={activeTab} onChange={this.handleTabChange} variant='fullWidth' indicatorColor='primary' textColor='primary'>
						    	<Tab label='Summary' />
						    	<Tab label='Funds' />
						    	<Tab label='Payouts' />
						  	</Tabs>
						</div>

						<TabPanel value={activeTab} index={0}>
				        	<Summary
				        		credits = {credits}
								payIns = {payIns}
								payOuts = {payOuts}
								payOutMethods = {payOutMethods}
								payInActivity ={payInActivity}
				        	/>
				      </TabPanel>
				      <TabPanel value={activeTab} index={1}>
				        	<Funds
				        		credits = {credits}
				        		allActivity = {allActivity}
				        	/>
				      </TabPanel>
				      <TabPanel value={activeTab} index={2}>
				        	<PayOuts
				        		userInfo = {userInfo}
				        		credits = {credits}
				        		payOutActivity = {payOutActivity}
				        		payOutMethods = {payOutMethods}
				        		getPayOutMethods = {getPayOutMethods}
				        		addPayOutMethod = {addPayOutMethod}
				        		deletePayOutMethod = {deletePayOutMethod}
				        		pendingRequests = {pendingRequests}
				        		requestPayout = {requestPayout}
				        		cancelPayoutRequest = {cancelPayoutRequest}
				        	/>
				      </TabPanel>

					</Grid>
				</Grid>
				<NextUpdate lastUpdated={lastUpdated} updateNow={this.handleLoadAccountSummary}/>
			</Container>
   		</React.Fragment>
    	)
  	}
}
const mapStateToProps = (state) => ({
	userInfo: state.auth.userInfo,
	lastUpdated: state.member.lastUpdated,
	credits: state.member.credits,
	payIns: state.member.payIns,
	payOuts: state.member.payOuts,
	payOutMethods:  state.member.payOutMethods,
	pendingRequests: state.member.payOutPendingRequests,
	allActivity: state.member.transactions,
	payInActivity: selectorLoadMemberActivity(state.member.transactions, 'payIns'),
	payOutActivity: selectorLoadMemberActivity(state.member.transactions, 'payOuts'),
})
const mapDispatchToProps = {	
	loadAccountSummary: loadAccountSummary, 
	getPayOutMethods: getPayOutMethods,
	addPayOutMethod: addPayOutMethod, 
	deletePayOutMethod: deletePayOutMethod,
	requestPayout: requestPayout,
	cancelPayoutRequest: cancelPayoutRequest
}
export default connect(mapStateToProps, mapDispatchToProps)(MemberAccountContainer)
