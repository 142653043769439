import React from 'react'
import { withRouter } from 'react-router-dom'
import Payments from '../Payments/'
import { withStyles } from '@material-ui/core/styles'
import DonationGuidelines from '../DonationGuidelines'

import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import Collapse from '@material-ui/core/Collapse'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Paper from '@material-ui/core/Paper'
import Slider from '@material-ui/core/Slider'
import Text from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'

const PrettoSlider = withStyles({
  root: {
    //color: '#52af77',
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: -8,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider)

function Donate({history, loggedIn, campaignId, beneficiary}) {
	const [expandPayment, setExpandPayment] = React.useState(false)
	const [disableDonate, setDisableDonate] = React.useState(false)
	const [guidelines, showGuidelines] = React.useState(false)

	const [donationAmount, setDonationAmount] = React.useState(25)
	const [donorMessage, setDonorMessage] = React.useState('')
	const [checkedAnonymous, setCheckedAnonymous] = React.useState(false)
	const [checkedHideMessage, setCheckedHideMessage] = React.useState(false)
	const [checkedTos, setCheckedTos] = React.useState(false)

	const donate = () => {
		setExpandPayment(true)
		setDisableDonate(true)
	}
	const handleChangeDonation = (event, newValue) => {
    	setDonationAmount(newValue)
  	}
	const handleChangeMessage = (e) => {
		setDonorMessage(e.target.value)
	}
	const handleClickLink = (link) => {
   	let loginReturnLink= 'rURL=/view/' + campaignId
      history.push({ pathname: link, search:loginReturnLink })
   }
   const showGuidelinesToggle = () => {
   	showGuidelines(!guidelines)
   }
	if (!campaignId || !beneficiary) { return (null) }

	return (
		<Paper style={{padding: 10, marginTop:20, paddingBottom:0}} >
			<Text variant='h6'>Contribute to this fundraiser</Text>
			<div style={{paddingTop:5, margin:'0 auto'}}>
				<Button onClick={donate} disabled={disableDonate} style={{padding:15}} fullWidth variant='contained' color='secondary'>donate</Button>
			</div>
			<Collapse in={expandPayment} timeout='auto' unmountOnExit>
			
  				{(!loggedIn) ? (
  					<div style={{padding:20}}>
						Please <b>Login</b> / <b>Register</b> to make your contribution.<br/>
						This is required so we know where to send your Donation Receipt.<br/> 
						It takes <b>one minute</b> and you would be returned right here when complete.
						<div align='center'>
							<Button onClick={() => {handleClickLink('/login')}} variant='contained' color='secondary' style={{margin:10}}>Login</Button>
							<Button onClick={() => {handleClickLink('/email/register')}} variant='contained' color='secondary' style={{margin:10}}>Register</Button>
						</div>
					</div>
  				) : (
  					<React.Fragment>
						<div style={{padding:10}}>
							<div align='center'>
								<Text component='span' variant='h1' style={{color:'#d4d4d4'}}>$</Text>
								<Text component='span' variant='h1' color='primary'>{donationAmount}</Text>
								<Text component='span' variant='h4' style={{color:'#d4d4d4'}}> USD</Text>
							</div>
							<PrettoSlider 
								value={donationAmount} 
								onChange={handleChangeDonation} 
								step={5}
        						min={5}
        						max={2500}
        					/>
        					<TextField
				          	fullWidth
				          	value={donorMessage}
				          	onChange={handleChangeMessage}
        						margin='dense'
				          	variant='outlined'
				          	label='Message to Beneficiary (optional)'
				          	id='donorMessage'
				          	multiline
				          	rows={4}
				          	style={{marginTop:10, marginBottom:10}}
				        	/>
				        	<FormControlLabel
					        control={<Checkbox checked={checkedAnonymous} onChange={ ()=>{setCheckedAnonymous(!checkedAnonymous)} } />}
					        label='Make donation anonymously.'
					        style={{marginLeft:0}}
					      />
					      <FormControlLabel
					        control={<Checkbox checked={checkedHideMessage} onChange={ ()=>{setCheckedHideMessage(!checkedHideMessage)} } />}
					        label='Hide message from public.'
					        style={{marginLeft:0}}
					      />
					      <FormControlLabel
					        	control={<Checkbox checked={checkedTos} style={{ zIndex: 3 }} onChange={ ()=>{setCheckedTos(!checkedTos)} } />}
					      	style={{borderTop:'2px solid #f1f1f1', borderBottom:'2px solid #f1f1f1', marginLeft:0}}
					        	label={
					        		<label>
					        			Please review our <Button style={{padding:0}} onClick={showGuidelinesToggle}><b>Donation Guidelines</b></Button>, then check this box to continue.
					        		</label>
					        	}
					      />
						</div>

						<Collapse in={checkedTos} timeout='auto' unmountOnExit>
						   <div align='center' style={{backgroundColor:'#f1f1f1', padding:10, borderRadius:3}}>
								<Text variant='h6' color='secondary'>Select Payment Method</Text>
								<Payments 
									type='campaign' 
									id={campaignId}
									amount={donationAmount}
									message={donorMessage}
									checkedAnonymous={checkedAnonymous}
									checkedHideMessage={checkedHideMessage}
								/>
							</div>
						</Collapse>
						<DonationGuidelines open={guidelines} showGuidelinesToggle={showGuidelinesToggle}/>
					</React.Fragment>
  				)}
			</Collapse>
			<div align='right' style={{padding:5}}>
				<Text variant='overline' color='textSecondary' style={{lineHeight:.7}}><i>Beneficiary: {beneficiary}</i></Text>
			</div> 
		</Paper>			
	)
}
export default withRouter(Donate)
