import React from 'react'

import Loader from '../../components/Loader'
import SubNav from '../../components/SubNav'
import Summary from './Summary'
import Notifications from './Notifications'
import Share from './Share'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'

function TabPanel(props) {
  	const { children, value, index, ...other } = props;
  	return (
    	<div role='tabpanel' hidden={value !== index} id={`nav-tabpanel-${index}`} {...other} >
      	{value === index && ( children )}
    	</div>
  	)
}

class GroupDashboardContainer extends React.Component {
	state = {
	 	loading:false,
		activeTab: 0
  	}
  	handleChange = (event, newValue) => {
  		this.setState({activeTab:newValue})
  	}
  	render() {
  		const {loading, activeTab} = this.state
    	return (
   		<React.Fragment>
   		<Loader loading={loading}/>
   		<Container maxWidth='lg' style={{ padding: 10, marginBottom: 50 }}>
				<Grid container justify='center' spacing={2}>
					<Grid item xs={12} sm={12} md={10} >
						<SubNav text='Dashboard'/>
					
						<div style={{borderBottom: '2px solid #eee'}}>  
						  	<Tabs
						    	value={activeTab}
						    	variant="fullWidth"
						    	onChange={this.handleChange}
						    	indicatorColor="primary"
						    	textColor="primary"							    
						  	>
						    	<Tab label='Summary' />
						    	<Tab label='Notifications' />
						    	<Tab label='Share' />
						  	</Tabs>
						</div>

						<TabPanel value={activeTab} index={0}>
				        	<Summary/>
				      </TabPanel>
				      <TabPanel value={activeTab} index={1}>
				        	<Notifications/>
				      </TabPanel>
				      <TabPanel value={activeTab} index={2}>
				        	<Share/>
				      </TabPanel>

					</Grid>
				</Grid>
			</Container>
   		</React.Fragment>
    	)
  	}
}
export default GroupDashboardContainer
