import React from 'react'
import { connect } from 'react-redux'
import { loadCampaigns  } from '../../redux/public/actions'

import PageMessage from '../components/PageMessage'
import CampaignCard from '../components/CampaignCard'

import AppBar from '@material-ui/core/AppBar'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import IconFilterList from '@material-ui/icons/FilterList'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Paper from '@material-ui/core/Paper'
import Text from '@material-ui/core/Typography'
import Toolbar from '@material-ui/core/Toolbar'

class Campaigns extends React.Component {
	mounted = true
	state = {
		anchorEl: null,
		pageLoading: true,
		pageTitle: undefined,	// Not Found',
		pageText: undefined,		// The campaign you are looking for was not found',
		type: undefined,			// type: list | category | location
		filter: undefined,
		campaigns: {}
	}
	componentDidMount = () => {
		this.handleLoadCampaigns()
	}
	componentDidUpdate = (prevProps) => {
		if((this.props.match.params.type !== this.state.type) || (this.props.match.params.filter !== this.state.filter)) {
			this.handleLoadCampaigns()
		}
	}
	componentWillUnmount = () => {
		this.mounted = false
	}
	handleLoadCampaigns = () => {
	 	return new Promise((resolve, reject) => {
	 		let _this = this
		 	let type = this.props.match.params.type
		 	let filter = this.props.match.params.filter

		 	_this.setState({ pageLoading:true, pageTitle:undefined, pageText:undefined, type:type, filter:filter })
		 	_this.props.loadCampaigns(type, filter)
		 	.then(() => {
		 		if(_this.mounted) {
		 			_this.setState({pageLoading:false})
		 		}
		 	})	
		 	.catch((e) => {
		 		if(_this.mounted) {
		 			_this.setState({pageLoading:false, pageTitle:e.title, pageText:e.message})
		 		}
		 	})
		 	.finally(() => {
		 		if(_this.mounted) {
		 			this.handleFilterCampaigns(type,filter)
		 		}
		 		resolve()
		 	})
		})		
	}
	handleFilterCampaigns = (type='list', filter='all') => {
		let campaigns = this.props.campaigns
		let filteredCampaigns = {}

		switch (type.toLowerCase()) {
			case 'location':
				Object.keys(campaigns).forEach(function (i) {
					if (campaigns[i].location.toLowerCase() === filter.toLowerCase()) {
						filteredCampaigns[i] = campaigns[i]
					}
				})
				break
			case 'category':
				Object.keys(campaigns).forEach(function (i) {
					if (campaigns[i].category.toLowerCase() === filter.toLowerCase()) {
						filteredCampaigns[i] = campaigns[i]
					}
				})
				break
			default:
				switch (filter) {
               case 'featured':
                  Object.keys(campaigns).forEach(function (i) {
							if (campaigns[i].featured) {
								filteredCampaigns[i] = campaigns[i]
							}
						})
                  break
               case 'trending':
               	let trending = Object.values(campaigns)
                  trending.sort((a, b) => (a.dateOfLastDonation > b.dateOfLastDonation) ? -1 : 1)
                  trending.forEach((i) => {
                  	filteredCampaigns[i.id] = i
                  })
                  break
               default: // all
                  let all = Object.values(campaigns)
                  all.sort((a, b) => (a.dateStart > b.dateStart) ? -1 : 1)
                  all.forEach((i) => {
                  	filteredCampaigns[i.id] = i
                  })
            }			
		}
		this.setState({ campaigns:filteredCampaigns })
	}
	handleListItemClick = (type,filter) => {
		this.setState({ anchorEl:null })
		this.props.history.push({ pathname: '/campaigns/' + type + '/' + filter }) 
	}

	handleMenuClick = (event) => {
		this.setState({ anchorEl:event.currentTarget })
	}    
	handleMenuClose = () => {
		this.setState({ anchorEl:null })
	}
	render() {
		const {anchorEl, pageLoading, pageTitle, pageText, type, filter, campaigns} = this.state

		const category = type + '-' + filter
		const name = {
			'undefined-undefined':'Recent Campaigns', 
			'list-all':'Recent Campaigns', 
			'list-featured':'Featured Campaigns', 
			'list-trending':'Trending Campaigns', 
			'category-Education':'Category: Education',
			'category-Family':'Category: Family',
			'category-Charity':'Category: Charity'
		}

		if(pageLoading || pageTitle) {
			return (<PageMessage pageLoading={pageLoading} pageTitle={pageTitle} pageText={pageText} />)
		}
		return (
			<React.Fragment>
				<Container maxWidth='lg' style={{ padding: 10, marginBottom: 50 }}>
					<Grid container justify='center' spacing={2}>
						<Grid item xs={12} sm={12} md={10} >
							<AppBar position='static' color='default'>
								<Toolbar variant="dense">
									<Button>
										<Text variant='h6' color='primary' style={{ paddingRight: 15, marginRight:0 }}>
										  	<b>Campaigns</b>
										</Text>
									</Button>
								</Toolbar>
							</AppBar> 
						
							<Paper style={{ marginTop:20, padding: 20, minHeight:800 }} >
								
								<Toolbar variant="dense" style={{ paddingLeft:5, display: 'flex', borderBottom: '2px solid #ccc'}}>
									<Button size='small' onClick={this.handleMenuClick}><IconFilterList style={{marginRight:5}}/>{name[category]}</Button>
									<Menu keepMounted anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={this.handleMenuClose}>
										<Text variant='overline' style={{ padding:5, color:'#999' }}>Browse Campaigns</Text>
						           	<Divider />
										<MenuItem selected={(category === 'list-all')} onClick={() => { this.handleListItemClick('list', 'all') }}>- Recent</MenuItem>
										<MenuItem selected={(category === 'list-featured')} onClick={() => { this.handleListItemClick('list', 'featured') }}>- Featured</MenuItem>
										<MenuItem selected={(category === 'list-trending')} onClick={() => { this.handleListItemClick('list', 'trending') }}>- Trending</MenuItem>
										<Text variant='overline' style={{ padding:5, color:'#999' }}>Browse Categories</Text>
										<Divider/>
										<MenuItem selected={(category === 'category-Education')} onClick={() => { this.handleListItemClick('category', 'Education') }}>- Education</MenuItem>
										<MenuItem selected={(category === 'category-Family')} onClick={() => { this.handleListItemClick('category', 'Family') }}>- Family</MenuItem>
										<MenuItem selected={(category === 'category-Charity')} onClick={() => { this.handleListItemClick('category', 'Charity') }}>- Charity</MenuItem>
									</Menu>
								</Toolbar>

								<Grid container justify='center' spacing={2}>
									<Grid item xs={12}>
										{(Object.keys(campaigns).length) ? (
											<Grid container spacing={2} style={{marginTop:20}}>
												{Object.keys(campaigns).map((i) => {
													return ( 
														<Grid key={i} item xs={12} sm={6} md={4}>
															<CampaignCard campaign={campaigns[i]} /> 
														</Grid>
													)
												})}
											</Grid>
										) : (
											<div style={{padding:30, textAlign:'center'}}>
												<Text variant='body1' color='textSecondary'>No Results for Current Filter</Text>						
											</div>
										)}		
									</Grid>	
								</Grid>
							</Paper>
						</Grid>
					</Grid>
				</Container>
			</React.Fragment>
		)
	}
}
const mapStateToProps = (state) => ({
	campaigns: state.public.campaigns || {}
})
const mapDispatchToProps = {
	loadCampaigns
}
export default connect(mapStateToProps, mapDispatchToProps)(Campaigns)
