import { MEMBER_LOAD_ACCOUNT_SUMMARY, MEMBER_LOAD_CAMPAIGNS_AND_BOARDS, MEMBER_LOAD_DONATIONS_AND_BOOKMARKS, MEMBER_LOAD_NOTIFICATIONS, MEMBER_LOAD_MORE_NOTIFICATIONS, MEMBER_CREATE_EDIT_CAMPAIGN, MEMBER_SUPPORT_LOAD, MEMBER_SUPPORT_LOAD_TICKET, MEMBER_UPDATE_PAYOUT_METHODS, MEMBER_UPDATE_PAYOUT_REQUESTS } from '../types'

const initialState = {
	lastUpdated: 0,
	credits: 0,
	payIns: 0,
	payOuts: 0,
	boards: {},
	campaigns: {},
	donations: {},
	bookmarks: {},
	notifications: {},
	transactions: {},
	payOutMethods: {},
	payOutPendingRequests: {},
	supportGroups: [],
	supportCategories: [],
	supportTickets: {}
}

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case MEMBER_LOAD_ACCOUNT_SUMMARY:
			return { 
				...state, 
				lastUpdated: action.payload.timestamp,
				credits: action.payload.credits,
				payIns: action.payload.payIns,
				payOuts: action.payload.payOuts,
				payOutMethods: action.payload.payOutMethods,
				payOutPendingRequests: action.payload.payOutPendingRequests,
				transactions: action.payload.transactions
			}
		case MEMBER_LOAD_CAMPAIGNS_AND_BOARDS:
			return { 
				...state, 
				lastUpdated: action.payload.timestamp,
				campaigns: action.payload.campaigns,
				boards: action.payload.boards
			}
		case MEMBER_LOAD_DONATIONS_AND_BOOKMARKS:
			return { 
				...state, 
				lastUpdated: action.payload.timestamp,
				donations: action.payload.donations,
				bookmarks: action.payload.bookmarks
			}
		case MEMBER_LOAD_NOTIFICATIONS:
			return { 
				...state, 
				lastUpdated: action.payload.timestamp,
				notifications: action.payload.notifications
			}
		case MEMBER_LOAD_MORE_NOTIFICATIONS:
			return { 
				...state, 
				lastUpdated: action.payload.timestamp,
				notifications: {
               ...state.notifications,
               ...action.payload.notifications
            }
			}
		case MEMBER_SUPPORT_LOAD:
			return { 
				...state, 
				lastUpdated: action.payload.timestamp,
				supportGroups: action.payload.supportGroups,
				supportCategories: action.payload.supportCategories,
				supportTickets: action.payload.supportTickets
			}	
		case MEMBER_SUPPORT_LOAD_TICKET:
			return { 
				...state, 
				supportTickets: {
               ...state.supportTickets,
               [action.payload.id]: action.payload.info
            }
			}				
		case MEMBER_CREATE_EDIT_CAMPAIGN:
			return { 
				...state, 
				lastUpdated: action.payload.timestamp,
				campaigns: {
               ...state.campaigns,
               [action.payload.id]: action.payload.info
            }
			}
		case MEMBER_UPDATE_PAYOUT_METHODS:
			return { 
				...state, 
				lastUpdated: action.payload.timestamp,
				payOutMethods: action.payload.methods
			}
		case MEMBER_UPDATE_PAYOUT_REQUESTS:
			return { 
				...state, 
				lastUpdated: action.payload.timestamp,
				payOutPendingRequests: action.payload.requests
			}	
		default:
			return state
	}
}
