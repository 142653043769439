import React from 'react'
import queryString from 'query-string'
import { Link, withRouter } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Divider from '@material-ui/core/Divider'
import Hidden from '@material-ui/core/Hidden'
import IconAccountCircle from '@material-ui/icons/AccountCircle'
import IconButton from '@material-ui/core/IconButton'
import IconExitApp from '@material-ui/icons/ExitToApp'
import IconHome from '@material-ui/icons/Home'
import IconMenu from '@material-ui/icons/Menu'
import IconMoreVert from '@material-ui/icons/MoreVert'
//import IconSearch from '@material-ui/icons/Search'
import IconSettings from '@material-ui/icons/Settings'
//import IconPerson from '@material-ui/icons/Person'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemText from '@material-ui/core/ListItemText'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Text from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Toolbar from '@material-ui/core/Toolbar'

const useStyles = makeStyles(theme => ({
	leftButtons: {
		flexGrow: 1,
		marginLeft: -20,
	},
	topButton: {
		paddingLeft: 10,
		paddingRight: 10
	},
	menuButton: {
		marginRight: theme.spacing(2),
	},
	loggedin: {
		marginRight: 12,
		lineHeight: 1.20
	},
	header: {
		paddingLeft: 5,
		color: '#999'
	}
}))

const TopNav = (props) => {
	 const style = useStyles()
	 const [menuEl, setMenuEl] = React.useState(null)
	 const [loggedInEl, setLoggedInEl] = React.useState(null)   
	 const [openSearch, setOpenSearch] = React.useState(false)
	 const [searchValue, setSearchValue] = React.useState('')
	 const qs = queryString.parse(props.location.search)
	 const rURL = qs.rURL

	 const handleClickLink = (link) => {
			reset()
			let search = '' 
			if(link === '/login' || link === '/email/register') {
				 search = (rURL) ? 'rURL='+rURL : 'rURL='+props.location.pathname
			}
			props.history.push({ pathname: link, search:search })
	 }
	 const handleMenuItemClick = (option) => {
			reset()
			props.handleOptionChange(option)
	 }
	 const search = () => {
			let value = searchValue
			reset() 
			props.history.push({ pathname: '/search/'+value })
	 }
	 const logoutUser = () => {
			reset()
			props.logOut()
	 }
	 const reset = () => {
			setMenuEl(null)
			setLoggedInEl(null)      
			setOpenSearch(false)
			setSearchValue('')      
	 }
	 return (
			<React.Fragment>
				 {(props.publicRoute) ? (
						<AppBar position='static'>
							 <Toolbar>
									<Hidden smUp>
										 <IconButton className={ style.menuButton } color='inherit' onClick={ props.sideNavToggle }>
												<IconMenu />
										 </IconButton>
									</Hidden>
									<div className={ style.leftButtons }>
										 <Button color='inherit' component={ Link } to='/' style={{marginRight:15, textTransform: 'none'}}><Text variant='h6' color='inherit'>LetsFundIt</Text></Button>
										 <Hidden xsDown>
												<Button className={style.topButton} size='small' color='inherit' component={ Link } to={'/campaigns'}>Campaigns</Button>
												<Button className={style.topButton} size='small' color='inherit' component={ Link } to={'/charities'}>Charities</Button>
												<Button className={style.topButton} size='small' color='inherit' component={ Link } to={'/boards'}>Feature Boards</Button>
										 </Hidden>
									</div>
								{/*
									<IconButton color="inherit" onClick={ () => { setOpenSearch(true) }}><IconSearch /></IconButton>
								*/}
									{(props.loggedIn) ? (                  
											<List component='nav' color='inherit'>
												 <ListItem button onClick={(event) => { setLoggedInEl(event.currentTarget) }}>
														<Avatar style={{ border: '1.5px solid #ddd', margin: 0, color: '#fff' }}
														alt={ props.selectedOption.name }
														src={ props.selectedOption.thumb }
														onClick={(event) => { setLoggedInEl(event.currentTarget) }}
														>
														{ props.selectedOption.initials }
												 </Avatar>         
														<IconMoreVert />
												 </ListItem>
											</List> 
									):(
										 <IconButton color="inherit" onClick={ (event) => { setMenuEl(event.currentTarget) }}><IconAccountCircle /></IconButton>
									)}
							 </Toolbar>
						</AppBar>
				 ):(
						<AppBar position='static'>
							<Toolbar>
								<Button className={ style.menuButton } color='inherit' startIcon={<IconMenu />} onClick={ props.sideNavToggle }>Menu</Button>
								<IconButton className={ style.menuButton } color='inherit' size='small' onClick={() => {handleClickLink('/')}}><IconHome /></IconButton>
								<div className={ style.leftButtons } /> 
								<List component='nav' color='inherit'>
									<ListItem button onClick={(event) => { setLoggedInEl(event.currentTarget) }}>
										<Avatar style={{ border: '1.5px solid #ddd', margin: 0, color: '#fff' }}
										   alt={ props.selectedOption.name } src={ props.selectedOption.thumb }
										>
										   { props.selectedOption.initials }
										</Avatar>         
										<IconMoreVert />
									</ListItem>
								</List>               
							 </Toolbar>
						</AppBar>         
				 )}

				 <Menu dense='true' anchorEl={menuEl} open={Boolean(menuEl)} onClose={() => { setMenuEl(null) }}>
						<MenuItem button onClick={() => {handleClickLink('/login')}}>Login</MenuItem>
						<MenuItem button onClick={() => {handleClickLink('/email/register')}}>Register</MenuItem>
						<Divider/>
						<MenuItem>
							 <Button fullWidth size='small' variant='contained' color='secondary' style={{textTransform:'none'}} onClick={() => {handleClickLink('/start')}}>Start a LetsFundIt</Button>
						</MenuItem>
				 </Menu>

				 <Menu dense='true' anchorEl={loggedInEl} open={Boolean(loggedInEl)} onClose={() => { setLoggedInEl(null) }}>
						<Text variant='body2' component='p' className={ style.header }>
								Log in as:
						</Text>
						<Divider />
						{props.loginOptions && props.loginOptions.map((option, index) => (
								<MenuItem
								key={index}
								selected={!props.publicRoute && option.name === props.selectedOption.name && option.role === props.selectedOption.role }
								onClick={(event) => {handleMenuItemClick(index)}}
								>
								<Avatar style={{ margin: 0, color: '#fff' }}
										alt={ option.name }
										src={ option.thumb }
								>
										{ option.initials }
								</Avatar>
								<ListItemText style={{ marginLeft: 15}} primary={option.name} secondary={option.role ? 'Role: ' + option.role.replace(/_/g, ' ') : 'Go to my Account'} />
								</MenuItem>
						))}  

						<MenuItem button onClick={() => {handleClickLink('/member/profile')}} >
							 <ListItemAvatar>
									<Avatar>
										<IconSettings />
									</Avatar>
							 </ListItemAvatar>
							 <ListItemText primary='Profile Settings' />
						</MenuItem>
						
						<Divider />
						<MenuItem>
							 <Button fullWidth size='small' variant='contained' color='secondary' onClick={() => {handleClickLink('/member/campaign')}}>start a letsfundit</Button>
						</MenuItem>

						<Divider />
						<MenuItem button onClick={logoutUser} >
							 <ListItemAvatar>
									<Avatar>
										<IconExitApp />
									</Avatar>
							 </ListItemAvatar>
							 <ListItemText primary='Logout' />
						</MenuItem>
				 </Menu>

				 <Dialog open={openSearch} onClose={() => { setOpenSearch(false) }}>
						<DialogTitle>Search</DialogTitle>
						<DialogContent>
							 <DialogContentText>Enter Keyword or Hashtag to search letsFundIt</DialogContentText>
							 <TextField
									autoFocus
									fullWidth
									margin="dense"
									label="Enter Keyword or Hashtag"
									value={searchValue}
									onChange={(e)=>{setSearchValue(e.target.value)}}
							 />
						</DialogContent>
						<DialogActions>
							 <Button onClick={() => { setOpenSearch(false) }} color="primary">Cancel</Button>
							 <Button onClick={search} color="primary">Search</Button>
						</DialogActions>
				 </Dialog>
			</React.Fragment>
	 )
}
export default withRouter(TopNav)
