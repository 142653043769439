import React from 'react'
import { withRouter } from 'react-router-dom'

import AppBar from '@material-ui/core/AppBar'
import Button from '@material-ui/core/Button'
import Text from '@material-ui/core/Typography'
import Toolbar from '@material-ui/core/Toolbar'

function SubNav({history, text, buttonText, buttonLink}) {
	const goTo = () => {
      history.push({ pathname: buttonLink })
   }
	return (
		<AppBar position='static' color='default'>
			<Toolbar variant="dense">
				<Button>
					<Text variant='h6' color='primary' style={{ paddingRight: 15, marginRight:0 }}>
					  	<b>{text}</b>
					</Text>
				</Button>
				{(buttonText) ? (
					<div align='right' style={{padding: 0, flexGrow:1}}>
						<Button onClick={goTo} variant='contained' color='secondary' size='small'>{buttonText}</Button>
					</div>
				) : (null)}
			</Toolbar>
		</AppBar> 
	)
}
export default withRouter(SubNav)
