import React from 'react'
import moment from 'moment'
import Loader from '../../../components/Loader'

import Button from '@material-ui/core/Button'
import Collapse from '@material-ui/core/Collapse'
import Divider from '@material-ui/core/Divider'
import Paper from '@material-ui/core/Paper'
import Text from '@material-ui/core/Typography'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import TextField from '@material-ui/core/TextField'

function TicketNotes({ticketId, notes, addTicketNote, status}){
	const [loading, setLoading] = React.useState(false)
	const [openNote, setOpenNote] = React.useState(false)
	const [message, setMessage] = React.useState('')
	const [note, setNote] = React.useState('')

	const handleChange = (e) => {
		setMessage('')
		setNote(e.target.value)
	}
	const handleUpdateNote = () => {
		if(note === ''){
			return setMessage('Please enter your note.')
		}
		setLoading(true)
		setMessage('Processing...')
		addTicketNote(ticketId, note)
		.then(() => {
			setLoading(false)
			setMessage('')
			setNote('')
			setOpenNote(false)
		})
		.catch((error) => {
			setLoading(false)
			setMessage(error)
		})
	}

   return (
    	<React.Fragment>
			<Loader loading={loading} />
			<Paper style={{marginTop:20, padding: 10}} >
				<Text variant='h5'>Ticket Notes</Text>
				<List>
					{notes.map((note,i) => {
						return (
							<div key={i}>
								<ListItem>									
									<ListItemText>
										<Text style={{marginTop:15, lineHeight:1.25, color:'#546e7a', whiteSpace:'pre-wrap'}}>{note.note}</Text>
										<Text style={{lineHeight:.8}} variant='caption' display='block' color='textSecondary'>
											{moment.unix(note.timestamp/1000).format('MMM Do YYYY - h:mm a')}
										</Text>
									</ListItemText>								
								</ListItem>
								<Divider/>
							</div>
						)
					})}
				</List>
				{(status !== 'archived') ? (
					<div align='right'><Button onClick={() => {setOpenNote(!openNote)}}>Add A Note</Button></div>
				):( null )}
				<Collapse in={openNote} timeout="auto" unmountOnExit>
					<div style={{padding:20}}>
					<Text variant='subtitle1' color='textSecondary' style={{lineHeight:1.25}}><i>Add a NOTE to this ticket.<br/> The client would not see this, only authorized Team Members.</i></Text> 
						<TextField
							style={{width:'100%', margin:'20px auto'}}
							id="post"
							label="Note"
							multiline
							rows={4}
							variant="outlined"
							value={note}
		          		onChange={handleChange}
						/>
						<div style={{textAlign:'center'}}><Text variant="overline" style={{color:'red'}}>{message}</Text></div>
						<Button onClick={handleUpdateNote} variant="contained" color="secondary" style={{marginBottom:20}}>Save Note</Button>
					</div>
				</Collapse>
			</Paper>			
		</React.Fragment>
   )
}
export default TicketNotes
