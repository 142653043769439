import React from 'react'
import countryList from '../../../includes/countryList'

import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import IconFolder from '@material-ui/icons/FolderOpen'
import IconLocation from '@material-ui/icons/LocationOn'
import Text from '@material-ui/core/Typography'

const useStyles = makeStyles((theme) => ({
	button: {
		padding:0, 
		color:'#ccc',
		textTransform: 'none !important'
	}
}))

function DetailsAbout({about,category,location}) {
	const classes = useStyles()

	return (
		<div>
			<Text>{about}</Text>
			<Grid container style={{borderTop: '2px solid #ccc', padding:5, marginTop:50}}>
				<Grid item sm={6} xs={6}>
					<Button size='small' startIcon={<IconFolder style={{marginBottom:4}} />} className={classes.button}>
						{category}
					</Button>
				</Grid>
				<Grid item sm={6} xs={6} style={{textAlign:'right'}}>
					{(countryList.find(o => o.code === location)) ? (
						<Button size='small' startIcon={<IconLocation style={{marginBottom:4}} />} className={classes.button}>
							{countryList.find(o => o.code === location).name}
						</Button>
					) : (null)}
				</Grid>
			</Grid>       
		</div>		
	)
}
export default DetailsAbout
