import React from 'react'
import AboutFeatureBoards from '../../components/AboutFeatureBoards'

import Button from '@material-ui/core/Button'
import Collapse from '@material-ui/core/Collapse'
import Divider from '@material-ui/core/Divider'
import IconExpandLess from '@material-ui/icons/ExpandLess'
import IconExpandMore from '@material-ui/icons/ExpandMore'
import Paper from '@material-ui/core/Paper'
import Text from '@material-ui/core/Typography'

function About() {
	const [about, showAbout] = React.useState(false)
	const [more, showMore] = React.useState(false)
	const showAboutToggle = () => {
   	showAbout(!about)
   }
	return (
		<div style={{maxWidth:650, margin:'0px auto', marginTop:15 }}>
			<Text variant='h5'>About Feature Boards</Text>
			<Paper elevation={1} style={{marginBottom:25, padding:15}} >
				<Text variant='h6'>What It Is</Text>
				<Text variant='body2'>
					A <b>LetsFundIt Feature Board</b> is a place where a group of like-minded people
					come together to give each other a hand. One persons' campaign is featured on the 
					board at a time, and it's featured until a predetermined amount of funds is raised.
				</Text>

				{(!more) ? (
				<div align='right'><Button onClick={() => { showMore(!more) }} size='small' color='secondary' startIcon={<IconExpandMore />}>Show More</Button></div>
				) : ( null )}
				
				<Collapse in={more} timeout='auto' unmountOnExit>
					<Text variant='h6'>How It Works</Text>
					<Text variant='body2'>
						All the members of the board work together to promote the board.
						New members must contribute to the boards' Featured Campaign before
						 they can get their campaign listed on it. 
						Campaigns are featured on the board in the order they were listed.

					</Text>
					<Text variant='h6'>Structure & Requirements</Text>
					<Text variant='body2'>
						<b>LetsFundIt Feature Boards</b> may vary in the details, which are decided by the boards owner,
						 but they all have a similar outline which is implemented and managed by <b>LetsFundIt</b>:
					</Text>
						 <ul>
						 	<li>Each member must contribute the same amount to qualify for a spot on the board</li>
						 	<li>Members are allowed to have the same amount of active spots</li>
						 	<li>Each member pays the same fee to get listed</li>
						 	<li>Each Campaign is Featured in the order it was listed</li>
						 </ul>

					<Divider />
					<Text color='textSecondary' variant='caption'>
						<i>Please keep in mind that a donation is a charity you give to someone without any expectations of anything in return. Although we all want to help each other in these times, please only give what you can afford to. We appreciate you and your contribution.</i>

					</Text>
					{/*
					<Text variant='body2'>
						 This is just an overview of <b>LetsFundIt Feature Boards</b>, for further 
						 details <Button onClick={() => { showAbout(!about) }} color='secondary' style={{padding:0}}>click here</Button>.
					</Text>
					*/}
					<div align='right'><Button onClick={() => { showMore(!more) }} size='small' color='secondary' startIcon={<IconExpandLess />}>Show Less</Button></div>
				</Collapse>
			</Paper>
			<AboutFeatureBoards open={about} showAboutToggle={showAboutToggle}/>
		</div>
	)
}
export default About
