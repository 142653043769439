import React from 'react'
import Text from '@material-ui/core/Typography'

function Stripe() {	
	return (
		<div align='center'>
			<Text variant='body1' color='textSecondary' style={{marginTop:25}}>Stripe payment option coming soon.</Text>
		</div>
	)
}
export default Stripe
