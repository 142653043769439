import React from 'react'
import { connect } from 'react-redux'
import { loadCampaignInfo, loadCampaignDonations, followCampaign } from '../../redux/public/actions'

import PageMessage from '../components/PageMessage'
import FundraiserDetails from '../components/FundraiserDetails/Details'
import FundraiserDonate from '../components/FundraiserDetails/Donate'
import FundraiserProfile from '../components/FundraiserDetails/Profile'
import FundraiserShare from '../components/Share'
import FundraiserStats from '../components/FundraiserDetails/Stats'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'

class View extends React.Component {
	mounted = true
	state = {
		pageLoading: true,
		pageTitle: undefined,	// Not Found',
		pageText: undefined,		// The campaign you are looking for was not found',
		campaignId: this.props.match.params.id
	}
	componentDidMount = () => {
		this.handleLoadInfo()
	}
	componentWillUnmount = () => {
		this.mounted = false
	}
	handleLoadInfo = () => {
	 	return new Promise((resolve, reject) => {
			let _this = this
		 	_this.props.loadCampaignInfo(_this.state.campaignId)
		 	.then(() => {
		 		if(_this.mounted) {
		 			_this.setState({pageLoading:false})
		 		}
		 	})	
		 	.catch((e) => {
		 		if(_this.mounted) {
		 			_this.setState({pageLoading:false, pageTitle:e.title, pageText:e.message})
		 		}
		 	})	
		 	.finally(() => {
		 		resolve()
		 	})
		})	
	}

	render() {
		const {pageLoading, pageTitle, pageText, campaignId} = this.state
		const {loggedIn, campaign, donations, loadCampaignDonations, followCampaign} = this.props

		if(pageLoading || pageTitle) {
			return (<PageMessage pageLoading={pageLoading} pageTitle={pageTitle} pageText={pageText} />)
		}
		if (!campaignId || !campaign){ return null }
		return (
			<React.Fragment>
				<Container maxWidth='lg' style={{ padding: 10, marginBottom: 150, minHeight:700  }}>
				<Grid container justify='center' spacing={2}>
					<Grid item xs={12} md={7} >
						<div style={{ padding:0, maxWidth:700, margin: '0 auto', }}>
							<FundraiserDetails details={campaign} campaignId={campaignId} donations={donations} loadCampaignDonations={loadCampaignDonations}/>
							<FundraiserProfile profile={campaign}/>
						</div>
					</Grid>

					<Grid item xs={12} md={5}  >
						<div style={{ padding:0, maxWidth:700, margin: '0 auto' }}>
							<FundraiserStats loggedIn={loggedIn} stats={campaign} followCampaign={followCampaign}/>
							<FundraiserDonate loggedIn={loggedIn} campaignId={campaignId} beneficiary={campaign.ownerName} />
							<FundraiserShare 
								path={'/view/' + campaignId}
								image={campaign.promoImage}
								title='Check out this LetsFundIt Campaign'
								description='Your friend would like you to check out a Campaign they saw on LetsFundIt'
							/>
						</div>
					</Grid>
				</Grid>
				</Container>
			</React.Fragment>
		)
	}
}
const mapStateToProps = (state, ownProps) => ({
	loggedIn: state.auth.loggedIn || false,
   campaign: state.public.campaignsInfo[ownProps.match.params.id] || {},
   donations: state.public.campaignsDonations[ownProps.match.params.id] || {}, 
})
const mapDispatchToProps = {
	loadCampaignInfo,
	loadCampaignDonations,
	followCampaign
}
export default connect(mapStateToProps, mapDispatchToProps)(View)
