import React from 'react'
import Loader from '../../components/Loader'
import { chkPassword }  from '../../../includes/functions'

import Button from '@material-ui/core/Button'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Info from '@material-ui/icons/InfoOutlined'
import InputAdornment from '@material-ui/core/InputAdornment'
import Paper from '@material-ui/core/Paper'
import Text from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'

function Security({updateMemberPassword}) {
	const [loading, setLoading] = React.useState(false)
	const [getOldPassword, setGetOldPassword] = React.useState(false)
	const [oldPassword, setOldPassword] = React.useState('')
	const [showPassword, setShowPassword] = React.useState(false) 
	const [showInfo, setShowInfo] = React.useState(false)
	const [password, setPassword] = React.useState('')
	const [confirm, setConfirm] = React.useState('')
	const [message, setMessage] = React.useState('')

	const handleChange = (f,v) => {
		f(v)
		setMessage ('')
	}
	const savePassword = () => {
		if (getOldPassword && !chkPassword(oldPassword)) {
			return setMessage('Please enter your Current Password.')
		}
		if (!chkPassword(password)) {
			return setMessage('Please enter a valid Password.')
		}
		if (password !== confirm) {
			return setMessage('Password and Confirm must match.')
		}
		setLoading(true)
		updateMemberPassword(oldPassword, password)
		.then(() => {
			setLoading(false)
			setOldPassword('')
			setPassword('')
			setConfirm('')
			setMessage('Password Updated')
		})
		.catch((error) => {
			setLoading(false)
			setMessage(error)
			if (error === 'Please enter your Current Password.') {
				setGetOldPassword(true)
			}
		})
	}
	return (
		<Paper style={{ padding: 20, minHeight:800 }} >
			<Loader loading={loading}/>
			<div style={{maxWidth:600, margin:'0px auto', marginTop:20}}>
				<Text variant='h5' gutterBottom>Security</Text>
				{(getOldPassword) ? (
					<Text variant='body1' color='textSecondary'>
						To set your password you must either enter your current password or re-login through your email.
					</Text>
				) : (
					<Text variant='body1' color='textSecondary'>
						Set an optional password to log into your account. If not you can always login to your account 
						through your email.
					</Text>
				)}

				<Grid container justify='center'>
					{(getOldPassword) ? (
						<Grid item xs={12} sm={12} style={{ padding: 5 }}>
							<TextField
								value={oldPassword}
								id='old'
								type={showPassword ? 'text' : 'password'}
								label='Current Password'
								onChange={(e) => { handleChange(setOldPassword, e.target.value) }}
								fullWidth={true}
								margin='normal'
								InputProps={{
									endAdornment: (
										<InputAdornment position='end'>
										<IconButton	onClick={() => { setShowPassword(!showPassword) }}>
											{showPassword ? <Visibility /> : <VisibilityOff />}
										</IconButton>
										</InputAdornment>
									)
								}}
							/>
						</Grid>
					):( null )}
					<Grid item xs={12} sm={6} style={{ padding: 5 }}>
						<TextField
							value={password}
							id='password'
							type={showPassword ? 'text' : 'password'}
							label='New Password'
							onChange={(e) => { handleChange(setPassword, e.target.value) }}
							fullWidth={true}
							margin='normal'
							InputProps={{
								endAdornment: (
									<InputAdornment position='end'>
									<IconButton	onClick={() => { setShowPassword(!showPassword) }}>
										{showPassword ? <Visibility /> : <VisibilityOff />}
									</IconButton>
									</InputAdornment>
								)
							}}
						/>
					</Grid>
					<Grid item xs={12} sm={6} style={{ padding: 5 }}>
						<TextField
							value={confirm}
							id='confirm'
							type={showPassword ? 'text' : 'password'}
							label='Confirm'
							onChange={(e) => { handleChange(setConfirm, e.target.value) }}
							fullWidth={true}
							margin='normal'
							InputProps={{
								endAdornment: (
									<InputAdornment position='end'>
									<ClickAwayListener onClickAway={() => { setShowInfo(false) }}>
										<span>
											<Tooltip
											PopperProps={{ disablePortal: false }}
											onClose={() => { setShowInfo(false) }}
											open={showInfo}
											disableFocusListener
											disableHoverListener
											disableTouchListener
											placement='top-end'
											title='password must be at least 6 characters long.'
											> 
											<IconButton aria-label='password must be at least 6 characters long.' onClick={() => { setShowInfo(!showInfo) }}>
												<Info />
											</IconButton>
											</Tooltip>
										</span>
									</ClickAwayListener>
									</InputAdornment>
								)
							}}
						/>                    
					</Grid>				
				</Grid>
				<div align='center' style={{paddingTop:5}}>
					<Text variant='body1' style={{ color: 'red' }}>{message}</Text>
				</div>
				<div align='center' style={{paddingTop:20}}>
					<Button onClick={savePassword} variant='outlined' color='secondary'>Save Password</Button>
				</div>
			</div>
		</Paper>
	)
}
export default Security
