import React from 'react'

import CircularProgress from '@material-ui/core/CircularProgress'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Text from '@material-ui/core/Typography'

function PageMessage({pageLoading, pageTitle='Page Loading', pageText='Loading content, please wait...'}){
	if (pageLoading) {
		return (
			<Container maxWidth='lg' style={{ padding: 10, marginBottom: 150, minHeight:700  }}>
				<Grid container justify='center' spacing={2}>
					<div align='center' style={{padding:50}}>
						<CircularProgress color='secondary' style={{ margin: '5px auto'}} />
						<Text variant='h6'>{pageTitle}</Text>
						<Text variant='subtitle1'>{pageText}</Text>
					</div>
				</Grid>
			</Container> 
		)
	}
	return (
		<Container maxWidth='lg' style={{ padding: 10, marginBottom: 150, minHeight:700  }}>
			<Grid container justify='center' spacing={2}>
				<div align='center' style={{padding:50}}>
					<Text variant='h6'>{pageTitle}</Text>
					<Text variant='subtitle1'>{pageText}</Text>
				</div>
			</Grid>
		</Container>
	)
}
export default PageMessage