import React from 'react'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Text from '@material-ui/core/Typography'

function Search(props) {
	const search = props.match.params.value
	return (
		<Container maxWidth='lg' style={{ padding: 10, marginBottom: 150, minHeight:700  }}>
		<Grid container justify='center' spacing={2}>
			<Grid item xs={12} sm={8} md={8} >
				<Paper style={{ padding: 20 }} >
					<Text variant='h5' gutterBottom>
						Search
					</Text>
					<Text variant='caption' gutterBottom>
						{search}
					</Text>
				</Paper>
			</Grid>
		</Grid>
		</Container>
	)
}
export default Search
