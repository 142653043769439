import React from 'react'
import moment from 'moment'
import { withRouter } from 'react-router-dom'
import {money, formattedNumber} from '../../../includes/functions'

import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Text from '@material-ui/core/Typography'
import Collapse from '@material-ui/core/Collapse'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import IconDot from '@material-ui/icons/FiberManualRecord'
import IconMinus from '@material-ui/icons/Remove';
import IconPlus from '@material-ui/icons/Add';
import IconInfo from '@material-ui/icons/InfoOutlined';
import Tooltip from '@material-ui/core/Tooltip'
//import IconDot from '@material-ui/icons/FiberManualRecord'

function AccountHistory({ history, loggedIn, boardId, memberTotalContribution, donationRequirement, fetchBoardDetails }) {
	const mounted = React.useRef(true)
	const [account, showAccount] = React.useState(false)
	const [message, setMessage] = React.useState('')
	const [loadingBoard, setLoadingBoard] = React.useState(false)
	const [data, setData] = React.useState('')

	React.useEffect(() => {
		if (!memberTotalContribution) {
			setMessage({ title:'Board History', message:'you currently have no history on this board' })
		}
		return () => {
         mounted.current = false
      }
	},[memberTotalContribution])

	const showAccountToggle = () => {
		let open = !account
   	showAccount(!account)
   	if (memberTotalContribution && open) {
   		handleLoadBoardData()
   	}
   }

	const handleLoadBoardData = (id) => {
  		setMessage('')
  		setLoadingBoard(true)
  		fetchBoardDetails(boardId)
  		.then((data) => {
  			if (mounted.current) {
  				setData(data)
  				setLoadingBoard(false)
  				console.log(data)
  			}
  		})
  		.catch((e) => {
  			if (mounted.current) {
  				setMessage(e)
  				setLoadingBoard(false)
  				console.log(e)
  			}
  		})  		
  	}

	const goTo = (link) => {
      history.push({ pathname:link })
   }
console.log('data: ',data)
   if (!loggedIn) return null
	return (
		<React.Fragment>
			<div align='right' style={{maxWidth:650, margin:'0px auto', marginTop:15,}}>
				<Button onClick={() => { goTo('/stepbystep/'+boardId) }} variant='outlined' color='secondary'>Join this board via Step by Step guide</Button>
			</div>
			<div align='right' style={{maxWidth:650, margin:'0px auto', marginTop:15,}}>
				<Button onClick={showAccountToggle} variant='outlined' color='secondary'>{(account) ? 'Hide my board history' : 'View my board history'}</Button>
			</div>
			<Collapse in={account} timeout='auto' unmountOnExit>
				<Paper style={{maxWidth:650, margin:'0px auto', marginTop:15}}>
					{(message) ? (
						<div align='center' style={{ padding:10 }}>
							<Text variant='h6' color='textSecondary'>{message.title}</Text>
							<Text variant='subtitle1' color='textSecondary'>{message.message}</Text>
						</div>
	        		) : (
	        			(loadingBoard) ? (
		        			<div align='center' style={{ padding:10 }}>
								<CircularProgress color='secondary' style={{ margin: '5px auto'}} />
								<Text variant='h6'>Loading Board History</Text>
								<Text variant='subtitle1'>Checking server for up to date board details.</Text>
							</div>
		        		):(
	        				<div style={{padding:10}}>
								<Text variant='h6' color='secondary'>Spots Summary</Text>
								<div style={{marginBottom:5, padding:5, backgroundColor:'#f1f1f1'}}>
								{data.spots && Object.keys(data.spots).map((i, key) => {
									return (
										<div key={key}>
											<Grid container style={{padding:4, backgroundColor:'#fff'}}>
								     			<Grid item xs={6} style={{textAlign:'right'}}>
								     				<Text variant='body1'><b>{data.spots[i].type}</b></Text>
								     			</Grid>
								     			<Grid item xs={6}>
								     				<IconDot style={{color:data.spots[i].color, textSize:'500px', marginBottom:-6, marginLeft:10, marginRight:5}}/>
								     				<Text variant='body1' component='span'><b>{data.spots[i].number}</b></Text>
								     			</Grid>
								     		</Grid>
								     		<div style={{height:1, margin:2}} />
								     	</div>
									)
								})}
					     		</div>
					     		<div style={{textAlign:'center'}}>
									<IconDot style={{margin:2, color:'red', marginBottom:-3, fontSize:16}}/>
									<Text variant='caption' color='textSecondary'>Completed</Text>
									<IconDot style={{margin:2, color:'#ffd333', marginBottom:-3, fontSize:16}}/>
									<Text variant='caption' color='textSecondary'>Pending</Text>
									<IconDot style={{margin:2, color:'#2dd117', marginBottom:-3, fontSize:16}}/>
									<Text variant='caption' color='textSecondary'>Active</Text>
									<IconDot style={{margin:2, color:'gray', marginBottom:-3, fontSize:16}}/>
									<Text variant='caption' color='textSecondary'>Current</Text>
								</div>
					     		<div style={{marginBottom:20}}>
					     			<Text color='textSecondary' variant='body2'>This is the Feature Board Chart for <b>{data.boardName}</b>, it lists the boards' current spot and all your spots on the board.</Text>
					     		</div>

					     		<Text variant='h6' color='secondary'>Account Summary</Text>
								<div align='center' style={{marginBottom:20, backgroundColor:'#fafafa', padding:10, borderRadius:3}}>
									<Text component='span' style={{borderBottom: '2px solid gray', paddingBottom:3}}>Total Contribution: <b>{money(memberTotalContribution,0)}</b></Text>
									<div style={{margin:'0 auto', maxWidth:200, marginTop:10}}>
									<Grid container justify='center' spacing={0}>
										<Grid item xs={9} style={{textAlign:'right'}}>Qualified Spots - </Grid>
										<Grid item xs={3} style={{textAlign:'center'}}>{(data.totalDonated) ? formattedNumber(parseInt(data.totalDonated / donationRequirement),0) : 0}</Grid>
										<Grid item xs={9} style={{textAlign:'right'}}>Used Spots - </Grid>
										<Grid item xs={3} style={{textAlign:'center'}}>{formattedNumber(data.totalSpotsUsed,0)}</Grid>
										<Grid item xs={9} style={{textAlign:'right'}}><b>Available Spots - </b></Grid>
										<Grid item xs={3} style={{textAlign:'center'}}><b>{formattedNumber(data.totalSpotsAvailable,0)}</b></Grid>
									</Grid>
									</div>
								</div>

								<Text variant='h6' color='secondary'>Account History</Text>
								<div style={{ padding:5, backgroundColor:'#f1f1f1'}}>
								{data.ledger && data.ledger.map((rec, key) => {
									return (
										<div key={key}>
											<Grid container alignItems='center' style={{padding:4, backgroundColor:'#fff'}}>
								     			<Grid item xs={8} sm={8}>
								     				<Text variant='caption' color='textSecondary'><i>{moment.unix(rec.date).format('MMM Do YYYY - h:mm a')}</i></Text>
								     				<Text variant='body1' style={{lineHeight:1}}><b>{rec.description}</b></Text>
								     				<Text variant='caption' color='textSecondary'><i>{rec.note}</i></Text>
								     			</Grid>
								     			<Grid item xs={4} sm={4} style={{textAlign:'right', paddingRight:5}}>
								     				{(rec.type === 'plus') ? (
								     					<IconPlus style={{color:'green', marginBottom:-5, marginRight:5}} fontSize='small'/>
								     				) : (
								     					<IconMinus style={{color:'red', marginBottom:-5, marginRight:5}} fontSize='small'/>
								     				)}
								     				<Text variant='body1' component='span'><b>{ money(rec.amount,0) } </b></Text>
													<Text variant='caption' component='span' color='textSecondary'><b> USD</b></Text><br/>
								     				<Tooltip title={rec.info} placement='top-end'>
								     					<IconInfo style={{color: 'lightGray', marginBottom:-5, marginLeft:5}} fontSize='small'/>
								     				</Tooltip>
								     			</Grid>
											</Grid>
											<div style={{height:1, margin:2}} />
										</div>
									)
								})}
								</div>
								<div style={{marginBottom:20}}>
					     			<Text color='textSecondary' variant='body2'>
					     				Please note that this Account History shows figures before any deducted fees.
					     				To get the real balance in your account please <Button onClick={ () => { goTo('/member/account') }} size='small' style={{padding:0}}>click here</Button> to go to your account page.</Text>
					     		</div>
	        				</div>
	        			)	
	        		)}
				</Paper>						
			</Collapse>
		</React.Fragment>
	)
}
export default withRouter(AccountHistory)
