import React from 'react'

class Step5 extends React.Component {
	render() {
		return (
			<div>
				WHATS Next<br/>
				- how to access affilate page<br/>
				- how to add team members<br/>
				- how to get verified
			</div>
		)
	}
}
export default Step5
