import React from 'react'
import moment from 'moment'
import Details from './Details'

import { green } from '@material-ui/core/colors'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import IconArrowForward from '@material-ui/icons/ArrowForwardIos'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import Paper from '@material-ui/core/Paper'
import Text from '@material-ui/core/Typography'

function SupportList({history, type, tickets, goToTab, updateTicket, closeTicket}) {
	const [ticketId, setTicketId] = React.useState('')

	const openTicketToggle = (id) => {
		setTicketId(id)
	}

	if (ticketId && tickets[ticketId]) {
		return (
			<Details 
				ticketId={ticketId} 
				ticket={tickets[ticketId]} 
				openTicketToggle={openTicketToggle}
				updateTicket={updateTicket}
				closeTicket={closeTicket}
				goToTab={goToTab}
			/>
		)
	}

	return (
		<Paper style={{ padding: 20, minHeight:800 }} >
			{(Object.keys(tickets).length) ? (
				<List style={{width:'100%', margin:'0px auto', backgroundColor:'#fff', padding:5}}>
				{Object.keys(tickets).map((i, key) => {
					return (
						<div key={key}>
							<ListItem button alignItems="flex-start" onClick={() => {openTicketToggle(i)}}>
								<ListItemAvatar>
								   <Avatar alt={tickets[i].groupName} src={tickets[i].groupImage} style={{borderRadius:5, width:70, height:70}} />
								</ListItemAvatar>
								<ListItemText style={{marginLeft:10, paddingTop:5}}>
									<Text style={{lineHeight:.8}} variant='caption' display='block' color='textSecondary'>
										{moment.unix(tickets[i].timestamp).format('MMM Do YYYY - h:mm a')}
									</Text>
									<Text style={{marginTop:5, lineHeight:1, color:'#546e7a'}}><b>{tickets[i].subject}</b></Text>
									{(tickets[i].closed) ? (
										<Text style={{lineHeight:1.5, color:'red'}}><b>CLOSED</b></Text>
									):(
										<Text style={{lineHeight:1.5, color:green[500]}}><b>OPEN</b></Text>
									)}
								</ListItemText>
								<ListItemSecondaryAction>
								   <IconArrowForward />
								</ListItemSecondaryAction>
							</ListItem>
							<Divider style={{height:3}}/>                
						</div>
					)
				})}
				</List>
			) : (
				<div style={{marginTop:50, textAlign:'center'}}>
					<Text variant='body1' color='textSecondary'>NO {type} TICKETS FOUND</Text>
				</div>
	  		)}   	  		
	  		<div align='right' style={{ marginTop:50 }}>
	  			<Button size='small' variant='outlined' color='secondary' onClick={() => { goToTab(2) }}>Open New Ticket</Button>
	  		</div>
		</Paper>
	)
}
export default SupportList
