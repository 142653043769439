import React from 'react'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Text from '@material-ui/core/Typography'

function HowItWorks() {
	return (
		<Container maxWidth='lg' style={{ padding: 10, marginBottom: 150, minHeight:700  }}>
		<Grid container justify='center' spacing={2}>
			<Grid item xs={12} sm={8} md={8} >
				<Paper style={{ padding: 20 }} >
					<Text variant='h5' gutterBottom>
						HowItWorks
					</Text>
					<Text variant='caption' gutterBottom>
						<ul>
							<li>Who can post a fundraiser</li>
							<li>What type of fundraisers are allowed</li>
							<li>How are payments made</li>
							<li>What are Feature Boards</li>
							<li>How they work and the benefits of them</li>
							<li>What are Premium Feature Boards and who are the Affilates that run them.</li>
						</ul>
					</Text>
				</Paper>
			</Grid>
		</Grid>
		</Container>
	)
}
export default HowItWorks
