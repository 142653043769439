import React from 'react'
import { connect } from 'react-redux'
import { transferGetProfile, transferSubmit, transferConfirm } from '../../../redux/payments/actions'
import { chkEmail, formattedNumber }  from '../../../includes/functions'

import Loader from '../../components/Loader'
import SubNav from '../../components/SubNav'

import CircularProgress from '@material-ui/core/CircularProgress'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Text from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Slider from '@material-ui/core/Slider'

class MemberTransferContainer extends React.Component {
	mounted = true
	state = {
		loading: false,
		step: 1,
		message: '',
		email: '',
		amount: 100,
		info: {},
		error: {}
  	}
  	componentDidMount = () => {
  		this.handleConfirm()
  	}
	componentWillUnmount = () => {
		this.mounted = false
	}
	goTo = (path) => {
		this.props.history.push({ pathname: path })
	}
	handleChange = (value) => {
		this.setState({ message:'', email:value })
	}
	handleChangeAmount = (event, newValue) => {
		this.setState({ message:'', amount:newValue })
	}
	handleCheckForUser = () => {
		let _this = this
		let email = this.state.email.toLowerCase()
		if (!chkEmail(email)) {
      	return this.setState({ message: 'Please enter a valid email address.' })
   	}
	   this.setState({step:2, message:''})
	   this.props.transferGetProfile(email)
		.then((info) => {
			if (_this.mounted) {
				_this.setState({ step:3, info:info })
			}
		})
		.catch((e) => {
			if (_this.mounted) {
				_this.setState({ step:1, message:e.message })
			}
		})
	}
	handleTransfer = () => {
		let _this = this
		let email = this.state.email.toLowerCase()
		let amount = this.state.amount
		this.setState({step:2, message:''})
		this.props.transferSubmit(email, amount)
		.then((info) => {
			if (_this.mounted) {
				_this.setState({ step:4 })
			}
		})
		.catch((e) => {
			if (_this.mounted) {
				_this.setState({ step:1, message:e.message })
			}
		})
	}
	handleConfirm = () => {
		let _this = this
		let transferId = this.props.match.params.transferId
		let tokenId = this.props.match.params.tokenId

  		if (transferId && tokenId) {
			this.setState({step:2, message:''})
		   this.props.transferConfirm(transferId, tokenId)
			.then((info) => {
				if (_this.mounted) {
					_this.setState({ step:5, info:info }) // step 5 - transfer completed
				}
			})
			.catch((e) => {
				if (_this.mounted) {
					_this.setState({ step:6, error:e }) // step 6 - error transfering
				}
			})
  		}
	}
  	render() {
  		const { loading, step, message, email, amount, info, error } = this.state

    	return (
   		<React.Fragment>
   		<Loader loading={loading} />
   		<Container maxWidth='lg' style={{ padding: 10, marginBottom: 50 }}>
				<Grid container justify='center' spacing={2}>
					<Grid item xs={12} sm={12} md={10} >
						<SubNav text='Transfer'/>
				     	<Paper style={{ marginTop:20, padding: 20, minHeight:800 }} >
				     		<div style={{ margin:'0px auto', marginTop:20, padding:10, maxWidth:500}}>
				     			<Text variant='h5'>Transfer Credits</Text>
				     			<Divider />
				     			<div align='center' style={{padding:5}}><Text variant='body1' style={{color:'red'}}><b>{ message }</b></Text></div>		
				     			
				     			{(step === 1) ? (
				     			<div  style={{padding:10, textAlign:'center'}}>
					     			<TextField 
					     				type='email'
										placeholder='Member Email Address'
										style={{minWidth:300}}
										onChange={(e) => { this.handleChange(e.target.value) }} 
										value={email} 
										size='small' 
										variant='outlined' 
									/>
									<Button onClick={this.handleCheckForUser} variant='contained' color='secondary' size='small' style={{margin:5}}>submit</Button>
				     			</div>
				     			) : ( null )}

				     			{(step === 2) ? (
				     			<div  style={{padding:10, textAlign:'center'}}>
					     			<CircularProgress color='secondary' style={{ margin: '5px auto'}} />
									<Text variant='h6'>Processing Request</Text>
									<Text variant='subtitle1'>Checking server to complete request.</Text>
				     			</div>
				     			) : ( null )}

				     			{(step === 3) ? (
				     			<div  style={{padding:10, textAlign:'center'}}>
					     			<Grid container justify='center' spacing={2} style={{maxWidth:500}}>
										<Grid item xs={12} sm={4}>
											<img alt={info.name} src={info.image} style={{width:'100%', maxWidth:300}}/>
										</Grid>
										<Grid item xs={12} sm={8}>
											<Text variant='h6'>{info.name}</Text>
											<Text variant='body1' color='textSecondary'>{info.email}</Text>
											<div align='center' style={{marginTop:30}}>
												<Text component='span' variant='h2' style={{color:'#d4d4d4'}}>$</Text>
												<Text component='span' variant='h2' color='primary'>{formattedNumber(amount,0)}</Text>
												<Text component='span' variant='h3' style={{color:'#d4d4d4'}}> USD</Text>
											</div>
											<Slider value={amount} onChange={this.handleChangeAmount} step={5} min={10} max={info.credits} />
											<Button onClick={this.handleTransfer} style={{marginTop:20, padding:10}} variant='contained' color='secondary'>Transfer ${formattedNumber(amount,0)} Now</Button>
										</Grid>
									</Grid>
				     			</div>
				     			) : ( null )}

				     			{(step === 4) ? (
				     			<div  style={{padding:10, textAlign:'center'}}>
					     			<Text variant='body1'><b>Transaction Pending Approval</b></Text>
					     			<Text variant='body1'>Please check your email to confirm and complete this transaction</Text>
				     			</div>
				     			) : ( null )}

				     			{(step === 5) ? (
				     			<div  style={{padding:10, textAlign:'center'}}>
					     			<Text variant='h6'><b>Transaction Complete</b></Text>
					     			<Text variant='body1'>You just completed a transfer of <b>${formattedNumber(info.amount,0)}</b> to <b>{info.name}</b> ({info.email})</Text>
				     			</div>
				     			) : ( null )}

				     			{(step === 6) ? (
				     			<div  style={{padding:10, textAlign:'center'}}>
					     			<Text variant='h6' style={{color:'red'}}><b>{error.title}</b></Text>
					     			<Text variant='body1'>{error.message}</Text>					     			
				     			</div>
				     			) : ( null )}

				     			<div align='right' style={{marginTop:100, borderTop:'2px solid #f1f1f1'}}><Button onClick={() => { this.goTo('/member/account') }} color='secondary' size='small'>go to my account</Button></div>
				     		</div>
				     	</Paper>
					</Grid>
				</Grid>
			</Container>
   		</React.Fragment>
    	)
  	}
}
const mapDispatchToProps = {
	transferGetProfile, 
	transferSubmit,
	transferConfirm
}
export default connect(null, mapDispatchToProps)(MemberTransferContainer)