import React from 'react'
import moment from 'moment'
import {money} from '../../../includes/functions'

import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import Grid from '@material-ui/core/Grid'
import IconExpandMore from '@material-ui/icons/ExpandMore'
import Text from '@material-ui/core/Typography'

function ViewTransactionsList({funds, count}){
	const [expanded, setExpanded] = React.useState(false)
	
	const handleChange = (panel) => (event, isExpanded) => {
    	setExpanded(isExpanded ? panel : false);
  	}
	if (!funds || !Object.keys(funds).length) {
		return (
			<div align='center' style={{margin:0, padding:50, backgroundColor:'#f1f1f1'}}><Text variant='body1' color='textSecondary'>No Transaction History</Text></div>
		)	
	}

	return (
		<div>
			{Object.keys(funds).slice(0,count).map((i, key) => {
				return (
					<Accordion key={key} expanded={expanded === i} onChange={handleChange(i)} style={{borderRight:(funds[i].type === 'payIn') ? '2px solid #89fa89' : '2px solid #ff9e9e'}}>
						<AccordionSummary	expandIcon={<IconExpandMore />}>
							<Grid container>
				     			<Grid item xs={12} sm={4}>
				     				<Text variant='body1' color='textSecondary'><i>{moment.unix(funds[i].timestamp).format('MMM Do YYYY - h:mm a')}</i></Text>
				     			</Grid>
				     			<Grid item xs={12} sm={4}>
				     				<Text variant='body1' color='primary'><b>{funds[i].description}</b></Text>
				     			</Grid>
				     			<Grid item xs={6} sm={2}>
				     				<Text variant='body1' color='textSecondary'><b>{funds[i].type}</b></Text>
				     			</Grid>
				     			<Grid item xs={6} sm={2} style={{textAlign:'right'}}>
				     				<Text variant='body1' component='span' color='primary'><b>{ money(funds[i].total,0) } </b></Text>
									<Text variant='caption' component='span' color='textSecondary'><b> USD</b></Text>
				     			</Grid>
							</Grid>
						</AccordionSummary>
						<AccordionDetails>
			        		<div style={{margin:'0 auto', width:'100%', maxWidth:600, marginBottom:25}}>
								<Grid container>
				        			<Grid item xs={4} style={{textAlign:'center'}} >
				        				<Text variant='overline'><b>{funds[i].transType}</b></Text><br/>
				        				<Text variant='h6' component='span' color='primary' style={{lineHeight:1}}><b>{ money(funds[i].amount,0) } </b></Text>
				        			</Grid>
				        			<Grid item xs={4} style={{textAlign:'center'}} >
				        				<Text variant='overline'><b>fees</b></Text><br/>
				        				<Text variant='h6' component='span' color='primary' style={{lineHeight:1}}><b>{ money(funds[i].fee,0) } </b></Text>
				        			</Grid>
				        			<Grid item xs={4} style={{textAlign:'center'}} >
				        				<Text variant='overline'><b>{(funds[i].type === 'payIn') ? 'received' : 'deducted'}</b></Text><br/>
				        				<Text variant='h6' component='span' color='primary' style={{lineHeight:1}}><b>{ money(funds[i].total,0) } </b></Text>
				        			</Grid>
				        			<Grid item xs={12} style={{textAlign:'center', paddingTop:10}}>
				        				{(funds[i].type === 'payIn') ? (	
				        					<Text variant='caption'>A payIn of { money(funds[i].amount,0) } was made, a fee of { money(funds[i].fee,0) } { funds[i].feeNote } was charged and you received { money(funds[i].total,0) } </Text>
				        				) : (
				        					<Text variant='caption'>You made a payOut of { money(funds[i].amount,0) } plus a fee of { money(funds[i].fee,0) }, a total of { money(funds[i].total,0) } was deducted from your credits. </Text>
				        				)}
				        			</Grid>
			        			</Grid>
							</div>
	        			</AccordionDetails>
					</Accordion>
				)
			})}
		</div>
	)
}
export default ViewTransactionsList
