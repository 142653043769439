import React from 'react'
import Loader from '../../components/Loader'

import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Paper from '@material-ui/core/Paper'
import Select from '@material-ui/core/Select'
import Text from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'

function New({supportGroups, supportCategories, openTicket, goToTab}) {
	const mounted = React.useRef(true)
	const [loading, setLoading] = React.useState(false)
	const [groupId, setGroupId] = React.useState('')
	const [category, setCategory] = React.useState('')
	const [subject, setSubject] = React.useState('')
	const [post, setPost] = React.useState('')
	const [message, setMessage] = React.useState('')

	// [START]: Track if component is mounted
   React.useEffect(() => {
      return () => { mounted.current = false }
   },[])
   // [END]: Track if component is mounted

	const handleSubmitTicket = () => {
		if(groupId === '' || category === '' || subject === '' || post === '') {
			return setMessage('Sorry, all fields are required.')
		}
		let ticket = {
			groupId: groupId,
			category: category,
			subject: subject,
			post: post
		}
		setLoading(true)
		openTicket(ticket)
		.then(() => {
			if (mounted.current) {
				setLoading(false)
				goToTab(0)
			}
		})
		.catch((e) => {
			if (mounted.current) {
				setLoading(false)
				setMessage(e.message)
			}
		})
	}

	return (
		<Paper style={{ padding: 20, minHeight:800 }} >
			<Loader loading={loading} />
			<div style={{maxWidth:600, margin:'0px auto', marginTop:10}}>
				<Text variant="caption" color="textSecondary">
					Need Assistance? <br/>Select the group you would like assistance with and open a Support Ticket with them.
				</Text>

				<FormControl style={{width:'100%', marginTop:15}}>
		        	<InputLabel>Who do you need assistance from?</InputLabel>
		        	<Select value={groupId} onChange={(e)=>{setGroupId(e.target.value)}}>
		        		{supportGroups && supportGroups.map((group) => {
						   return (
							 	<MenuItem key={group.id} value={group.id}>{group.name}</MenuItem>
						   )
						})}
		        	</Select>
		      </FormControl>

		      <FormControl style={{width:'100%', marginTop:10}}>
		        	<InputLabel>What do you need assistance with?</InputLabel>
		        	<Select value={category} onChange={(e)=>{setCategory(e.target.value)}}>
		        		{supportCategories && supportCategories.map((category) => {
		        			return (
		          			<MenuItem key={category.id} value={category}>{category.name}</MenuItem>
		        			)
		        		})}
		        	</Select>
		      </FormControl>

		      <TextField							
					style={{width:'100%', marginTop:10}}
					label="Subject"
					value={subject}
          		onChange={(e)=>{setSubject(e.target.value)}}
				/>

				<TextField
					style={{width:'100%', marginTop:30}}
					label="Post"
					value={post}
          		onChange={(e)=>{setPost(e.target.value)}}
					multiline
					rows={4}
					variant="outlined"
				/>

				<div style={{marginTop:10, textAlign:'center'}}><Text variant='body1' style={{color:'red'}}>{message}</Text></div>
				<div style={{marginTop:10, marginBottom:20, textAlign:'right'}}><Button onClick={handleSubmitTicket} variant="contained" color="secondary">Submit Ticket</Button></div>
			</div>
		</Paper>
	)
}
export default New
