import React from 'react'
import { withRouter } from 'react-router-dom'

import {money} from '../../../includes/functions'
import ViewPayOutsList from './ViewPayOutsList'
import PayoutRequests from './components/PayoutRequests'
import PayoutMethods from './components/PayoutMethods'

import Alert from '@material-ui/lab/Alert'
import AppBar from '@material-ui/core/AppBar'
import Button from '@material-ui/core/Button'
import Collapse from '@material-ui/core/Collapse'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import IconClose from '@material-ui/icons/Close'
import IconPayouts from '@material-ui/icons/CallMade'
import Paper from '@material-ui/core/Paper'
import Slide from '@material-ui/core/Slide'
import Text from '@material-ui/core/Typography'
import Toolbar from '@material-ui/core/Toolbar'
import withMobileDialog from '@material-ui/core/withMobileDialog'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction='up' ref={ref} {...props} />
})

function Payouts({fullScreen, history, userInfo, credits, payOutActivity, payOutMethods, getPayOutMethods, addPayOutMethod, deletePayOutMethod, pendingRequests, requestPayout, cancelPayoutRequest}) {
	const [showAlert, setShowAlert] = React.useState(true)
	const [viewPayouts, setViewPayouts] = React.useState(false)
	const payouts = payOutActivity

  	const toggleViewAllPayouts = () => {
  		setViewPayouts(!viewPayouts)
  	}
  	const goTo = (path) => {
		history.push({ pathname: path })
	}
	return (
		<React.Fragment>
			<Paper style={{ padding: 20, minHeight:800 }} >

				<Grid container>
	     			<Grid item xs={12} sm={6} style={{textAlign:'left'}} >
	     				<Text variant='h4' color='primary' style={{lineHeight:1}}><b>Credits</b></Text>
	     				<Text variant='overline' color='textSecondary'><b>current balance</b></Text>
	     			</Grid>
	     			<Grid item xs={12} sm={6} style={{textAlign:'right'}} >
	     				<Text variant='h4' component='span' color='primary'><b>{ money(credits,0) } </b></Text>
						<Text variant='caption' component='span' color='textSecondary'><b> USD</b></Text>
	     			</Grid>
	     			<Grid item xs={12}><Divider/></Grid>
				</Grid>

				{(!payOutMethods || !Object.keys(payOutMethods).length) ? (
						<Collapse in={showAlert}>
						<div align='center' style={{margin:'0px auto', marginTop:20, maxWidth:600}}>
							<Alert severity="error"
								action={
					            <IconButton color='inherit' size='small' onClick={() => { setShowAlert(false) }}>
					              <IconClose fontSize='inherit' />
					            </IconButton>
					          }
							>You need to setup a <b>Payout Method</b> before you can <b>Request A Payout</b>.</Alert>
						</div>
						</Collapse>
				) : (null)}
			
				<div style={{marginTop:30}}>
					<Text variant='h5' color='textSecondary'>Payout History</Text>
					<ViewPayOutsList payouts={payouts} count={5}/>
					<div align='right'><Button size='small' color='secondary' onClick={toggleViewAllPayouts}>View All</Button></div>
				</div>

				<PayoutRequests 
					credits={credits} 
					payOutMethods={payOutMethods} 
					pendingRequests={pendingRequests} 
					requestPayout={requestPayout} 
					cancelPayoutRequest={cancelPayoutRequest}
				/>

				<PayoutMethods 
					userInfo={userInfo} 
					payOutMethods={payOutMethods}
					getPayOutMethods={getPayOutMethods} 
					addPayOutMethod={addPayOutMethod}
					deletePayOutMethod={deletePayOutMethod}
				/>

				<div style={{marginTop:150, marginBottom:100}}>
					<Text variant='h5' color='textSecondary'>Transfer Credits</Text>
					<Text variant='body1' color='textSecondary'>
						Transfer Funds to another LetsFundIt member via their email address. 
						This transaction CAN NOT be reversed, so please ensure you enter the correct email address. 
						<Button onClick={() => { goTo('/member/transfer') }} size='small' style={{padding:0, color:'gray'}}> Click Here to transfer credits</Button>
					</Text>
				</div>
			</Paper>

			<Dialog keepMounted fullScreen={fullScreen} open={viewPayouts} TransitionComponent={Transition} onClose={toggleViewAllPayouts}>
				<AppBar style={{ position: 'relative' }} >
               <Toolbar>
                   <IconPayouts style={{ marginRight: 5 }} />
                   <Text variant='subtitle2' color='inherit' style={{ flex: 1 }}>
                      	Payout History
                   </Text>
                   <IconButton color='inherit' onClick={toggleViewAllPayouts}>
                       <IconClose />
                   </IconButton>
               </Toolbar>
           	</AppBar>
           	<DialogContent>
           		<ViewPayOutsList payouts={payouts}/>
           	</DialogContent>
            <DialogActions>
               <Button onClick={toggleViewAllPayouts} color='primary'>Close</Button>
            </DialogActions>
			</Dialog>			
		</React.Fragment>
	)
}
export default withMobileDialog()(withRouter(Payouts))
