import React from 'react'
import swal from 'sweetalert2'
import {money} from '../../../includes/functions'
import Loader from '../../components/Loader'

import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Text from '@material-ui/core/Typography'

function Credits({processingFees, getUserCredits, userCredits, payWithCredits, type, id, amount, message, checkedAnonymous, checkedHideMessage, paymentDescription, buttonText}) {
	const mounted = React.useRef(true)
	const [loading, setLoading] = React.useState(false)
	const [chkCredits, setChkCredits] = React.useState(true)
	const [creditsError, setCreditsError] = React.useState(false)

	const donation = Number(amount)
	const fees = processingFees.creditsFixedFee
	const totalDue = donation + fees
	const newBalance = Number(userCredits) - totalDue

	// [START]: Track if component is mounted
   React.useEffect(() => {
      return () => {
         mounted.current = false
      }
   },[])
   // [END]: Track if component is mounted

	getUserCredits()
	.then(() => {
		if (mounted.current) {
			setChkCredits(false)	
		}
	})
	.catch(() => {
		if (mounted.current) {	
			setChkCredits(false)
			setCreditsError(true)
		}	
	})	
	const confirmDonation = () => {
		swal.fire({
			  title: 'Transfer ' + money(amount,2),
			  text: 'You are about to transfer ' + money(donation,2) + ' (plus ' + money(fees,2) + ') processing fee. You would not be able to undo this action.',
			  icon: 'warning',
			  showCancelButton: true,
			  confirmButtonColor: '#4caf50',
			  cancelButtonColor: '#d33',
			  confirmButtonText: 'Confirm Transaction'
		}).then((result) => {
			if (result.isConfirmed) {
			   makeDonation()
			}
		})		
	}
	const makeDonation = () => {
		setLoading(true)
		payWithCredits(type, id, donation, message, checkedHideMessage, checkedAnonymous)	
		.then(paymentUrl => {
			setLoading(false)
         window.location.assign(paymentUrl)
      })       
      .catch(error => {
        	setLoading(false)
         swal.fire({
            position: 'top-end',
            icon: 'error',
            title: error.title,
            text: error.message,
            showConfirmButton: false,
            timer: 3000
         })                    
      }) 		
	}
	if (chkCredits) { 
		return (
			<div align='center' style={{marginTop:20}}>
				<CircularProgress color='secondary' style={{ margin: '5px auto'}} />
				<Text variant='h6'>Checking Credits</Text>
				<Text variant='subtitle1'>Checking server for your Credits.</Text>
			</div>
		) 
	}
	if (creditsError) { 
		return (
			<div align='center' style={{marginTop:20}}>
				<Text variant='h6'>Server Error</Text>
				<Text variant='subtitle1'>Sorry, there was an error checking your Credits.</Text>
			</div>
		) 
	}
	return (
		<div>
			<Loader loading={loading} title={'Checking Credits'}/>
			<div align='center' style={{maxWidth:300, marginLeft:20, marginRight:10, paddingTop:20}}>
				<div style={{display: 'flex', lineHeight:1.5}}>
               <Text variant='h6' color='secondary'>{paymentDescription}:</Text>
               <div style={{ marginLeft:'auto', marginRight:0, textAlign:'right'}}>
                  <Text variant='h6' color='secondary'><i>{money(donation,2)}</i></Text>
               </div>
            </div>
            <div style={{display: 'flex', lineHeight:1.5}}>
               <Text variant='body1' color='textSecondary'>Processing Fee:</Text>
               <div style={{ marginLeft:'auto', marginRight:0, textAlign:'right'}}>
                  <Text variant='body1' color='textSecondary'><i>{money(fees,2)}</i></Text>
               </div>
            </div>
            <div style={{display: 'flex', lineHeight:1.5}}>
               <Text variant='h6' color='textSecondary'>Total:</Text>
               <div style={{ marginLeft:'auto', marginRight:0, textAlign:'right'}}>
                  <Text variant='h6' color='textSecondary'><i>{money(totalDue,2)}</i></Text>
               </div>
            </div>
            {(newBalance >= 0) ? (
					<div style={{marginTop:5, paddingTop:5, borderTop: '1px solid #f73378'}}>
						<Text variant='body1'>
							You currently have <b>{money(userCredits,2)}</b> credits.<br/>
							Your balance after {paymentDescription.toLowerCase()} and fees would be <b>{money(newBalance,2)}</b>
						</Text>
	            	<div style={{paddingTop: 10}}>
	               	<Button style={{backgroundColor:'#4caf50', color:'#fff'}} fullWidth variant='contained' color='primary' onClick={confirmDonation}>{buttonText}</Button>
	            	</div>
					</div>
            ):(
					<div style={{marginTop:5, paddingTop:5, borderTop: '1px solid #f73378'}}>
						<Text variant='body1'>
							You currently have <b>{money(userCredits,2)}</b> credits.<br/>
							You do not have enough credits to complete this transaction.
						</Text>
					</div>
            )}
		   </div>
		</div>		
	)
}
export default Credits
