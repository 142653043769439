import React from 'react'

import Paper from '@material-ui/core/Paper'
import Text from '@material-ui/core/Typography'

function Share() {
	return (
		<Paper style={{ padding: 20, minHeight:800 }} >
			<Text variant='h5' gutterBottom>
				Share
			</Text>
			<Text variant='caption' gutterBottom>
				Features to help organization share their campaigns.
			</Text>
		</Paper>
	)
}
export default Share
