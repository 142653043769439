import React from 'react'
import { connect } from 'react-redux'
import { loadBoardInfo } from '../../../redux/public/actions'
import { fetchBoardDetails } from '../../../redux/member/actions'

import PageMessage from '../../components/PageMessage'
import NextUpdate from '../../components/NextUpdate'
import Share from '../../components/Share'
import About from './About'
import AccountHistory from './AccountHistory'
import Feature from './Feature'
import Intro from './Intro'
import Join from './Join'
import Stats from './Stats'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Text from '@material-ui/core/Typography'

class BoardContainer extends React.Component {
	mounted = true
	state = {
		pageLoading: true,
		pageTitle: undefined,	// Not Found',
		pageText: undefined,		// The campaign you are looking for was not found',
		lastUpdated: Math.floor(Date.now() / 1000),
		boardId: this.props.match.params.id,
		board: {}
	}
	componentDidMount = () => {
		if (!this.state.boardId) {
			return this.setState({pageLoading:false, pageTitle:'Processing Error', pageText:'Sorry, board Id required.'})
		}
		this.handleLoadBoard()
	}
	componentWillUnmount = () => {
		this.mounted = false
	}
	handleLoadBoard = () => {
	 	return new Promise((resolve, reject) => {
			let _this = this
	 		let boardId = this.state.boardId
		 	this.props.loadBoardInfo(boardId)
		 	.then((data) => {
		 		if(_this.mounted) {
		 			let timestamp = Math.floor(Date.now() / 1000)
		 			_this.setState({pageLoading:false, lastUpdated:timestamp, board:data})
		 		}
		 	})	
		 	.catch((e) => {
		 		if(_this.mounted) {
		 			_this.setState({pageLoading:false, pageTitle:e.title, pageText:e.message})
		 		}
		 	})	
		 	.finally(() => {
		 		resolve()
		 	})
		})	
	}
	render() {
		const {pageLoading, pageTitle, pageText, lastUpdated, boardId, board} = this.state
		const {loggedIn, fetchBoardDetails} = this.props
console.log(board)
		if(pageLoading || pageTitle) {
			return (<PageMessage pageLoading={pageLoading} pageTitle={pageTitle} pageText={pageText} />)
		}
		if (!board) { 
			return (
				<Container maxWidth='lg' style={{ padding: 10, marginBottom: 150, minHeight:700  }}>
					<div align='center' style={{paddingTop:100}}><Text variant='h6' color='textSecondary'>Board Not Found</Text></div>
				</Container>
			) 
		}
		return (
			<React.Fragment>
				<Container maxWidth='lg' style={{ padding: 10, marginBottom: 150, minHeight:700  }}>
				<Grid container justify='center' spacing={2}>
					<Grid item xs={12} md={7} >
						<Intro 
							image={board.boardImage} 
							name={board.boardName} 
							intro={board.boardIntro} 
							signature={board.boardSignature}
						/>		
						<Feature 
							loggedIn={loggedIn} 
							boardId={boardId}
							featureImage={board.featureImage}
							featureTitle={board.featureTitle}
							featureAbout={board.featureAbout}
							featureOwner={board.featureOwner}
							featureRaised={board.featureRaised}
							donationTarget={board.donationTarget}
							donationDefault={board.donationDefault}
						/>
						<div style={{maxWidth:650, margin:'0px auto', padding:'0px 18px'}}>
							<Share 
								path={'/board/' + boardId}
								image={board.boardImage}
								title='Check out this LetsFundIt Feature Board'
								description='Your friend would like you to check out a Feature Board they saw on LetsFundIt'
							/>
						</div>
					</Grid>
					<Grid item xs={12} md={5}  >
						<Stats 
							name={board.boardName}
							raised={board.statsRaised} 
							featured={board.statsFeatured} 
							donations={board.statsDonations}
						/>	
						<About/>
						<Join 
							loggedIn={loggedIn} 
							isMember={board.isMember}
							boardId={boardId}
							boardName={board.boardName}
							boardOwner={board.boardOwner}
							boardFee={board.boardFee}
							boardSpotsAllowed={board.boardSpotsAllowed}
							donationRequirement={board.donationRequirement}
							memberTotalContribution={board.memberTotalContribution}
							memberTotalSpotsUsed={board.memberTotalSpotsUsed}
							memberTotalSpotsCompleted={board.memberTotalSpotsCompleted}
							memberCampaigns={board.memberCampaigns}
							termsOfService={board.termsOfService}
						/>
						<AccountHistory 
							loggedIn={loggedIn} 
							boardId={boardId} 
							memberTotalContribution={board.memberTotalContribution}
							donationRequirement={board.donationRequirement}
							fetchBoardDetails={fetchBoardDetails} 
						/>
					</Grid>
				</Grid>
				</Container>
				<NextUpdate lastUpdated={lastUpdated} updateNow={this.handleLoadBoard}/>
			</React.Fragment>
		)
	}
}
const mapStateToProps = (state, ownProps) => ({
	loggedIn: state.auth.loggedIn || false
})
const mapDispatchToProps = {
	loadBoardInfo,
	fetchBoardDetails
}
export default connect(mapStateToProps, mapDispatchToProps)(BoardContainer)
