import React from 'react'
import moment from 'moment'
import { withRouter } from 'react-router-dom'
import {money, progress, truncate, formattedNumber} from '../../../includes/functions'
import CampaignDonors from '../../components/CampaignDonors'
import Share from '../../components/Share'

import { green } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert'
import Avatar from '@material-ui/core/Avatar'
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionActions from '@material-ui/core/AccordionActions'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import IconDot from '@material-ui/icons/FiberManualRecord'
import IconExpandMore from '@material-ui/icons/ExpandMore'
import LinearProgress from '@material-ui/core/LinearProgress'
import Paper from '@material-ui/core/Paper'
import Text from '@material-ui/core/Typography'
import Switch from '@material-ui/core/Switch'

const GreenSwitch = withStyles({
  switchBase: {
    '&$checked': {
      color: green[500],
    },
    '&$checked + $track': {
      backgroundColor: green[500],
    },
  },
  checked: {},
  track: {},
})(Switch)

const getDaysActive = (day) => {
	let a = moment().startOf('day')
	let b = moment.unix(day);
	return a.diff(b, 'days')
}

function Campaigns({history, campaigns, fetchDonorDetails, campaignLiveToggle}) {
	const [expanded, setExpanded] = React.useState(false)
	const [showDonors, toggleDonors] = React.useState(false)
	const [campaignId, setCampaignId] = React.useState('')

  	const handleChange = (panel) => (event, isExpanded) => {
    	setExpanded(isExpanded ? panel : false);
  	}
  	const handleToggleDonors = (id) => {
  		setCampaignId(id)
  		toggleDonors(!showDonors)
  	}
  	const handleLiveToggle = (id, status) => {
  		campaignLiveToggle(id, !status)
  
  	}
	const goTo = (path) => {
      history.push({ pathname: path })
	}

	if (!campaigns || !Object.keys(campaigns).length) { 
		return (
			<Paper style={{ padding: 20, minHeight:800 }} >
				<div align='center' style={{marginTop:20}}>
					<Text variant='body1' color='textSecondary'>No Campaigns Listed</Text>
				</div>
			</Paper>
		) 
	}

	return (
		<Paper style={{ padding: 10, minHeight:800 }} >
			{(showDonors) ? (
				<CampaignDonors showDonors={showDonors} toggleDonors={toggleDonors} fetchDonorDetails={fetchDonorDetails} campaignId={campaignId}/>
			) : ( null )}
			{Object.keys(campaigns).map((i, key) => {
				return (
					<Accordion key={key} expanded={expanded === i} onChange={handleChange(i)}>
			        	<AccordionSummary	expandIcon={<IconExpandMore />}>
			        		<Avatar alt={campaigns[i].title} src={campaigns[i].promoImage} style={{borderRadius:5, margin:5, marginRight:10}} />
			        		<Grid container>
			        			<Grid item xs={12} sm={6} md={6} >
									<Text variant='h6' style={{marginTop:5, lineHeight:1.1}}>{truncate(campaigns[i].title, 25)}</Text>
										<Text variant='caption' display='block' color='textSecondary'>
											Listed on: {moment.unix(campaigns[i].dateStart).format('MMM Do YYYY - h:mm a')}
										</Text>
			        			</Grid>
			        			<Grid item xs={4} sm={2} md={2} style={{textAlign:'center'}} >
			        				<Text variant='overline'>donations</Text><br/>
			        				<Text variant='body1' color='primary'><b>{campaigns[i].totalDonors}</b></Text>
			        			</Grid>
			        			<Grid item xs={4} sm={2} md={2} style={{textAlign:'center'}} >
			        				<Text variant='overline'>days</Text><br/>
			        				<Text variant='body1' color='primary'><b>{getDaysActive(campaigns[i].dateStart)}</b></Text>
			        			</Grid>
			        			<Grid item xs={4} sm={2} md={2} style={{textAlign:'center'}} >
			        				<Text variant='overline'>live</Text><br/>
			        				<IconDot style={{color:(campaigns[i].live) ? green[500] : '#E8E8E8'}} fontSize='small'/>
			        			</Grid>
			        			<Grid item xs={12}>
									<Text component='span' variant='body1' color='primary'><b>{money(campaigns[i].totalDonations,0)} </b></Text>
					          	<Text component='span' variant='caption' color='textSecondary'> raised of </Text>
					          	<Text component='span' variant='body1' color='textSecondary' ><b>{money(campaigns[i].target,0)} </b></Text>
					          	<Text component='span' variant='caption' color='textSecondary'> target. </Text>
					          	<LinearProgress variant="determinate" value={progress(campaigns[i].totalDonations, campaigns[i].target)} style={{marginBottom:20}}/>
			        			</Grid>
			        		</Grid>
			        	</AccordionSummary>
			        	<AccordionDetails>
			        		<Grid container spacing={2} style={{textAlign:'center'}}>
			        			<Grid item xs={12} sm={6}>

									<div style={{textAlign:'left', maxWidth: 500}}>
										<img alt={campaigns[i].title} src={campaigns[i].promoImage} style={{width:'100%', borderRadius:3}} />
										<Text variant='h6'>{campaigns[i].title}</Text>
										<Text variant='body1'>{campaigns[i].about}</Text>
										<Divider style={{margin:5}} />
										<Text variant='body1' color='textSecondary'><b>Category:</b> {campaigns[i].category}</Text>
										<Text variant='body1' color='textSecondary'><b>Target:</b> {formattedNumber(campaigns[i].target,0)}</Text>
										<Divider style={{color:'black', height:3, marginTop:5}} />
									</div>
									<div style={{textAlign:'left', maxWidth:500,marginTop:30, marginBottom:30}}>
										<Text variant='h5'>Settings</Text>
										<Divider />
										<Text variant='body1'><b>Listed on Site:</b> {(campaigns[i].listed) ? 'Yes - Listed on LetsFundIt' : 'No - Only people I share it with'}</Text>					
									  	<Text variant='body1'><b>Stop At Target:</b> {(campaigns[i].stopAtTarget) ? 'Yes - Stop when target is reached.' : 'No - Do not end when target is reached.'}</Text>
									  	<Text variant='body1'><b>Campaign End Date:</b> {(campaigns[i].deadline) ? moment.unix(campaigns[i].dateEnd).format('MMM Do YYYY - h:mm a') : 'Ongoing, no end.'}</Text>	
									  	<Text variant='body1'><b>Thankyou Message</b></Text>	
									  	<Text variant='body1'>{campaigns[i].thankyouMessage}</Text>
										<Divider style={{color:'black', height:3, marginTop:5}} />
									</div>

			        			</Grid>
			        			<Grid item xs={12} sm={6}>
			        				
			        				{(campaigns[i].status === 'pending approval') ? (
			        					<Alert severity='warning'>This Campaign is Pending Approval</Alert>
			        				) : ( null )}	
			        				{(campaigns[i].status === 'approved') ? (
			        					<Alert severity='success'>This Campaign has been Approval</Alert>
			        				) : ( null )}
			        				{(campaigns[i].status === 'pending revision') ? (
			        					<Alert severity='error'>This Campaign is Pending Revision</Alert>
			        				) : ( null )}

				        			<div style={{display: 'flex', padding:20, alignItems:'center'}}>
					               <Text variant='body1'>This campaign is currently {(campaigns[i].live) ? 'LIVE' : 'OFFLINE'}</Text>
					               <div style={{ marginLeft:'auto', marginRight:0, textAlign:'right'}}>
					                  <GreenSwitch onChange={ () => { handleLiveToggle(i, campaigns[i].live) }} checked={(campaigns[i].live) ? true : false} />
					               </div>
					            </div>
					               <Divider style={{color:'gray', height:2, marginBottom:22}}/>

				        			<div style={{padding:'0px 15px'}}>
				        				<Button fullWidth onClick={() => { handleToggleDonors(i) }} style={{padding:15}} variant='outlined' color='secondary'>View Donations Details</Button>
				        			</div>
				        			<div style={{padding:'0px 15px'}}>
				        				<Share 
											path={'/view/' + i}
											image={campaigns[i].promoImage}
											title='Check out this LetsFundIt Campaign'
											description='Your friend would like you to check out a Campaign they saw on LetsFundIt'
										/>
				        			</div>
				        			<div style={{padding:'0px 15px', marginTop:22}}>
				        				<Button fullWidth onClick={() => {goTo('/view/'+i)}} style={{padding:15}} variant='outlined' color='secondary'>view campaign page</Button>
				        			</div>
				        			<div style={{padding:'0px 15px', marginTop:22}}>
				        				<Button fullWidth onClick={() => {goTo('/member/campaign/'+i)}} style={{padding:15}} variant='outlined' color='secondary'>edit campaign</Button>
				        			</div>
			        			</Grid>
			        		</Grid>
			        	</AccordionDetails>
			        	<Divider />
			        	<AccordionActions>
			        		
				         <Button onClick={() => {setExpanded(false)}} size='small' variant='contained' color='primary' style={{marginLeft:20}}>close</Button>
			        	</AccordionActions>
			      </Accordion>
				)
			})}
		</Paper>
	)
}
export default withRouter(Campaigns)
