import { 
	APP_LAST_UPDATED, 
	AUTH_LOGIN_USER, 
	AUTH_LOGOUT_USER, 
	AUTH_CHANGE_LOGIN_OPTION, 
	AUTH_USER_INFO, 
	AUTH_LOAD_LOGIN_OPTIONS, 
	AUTH_LOAD_USER_CREDITS, 
	APP_LOAD_SETTINGS 
} from '../types'
import { chkExpiredData } from '../app/actions'

// [START]: Login & Logout Process :::::::::::::::::::::::::::::::::::::::::::::::::::::::
// :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
	export function logIn(email, password) {
		return (dispatch, getstate, firebase) => new Promise(function (resolve, reject) {
			firebase.auth().signInWithEmailAndPassword(email, password)
			.then(() => {
				dispatch({ type: AUTH_LOGIN_USER })  
				resolve()
			})
			.catch((e) => {
	   		console.log(e.message)
	   		reject(e.message)
	      })
		})
	}

	export function emailLogIn(email, type, rURL) {
		return (dispatch, getstate, firebase) => new Promise(function (resolve, reject) {
			// Setup return link
			let link = 'https://'+window.location.hostname+'/email/confirm/'
			if (window.location.hostname === 'localhost') {
				link = 'http://localhost:3000/email/confirm/'
			}
			if(rURL === ''){
				rURL = '?rURL='
			}
			// Setup actionCodeSettings
			let actionCodeSettings = {
				url: link + type + rURL + '&lfiSetup=1',
				handleCodeInApp: true
			}

	      // Send Email
	      const sendEmail = firebase.functions().httpsCallable('emailLogin')
	      sendEmail({ email:email, actionCodeSettings:actionCodeSettings })
	      .then((returned) => {
	         window.localStorage.setItem('emailForSignIn', email)
	         resolve('complete')
	      })
	      .catch(function(e) {
	         reject(e.message)
	      })
		})
	}

	export function emailLogInConfirm(email, url){
		return (dispatch, getstate, firebase) => new Promise(function (resolve, reject) {
			if(email && url){
				firebase.auth().signInWithEmailLink(email, url)
				.then(function(result) {
					dispatch({ type: AUTH_LOGIN_USER })
					resolve()
				})
				.catch(function(e) {
					reject(e.message)
				})
			} else {
				reject('Please enter your email address')
			}
		})
	}

	export function logOut(){
		return (dispatch, getstate, firebase) => {
			firebase.auth().signOut()
			.then(() => {
				dispatch({ type: AUTH_LOGOUT_USER })   
				// reset member and group redux here
				// '''''''''''''''''''''''''''''''''   
			}).catch((err) => {
				dispatch({ type: AUTH_LOGOUT_USER })
			})
		}
	}
// :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::	
// [END]: Login & Logout Process :::::::::::::::::::::::::::::::::::::::::::::::::::::::::

	export function changeOption(option) {
		return (dispatch, getstate) => {
			let optionsCount = getstate().auth.loginOptions.length
			if (option >= optionsCount) {
				option = 0
			}
			dispatch({ type: AUTH_CHANGE_LOGIN_OPTION, payload: option })
		}
	}

	export function getUserCredits(force=false){
		return (dispatch, getstate, firebase) => new Promise(function (resolve, reject) { 
		   const chkIfExpired = dispatch(chkExpiredData(force, 'paymentsCredits'))
	     	chkIfExpired
	     	.then((currentTime) => {
	     	// ---------------------------------------------------------------------------  
		      const fetchCredits = firebase.functions().httpsCallable('fetchMemberCredits')
		      fetchCredits()
		      .then((response) => {
		      	dispatch({ type: AUTH_LOAD_USER_CREDITS, payload: response.data })
		      	dispatch({ type: APP_LAST_UPDATED, payload:{key:'paymentsCredits', value:currentTime} })
		      	resolve()
		      })
		      .catch((e) => {
		      	let error = {title:'Error',message:''}
		      	try { error = JSON.parse(e.message) } 
		      	catch (err) { error.message = (e.message === 'internal') ? 'Error connecting to server.' : e.message }
		      	reject(error)
		      }) 
		   // ---------------------------------------------------------------------------
	     	})
	     	.catch(() => {
	         // Current data still good so do nothing.
	         resolve()
	     	})
		})
	}

// :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
// ALL LISTENERS BELOW HERE ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
// :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

// [START]: Auth Listener ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
	let monitorLogin
	let monitorUser
	let monitorAppSettings
	export function monitorLoginStatus(){
		return (dispatch, getstate, firebase) => {
			dispatch(monitorApp())
			monitorLogin = firebase.auth().onAuthStateChanged(user => {
				if (user) {
					dispatch({ type: AUTH_LOGIN_USER })
					dispatch(monitorUserInfo(user.uid))
				} else {
					dispatch({ type: AUTH_LOGOUT_USER })
				}
			})
		}
	}
	export function stopMonitoringLoginStatus(){
		return () => {
			monitorLogin && monitorLogin()
			monitorUser && monitorUser()
			monitorAppSettings && monitorAppSettings()
		}
	}
// [END]: Auth Listener :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

// [START]: Member Profile Listener :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
	export function monitorUserInfo(uid) {
		return (dispatch, getstate, firebase) => {   
			monitorUser = firebase.firestore().collection('members').doc(uid).onSnapshot(querySnapshot => {
				if (querySnapshot.data()) {
					let data = querySnapshot.data()

					// save userInfo to reducer
					dispatch({ type: AUTH_USER_INFO, payload: data })
					
					// get user credits.
					dispatch(getUserCredits(true))

					// Create USER login options
					let options = []
					let user = {
						login: 'member',
						id: data.id,
						name: data.name,
						role: '',
						roleId: 0,
						thumb: data.image,
						initials: data.initials        
					}
					options.push(user)

					let roles = data.roles
					Object.keys(roles).forEach(r => {
						if (roles[r].status === 'accepted') {
							let option = {
								login: 'group',
								id: roles[r].groupId,
								name: roles[r].groupName,
								role: roles[r].roleName,
								roleId: roles[r].roleId,
								thumb: roles[r].groupThumb,
								initials: roles[r].groupName.charAt(0).toUpperCase()
							}
							options.push(option)
						}
					})
					dispatch({ type: AUTH_LOAD_LOGIN_OPTIONS, payload: options })
				}
			}, err => {
				console.log('monitorUserInfo error:', err)
			})
		}
	}
// [END]: Member Profile Listener :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

// [START]: App Settings Listener :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
	export function monitorApp() {
		return (dispatch, getstate, firebase) => {   
			monitorAppSettings = firebase.firestore().collection('admin').doc('settings').onSnapshot(querySnapshot => {
				if (querySnapshot.data()) {
					let data = querySnapshot.data()
					dispatch({ type: APP_LOAD_SETTINGS, payload: data })
				}
			}, err => {
				console.log('monitorAppSettings error:', err)
			})
		}
	}
// [END]: App Settings Listener :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

