import React from 'react'

import Button from '@material-ui/core/Button'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Text from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'

const options = {
  	NGO: 'Non Governmental Organization',
  	SCHOOL: 'Legally Registered School',
  	AGENCY: 'Advertising / Marketing Agency'
}

class Step1 extends React.Component {
	state = {
		typeEl: null,
		name: this.props.group.name,
		summary: this.props.group.summary,
		type: this.props.group.type,
		message: ''
	}
	handleChange = (e) => {
		this.setState({ [e.target.name]: e.target.value })
	}
	handleNext = () => {
		let {name, summary, type} = this.state
		if (name === '' || summary === '' || type === '') {
			return this.setState({ message: 'All fields are required.'})
		}
		this.props.updateInfo(name, summary, type)
	}
	render() {
		return (
			<div>
				<form noValidate autoComplete='off'>
				  	<TextField fullWidth
					 	label='Name'
					 	name='name'
					 	value={this.state.name}
					 	onChange={this.handleChange}
					 	margin='normal'
				  	/>
				  	<TextField fullWidth
					 	multiline
					 	rowsMax='7'
					 	label='Summary'
					 	name='summary'
					 	value={this.state.summary}
					 	onChange={this.handleChange}
					 	margin='normal'
				  	/>
				  	<div style={{marginTop:20}}><Text variant="caption" display="block" style={{color:'#90a4ae'}}>Select the type of organization you would like to create.</Text></div>
				  	<List component="nav" style={{backgroundColor:'#f1f1f1', padding:2}}>
					 	<ListItem button onClick={(event) => { this.setState({typeEl: event.currentTarget}) }} style={{padding:10}}>
							<ListItemText primary={'Type of Group: ' + this.state.type} secondary={options[this.state.type]} />
					 	</ListItem>
				  	</List>             
				  	<Menu 
					 	dense='true' 
					 	anchorEl={this.state.typeEl}
					 	open={Boolean(this.state.typeEl)}
					 	onClose={() => {this.setState({typeEl: null}) }}
				  	>
					 	<MenuItem value='NGO' selected={this.state.type === 'NGO' } onClick={() => {this.setState({type: 'NGO', typeEl: null})}}>
							<ListItemText style={{whiteSpace: 'normal'}} primary='NGO' secondary={options['NGO']} />
					 	</MenuItem>
					 	<MenuItem value='SCHOOL' selected={this.state.type === 'SCHOOL' } onClick={() => {this.setState({type: 'SCHOOL', typeEl: null})}}>
							<ListItemText style={{whiteSpace: 'normal'}} primary='SCHOOL' secondary={options['SCHOOL']} />
					 	</MenuItem>
					 	<MenuItem value='AGENCY' selected={this.state.type === 'AGENCY' } onClick={() => {this.setState({type: 'AGENCY', typeEl: null})}}>
							<ListItemText style={{whiteSpace: 'normal'}} primary='AGENCY' secondary={options['AGENCY']} />
					 	</MenuItem>
				  	</Menu>
				</form>
				<div align='center' style={{ height: 25, padding: 10 }}>
					<Text variant='caption' style={{ color: 'red' }}>
						{this.state.message}
					</Text>
				</div>
				<div align='right'>
				 	<Button variant='contained' color='primary' onClick={this.handleNext}>
						next
				 	</Button>
				</div>				
			</div>
		)
	}
}
export default Step1
