import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Text from '@material-ui/core/Typography'

//import Paper from '@material-ui/core/Paper'
//import IconCreate from '@material-ui/icons/BorderColor';
//import IconShare from '@material-ui/icons/CameraFront';
//import IconAccept from '@material-ui/icons/HowToVote';

function Home({history, version}) {
	const goTo = (link) => {
      history.push({ pathname:link })
   }
	return (
		<Container maxWidth='lg' style={{ padding:10, marginBottom:50, minHeight:700  }}>
		<Grid container justify='center' spacing={2}>
			<Grid item xs={12} sm={12} md={10} >

				<div style={{marginTop:20, padding:40, paddingTop:60, textAlign:'center', backgroundColor:'#fff'}}>
					<Text variant='h3'>Welcome to LetsFundIt</Text>
					<Text variant='caption' color='textSecondary'>{version}</Text>
					<Text variant='body1' style={{maxWidth:600, margin:'0px auto'}}>
					Please excuse us while we put together the final pieces of the site.
					In the meantime, feel free to Create your Campaign and start fundraising.
					</Text>
					
					<Button onClick={() => { goTo('/start') }} style={{margin:50, textTransform:'none'}} size='large' variant='contained' color='secondary'>Start a LetsFundIt</Button>

					<Grid container justify='center' spacing={2} style={{maxWidth:600, margin:'0px auto', marginBottom:10}}>
						<Grid item xs={12} sm={4}>
							<Button onClick={() => { goTo('/campaigns') }} size='small' variant='outlined' color='secondary'>Campaigns</Button>
						</Grid>
						<Grid item xs={12} sm={4}>
							<Button onClick={() => { goTo('/charities') }} size='small' variant='outlined' color='secondary'>Charities</Button>
						</Grid>
						<Grid item xs={12} sm={4}>
							<Button onClick={() => { goTo('/boards') }} size='small' variant='outlined' color='secondary'>Feature Boards</Button>
						</Grid>
					</Grid>
					
					<Divider/>
					<Text variant='body1' color='textSecondary' style={{maxWidth:600, margin:'0px auto'}}>
						If you come across any errors/bugs please let us know through the support page within your account.	
					</Text>
				</div>

			{/*	
				<div style={{minHeight:400}}>
					<ul>
						<li>jumbotron</li>
						<li>searh bar</li>
						<li>featured fundraiser</li>
						<li>give a hand - browse companies</li>
						<li>success Stories</li>
					</ul>
				</div>

				<div style={{padding:40, textAlign:'center', backgroundColor:'#fff'}}>
					<Text variant='h3'>Need a Hand?</Text>
					<Button href='/start' style={{marginTop:20}} size='large' variant='contained' color='secondary'>start a letsfundit</Button>
				</div>

				<div style={{padding:40, textAlign:'center', backgroundColor:'#f1f1f1'}}>
					<div style={{maxWidth:800, margin:'0px auto'}}>
					<Grid container justify='center'alignItems="flex-end" spacing={2}>
						<Grid item xs={4} sm={4} >
							<IconCreate style={{fontSize:'65px'}} color='secondary'/><br/>
							<Text variant='h5' color='secondary'>Create</Text>
						</Grid>
						<Grid item xs={4} sm={4} >
							<IconShare style={{fontSize:'70px'}} color='secondary'/><br/>
							<Text variant='h5' color='secondary'>Share</Text>
						</Grid>
						<Grid item xs={4} sm={4} >
							<IconAccept style={{fontSize:'80px'}} color='secondary'/><br/>
							<Text variant='h5' color='secondary'>Accept</Text>
						</Grid>
					</Grid>
					</div>
				</div>
			*/}

			</Grid>
		</Grid>
		</Container>
	)
}
const mapStateToProps = (state, ownProps) => ({
	version: state.app.currentVersion
})
const mapDispatchToProps = {
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Home))