import React from 'react' 
import moment from 'moment'

import Button from '@material-ui/core/Button'
//import Text from '@material-ui/core/Typography'

class NextUpdate extends React.Component {
	mounted = true
	state ={
		message: null,
		lastTimeUpdate: 0
	}
	componentDidMount = () => {
		this.handleSetInterval()	
	}
	componentWillUnmount = () => {
		clearInterval(this.intervalID)
		this.mounted = false
	}
	handleSetInterval = () => {
		if(this.mounted) {
			this.intervalID = setInterval(() => this.tick(), 1000);
		}
	}
	tick = () => {
		let currentTime = Math.floor(Date.now() / 1000)
		let lastUpdated = (this.props.lastUpdated) ? this.props.lastUpdated : currentTime
      let difference = currentTime - lastUpdated
      let minutes = moment.duration(difference*1000, 'milliseconds').minutes()  
		let seconds = moment.duration(difference*1000, 'milliseconds').seconds() 
		let mins = (minutes < 1) ? '' : ((minutes === 1) ? minutes+'min' : minutes+'mins')
		let secs = (seconds === 1) ? seconds+'sec' : seconds+'secs'
		let time = mins +' '+secs
      this.setState({lastTimeUpdate: time, message: null})
	}

	updateNow = () => {
		let _this = this
		clearInterval(this.intervalID)
		this.setState({message: 'updating now...'})
		this.props.updateNow(true)
		.then(() => {
			_this.handleSetInterval()
		})
	}
	render() {
		return (
			<div style={{textAlign:'center'}}>
				<Button  
					onClick={() => {this.updateNow()}} 
					style={{
						color: '#78909c', 
						fontStyle:'italic',
						textTransform: 'none',
						fontSize: 11,
						padding:2
					}}
				>
					{(this.state.message) ? this.state.message : 'Last updated ' + this.state.lastTimeUpdate + ' ago. Click to update now.'}
 				</Button>
			</div>
		)
	}
}
export default NextUpdate
