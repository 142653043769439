import React from 'react'
import moment from 'moment'
import {money} from '../../../includes/functions'

import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import Grid from '@material-ui/core/Grid'
import IconExpandMore from '@material-ui/icons/ExpandMore'
import Text from '@material-ui/core/Typography'

function ViewPayInsList({payIns, count}){
	const [expanded, setExpanded] = React.useState(false)
	
	const handleChange = (panel) => (event, isExpanded) => {
    	setExpanded(isExpanded ? panel : false);
  	}
	if (!payIns || !Object.keys(payIns).length) {
		return (
			<div align='center' style={{margin:0, padding:50, backgroundColor:'#f1f1f1'}}><Text variant='body1' color='textSecondary'>No PayIn Activity Recorded</Text></div>
		)	
	}
	return (
		<div>
			{Object.keys(payIns).slice(0,count).map((i, key) => {
				return (
					<Accordion key={key} expanded={expanded === i} onChange={handleChange(i)} style={{borderRight: '2px solid #89fa89'}}>
						<AccordionSummary	expandIcon={<IconExpandMore />}>
							<Grid container>
				     			<Grid item xs={12} sm={4}>
				     				<Text variant='body1' color='textSecondary'><i>{moment.unix(payIns[i].timestamp).format('MMM Do YYYY - h:mm a')}</i></Text>
				     			</Grid>
				     			<Grid item xs={12} sm={6}>
				     				<Text variant='body1' color='primary'><b>{payIns[i].description}</b></Text>
				     			</Grid>
				     			<Grid item xs={12} sm={2}>
				     				<Text variant='body1' component='span' color='primary'><b>{ money(payIns[i].total,0) } </b></Text>
									<Text variant='caption' component='span' color='textSecondary'><b> USD</b></Text>
				     			</Grid>
							</Grid>
						</AccordionSummary>
						<AccordionDetails>
			        		<div style={{margin:'0 auto', width:'100%', maxWidth:600, marginBottom:25}}>
								<Grid container>
				        			<Grid item xs={4} style={{textAlign:'center'}} >
				        				<Text variant='overline'><b>{(payIns[i].transType === 'transfer') ? 'transfer' : 'donation'}</b></Text><br/>
				        				<Text variant='h6' component='span' color='primary' style={{lineHeight:1}}><b>{ money(payIns[i].amount,0) } </b></Text>
				        			</Grid>
				        			<Grid item xs={4} style={{textAlign:'center'}} >
				        				<Text variant='overline'><b>fees</b></Text><br/>
				        				<Text variant='h6' component='span' color='primary' style={{lineHeight:1}}><b>{ money(payIns[i].fee,0) } </b></Text>
				        			</Grid>
				        			<Grid item xs={4} style={{textAlign:'center'}} >
				        				<Text variant='overline'><b>received</b></Text><br/>
				        				<Text variant='h6' component='span' color='primary' style={{lineHeight:1}}><b>{ money(payIns[i].total,0) } </b></Text>
				        			</Grid>
				        			<Grid item xs={12} style={{textAlign:'center', paddingTop:10}}>
			        					<Text variant='caption'>A payIn of { money(payIns[i].amount,0) } was made, a fee of { money(payIns[i].fee,0) } { payIns[i].feeNote } was charged and you received { money(payIns[i].total,0) } </Text>
				        			</Grid>
			        			</Grid>
							</div>
	        			</AccordionDetails>
					</Accordion>
				)
			})}
		</div>
	)
}
export default ViewPayInsList
