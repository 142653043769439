import React from 'react'
import { InlineShareButtons } from 'sharethis-reactjs'

import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormControl from '@material-ui/core/FormControl'
import IconChecked from '@material-ui/icons/Check'
import IconShare from '@material-ui/icons/Share'
import InputAdornment from '@material-ui/core/InputAdornment'
import InputLabel from '@material-ui/core/InputLabel'
import OutlinedInput from '@material-ui/core/OutlinedInput'

function Share({path, image, title, description, buttonType='default'}) {
	const [showPopup, setShowPopup] = React.useState(false)
	const [copied, setCopied] = React.useState(false)

	// setup return link
   let hostname = 'https://' + window.location.hostname
   if (hostname.includes('localhost')) {
      hostname = 'http://localhost:3000'
   }
	let link = hostname + path

	const handleShowPopupToggle = () => {
		let status = !showPopup
	   setShowPopup(status)
	}
	const handleCopyLink = () => {
    	let copyText = document.querySelector('#copiedText');
  		copyText.select()
  		document.execCommand('copy')

    	setCopied(true)
  	}

	return (
		<React.Fragment>
			<div style={{paddingTop:20, margin:'0 auto'}}>
				{(buttonType === 'default') ? (
					<Button onClick={handleShowPopupToggle} style={{padding:15}} fullWidth variant='outlined' color='secondary' startIcon={<IconShare />}>share</Button>
				) : ( null )}
			</div>		
			<Dialog open={showPopup} onClose={handleShowPopupToggle}>
				<DialogTitle>Share on</DialogTitle>
        		<Divider/>
				<DialogContent>

       		<div style={{padding:15, textAlign:'center'}}>
        			<InlineShareButtons
          			config={{
			            alignment: 'center',  // alignment of buttons (left, center, right)
			            color: 'social',      // set the color of buttons (social, white)
			            enabled: true,        // show/hide buttons (true, false)
			            font_size: 16,        // font size for the buttons
			            labels: 'cta',        // button labels (cta, counts, null)
			            language: 'en',       // which language to use (see LANGUAGES)
			            padding: 12,          // padding within buttons (INTEGER)
			            radius: 4,            // the corner radius on each button (INTEGER)
			            size: 50,             // the size of each button (INTEGER)
			            networks: [           // which networks to include (see SHARING NETWORKS)
			              'whatsapp',
			              'messenger',
			              'telegram',
			              'sms'
			            ],			 
			            // OPTIONAL PARAMETERS
			            url: link,								 			// (defaults to current url)
			            image: image,  						 			// (defaults to og:image or twitter:image)
			            description: description + ' ' + link,    // (defaults to og:description or twitter:description)
			            title: title,            						// (defaults to og:title or twitter:title)
			            message: description + ' ' + link,     	// (only for email sharing)
			            subject: title  									// (only for email sharing)
			            //username: 'custom twitter handle' 		// (only for twitter sharing)
		          	}}
        			/>
        		</div>        

				<div style={{padding:15, textAlign:'center'}}>	
		        	<InlineShareButtons
		          	config={{
			            alignment: 'center',  // alignment of buttons (left, center, right)
			            color: 'social',      // set the color of buttons (social, white)
			            enabled: true,        // show/hide buttons (true, false)
			            font_size: 16,        // font size for the buttons
			            labels: 'cta',        // button labels (cta, counts, null)
			            language: 'en',       // which language to use (see LANGUAGES)
			            padding: 12,          // padding within buttons (INTEGER)
			            radius: 4,            // the corner radius on each button (INTEGER)
			            size: 50,             // the size of each button (INTEGER)
			            networks: [           // which networks to include (see SHARING NETWORKS)
			              'facebook',
			              'twitter',
			              'gmail',			              
			              'email'
			            ],	 
			            // OPTIONAL PARAMETERS
			            url: link, 											// (defaults to current url)
			            image: image,  									// (defaults to og:image or twitter:image)
			            description: description + ' ' + link,    // (defaults to og:description or twitter:description)
			            title: title,				            		// (defaults to og:title or twitter:title)
			            message: description + ' ' + link,	     	// (only for email sharing)
			            subject: title,						  			// (only for email sharing)
			            //username: 'custom twitter handle' 		// (only for twitter sharing)
		          	}}
		        	/>
	        	</div>

	        	<div style={{padding:20, textAlign:'center'}}>
	        		<FormControl size='small' variant='outlined' fullWidth>
		          	<InputLabel htmlFor='copiedText'>Copy URL</InputLabel>
		          	<OutlinedInput
		            	id='copiedText'
		            	type='text'
		            	value={link}
		            	style={{color:'gray'}}
		            	labelWidth={80}
		            	endAdornment={
		              		<InputAdornment position='end'>
		                		{(copied) ? (
		                			<Button startIcon={<IconChecked />} size='small' variant='contained' style={{backgroundColor:'#04b312', color:'#fff' }}>Copied!</Button>
		                		) : (
		                			<Button onClick={handleCopyLink} size='small' variant='contained' color='secondary'>copy link</Button>
		                		)}
		      				</InputAdornment>
		            	}
		          	/>
		        	</FormControl>
	        	</div>
				
				</DialogContent>
				<Divider/>
				<DialogActions>
				   <Button onClick={handleShowPopupToggle} color='primary' autoFocus>
						Close
				   </Button>
				</DialogActions>
			</Dialog>
		</React.Fragment>
	)
}
export default Share
