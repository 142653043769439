import React from 'react'
import { Route, Redirect, Switch } from 'react-router-dom'
 
import Accounts from './views-group/accounts/_Container'
import Board from './views-group/board/_Container'
import Boards from './views-group/boards/_Container'
import Campaign from './views-group/campaign/_Container'
import Campaigns from './views-group/campaigns/_Container'
import Dashboard from './views-group/dashboard/_Container'
import Settings from './views-group/settings/_Container'
import Support from './views-group/support/_Container'
import Team from './views-group/team/_Container'

function GroupRoutes() {
	return (
		<Switch>
			<Route path='/group/accounts' component={Accounts}/>
			<Route path='/group/board/:id?' component={Board}/>
			<Route path='/group/boards' component={Boards}/>
			<Route path='/group/campaign/:id?' component={Campaign}/>
			<Route path='/group/campaigns' component={Campaigns}/>
			<Route path='/group/dashboard' component={Dashboard}/>
			<Route path='/group/settings' component={Settings}/>
			<Route path='/group/support/:section?/:filter?/:ticketId?' component={Support}/>
			<Route path='/group/team' component={Team}/>
			<Redirect from='/group' to='/group/dashboard'/>
		</Switch>
	)
}
export default GroupRoutes
