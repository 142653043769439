import React from 'react'
import SubNav from '../components/SubNav'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Text from '@material-ui/core/Typography'

function Affiliates() {
	return (
		<Container maxWidth='lg' style={{ padding: 10, marginBottom: 50 }}>
		<Grid container justify='center' spacing={2}>
			<Grid item xs={12} sm={8} md={8} >
				<Grid item xs={12} sm={12} md={10} >
					<SubNav text='Affiliates'/>
					<Paper style={{ marginTop:20, padding: 20, minHeight:800 }} >
						<div style={{padding:20, textAlign:'center'}}>
							<Text variant='body1' color='textSecondary'>
								Coming Soon
							</Text>
						</div>
					</Paper>
				</Grid>
			</Grid>
		</Grid>
		</Container>
	)
}
export default Affiliates
