import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { logIn } from '../../redux/auth/actions'
import { chkEmail, chkPassword }  from '../../includes/functions'
import ServiceAndPolicies from '../components/ServiceAndPolicies'

import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Text from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'

class Login extends React.Component {
  state = { 
    email: null, 
    password: null,
    message: null,
    btnText: 'login',
    btnDisabled: false,
    checked: false
  }

  handleChange = (e) => {
    this.setState({[e.target.id]: e.target.value, message: null})
  }

  handleSubmit = (e) => {
    e.preventDefault()
    if (!chkEmail(this.state.email)) {
      return this.setState({ message: 'Sorry, please enter a valid email address.' })
    }
    if (!chkPassword(this.state.password)) {
      return this.setState({ message: 'Sorry, your password is invalid.' })
    }
    if (!this.state.checked) {
      return this.setState({ message: 'Sorry, please agree with the TOS (Terms of Service)' })
    }
    this.setState({ btnText: 'logging In', btnDisabled: true })
    this.props.logIn(this.state.email.toLowerCase(), this.state.password)
    .catch((err) => {
      this.setState({ btnText: 'Login', btnDisabled: false, message: err })
    })
  }
  
  chkCheckbox = () => {
    this.setState({ checked: !this.state.checked })
  }

  render() {
    const { message, btnText, btnDisabled, checked } = this.state
    return (
      <Container maxWidth='xl' style={{ padding: 10, marginBottom: 150, minHeight:700, marginTop:80 }}>
        <Grid container justify='center' spacing={2}>
          <Grid item xs={12} sm={5}>
            <Paper style={{ padding: 20 }} >
              <Text variant='h5' gutterBottom>
                LOGIN
              </Text>
              <Text variant='caption' component='p'>
                Login to your letsFundIt account.
              </Text>
              <Text style={{ marginTop: 10, color: 'red', height: 15 }} variant='caption' component='p' align='center'>
                { message }
              </Text>
              <form onSubmit={ this.handleSubmit }>
                <TextField
                  type='email'
                  id='email'
                  label='Email'
                  placeholder='Enter your email address'
                  margin='normal'
                  fullWidth={true}
                  onChange={ this.handleChange }
                />
                <TextField
                  type='password'
                  id='password'
                  label='Password'
                  placeholder='Enter your password'
                  margin='normal'
                  fullWidth={true}
                  onChange={ this.handleChange }
                />
                <Checkbox
                  checked={checked}
                  onChange={this.chkCheckbox}
                  color='primary'
                />
                <ServiceAndPolicies tab={3} />
                <div style={{ height: 15 }} />
                <Button disabled={ btnDisabled } variant='contained' color='primary'  type='submit'>
                  { btnText }
                </Button>
                <div style={{ height: 20 }} />
                <Button style={{marginRight:25}} size='small' color='primary' component={ Link } to={'/email/login' + this.props.location.search}>
                  Login through Email
                </Button>
                <Button size='small' color='primary' component={ Link } to={'/email/register' + this.props.location.search}>
                  Register a New Account
                </Button>
              </form>
            </Paper>
          </Grid>
        </Grid>  
      </Container>
    )
  }
}
const mapDispatchToProps = {
  logIn
}
export default connect(null, mapDispatchToProps)(Login)
