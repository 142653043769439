import React from 'react'
import { connect } from 'react-redux'
import { loadCharities } from '../../redux/public/actions'

import PageMessage from '../components/PageMessage'
import SubNav from '../components/SubNav'
import NextUpdate from '../components/NextUpdate'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Text from '@material-ui/core/Typography'

class Charities extends React.Component {
	mounted = true
	state = {
		pageLoading: true,
		pageTitle: undefined,	// Not Found',
		pageText: undefined		// The Boards',
	}
	componentDidMount = () => {
		this.handleLoadCharities(false)
	}
	componentWillUnmount = () => {
		this.mounted = false
	}
	handleLoadCharities = (force) => {
	 	return new Promise((resolve, reject) => {
	 		let _this = this
			_this.setState({ pageLoading:true, pageTitle:'Loading Charities', pageText:'Checking server for updated list of Charities.' })
		 	_this.props.loadCharities(force)
		 	.then(() => {
		 		if(_this.mounted) {
		 			_this.setState({ pageLoading:false, pageTitle:undefined, pageText:undefined })
		 		}
		 	})	
		 	.catch((e) => {
		 		if(_this.mounted) {
		 			_this.setState({pageLoading:false, pageTitle:e.title, pageText:e.message})
		 		}
		 	})
		})		
	}
	goTo =(id) => {
		this.props.history.push({ pathname: '/charity/' + id })
	}
	render() {		
		const { pageLoading, pageTitle, pageText } = this.state
		const { lastUpdated, charities } = this.props
	
		if(pageLoading || pageTitle) {
			return (<PageMessage pageLoading={pageLoading} pageTitle={pageTitle} pageText={pageText} />)
		}
		return (
			<React.Fragment>
				<Container maxWidth='lg' style={{ padding: 10, marginBottom: 50 }}>
				<Grid container justify='center' spacing={2}>
					<Grid item xs={12} sm={12} md={10} >
						<SubNav text='Charities'/>
						<Paper style={{ marginTop:20, padding: 20, minHeight:800 }} >
							{(Object.keys(charities).length) ? (
								<div>
									{Object.keys(charities).map((i) => {
										return ( 
											<div>Charities list</div>
										)
									})}
								</div>
							) : (
								<div style={{padding:20, textAlign:'center'}}>
									<Text variant='body1' color='textSecondary'>
										No Active Charities
									</Text>
								</div>
							)}
						</Paper>
					</Grid>
				</Grid>
				<NextUpdate lastUpdated={lastUpdated} updateNow={this.handleLoadCharities}/>
				</Container>
			</React.Fragment>
		)
	}
}
const mapStateToProps = (state) => ({
	charities: state.public.charities,
	lastUpdated: state.app.lastUpdated['publicCharities']
})
const mapDispatchToProps = {
	loadCharities
}
export default connect(mapStateToProps, mapDispatchToProps)(Charities)
