import React from 'react'
import { connect } from 'react-redux'
import { loadTickets, assignTicket, updateTicket, closeTicket, archiveTicket, addTicketNote } from '../../../redux/group/actions'
import { selectorLoadGroupTickets } from '../../../redux/group/selectors'

import PageMessage from '../../components/PageMessage'
import NextUpdate from '../../components/NextUpdate'
import SubNav from '../../components/SubNav'
import Ticket from './Ticket'
import List from './List'
import Notes from './Notes'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'

const names = ['unassigned', 'assigned', 'archived', 'notes']

class GroupSupportContainer extends React.Component {
	mounted = true
	state = {
		pageLoading: true,
		pageTitle: 'Loading Support Tickets',	//Not Found',
		pageText: 'fetching support tickets from server',		//The campaign you are looking for was not found',
	 	activeTab: 0,
		section: 'unassigned',
	 	filter: 'all',
	 	ticketId: null,
	 	tickets: {}
  	}
  	componentDidMount = () => {
		this.handleFetchSupportTickets()
	}
	componentDidUpdate = (prevProps) => {
	 	if (this.props !== prevProps) {	
			this.sortFilter()
	 	}
   }
   componentWillUnmount = () => {
		this.mounted = false
	}
  	handleTabChange = (event, newValue) => {
 		let path = '/group/support/' + names[newValue]
  		this.props.history.push({ pathname: path })
  	}
	handleFetchSupportTickets = (force) => {
		return new Promise((resolve, reject) => {
		 	let _this = this
		 	this.props.loadTickets(force)
		 	.then(() => {
		 		if(_this.mounted) {
		 			_this.setState({ pageLoading:false, pageTitle:undefined, pageText:undefined })
		 		}
		 	})	
		 	.catch((e) => {
		 		if(_this.mounted) {
		 			_this.setState({ pageLoading:false, pageTitle:e.title, pageText:e.message })
		 		}
		 	})	
		 	.finally(() => {
		 		resolve()
		 		_this.sortFilter()
		 	})		
		})
	}
   sortFilter = () => {
	 	let tickets = {}
	 	let section = (this.props.match.params.section) ? this.props.match.params.section.toLowerCase() : 'unassigned'
	 	let filter = (this.props.match.params.filter) ? this.props.match.params.filter.toLowerCase() : 'all'
	 	let ticketId = (this.props.match.params.ticketId) ? this.props.match.params.ticketId : null
		let allTickets = this.props.allTickets || {}
		this.setState({activeTab:names.indexOf(section)})

		if (ticketId && allTickets.hasOwnProperty(ticketId)) {	 
		  	tickets = { [ticketId]: allTickets[ticketId] }
		} else {
	  		ticketId=null
		 	switch(section) {
				case 'unassigned':
					tickets = this.props.unassignedTickets
					break
				case 'assigned':
					tickets = this.props.assignedTickets
					break
				case 'archived':
					tickets = this.props.archivedTickets
					break
				default:
					tickets = allTickets
			}
		} 
		this.setState({section:section, filter:filter, ticketId:ticketId, tickets:tickets })
  	}

  	render() {
  		const { pageLoading, pageTitle, pageText, activeTab, section, filter, ticketId, tickets } = this.state
    	const { lastUpdated, notes, team, memberId, roleId, assignTicket, updateTicket, closeTicket, archiveTicket, addTicketNote } = this.props

  		if(pageLoading || pageTitle) {
			return (<PageMessage pageLoading={pageLoading} pageTitle={pageTitle} pageText={pageText} />)
		}

    	return (
   		<React.Fragment>
   		<Container maxWidth='lg' style={{ padding: 10, marginBottom: 50 }}>
				<Grid container justify='center' spacing={2}>
					<Grid item xs={12} sm={12} md={10} >
						<SubNav text='Support Desk'/>
					
						<div style={{borderBottom: '2px solid #eee'}}>  
						  	<Tabs
						    	value={activeTab}
						    	variant="fullWidth"
						    	onChange={this.handleTabChange}
						    	indicatorColor="primary"
						    	textColor="primary"							    
						  	>
						    	<Tab label='Unassigned' />
						    	<Tab label='Assigned' />
						    	<Tab label='Archived' />
						    	<Tab label='Notes' />
						  	</Tabs>
						</div>

						<div>
							{ (section === 'notes') ? (
							 	<Notes notes={notes} />
						  	) : (
							 	(ticketId) ? (
									<Ticket 
										section={section} 
										filter={filter} 
										ticketId={ticketId}
										tickets={tickets} 
										roleId={roleId}   
										memberId={memberId} 
										team={team}
										assignTicket={assignTicket} 
										addTicketNote={addTicketNote}
										updateTicket={updateTicket} 
										closeTicket={closeTicket} 
										archiveTicket={archiveTicket} 
									/>
							 	) : (
									<List section={section} filter={filter} tickets={tickets} />
							 	)
						  	)}
						</div>
					</Grid>
				</Grid>
				<NextUpdate lastUpdated={lastUpdated} updateNow={this.handleFetchSupportTickets}/>
			</Container>
   		</React.Fragment>
    	)
  	}
}

const mapStateToProps = (state) => ({
	lastUpdated: state.app.lastUpdated.groupSupportTickets,
	allTickets: state.group.supportTickets,
	unassignedTickets: selectorLoadGroupTickets(state.group.supportTickets, 'unassigned'),
	assignedTickets: selectorLoadGroupTickets(state.group.supportTickets, 'assigned'),
	archivedTickets: selectorLoadGroupTickets(state.group.supportTickets, 'archived'),
	notes: state.group.supportNotes,
	team: state.group.team,
	memberId: state.auth.userInfo.id,
	roleId: state.auth.loginOptions[state.auth.loginOptionID].roleId,
})
const mapDispatchToProps = {
	loadTickets,
	assignTicket, 
	updateTicket, 
	closeTicket, 
	archiveTicket,
	addTicketNote
}
export default connect(mapStateToProps, mapDispatchToProps)(GroupSupportContainer)
