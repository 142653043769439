import React from 'react'
import { Link } from 'react-router-dom'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Text from '@material-ui/core/Typography'
var HtmlToReactParser = require('html-to-react').Parser

function CreditsTransfer() {

    let supportLink = 'https://dev.letsfundit.org/member/support'
    let date = 'Fri, Nov 30, 2018, 6:23 AM'
    let recipientName = 'John Doe'
    let recipientEmail = 'john@doe.com'
    let amount = '105'
    let subject, html//, text 
    let emailLink = 'xxx'

    subject = 'LetsFundIt - Credits Transfer Confirmation'
    //text = 'Please go to the following link to confirm your credits transfer: ' + JSON.stringify(emailLink)

    html  = '<div style="background-color:#F5F5F5; padding:5px">'
    html += '   <div align="center" style="margin-top:40px; font: 30px Arial, sans-serif; color:black"><b>LetsFundIt</b></div>'
    html += '   <div style="padding:15px; padding-bottom:0px; max-width:600px; background-color:#fff; margin:10px auto; border: 2px solid #eee">'
    html += '       <div style="font:15px Arial, sans-serif; color:#8b8d8e">'
    html += '           Hello,'
    html += '           <p>You recently requested to transfer credits out of your account.<br/>'
    html += '           Please confirm that you would like us to proceed with this transaction.</p>'
    html += '           <p>If you did not make this request or have any questions or concerns please do not respond to this email, contact us through the <a href="' + supportLink + '">support page</a> on LetsFundIt.</p>'
    html += '           <p>Thanks,<br/>Your LetsFundIt team.</p>'        
    html += '       </div>'
    html += '   </div>'

    html += '   <div style="max-width:600px; margin:20px auto; margin-bottom:0px; font: 20px Arial, sans-serif; color:black"><b>Transaction Details</b></div>'
    html += '   <div style="padding:15px; max-width:600px; background-color:#fff; margin:10px auto; border: 2px solid #eee">'
    html += '       <div style="padding-left:10px; font: 15px Arial, sans-serif; color:#58504D">'
    html += '           <b style="line-height:1.25">Date: </b>'+ date +'<br/>'
    html += '           <b style="line-height:1.25">Recipient Name: </b>'+ recipientName +'<br/>'
    html += '           <b style="line-height:1.25">Recipient Email: </b> '+ recipientEmail +'<br/>'
    html += '           <b style="line-height:1.25">Amount: </b> $'+ amount +'<br/>'
    html += '       </div>'

    html += '       <div style="margin:0px auto; text-align:center; font:20px Arial, sans-serif; color:#999">'
    html += '           <h1>$' + amount + ' USD</h1>'
    html += '       </div>'
    html += '       <div style="background-color:#32a852; max-width:300px; padding:10px; margin:0px auto; text-align:center">'
    html += '           <a href=' + JSON.stringify(emailLink) + ' style="color:white; text-decoration:none"><b>CONFIRM CREDITS TRANSFER</b></a>'
    html += '       </div>'    
    html += '       <div style="text-align:center; margin-bottom:30px; margin-top:10px; font:15px Arial, sans-serif; color:#8b8d8e"><i>This amount would be deducted from your accout.</i></div>'
    html += '   </div>'
    html += '</div>'

    let htmlToReactParser = new HtmlToReactParser()
    let reactElement = htmlToReactParser.parse(html)

    // Preview Template
    return (
        <Container maxWidth='lg' style={{ padding: 10, minHeight:700  }}>
        <Grid container justify='center' spacing={2}>
            <Grid item xs={12} sm={8} md={8} >
                <Paper style={{ padding: 10 }} >
                    <b>Subject:</b> {subject}
                    {reactElement}
                </Paper>
                <div align='right' style={{marginBottom:50}}>
                    <Text variant='caption' gutterBottom>
                        <Link to='/templates'>Bact To Templates</Link>
                    </Text>
                </div>
            </Grid>
        </Grid>
        </Container>
    )
}
export default CreditsTransfer
