import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/functions'
import 'firebase/storage'

const stageConfig = {
  apiKey: "AIzaSyAmGJ1pHH3gpUgl5-TYetaNvVWUaeEAbMs",
  authDomain: "letsfundit-stage.firebaseapp.com",
  databaseURL: "https://letsfundit-stage.firebaseio.com",
  projectId: "letsfundit-stage",
  storageBucket: "letsfundit-stage.appspot.com",
  messagingSenderId: "664913400934",
  appId: "1:664913400934:web:2054e7961a5da428e1c9a7"
}

const liveConfig = {
  apiKey: "AIzaSyAg5ogdcv2vnVK8EXyX3fv5hxjiSHXYOVo",
  authDomain: "letsfundit-app.firebaseapp.com",
  databaseURL: "https://letsfundit-app.firebaseio.com",
  projectId: "letsfundit-app",
  storageBucket: "letsfundit-app.appspot.com",
  messagingSenderId: "122400330413",
  appId: "1:122400330413:web:c0fff7a28820f255b46c24"
}

// use liveConfig on live project.
let config = liveConfig
let hostname = window.location.hostname

// use stageConfig locally and on stage project.
if (hostname.includes('dev') || hostname.includes('localhost')) {
   config = stageConfig
}

// Initialize Firebase
firebase.initializeApp(config)
export default firebase
