import { AUTH_SET_GROUPID, GROUP_RESET_DATA } from '../types'

export function chkExpiredData(force=true, reducer=null){
	return (dispatch, getstate) => new Promise((resolve, reject) => {
			// TRUE - resolve if date expired
			// TRUE - resolve if force is true
			// FALSE - reject if date is still good    
			let currentTime = Math.floor(Date.now() / 1000)
			if (force) {
				 resolve(currentTime)
			}

			if (reducer) {
				 let lastUpdated = (getstate().app.lastUpdated.hasOwnProperty(reducer)) ? getstate().app.lastUpdated[reducer] : 0
				 let updateInterval = (getstate().app.updateIntervals.hasOwnProperty(reducer)) ?  getstate().app.updateIntervals[reducer] : getstate().app.updateIntervals.default
				 let expiresAt = lastUpdated + updateInterval
				 if (currentTime < expiresAt) {
						reject('current data good')
				 } else  {    
						resolve(currentTime)
				 }
			} else {
					resolve(currentTime)
			}
	})
}

export function chkGroupId(){
	return (dispatch, getstate) => new Promise((resolve, reject) => {
		// set needed variables
			let currentGroupId = getstate().auth.currentGroupId
			let loginOptionID = getstate().auth.loginOptionID
			let loginOptions = getstate().auth.loginOptions
			let loginInfo = loginOptions[loginOptionID]
			let loggedInGroupId = loginInfo.id

		// reset groupId and group reducers if groupId doesnt match loggedInGroupId
			if (currentGroupId !== loggedInGroupId) {
				dispatch({ type:AUTH_SET_GROUPID, payload:loggedInGroupId })
				// ** NOTICE ** 
				// reset all group reducers
				dispatch({ type:GROUP_RESET_DATA })
			}      
		resolve(loggedInGroupId)
	})
}