import React from 'react'
import moment from 'moment'
import swal from 'sweetalert2'
import { connect } from 'react-redux'
import { addEditCampaign } from '../../redux/member/actions'
import {formattedNumber} from '../../includes/functions'
import Loader from '../components/Loader'
import ImageSelector from '../components/ImageSelector'

import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import Paper from '@material-ui/core/Paper'
import Slider from '@material-ui/core/Slider'
import Text from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemText from '@material-ui/core/ListItemText'

class New extends React.Component {
	mounted = true
	state = {
		loading: false,
		message: '',
		id: this.props.match.params.id || undefined,
		campaignInfo: {
			id: null,
			ownerType: 'member',
			type: 'fundraiser',
			listed: true,			
			category: 0,
			target: 5000,
			stopAtTarget: false,
			donationType: 'any',
			donationFixedAmount: 100,
			deadline: false,
			dateStart: moment(Date.now()).format('X'), // current date in seconds
			dateEnd: moment(Date.now()).add(180, 'd').format('X'), // current date plus 180 days in seconds
			title: '',
			about: '',
			headerImage: '/images/campaigns/default.jpg', //null,
			promoImage: '/images/campaigns/default.jpg', //null,
			thankyouMessage: 'Thankyou for supporting me and contributing to my campaign. Your kindness is greatly appreciated.',
			thankyouEmail: 'Thankyou for supporting me and contributing to my campaign. Your kindness is greatly appreciated.',
			live: true,
			featured: false,	
		}, 	
		promoImages: [ // ImageSelector variable,
			'/images/campaigns/default.jpg'
		],
	}	
	componentWillUnmount = () => {
		this.mounted = false
	}
	handleSelectPromo = (src) => {
		let campaign = this.state.campaignInfo
		let promoImages = this.state.promoImages
		campaign.promoImage = src
		if (!promoImages.includes(src)) {
			promoImages.push(src)
		}
		this.setState({ campaignInfo:campaign, promoImages:promoImages })
	}	
	handleChangeDonation = (event, newValue) => {
		let campaign = this.state.campaignInfo
		campaign.target = newValue
		this.setState({ campaignInfo:campaign })
	}
	handleChange = (f,value) => {
		let campaign = this.state.campaignInfo
		campaign[f] = value
		this.setState({ campaignInfo:campaign, message:'' })
	}
	handleClickLink = (link) => {
   	let returnLink= 'rURL=/new/' + this.state.id
      this.props.history.push({ pathname: link, search:returnLink })
   }
   handleEditProfile = () => {
   	let returnLink= 'nextURL=/new/' + this.state.id + '&noPw=1'
      this.props.history.push({ pathname:'/member/setup', search:returnLink })
   }
   handleGoToBoard = () => {
      this.props.history.push({ pathname: '/board/' + this.state.id })
   }
	handleSaveCampaign = () => {
		let _this = this
		let campaign = this.state.campaignInfo
		if (!campaign.category) {
			return this.setState({message:'Sorry, please select a Category for your campaign'})
		}
		if (campaign.title === '' || campaign.about === '') {
			return this.setState({message:'Sorry, both Title and About are required'})
		}
		swal.fire({
		  	imageUrl: campaign.promoImage,
		  	html: '<div align="left"><b>' + campaign.title+ '</b><br/>' + campaign.about + 
		  	'<hr/><i style="color:gray">Category: ' + campaign.category + '</i><br /><i style="color:gray"> Target: $' + formattedNumber(campaign.target,0)+ ' USD </i></div>',
		  	showCancelButton: true,
		  	confirmButtonText: 'Save & Publish',
		  	confirmButtonColor: '#32a852',
		}).then((result) => {
		  	if (result.isConfirmed) {
		  		_this.setState({loading: true})
		  		_this.props.addEditCampaign(campaign)
		  		.then(() => {
					_this.props.history.push({ pathname: '/board/' + this.state.id })	    	
		  		})
		  		.catch((e) => {
					console.log(e)
		  		})
		  	} 
		})

	}
	render() {
		const { loggedIn, userInfo } = this.props
		const { loading, message, promoImages, campaignInfo } = this.state

		if (!loggedIn) {
			return (
				<Paper style={{ padding: 20, maxWidth:600, margin:'0 auto', marginTop:50 }} >	    			
					Please <b>Login</b> / <b>Register</b> to create a campaign.<br/> 
					It takes <b>one minute</b> and you would be returned right here when complete.
					<div align='center'>
						<Button onClick={() => {this.handleClickLink('/login')}} variant='contained' color='secondary' style={{margin:10}}>Login</Button>
						<Button onClick={() => {this.handleClickLink('/email/register')}} variant='contained' color='secondary' style={{margin:10}}>Register</Button>
					</div>			
				</Paper>
			)
		}

		return (
			<Container maxWidth='lg' style={{ padding: 10, marginBottom: 150, minHeight:700  }}>
			<Loader loading={loading} />
			<Grid container justify='center' spacing={2}>
				<Grid item xs={12} sm={10} md={8} >
					<Paper style={{ padding: 20, maxWidth:600, margin:'0 auto', marginTop:50 }} >
						<Text variant='h5' gutterBottom>
							Create New Campaign
						</Text>
						<div style={{marginTop:20}}>
							<Text variant='subtitle2' color='primary'>How much would you like to raise?</Text>
							
							<div align='center' style={{marginTop:5}}>
								<Text component='span' variant='h2' style={{color:'#d4d4d4'}}>$</Text>
								<Text component='span' variant='h2' color='primary'>{formattedNumber(campaignInfo.target,0)}</Text>
								<Text component='span' variant='h3' style={{color:'#d4d4d4'}}> USD</Text>
							</div>
							<Slider 
								value={campaignInfo.target} 
								onChange={this.handleChangeDonation} 
								step={100}
        						min={100}
        						max={10000}
        					/>
						</div>
						<div style={{marginTop:20}}>
							<Text variant='subtitle2' color='primary'>Select or Upload an Image for your campaign.</Text>
							{/** ImageSelector Starts Here */}
							<div align='center' style={{ maxWidth: 600, margin:'0 auto', marginTop:0}}>
								<ImageSelector 
									imgBorder='1px solid #ccc'
									imgBorderRadius={10}
									imgPadding={5}
									imgRatio='16:9'
									images={promoImages}
									currentImage={campaignInfo.promoImage}
									selectImage={this.handleSelectPromo}
								/>
							</div>
							{/** ImageSelector Ends Here */}
						</div>
						<div style={{marginTop:20}}>	
							<Text variant='subtitle2' color='primary'>Select a category for your fundraiser?</Text>
							<TextField select fullWidth onChange={(e) => { this.handleChange('category', e.target.value) }} value={campaignInfo.category} size='small' variant='outlined' id='category'>
							  	<MenuItem value='0' >Select Category</MenuItem>
							  	<MenuItem value='Charity'>Charity</MenuItem>
							  	<MenuItem value='Education'>Education</MenuItem>
							  	<MenuItem value='Family'>Family</MenuItem>
							  	<MenuItem value='Home Improvement'>Home Improvement</MenuItem>
							  	<MenuItem value='Repairs'>Repairs</MenuItem>
							</TextField>
						</div>
						<div style={{marginTop:20}}>
							<Text variant='subtitle2' color='primary'>Give your campaign a title</Text>
							<TextField 
								placeholder='Campaign Title'
								//style={{maxWidth:300}}
								fullWidth 
								onChange={(e) => { this.handleChange('title', e.target.value) }} 
								value={campaignInfo.title} 
								size='small' 
								variant='outlined' 
							/>
						</div>
						<div style={{marginTop:20}}>
							<Text variant='subtitle2' color='primary'>Tell us about this campaign</Text>
							<TextField 
								placeholder='About Campaign'
								multiline
			          		rows={4}
								fullWidth 
								onChange={(e) => { this.handleChange('about', e.target.value) }} 
								value={campaignInfo.about} 
								size='small' 
								variant='outlined'
							/>
						</div>

						<div style={{marginTop:20}}/>
						<div style={{ backgroundColor:'#f1f1f1'}}>
							<List>
								<ListItem>
									<ListItemAvatar>
										<Avatar alt={userInfo.name} src={userInfo.image} style={{borderRadius:5}} />
									</ListItemAvatar>
									<ListItemText>
										<Text style={{lineHeight:.5}} variant='caption' display='block' color='textSecondary'>
											Campaign by:
										</Text>
										<Text variant='h6'>{userInfo.name}</Text>
										<Text style={{lineHeight:.8}} variant='caption' display='block' color='textSecondary'>
											Listed on {moment.unix(campaignInfo.dateStart).format('MMM Do YYYY - h:mm a')}
										</Text>
									</ListItemText>
								</ListItem>
							</List>

						</div>
						<Text variant='caption' color='textSecondary'>
						<b>NOTICE:</b> As noted in our Terms of Service, funds raised under a false identity may be forfeited.
						Please ensure your name and image are correctly displayed.  
						</Text> 
						<Button onClick={this.handleEditProfile} size='small' color='secondary' style={{padding:0, marginLeft:5}}> Edit Profile Here</Button>



						<div align='center' style={{padding:10}}>
							<Text variant='body1' style={{color:'red'}}>{message}</Text>
						</div>
						<div align='center' style={{padding:10}}>
							<Button onClick={this.handleSaveCampaign} variant='contained' color='secondary'>Save & Publish Campaign</Button>
						</div>
						<div>
							<Text variant='caption' color='textSecondary'>
								<b>NOTE:</b> When you Save & Publish your Campaign you would be returned to the 
								Feature Board. To Update/Edit or Check on the progress of your Campaign,
								navigate to your Campaigns via your account page (top right nav)
							</Text>
						</div>
					</Paper>
					<div style={{ padding: 0, maxWidth:600, margin:'0 auto', textAlign:'right' }}><Button onClick={this.handleGoToBoard} size='small' color='secondary'>Back to Feature Board</Button></div>
				</Grid>
			</Grid>
			</Container>
		)
	}
}
const mapStateToProps = (state, ownProps) => ({
	loggedIn: state.auth.loggedIn || false,
	userInfo: state.auth.userInfo || {}
})
const mapDispatchToProps = {
	addEditCampaign
}
export default connect(mapStateToProps, mapDispatchToProps)(New)
