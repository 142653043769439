import React from 'react'
import swal from 'sweetalert2'
import { connect } from 'react-redux'
import { addEditCampaign, fetchCampaignInfo } from '../../../redux/member/actions'

import PageMessage from '../../components/PageMessage'
import SubNav from '../../components/SubNav'
import Index from './Index'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'

class MemberAffilateContainer extends React.Component {
	mounted = true
	state = {
		pageLoading: false,
		pageTitle: '',	//Not Found',
		pageText: '',	//The campaign you are looking for was not found',
		id: undefined,
		info: undefined
	}
	componentDidMount = () => {
		let id = this.props.match.params.id
		if(id) {
			this.setState({ id:id })
			this.handleFetchCampaign(id)
		}
	}
	componentWillUnmount = () => {
		this.mounted = false
	}
	handleFetchCampaign = (id) => {
		let _this = this
		this.setState({ pageLoading: true, pageTitle: 'Loading Campaign', pageText: 'fetching campaign info from server'})
		this.props.fetchCampaignInfo(id)
		.then((info) => {
			if (_this.mounted) {
				_this.setState({ pageLoading:false, pageTitle:'', pageText:'', info:info })
			}
		})
		.catch((error) => {
			if (_this.mounted) {
				swal.fire({
					  title: error.title,
					  text: error.message,
					  icon: 'warning',
					  confirmButtonColor: '#4caf50',
					  confirmButtonText: 'ok'
				}).then((result) => {
					if (result.isConfirmed) {
					   _this.props.history.push({ pathname:'/member/fundraisers' })
					}
				})	
			}
		})
	}

  	render() {
  		const {pageLoading, pageTitle, pageText, id, info} = this.state 
  		const {addEditCampaign} = this.props

  		if(pageLoading || pageTitle) {
			return (<PageMessage pageLoading={pageLoading} pageTitle={pageTitle} pageText={pageText} />)
		}

    	return (
   		<Container maxWidth='lg' style={{ padding: 10, marginBottom: 50 }}>
				<Grid container justify='center' spacing={2}>
					<Grid item xs={12} sm={12} md={10} >
						<SubNav text='Campaign'  buttonText='View My Campaigns' buttonLink='/member/campaigns/'/>
			        	<div style={{marginTop:20}}>
			        		<Index id={id} info={info} addEditCampaign={addEditCampaign}/>
			        	</div>
					</Grid>
				</Grid>
			</Container>
    	)
  	}
}
const mapDispatchToProps = {
	addEditCampaign,
	fetchCampaignInfo
}
export default connect(null, mapDispatchToProps)(MemberAffilateContainer)
