import React from 'react'
import { Redirect } from 'react-router-dom'
import queryString from 'query-string'

function LoginRedirect({location}) {
	let qs = queryString.parse(location.search)

	// rURL = the url to return to after signin
	let rURL = (qs.rURL) ? qs.rURL : null

	// setup = user login/register through email, so take them to setup page to set password
	let setup = (qs.lfiSetup) ? true : false
	
	// redirect to correct route
	if (setup) {
		return <Redirect to={'/member/setup?nextURL=' + rURL} />
	}
	if (rURL) {
		return <Redirect to={rURL} />
	}
	return <Redirect to='/' />
}
export default LoginRedirect

