import React from 'react'

import Paper from '@material-ui/core/Paper'
import Text from '@material-ui/core/Typography'

function Verification() {
	return (
		<Paper style={{ padding: 20, minHeight:800 }} >
			<div align='center'>
				<Text variant='body1' color='textSecondary'>
					<i>KYC Verification Coming Soon.</i>
				</Text>
			</div>
		</Paper>
	)
}
export default Verification
