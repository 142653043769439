import React from 'react'
import moment from 'moment'

import Button from '@material-ui/core/Button'
import MenuItem from '@material-ui/core/MenuItem'
import Text from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'

function Step2 ({campaignInfo, handleUpdateCampaignSettings}) {
	const [listed, setListed] = React.useState(campaignInfo.listed)
	const [stopAtTarget, setStopAtTarget] = React.useState(campaignInfo.stopAtTarget)
	const [deadline, setDeadline] = React.useState(campaignInfo.deadline)
	const [dateEnd, setDateEnd] = React.useState(campaignInfo.dateEnd)
	const [thankyouMessage, setThankyouMessage] = React.useState('')
	const [thankyouEmail, setThankyouEmail] = React.useState('')
	const [message, setMessage] = React.useState('')
	const dateStart = campaignInfo.dateStart
	const expirationDate = moment.unix(campaignInfo.dateStart).add(180, 'd')

	const handleChange = (f,value) => {
		f(value)
		setMessage('')
	}
	const handleNext = () => {
		let i = {
			listed: listed,
			stopAtTarget: stopAtTarget,
			deadline: deadline,
			dateEnd: dateEnd,
			thankyouMessage: thankyouMessage,
			thankyouEmail: thankyouEmail,
		}
		if (i.deadline && (moment.unix(dateEnd).diff(moment.unix(dateStart), 'days') > 180)) {
			return setMessage('Sorry, your campaign can not exceed 6 months. (' + expirationDate.format('DD/MM/yyyy') + ')')
		}	
		handleUpdateCampaignSettings(i)
	}
	return (
		<div style={{maxWidth:500, margin:'0 auto'}}>
			<div style={{marginTop:20}}>
				<Text variant='subtitle2' color='primary'>Who can see your fundraiser?</Text>
				<TextField select fullWidth style={{maxWidth:300}} onChange={(e) => { handleChange(setListed, e.target.value) }} value={listed} size='small' variant='outlined' id='listed'>
				  	<MenuItem value={true}>Everyone (list on site)</MenuItem>
				  	<MenuItem value={false}>Only people I share it with</MenuItem>
				</TextField>
			</div>

			<div style={{marginTop:20}}>
				<Text variant='subtitle2' color='primary'>Stop fundraiser when target is reached?</Text>
				<TextField select fullWidth style={{maxWidth:200}} onChange={(e) => { handleChange(setStopAtTarget, e.target.value) }} value={stopAtTarget} size='small' variant='outlined' id='stopAtTarget'>
				  	<MenuItem value={false}>No</MenuItem>
				  	<MenuItem value={true}>Yes</MenuItem>
				</TextField>
			</div>

			<div style={{marginTop:20}}>
				<Text variant='subtitle2' color='primary'>Does your campaign have a specific end date?</Text>
				<TextField select fullWidth style={{maxWidth:300}} onChange={(e) => { handleChange(setDeadline, e.target.value) }} value={deadline} size='small' variant='outlined' id='deadline'>
				  	<MenuItem value={false}>No it's ongoing</MenuItem>
				  	<MenuItem value={true}>Yes it has an end date</MenuItem>
				</TextField>
			</div>

			{(deadline) ? (
				<div style={{marginTop:20}}>
					<Text variant='subtitle2' color='primary'>When should this campaign end?</Text>
					<TextField 
						fullWidth
						style={{maxWidth:300}}
						variant='outlined' 
						size='small'
						onChange={(e) => { handleChange(setDateEnd, moment(e.target.value).format('X')) }} 
						type='date'
						value={moment.unix(dateEnd).format('yyyy-MM-DD')}
						helperText='Campaign can not exceed 6 months.'
					/>
				</div>
			):( null )}

			<div style={{marginTop:20}}>
				<Text variant='subtitle2' color='primary'>Leave a Thankyou message for your donors.</Text>
				<TextField 
					placeholder='Thankyou Message'
					multiline
          		rows={4}
					fullWidth 
					onChange={(e) => { handleChange(setThankyouMessage, e.target.value) }} 
					value={thankyouMessage} 
					size='small' 
					variant='outlined' 
				/>
			</div>

			<div style={{marginTop:20}}>
				<Text variant='subtitle2' color='primary'>What email message would you like to send to your donors.</Text>
				<TextField 
					placeholder='Thankyou Email'
					multiline
          		rows={4}
					fullWidth 
					onChange={(e) => { handleChange(setThankyouEmail, e.target.value) }} 
					value={thankyouEmail} 
					size='small' 
					variant='outlined' 
				/>
			</div>

			<div align='center' style={{ height: 60, padding: 10, borderBottom: '2px solid #ddd' }}>
            <Text variant='body2' style={{ color: 'red' }}>{message}</Text>
         </div>

		  	<div align='right' style={{ marginTop:50 }}>
				<Button variant='contained' color='primary' onClick={handleNext}>
					 Review
				</Button>
		  	</div>

		</div>
	)
}
export default Step2
