import React from 'react'
import swal from 'sweetalert2'
import { connect } from 'react-redux'
import { addEditBoard, fetchBoardInfo } from '../../../redux/group/actions'

import PageMessage from '../../components/PageMessage'
import SubNav from '../../components/SubNav'
import Index from './Index'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Text from '@material-ui/core/Typography'

class GroupBoardContainer extends React.Component {
	mounted = true
	state = {
		pageLoading: false,
		pageTitle: '',	//Not Found',
		pageText: '',	//The campaign you are looking for was not found',
		id: undefined,
		info: undefined
	}
	componentDidMount = () => {
		let id = this.props.match.params.id
		if(id) {
			this.setState({ id:id })
			this.handleFetchBoard(id)
		}
	}
	componentWillUnmount = () => {
		this.mounted = false
	}
	handleFetchBoard = (id) => {
		let _this = this
		this.setState({ pageLoading: true, pageTitle: 'Loading Board', pageText: 'fetching board info from server'})
		this.props.fetchBoardInfo(id)
		.then((info) => {
			if (_this.mounted) {
				_this.setState({ pageLoading:false, pageTitle:'', pageText:'', info:info })
			}
		})
		.catch((error) => {
			if (_this.mounted) {
				swal.fire({
					  title: error.title,
					  text: error.message,
					  icon: 'warning',
					  confirmButtonColor: '#4caf50',
					  confirmButtonText: 'ok'
				}).then((result) => {
				   _this.props.history.push({ pathname:'/group/boards' })
				})	
			}
		})
	}

  	render() {
  		const {pageLoading, pageTitle, pageText, id, info} = this.state 
  		const {addEditBoard} = this.props

  		if(pageLoading || pageTitle) {
			return (<PageMessage pageLoading={pageLoading} pageTitle={pageTitle} pageText={pageText} />)
		}

    	return (
   		<Container maxWidth='lg' style={{ padding: 10, marginBottom: 50 }}>
				<Grid container justify='center' spacing={2}>
					<Grid item xs={12} sm={12} md={10} >
						<SubNav text='Boards'  buttonText='View Group Boards' buttonLink='/group/boards/'/>
			        	<div style={{marginTop:20}}>
			        		{(info && info.approvedAndLocked) ? (
			        			<Paper square  style={{padding:0, minHeight:800}}>
			        				<div align='center' style={{padding:50}}>
			        					<Text variant='h6'>Approved and Locked</Text>
										<Text variant='subtitle1'>Sorry, Board Locked by LetsFundIt and can not be edited.</Text>			        					
			        				</div>
			        			</Paper>
			        		) : (	
			        			<Index id={id} info={info} addEditBoard={addEditBoard}/>
			        		)}
			        	</div>
					</Grid>
				</Grid>
			</Container>
    	)
  	}
}
const mapDispatchToProps = {
	addEditBoard,
	fetchBoardInfo
}
export default connect(null, mapDispatchToProps)(GroupBoardContainer)
