import React from 'react'
import moment from 'moment'
import {money} from '../../includes/functions'

import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AppBar from '@material-ui/core/AppBar'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Grid from '@material-ui/core/Grid'
import IconAssignment from '@material-ui/icons/Assignment'
import IconButton from '@material-ui/core/IconButton'
import IconClose from '@material-ui/icons/Close'
import IconExpandMore from '@material-ui/icons/ExpandMore'
import Slide from '@material-ui/core/Slide'
import Text from '@material-ui/core/Typography'
import Toolbar from '@material-ui/core/Toolbar'
import withMobileDialog from '@material-ui/core/withMobileDialog'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction='up' ref={ref} {...props} />
})

const CampaignDonors = ({fullScreen, showDonors, toggleDonors, fetchDonorDetails, campaignId}) => {
	const mounted = React.useRef(true)
	const [show, setShow] = React.useState('loading') // loading | error | show
	const [donations, setDonations] = React.useState({})
	const [expanded, setExpanded] = React.useState(false)
	
	const handleChange = (panel) => (event, isExpanded) => {
    	setExpanded(isExpanded ? panel : false);
  	}

   React.useEffect(() => {
		fetchDonorDetails(campaignId)
		.then((data) => {
			if (mounted.current) {
				setDonations(data)
				setShow('show')	
			}
		})
		.catch(() => {
			if (mounted.current) {
				setShow('error')
			}	
		})

      return () => {
         mounted.current = false
      }
   },[fetchDonorDetails, campaignId])

	return (
		<Dialog keepMounted={false} fullScreen={fullScreen} open={showDonors} TransitionComponent={Transition} onClose={() => { toggleDonors(!showDonors) }}>
			<AppBar style={{ position: 'relative' }} >
            <Toolbar>
                <IconAssignment style={{ marginRight: 5 }} />
                <Text variant='subtitle2' color='inherit' style={{ flex: 1 }}>
                   	All Donations Details
                </Text>
                <IconButton color='inherit' onClick={() => { toggleDonors(!showDonors) }}>
                    <IconClose />
                </IconButton>
            </Toolbar>
        	</AppBar>
        	<DialogContent>
        		{(show === 'loading') ? (
        			<div align='center' style={{marginTop:20}}>
						<CircularProgress color='secondary' style={{ margin: '5px auto'}} />
						<Text variant='h6'>Loading Donations</Text>
						<Text variant='subtitle1'>Checking server for donations.</Text>
					</div>
        		) : ( null )}
    			{(show === 'error') ? (
        			<div align='center' style={{marginTop:20}}>
						<Text variant='h6'>Server Error</Text>
						<Text variant='subtitle1'>Sorry, there was an error retreving records.</Text>
					</div>
        		) : ( null )}
    			{(show === 'show') ? (
        			(!donations || !Object.keys(donations).length) ? (
	        			<div align='center' style={{marginTop:20}}>
							<Text variant='body1' color='textSecondary'>No Donations Recorded</Text>
						</div>
	        		) : ( 
	        			<div>
							{Object.keys(donations).map((i, key) => {
								return (
									<Accordion key={key} expanded={expanded === i} onChange={handleChange(i)} style={{borderRight: '2px solid #89fa89'}}>
										<AccordionSummary	expandIcon={<IconExpandMore />}>
											<Grid container>
								     			<Grid item xs={12} sm={4}>
								     				<Text variant='body1' color='textSecondary'><i>{moment.unix(donations[i].timestamp).format('MMM Do YYYY')}</i></Text>
								     			</Grid>
								     			<Grid item xs={12} sm={6}>
								     				<Text variant='body1' color='primary'><b>{donations[i].donorName}</b></Text>
								     				<Text variant='body2' color='textSecondary'><i>{donations[i].donorMessage}</i></Text>
								     			</Grid>
								     			<Grid item xs={12} sm={2}>
								     				<Text variant='body1' component='span' color='primary'><b>{ money(donations[i].donationAmount,0) } </b></Text>
													<Text variant='caption' component='span' color='textSecondary'><b> USD</b></Text>
								     			</Grid>
											</Grid>
										</AccordionSummary>
										<AccordionDetails>
							        		<div style={{margin:'0 auto', width:'100%', maxWidth:600, marginBottom:25}}>
												<Grid container>
								        			<Grid item xs={6} sm={3} style={{textAlign:'center'}} >
								        				<Text variant='overline'><b>donation</b></Text><br/>
								        				<Text variant='h6' component='span' color='primary' style={{lineHeight:1}}><b>{ money(donations[i].donationAmount,0) } </b></Text>
								        			</Grid>
								        			<Grid item xs={6} sm={3} style={{textAlign:'center'}} >
								        				<Text variant='overline'><b>website fee</b></Text><br/>
								        				<Text variant='h6' component='span' color='primary' style={{lineHeight:1}}><b>{ money(donations[i].donationWebsiteFee,0) } </b></Text>
								        			</Grid>
								        			{(donations[i].donationBoardFee) ? (
								        				<Grid item xs={6} sm={3} style={{textAlign:'center'}} >
									        				<Text variant='overline'><b>board fee</b></Text><br/>
									        				<Text variant='h6' component='span' color='primary' style={{lineHeight:1}}><b>{ money(donations[i].donationBoardFee,0) } </b></Text>
									        			</Grid>
								        			) : ( null )}
								        			<Grid item xs={6} sm={3} style={{textAlign:'center'}} >
								        				<Text variant='overline'><b>received</b></Text><br/>
								        				<Text variant='h6' component='span' color='primary' style={{lineHeight:1}}><b>{ money(donations[i].donationBalance,0) } </b></Text>
								        			</Grid>
								        			<Grid item xs={12} style={{textAlign:'center', paddingTop:10}}>
							        					<Text variant='caption'>A donation of { money(donations[i].donationAmount,0) } was made, a fee of { money((donations[i].donationWebsiteFee + donations[i].donationBoardFee),0) } was charged and you received { money(donations[i].donationBalance,0) } </Text>
								        			</Grid>
							        			</Grid>
											</div>
					        			</AccordionDetails>
									</Accordion>
								)
							})}
	        			</div>
	        		)
        		) : ( null )}
        	</DialogContent>
         <DialogActions>
            <Button onClick={() => { toggleDonors(!showDonors) }} color='primary'>Close</Button>
         </DialogActions>
		</Dialog>
	)
}
export default withMobileDialog()(CampaignDonors)
