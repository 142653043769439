import React from 'react'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Divider from '@material-ui/core/Divider'
import Text from '@material-ui/core/Typography'
import withMobileDialog from '@material-ui/core/withMobileDialog'

function AboutFeatureBoards({fullScreen, open, showAboutToggle}){
	return (
		<Dialog fullScreen={fullScreen} open={open} onClose={showAboutToggle}>
			<DialogTitle>How Feature Boards Work</DialogTitle>
        	<Divider/>
			<DialogContent style={{minHeight:300}}>
				<Text variant='h6' color='secondary'>What It Is</Text>
				<Text variant='body1'>
					A <b>LetsFundIt Feature Board</b> is a place where a group of like-minded people
					come together to give each other a hand. One persons' campaign is featured on the 
					board at a time, and it's featured until a predetermined amount of funds is raised.
				</Text>

				<Text variant='h6' color='secondary' style={{marginTop:20}}>How It Works</Text>
				<Text variant='body1'>
					All the members of the board work together to promote the board.
					New members must contribute to the boards' Featured Campaign before
					 they can get their campaign listed on it. 
					Campaigns are featured on the board in the order they were listed.
				</Text>

				<Text variant='h6' color='secondary' style={{marginTop:20}}>Structure & Requirements</Text>
				<Text variant='body1'>
					<b>LetsFundIt Feature Boards</b> may vary in the details, which are decided by the boards owner,
					 but they all have a similar outline which is implemented and managed by <b>LetsFundIt</b>:
				</Text>
				<Text variant='body1'>
					 <ul>
					 	<li>Each member must contribute the same amount to qualify for a spot on the board</li>
					 	<li>Members are allowed to have the same amount of active spots</li>
					 	<li>Each member pays the same fee to get listed</li>
					 	<li>Each Campaign is Featured in the order it was listed</li>
					 </ul>	
				</Text>		 

				<Divider />
				<div style={{padding:10}}>
					<Text variant='body1'>
						<i>Please keep in mind that a donation is a charity you give to someone without expectation of anything in return. Although we all want to help each other in these times, please only give what you can afford to. We appreciate you and your contribution.</i>
					</Text>
				</div>
			</DialogContent>
			<Divider/>
			<DialogActions>
			   <Button onClick={showAboutToggle} color='primary' autoFocus>Close</Button>
			</DialogActions>
		</Dialog>
	)
}
export default withMobileDialog()(AboutFeatureBoards)
