import { 
	PUBLIC_LOAD_BOARDS,
	PUBLIC_LOAD_CAMPAIGNS, 
	PUBLIC_LOAD_CAMPAIGN_INFO, 
	PUBLIC_LOAD_CAMPAIGN_DONATIONS,
	PUBLIC_FOLLOW_CAMPAIGN,
	PUBLIC_LOAD_CHARITIES
} from '../types'

const initialState = {
	lastUpdated: 0,
	boards: {},
	campaigns: {},
	campaignsInfo: {},
	campaignsDonations: {},
	charities: {}
}

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case PUBLIC_LOAD_BOARDS:
			return { 
				...state, 
				lastUpdated: action.payload.timestamp,
				boards: action.payload.boards
			}		
		case PUBLIC_LOAD_CAMPAIGNS:
			return { 
				...state, 
				lastUpdated: action.payload.timestamp,
				campaigns: action.payload.campaigns
			}
		case PUBLIC_LOAD_CHARITIES:
			return { 
				...state, 
				lastUpdated: action.payload.timestamp,
				charities: action.payload.charities
			}
		case PUBLIC_LOAD_CAMPAIGN_INFO:
			return { 
				...state, 
				lastUpdated: action.payload.timestamp,
				campaignsInfo: action.payload.info
			}
		case PUBLIC_LOAD_CAMPAIGN_DONATIONS:
			return { 
				...state, 
				lastUpdated: action.payload.timestamp,
				campaignsDonations: action.payload.donations
			}
		case PUBLIC_FOLLOW_CAMPAIGN:
			return { 
				...state, 
				lastUpdated: action.payload.timestamp,
				campaignsInfo: {
					...state.campaignsInfo,
					[action.payload.id]:action.payload.info
				}
			}
		default:
			return state
	}
}
