import React from 'react'


import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Text from '@material-ui/core/Typography'

function GetStarted({loggedIn}) {
	return (
		<Container maxWidth='lg' style={{ padding: 10, marginBottom: 50, minHeight:700  }}>
		<Grid container justify='center' spacing={2}>
			<Grid item xs={12} sm={12} md={10} >
				<Paper style={{ marginTop:20, padding:40, paddingTop:60 }} >
					<Text variant='h3' gutterBottom>
						Start A Fundraiser
					</Text>
					
					<Text variant='body1' gutterBottom>
						Hi welcome to LetsFundIt.<br/>
						We are currently in a beta version while we put the final pieces in place. Most likely you are here 
						because you were invited by someone. Although the site is fully functional we would only be available 
						in Trinidad & Tobago and our payout methods would be limited to local bank withdrawals until our 
						official launch.
					</Text>
					<div style={{height:50}}/>
					{(loggedIn) ? (
						<Button variant='contained' color='secondary' size='small' href='/member/campaign'>
							Get Started
						</Button>
					):(
						<Button variant='contained' color='secondary' size='small' href='/login?rURL=/member/campaign'>
							Login/Register to Get Started
						</Button>
					)}
				</Paper>
			</Grid>
		</Grid>
		</Container>
	)
}
export default GetStarted
