import React from 'react'
import { withRouter } from 'react-router-dom'
import {money, formattedNumber} from '../../../includes/functions'

import { green } from '@material-ui/core/colors'
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Grid from '@material-ui/core/Grid'
import Text from '@material-ui/core/Typography'

function Step3 ({history, loggedIn, boardId, boardName, boardSpotsAllowed, donationRequirement, memberTotalContribution, memberTotalSpotsUsed, memberTotalSpotsCompleted, handleGoTo}) {
	const [checkedAgree, setCheckedAgree] = React.useState(false)
	const [message, setMessage] = React.useState('')
	const qualifiedSpots = parseInt(memberTotalContribution / donationRequirement)
	const usedSpots = memberTotalSpotsUsed || 0
	const availableSpots = (qualifiedSpots - usedSpots >= 0) ? qualifiedSpots - usedSpots : 0
	const activeSpots = memberTotalSpotsUsed - memberTotalSpotsCompleted
	const activeLimit = (activeSpots >= boardSpotsAllowed) ? true : false

	const handleNext = () => {
		if (!checkedAgree) {
			return setMessage('Please Check the Checkbox to Agree.')
		}
		handleGoTo(3)
	}

	const goTo = (link) => {
      history.push({ pathname:link })
   }

   if (!loggedIn) return null
	return (
		<div>
    		<div style={{ margin:'0px auto', padding:20, maxWidth:600 }}>
    			<Text variant='body1'>
    			Your must make a collective contribution to this Feature Board of at least <b>{money(donationRequirement,0)}</b>. 
    			Each collective <b>{money(donationRequirement,0)}</b> contribution would allow you one spot on the 
    			board. Here is a summary of your activity on this board.	
    			</Text>

				<div style={{marginTop:10, borderTop: '2px solid pink'}}>
        			<div align='center' style={{backgroundColor:'#fafafa', padding:10, borderRadius:3}}>
						<Text component='span' style={{borderBottom: '2px solid gray', paddingBottom:3}}>Total Contribution: <b>{money(memberTotalContribution,0)}</b></Text>
						<div style={{margin:'0 auto', maxWidth:200, marginTop:10}}>
						<Grid container justify='center' spacing={0}>
							<Grid item xs={9} style={{textAlign:'right'}}>Qualified Spots - </Grid>
							<Grid item xs={3} style={{textAlign:'center'}}>{formattedNumber(qualifiedSpots,0)}</Grid>
							<Grid item xs={9} style={{textAlign:'right'}}>Used Spots - </Grid>
							<Grid item xs={3} style={{textAlign:'center'}}>{formattedNumber(usedSpots,0)}</Grid>
							<Grid item xs={9} style={{textAlign:'right'}}><b>Available Spots - </b></Grid>
							<Grid item xs={3} style={{textAlign:'center'}}><b>{formattedNumber(availableSpots,0)}</b></Grid>
						</Grid>
						</div>
					</div>
				</div>

				{(activeLimit) ? (
					<div align='center' style={{padding:20}}>
						<Text variant='body1' style={{color:'red'}}>
	       			You currently have <b>{activeSpots} active spots</b> on this Feature Board, which is the limit.
	       			Please wait untill one of your spots is complete before registering another.	
	       			</Text>
	       			<Button style={{margin:10}} onClick={() => { goTo('/board/'+boardId) }} size='small' variant='outlined'>return to feature board</Button>
					</div>

				) : (
					<div>
						{(availableSpots) ? (
							<div align='center' style={{padding:20}}>
								<Text variant='body1' style={{color:'red'}}>
			       				You have <b>{availableSpots} available spots</b> on this Feature Board. 
			       				This Step by Step guide is for users with no available spots on the board.
			       				If you would like to register one of your available spots, please do so directly 
			       				on the board.
			       			</Text>
			       			<Button style={{margin:10}} onClick={() => { goTo('/board/'+boardId) }} size='small' variant='outlined'>return to feature board</Button>
							</div>
		       		) : (
		       			<div>
		       				<div style={{marginTop:20}} />
		       					<div align='center'>
		       						<Text variant='button' color='secondary'>Make Donation</Text><br/>
										<Text component='span' variant='h2' style={{color:'#d4d4d4'}}>$</Text>
										<Text component='span' variant='h2' color='secondary'>{donationRequirement}</Text>
										<Text component='span' variant='h5' style={{color:'#d4d4d4'}}> USD</Text>
									</div>
									<FormControlLabel
							      	style={{marginLeft:0, padding:0}}
							        	control={<Checkbox checked={checkedAgree} style={{ zIndex: 3 }} onChange={ ()=>{setCheckedAgree(!checkedAgree)} } />}
							        	label={
							        		<label>
							        			<Text>
							        				I agree to make a <b>{money(donationRequirement,2)}</b> donation to the Featured 
							        				Campaign on the <b>{boardName}</b> Feature Board. I also understand that I make 
							        				this donation without expectation of anything in return.
							        			</Text>
							        		</label>
							        	}
							      />
		       			</div>
		       		)}	
					</div>
				)}
    		</div>
    		{(!activeLimit && !availableSpots) ? (
    			<div>
			      <div align='center' style={{ minHeight: 50, padding: 10, borderBottom: '2px solid #ddd' }}>
		            <Text variant='h6' style={{ color: 'red' }}>{message}</Text>
		         </div>
				  	<div align='right' style={{marginTop:20}}>
					 	<Button variant='contained' style={{backgroundColor:green[500], color:'#fff'}} onClick={handleNext}>
							I Agree to make a {money(donationRequirement,0)} donation
					 	</Button>
					</div>
    			</div>
    		) : ( null )}
		</div>
	)
}
export default withRouter(Step3)
