import React from 'react'
import Swal from 'sweetalert2'
import Loader from '../../../components/Loader'

import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Paper from '@material-ui/core/Paper'
import Select from '@material-ui/core/Select'
import Text from '@material-ui/core/Typography'

function TicketAssignTo({ticketId, ticket, team, assignTicket}){
	const [loading, setLoading] = React.useState(false)
	const [assignedTo, setAssignedTo] = React.useState('')
	const [assignedToMessage, setAssignedToMessage] = React.useState('')
	
	const handleAssignTicket = () => {
		if (assignedTo === ''){
			return setAssignedToMessage('Please select assigned Team Member.')
		}		
		Swal.fire({
			title: 'Assign Ticket',
			text: 'Assign this ticket to ' + assignedTo.memberName + '?',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Confirm'
		}).then((result) => {
			if (result.value) {
				setLoading(true)
				assignTicket(ticketId, assignedTo)
				.then(() => {
					setLoading(false)
					setAssignedTo('')
					setAssignedToMessage('')
				})
				.catch((error) => {
					setLoading(false)
					setAssignedTo('')
					setAssignedToMessage(error)
				})				
			}
		})	
	}
    return (
    	<React.Fragment>
			<Loader loading={loading} />
			<Paper style={{marginTop:20, padding: 10}} >
				<Text variant='h5'>Assign Ticket</Text>
				{(ticket.status === 'unassigned') ? (
					<Text variant='subtitle1' color='textSecondary'><i>This ticket is currently unassigned.</i></Text> 
				):( 
					<Text variant='subtitle1' color='textSecondary'><i>This ticket is currently assigned to {ticket.assignedToName}</i></Text>
				)}	
				<FormControl style={{width:'100%'}}>
		        	<InputLabel>Team Member</InputLabel>
		        	<Select value={assignedTo} onChange={(e)=>{setAssignedTo(e.target.value)}}>
		        		<MenuItem value=''>Select Team Member</MenuItem>
		        		{Object.keys(team).map((i) => {
		        			return (
							 	<MenuItem key={team[i].memberId} value={team[i]}>{team[i].memberName}</MenuItem>
						   )
						})}
		        	</Select>
		      </FormControl>
		      <div style={{textAlign:'center'}}><Text variant="overline" style={{color:'red'}}>{assignedToMessage}</Text></div>
				<Button onClick={handleAssignTicket} variant="contained" color="secondary" style={{marginTop:10, marginBottom:10}}>Assign Ticket</Button>
			</Paper>
		</React.Fragment>
   )
}
export default TicketAssignTo
