import { combineReducers } from 'redux'
import appReducer from './app/reducer'
import authReducer from './auth/reducer'
import groupReducer from './group/reducer'
import memberReducer from './member/reducer'
import paymentsReducer from './payments/reducer'
import publicReducer from './public/reducer'

const rootReducer = combineReducers({
	app: appReducer,
	auth: authReducer,
	group: groupReducer,
	member: memberReducer,
	payments: paymentsReducer,
	public: publicReducer
})
export default rootReducer
