import React from 'react'
import moment from 'moment'
import {money} from '../../../includes/functions'

import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import Grid from '@material-ui/core/Grid'
import IconExpandMore from '@material-ui/icons/ExpandMore'
import Text from '@material-ui/core/Typography'

function ViewPayoutsList({payouts, count}){
	const [expanded, setExpanded] = React.useState(false)
	
	const handleChange = (panel) => (event, isExpanded) => {
    	setExpanded(isExpanded ? panel : false);
  	}
	if (!payouts || !Object.keys(payouts).length) {
		return (
			<div align='center' style={{margin:0, padding:50, backgroundColor:'#f1f1f1'}}><Text variant='body1' color='textSecondary'>No Payout History</Text></div>
		)	
	}

	return (
		<div>
			{Object.keys(payouts).slice(0,count).map((i, key) => {
				return (
					<Accordion key={key} expanded={expanded === i} onChange={handleChange(i)} style={{borderRight: '2px solid #ff9e9e'}}>
						<AccordionSummary	expandIcon={<IconExpandMore />}>
							<Grid container>
				     			<Grid item xs={12} sm={4}>
				     				<Text variant='body1' color='textSecondary'><i>{moment.unix(payouts[i].timestamp).format('MMM Do YYYY - h:mm a')}</i></Text>
				     			</Grid>
				     			<Grid item xs={12} sm={4}>
				     				<Text variant='body1' color='primary'><b>{payouts[i].description}</b></Text>
				     			</Grid>
				     			<Grid item xs={12} sm={4}>
				     				<Text variant='body1' component='span' color='primary'><b>{ money(payouts[i].total,0) } </b></Text>
									<Text variant='caption' component='span' color='textSecondary'><b> USD</b></Text>
				     			</Grid>
							</Grid>
						</AccordionSummary>
						<AccordionDetails>
	        				<div align='center' style={{margin:'0 auto', width:'100%', maxWidth:800, marginBottom:25}}>
								<Grid container>
				        			<Grid item xs={4} style={{textAlign:'center'}} >
				        				<Text variant='overline'><b>{(payouts[i].transType === 'transfer') ? 'transfer' : 'withdrew'}</b></Text><br/>
				        				<Text variant='h6' component='span' color='primary' style={{lineHeight:1}}><b>{ money(payouts[i].amount,0) } </b></Text>
				        			</Grid>
				        			<Grid item xs={4} style={{textAlign:'center'}} >
				        				<Text variant='overline'><b>fees</b></Text><br/>
				        				<Text variant='h6' component='span' color='primary' style={{lineHeight:1}}><b>{ money(payouts[i].fee,0) } </b></Text>
				        			</Grid>
				        			<Grid item xs={4} style={{textAlign:'center'}} >
				        				<Text variant='overline'><b>deducted</b></Text><br/>
				        				<Text variant='h6' component='span' color='primary' style={{lineHeight:1}}><b>{ money(payouts[i].total,0) } </b></Text>
				        			</Grid>
				        			<Grid item xs={12} sm={12} style={{textAlign:'center', marginTop:10}}>
					     				<Text variant='caption'>You made a payOut of { money(payouts[i].amount,0) } plus a fee of { money(payouts[i].fee,0) }, a total of { money(payouts[i].total,0) } was deducted from your credits. </Text>
					     			</Grid>
			        			</Grid>
	        				</div>
	        			</AccordionDetails>
					</Accordion>
				)
			})}
		</div>
	)
}
export default ViewPayoutsList
