export function chkEmail(email) {
	let emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
	if (emailRex.test(email)) {
		return true
	}
	return false
}

export function chkPassword(password) {
	let regExp = /^[a-zA-Z0-9!@#$%^&*]{6,26}$/
	if (regExp.test(password)) {
		return true
	}
	return false
}

export function customDelay(ms) {
	return new Promise(resolve => setTimeout(resolve, ms));
}

export function money(num, cents) {
	let number = '$0'
	if (num) {
		number = '$' + parseFloat(num).toFixed(cents).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
	}
	return number
}

export function formattedNumber(num, points) {
	let number = '0'
	if (num) {
		number = parseFloat(num).toFixed(points).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
	}
	return number
}

export function progress(part, total) {
	let p = (part / total) * 100
	p = parseFloat(p).toFixed(0)
	return Number(p) 
}

export function truncate(input, length) {
	if (input && input.length > length) {
		return input.substring(0, length) + '...'
   }
   return input
}