import React from 'react'
import { Link } from 'react-router-dom'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Text from '@material-ui/core/Typography'
var HtmlToReactParser = require('html-to-react').Parser

function LoginRegister() {
    let invoiceId = 'O2343334544KH'
    let ticketCount = [1,2,3]
    let itemsCount = []
    let memberName = 'james'
    let memberEmail = 'james.caesar@gmail.com'

    let invoiceDate = 'Fri, Nov 30, 2018, 6:23 AM'
    let invoiceAmount = '$5.70'
    let invoiceCurrency = 'USD'
    let invoicePaymentMethod = 'Credit Card'
    let invoicePaymentId = 'XXXXXXXXXXX6785'
    let invoiceAuthCode = '123456'
    let merchantId = 'Online Solutions Ltd (Displayed on your Credit Card statement.)'

    let subject, html //text, 
    subject = 'SecureTix receipt for Order #: O2343334544KH'
    //text = 'Thankyou for your purchase. Please see your attached tickets. Your transaction details are saved in your SecureTix account under My Invoices.'

    html  = '<div style="background-color:#F5F5F5; padding:5px">'
    html += '   <div align="center" style="margin-top:40px; font: 30px Arial, sans-serif; color:black"><b>SecureTix</b></div>'
    html += '   <div style="padding:15px; padding-bottom:0px; max-width:600px; background-color:#fff; margin:10px auto; border: 2px solid #eee">'
    html += '       <div style="font:15px Arial, sans-serif; color:#8b8d8e">'
    html += '           Hello ' + memberName + ','
    html += '           <p>Thankyou for your purchase.<br/> Please review the following transaction details and the attached eTickets.<br/>'
    html += '           This Invoice would also be saved to your SecureTix account along with the eTickets.</p>'
    html += '           <p>If you have any questions or concerns please do not respond to this email, contact us through the support page on SecureTix.</p>'
    html += '           <p>Thanks,<br/>Your SecureTix team.</p>'        
    html += '       </div>'
    html += '   </div>'

    html += '   <div style="max-width:600px; margin:20px auto; margin-bottom:0px; font: 20px Arial, sans-serif; color:black"><b>Transaction Details</b></div>'
    html += '   <div style="padding:15px; max-width:600px; background-color:#fff; margin:10px auto; border: 2px solid #eee">'
    html += '       <div style="font: bold 20px Arial, sans-serif; color:#999">Summary</div>'
    html += '       <div style="padding-left:20px; font: 15px Arial, sans-serif; color:#58504D">'
    html += '           <b>Invoice #: </b>'+invoiceId+'<br/>'       
    if(ticketCount){
    html += '           <b># of Tickets: </b>'+ticketCount+'<br/>'
    }          
    if(itemsCount){
    html += '           <b># of Items: </b>'+itemsCount+'<br/>'
    }
    html += '           <b>Billed To: </b>'+ memberEmail +'<br/>'
    html += '           <b>Status: </b>PAID IN FULL<br/>'
    html += '       </div>  '

    html += '       <div style="margin-top:20px; font: bold 20px Arial, sans-serif; color:#999">Payment Details</div>'
    html += '       <div style="padding-left:20px; font: 15px Arial, sans-serif; color:#58504D">'
    html += '           <b>Date of Payment: </b>'+ invoiceDate +'<br/>'
    html += '           <b>Amount Paid: </b>'+ invoiceAmount +' '+ invoiceCurrency +'<br/>'
    html += '           <b>Payment Method: </b>'+ invoicePaymentMethod +'<br/>'
    html += '           <b>Payment ID: </b>'+ invoicePaymentId +'<br/>'
    html += '           <b>Authorization #: </b>'+ invoiceAuthCode +'<br/>'
    html += '           <b>Merchant ID: </b>'+ merchantId +'<br/>'
    html += '       </div>'

    html += '       <div style="margin-top:20px; font: bold 20px Arial, sans-serif; color:#999">Status</div>'
    html += '       <div style="padding-left:20px; font: 15px Arial, sans-serif; color:#58504D">'
    html += '           <b>Transaction Complete</b>'
    html += '       </div>'
    html += '   </div>'
    html += '   <div align="center" style="max-width:600px; margin:20px auto; font: 20px Arial, sans-serif; color:#999"><b>eTickets Attached</b></div>'
    html += '</div>'

    let htmlToReactParser = new HtmlToReactParser()
    let reactElement = htmlToReactParser.parse(html)

    // Preview Template
    return (
        <Container maxWidth='lg' style={{ padding: 10, minHeight:700  }}>
        <Grid container justify='center' spacing={2}>
            <Grid item xs={12} sm={8} md={8} >
                <Paper style={{ padding: 10 }} >
                    <b>Subject:</b> {subject}
                    {reactElement}
                </Paper>
                <div align='right'>
                    <Text variant='caption' gutterBottom>
                        <Link to='/templates'>Bact To Templates</Link>
                    </Text>
                </div>
            </Grid>
        </Grid>
        </Container>
    )
}
export default LoginRegister
