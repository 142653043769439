import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { emailLogIn } from '../../redux/auth/actions'
import { chkEmail }  from '../../includes/functions'
import ServiceAndPolicies from '../components/ServiceAndPolicies'

import Checkbox from '@material-ui/core/Checkbox'
import Container from '@material-ui/core/Container'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Text from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'

import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconChecked from '@material-ui/icons/Check'


class Register extends React.Component {
  state = {
    email: null,
    sent: false,
    message: null,
    btnText: 'submit',
    btnDisabled: false,
    checked: false,
    copied: false
  }

  handleChange = (e) => {
    this.setState({[e.target.id]: e.target.value, message: null})
  }
  
  chkCheckbox = () => {
    this.setState({ checked: !this.state.checked })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    if (!chkEmail(this.state.email)) {
      return this.setState({ message: 'Please enter a valid email address.' })
    }
    if (!this.state.checked) {
      return this.setState({ message: 'Sorry, please agree with the TOS (Terms of Service)' })
    }
    this.setState({ btnText: 'Processing Request', btnDisabled: true })
    this.props.emailLogIn(this.state.email.toLowerCase(), this.props.match.params.type, this.props.location.search)
    .then(() => {
      this.setState({ sent: true })
    })
    .catch((err) => {
      this.setState({ btnText: 'Submit', btnDisabled: false, message: err }) 
    })
  }

  handleCopyLink = () => {
    let copyText = document.querySelector('#copiedText');
    copyText.select()
    document.execCommand('copy')
    this.setState({ copied:true })
  }

  render() {
    const {sent, message, btnText, btnDisabled, checked, copied } = this.state
    return (
      <Container maxWidth='xl' style={{ padding: 10, marginBottom: 150, minHeight:700, marginTop:80 }}>
        <Grid container justify='center' spacing={2}>
          <Grid item xs={12} sm={8}>
            { sent ? (
                <Paper style={{ padding: 20 }} >
                  <Text variant='h5' gutterBottom>
                  Email Confirmation.
                  </Text>
                  <Text style={{ marginTop: 10, color: 'red' }} variant='button' component='p' align='center'>
                    Check Your Email
                  </Text>
                  <Text style={{ marginTop: 10 }} variant='body1'>
                    A Login Link was sent to your email address - <b>{this.state.email}</b>.<br />
                    Welcome to letsFundIt
                  </Text>
                  <Divider style={{margin:10}}/>
                  <Text style={{ marginTop: 10, color: 'red' }} variant='button' component='p' align='center'>
                    Check Your Spam / Promotions Folders
                  </Text>
                  <Text style={{ marginTop: 10 }} variant='body1'><i>
                    The Login Link might end up in your SPAM / PROMOTIONS folder. Please 
                    copy and add the following email address to your contacts so emails from us always go to your inbox.
                  </i></Text>



                  <div style={{padding:20, textAlign:'center', maxWidth:400, margin:'10px auto'}}>
                    <FormControl size='small' variant='outlined' fullWidth>
                      <InputLabel htmlFor='copiedText'>Copy URL</InputLabel>
                      <OutlinedInput
                        id='copiedText'
                        type='text'
                        value='noreply@mail.letsfundit.org'
                        style={{ color:'gray' }}
                        labelWidth={80}
                        endAdornment={
                            <InputAdornment position='end'>
                              {(copied) ? (
                                <Button startIcon={<IconChecked />} size='small' variant='contained' style={{backgroundColor:'#04b312', color:'#fff' }}>Copied!</Button>
                              ) : (
                                <Button onClick={this.handleCopyLink} size='small' variant='contained' color='secondary'>copy</Button>
                              )}
                        </InputAdornment>
                        }
                      />
                    </FormControl>
                  </div>



                </Paper>
            ):(
                <Paper style={{ padding: 20 }} >
                  <Text variant='h5' gutterBottom>
                    {this.props.match.params.type.toUpperCase()}
                  </Text>
                  <Text variant='caption' component='p'>
                  {this.props.match.params.type.charAt(0).toUpperCase()+this.props.match.params.type.substring(1)} through your email account.
                  </Text>
                  <Text style={{ marginTop: 10, color: 'red', height: 50 }} variant='caption' component='p' align='center'>
                    { message }
                  </Text>

                  <form onSubmit={ this.handleSubmit }>
                    <TextField
                      type='email'
                      id='email'
                      label='Email'
                      placeholder='Enter your email address'
                      margin='normal'
                      fullWidth={true}
                      onChange={ this.handleChange }
                    />
                    <Checkbox
                      checked={checked}
                      onChange={this.chkCheckbox}
                      color='primary'
                    />
                    <ServiceAndPolicies tab={3} /><br/>
                    <div style={{ height: 20 }} />
                    <Button disabled={ btnDisabled } variant='contained' color='primary'  type='submit'>
                      { btnText }
                    </Button>
                    <div style={{ height: 20 }} />
                    { (this.props.match.params.type === 'register')
                        ? (<Button style={{marginRight:25}} size='small' color='primary' component={ Link } to={'/email/login' + this.props.location.search}>Login through Email</Button>)
                        : (<Button style={{marginRight:25}} size='small' color='primary' component={ Link } to={'/email/register' + this.props.location.search}>Register a New Account</Button>)
                    }                    
                    <Button size='small' color='primary' component={ Link } to={'/login' + this.props.location.search}>
                      Login with Email & Password
                    </Button>
                  </form>
                </Paper>
            )}
          </Grid>
        </Grid>  
      </Container>
    )
  }
}
const mapDispatchToProps = {
  emailLogIn
}
export default connect(null, mapDispatchToProps)(Register)
