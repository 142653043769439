import React from 'react'
import { connect } from 'react-redux'
import { loadMemberNotifications, loadMoreMemberNotifications } from '../../../redux/member/actions'

import PageMessage from '../../components/PageMessage'
import NextUpdate from '../../components/NextUpdate'
import SubNav from '../../components/SubNav'
import NotificationsList from './NotificationsList'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'

class MemberNotificationsContainer extends React.Component {
	mounted = true
	state = {
		pageLoading: true,
		pageTitle: undefined,	//Not Found',
		pageText: undefined	//The campaign you are looking for was not found',
  	}
	componentDidMount = () => {
		this.handleFetchNotifications(false)
	}
	componentWillUnmount = () => {
		this.mounted = false
	}
	handleFetchNotifications = (force) => {
	 	return new Promise((resolve, reject) => {
		 	let _this = this
		 	this.props.loadMemberNotifications(force)
		 	.then(() => {
		 		if(_this.mounted) {
		 			_this.setState({ pageLoading:false })
		 		}
		 	})	
		 	.catch((e) => {
		 		if(_this.mounted) {
		 			_this.setState({ pageLoading:false, pageTitle:e.title, pageText:e.message })
		 		}
		 	})	
		 	.finally(() => {
		 		resolve()
		 	})		
	 	})
	}
  	render() {
  		const {pageLoading, pageTitle, pageText} = this.state
  		const {lastUpdated, notifications, loadMoreMemberNotifications} = this.props
      
      if(pageLoading || pageTitle) {
			return (<PageMessage pageLoading={pageLoading} pageTitle={pageTitle} pageText={pageText} />)
		}

    	return (
   		<React.Fragment>
   		<Container maxWidth='lg' style={{ padding: 10, marginBottom: 50 }}>
				<Grid container justify='center' spacing={2}>
					<Grid item xs={12} sm={12} md={10} >
						<SubNav text='Notifications'/>
				     	<NotificationsList notifications={notifications} loadMoreMemberNotifications={loadMoreMemberNotifications} />
					</Grid>
				</Grid>
				<NextUpdate lastUpdated={lastUpdated} updateNow={this.handleFetchNotifications}/>
			</Container>
   		</React.Fragment>
    	)
  	}
}
const mapStateToProps = (state) => ({
	lastUpdated: state.app.lastUpdated.memberNotifications,
	notifications: state.member.notifications
})
const mapDispatchToProps = {
	loadMemberNotifications,
	loadMoreMemberNotifications
}
export default connect(mapStateToProps, mapDispatchToProps)(MemberNotificationsContainer)