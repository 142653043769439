import React from 'react'
import { connect } from 'react-redux'
import { loadMemberSupport, openTicket, updateTicket, closeTicket } from '../../../redux/member/actions'
import { selectorLoadTickets } from '../../../redux/member/selectors'

import PageMessage from '../../components/PageMessage'
import NextUpdate from '../../components/NextUpdate'
import SubNav from '../../components/SubNav'
import New from './New'
import List from './List'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'

const tabNames = {'open':0,'closed':1,'new':2}
function TabPanel(props) {
  	const { children, value, index, ...other } = props;
  	return (
    	<div role='tabpanel' hidden={value !== index} id={`nav-tabpanel-${index}`} {...other} >
      	{value === index && ( children )}
    	</div>
  	)
}

class MemberSupportContainer extends React.Component {
	mounted = true
	state = {
	 	pageLoading: true,
		pageTitle: undefined,	//Not Found',
		pageText: undefined,	//The campaign you are looking for was not found',
		activeTab: (this.props.match.params.page && tabNames[this.props.match.params.page]) ? tabNames[this.props.match.params.page] : 0
  	}
  	componentDidMount = () => {
		this.handleFetchMemberSupport(false)
	}
	componentWillUnmount = () => {
		this.mounted = false
	}
	handleFetchMemberSupport = (force) => {
	 	return new Promise((resolve, reject) => {
		 	let _this = this
		 	this.props.loadMemberSupport(force)
		 	.then(() => {
		 		if(_this.mounted) {
		 			_this.setState({ pageLoading:false, pageTitle:undefined, pageText:undefined })
		 		}
		 	})	
		 	.catch((e) => {
		 		if(_this.mounted) {
		 			_this.setState({ pageLoading:false, pageTitle:e.title, pageText:e.message })
		 		}
		 	})	
		 	.finally(() => {
		 		resolve()
		 	})		
	 	})
	}
  	handleTabChange = (event, newValue) => {
  		this.setState({activeTab:newValue})
  	}
  	goToTab = (tab) => {
  		this.setState({activeTab:tab})
  	}
  	render() {
  		const {pageLoading, pageTitle, pageText, activeTab} = this.state
  		const {lastUpdated, supportGroups, supportCategories, openTickets, closedTickets, openTicket, updateTicket, closeTicket} = this.props

      if(pageLoading || pageTitle) {
			return (<PageMessage pageLoading={pageLoading} pageTitle={pageTitle} pageText={pageText} />)
		}
    	return (
   		<Container maxWidth='lg' style={{ padding: 10, marginBottom: 50 }}>
				<Grid container justify='center' spacing={2}>
					<Grid item xs={12} sm={12} md={10} >
						<SubNav text='Support Desk'/>
					
						<div style={{borderBottom: '2px solid #eee'}}>  
						  	<Tabs
						    	value={activeTab}
						    	variant="fullWidth"
						    	onChange={this.handleTabChange}
						    	indicatorColor="primary"
						    	textColor="primary"							    
						  	>						    	
						    	<Tab label='Open' />
						    	<Tab label='Closed' />
						    	<Tab label='New' />
						  	</Tabs>
						</div>
						
				      <TabPanel value={activeTab} index={0}>
				        	<List 
				        		type='OPEN' 
				        		tickets={openTickets}
				        		goToTab={this.goToTab} 
				        		updateTicket={updateTicket}
				        		closeTicket={closeTicket}
				        	/>
				      </TabPanel>
				      <TabPanel value={activeTab} index={1}>
				        	<List 
				        		type='CLOSED' 
				        		tickets={closedTickets}
				        		goToTab={this.goToTab} 
				        		updateTicket={updateTicket}
				        		closeTicket={closeTicket}
				        	/>
				      </TabPanel>
				      <TabPanel value={activeTab} index={2}>
				        	<New 
				        		supportGroups={supportGroups} 
				        		supportCategories={supportCategories}
				        		openTicket={openTicket}
				        		goToTab={this.goToTab}
				        	/>
				      </TabPanel>
					</Grid>
				</Grid>
				<NextUpdate lastUpdated={lastUpdated} updateNow={this.handleFetchMemberSupport}/>
			</Container>
    	)
  	}
}
const mapStateToProps = (state) => ({
	lastUpdated: state.app.lastUpdated.memberSupport,
	supportGroups: state.member.supportGroups,
	supportCategories: state.member.supportCategories,
	openTickets: selectorLoadTickets(state.member.supportTickets, false),
	closedTickets: selectorLoadTickets(state.member.supportTickets, true)
})
const mapDispatchToProps = {
	loadMemberSupport,
	openTicket, 
	updateTicket, 
	closeTicket
}
export default connect(mapStateToProps, mapDispatchToProps)(MemberSupportContainer)
