import React from 'react'

import Button from '@material-ui/core/Button'
import Slider from '@material-ui/core/Slider'
import Divider from '@material-ui/core/Divider'
import Text from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'

const PrettoSlider = withStyles({
  root: {
    //color: '#52af77',
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: -8,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider)

function Step2 ({boardInfo, updateBoardSetup, handleBack}) {
	const [donationTarget, setDonationTarget] = React.useState(boardInfo.donationTarget)
	const [donationRequirement, setDonationRequirement] = React.useState(boardInfo.donationRequirement)
	const [donationDefault, setDonationDefault] = React.useState(boardInfo.donationDefault)
	const [boardFee, setBoardFee] = React.useState(boardInfo.boardFee)
	const [boardSpotsAllowed, setBoardSpotsAllowed] = React.useState(boardInfo.boardSpotsAllowed)	

	const goToStepThree = () => {
		updateBoardSetup(donationTarget, donationRequirement, donationDefault, boardFee, boardSpotsAllowed)
 	}
	return (
		<div style={{maxWidth:500, margin:'0 auto'}}>
			
			<div style={{marginTop:20}}>
				<Text variant='subtitle2' color='primary'>Donation Target (USD)</Text>
				<Text variant='body1'>This is the TOTAL AMOUNT that each featured campaign will receive.</Text>
				<div style={{marginTop:40}}>
					<PrettoSlider 
						value={donationTarget} 
						onChange={(e, value) =>{ setDonationTarget(value) }} 
						step={10}
						min={50}
						max={5000}
						valueLabelDisplay='on'
					/>
				</div>
	      </div> 

			<Divider style={{marginTop:50, marginBottom:50}}/>
	      
	      <div style={{marginTop:20}}>
				<Text variant='subtitle2' color='primary'>Donation Requirement (USD)</Text>
				<Text variant='body1'>Amount REQUIRED from DONOR, to get listed on board.</Text>
				<div style={{marginTop:40}}>
					<PrettoSlider 
						value={donationRequirement} 
						onChange={(e, value) =>{ setDonationRequirement(value) }} 
						step={5}
						min={50}
						max={1000}
						valueLabelDisplay='on'
					/>
				</div>
	      </div>   

	      <Divider style={{marginTop:50, marginBottom:50}}/>
	      
	      <div style={{marginTop:20}}>
				<Text variant='subtitle2' color='primary'>Donation Default (USD)</Text>
				<Text variant='body1'>This is the default donation on board</Text>
				<div style={{marginTop:40}}>
					<PrettoSlider 
						value={donationDefault} 
						onChange={(e, value) =>{ setDonationDefault(value) }} 
						step={5}
						min={10}
						max={1000}
						valueLabelDisplay='on'
					/>
				</div>
	      </div>   									
			
	      <Divider style={{marginTop:50, marginBottom:50}}/>

	      <div style={{marginTop:20}}>
				<Text variant='subtitle2' color='secondary'>Board Fee (USD)</Text>
				<Text variant='body1'>How much does is COST to get listed?</Text>
				<div style={{marginTop:40}}>
					<PrettoSlider 
						value={boardFee} 
						onChange={(e, value) =>{ setBoardFee(value) }} 
						step={5}
						min={5}
						max={200}
						valueLabelDisplay='on'
					/>
				</div>
	      </div>   									

	      <Divider style={{marginTop:50, marginBottom:50}}/>

	      <div style={{marginTop:20}}>
				<Text variant='subtitle2' color='secondary'>Board Spots Allowed</Text>
				<Text variant='body1'>Number of ACTIVE SPOTS user is allowed to have</Text>
				<div style={{marginTop:40}}>
					<PrettoSlider 
						value={boardSpotsAllowed} 
						onChange={(e, value) =>{ setBoardSpotsAllowed(value) }} 
						step={1}
						min={1}
						max={10}
						valueLabelDisplay='on'
					/>
				</div>
	      </div> 

		  	<div align='right' style={{marginTop:20}}>
			 	<Button variant='outlined' color='primary' style={{ marginRight: 10 }} onClick={handleBack}>Back</Button>
				<Button variant='contained' color='primary' onClick={goToStepThree}>
					next
			 	</Button>
			</div>				
		</div>
	)
}
export default Step2
