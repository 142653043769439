import React from 'react'
import AddImage from './AddImage'
import RemoveImage from './RemoveImage'
import SwipeableViews from 'react-swipeable-views'

import IconAdd from '@material-ui/icons/Add'
import IconRemove from '@material-ui/icons/Remove'
import Button from '@material-ui/core/Button'
import Fab from '@material-ui/core/Fab'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import MobileStepper from '@material-ui/core/MobileStepper'

class ImageSelector extends React.Component {
	state = {
		activeStep: 0,
		addImage: false,
		removeImage: false,
		images: this.props.images,
		maxSteps: this.props.images.length
	}
	componentDidMount = () => {
		if (this.props.currentImage) {
			let imagePosition = this.props.images.indexOf(this.props.currentImage) 
			if (imagePosition >= 0) {
				this.setState({ activeStep: imagePosition })
				this.props.selectImage(this.props.currentImage)
			} else {
				this.addNewImage(this.props.currentImage)
			}
		} else {
			this.props.selectImage(this.state.images[0])
		}
	}
	componentDidUpdate = (prevProps) => {
		if (this.props.currentImage !== prevProps.currentImage && this.props.currentImage === null) {
			this.setState({ activeStep: 0 })
		}
	}
	handleNext = () => {
		if (!this.props.returnAllImages) {
			this.props.selectImage(this.state.images[this.state.activeStep+1])
		}
		this.setState({ activeStep: this.state.activeStep+1 })
	}
	
	handleBack = () => {
		if (!this.props.returnAllImages) {
			this.props.selectImage(this.state.images[this.state.activeStep-1])
		}
		this.setState({ activeStep: this.state.activeStep-1 })
	}

	handleStepChange = (step) => {
		if (!this.props.returnAllImages) {
			this.props.selectImage(this.state.images[step])
		}
		this.setState({ activeStep: step })
	}    
	
	addImageOpen = () => {
		this.setState({ addImage: true })
	}
	addImageClose = () => {
		this.setState({ addImage: false })
	}
	removeImageOpen = () => {
		this.setState({ removeImage: true })
	}
	removeImageClose = () => {
		this.setState({ removeImage: false })
	}
	addNewImage = (src) => {
		let images = this.state.images
		images.push(src)
		if(this.props.returnAllImages && images.length > 1) {
			images = images.filter(image => image !== this.props.currentImage)
			this.props.selectImage(images)
		} else {
			this.props.selectImage(src)
		}
		this.setState({ images: images, activeStep: images.length-1, maxSteps: images.length })
	}
	removeSelectedImage = () => {
		let images = this.state.images
		let step = this.state.activeStep
		images.splice(step, 1)
		this.props.selectImage(images)
		if(!images.length) {
			images = [this.props.currentImage]
		}
		this.setState({ images: images, activeStep: images.length-1, maxSteps: images.length })
		this.removeImageClose()
	}
	render(){
		const {images, addImage, removeImage, activeStep, maxSteps} = this.state
		const {imgRatio, imgBorderRadius, imgPadding, imgBorder} = this.props
		
//console.log(images, activeStep)
		return (
			<React.Fragment>
			<AddImage 
				defaultImage={images[0]}
				ratio={imgRatio}
				borderRadius={imgBorderRadius}
				openUi={addImage} 
				closeUi={this.addImageClose} 
				cb={this.addNewImage} 
			/>
			<RemoveImage 
				image={images[activeStep]}
				borderRadius={imgBorderRadius}
				openUi={removeImage} 
				closeUi={this.removeImageClose} 
				cb={this.removeSelectedImage} 
			/>
			<div>
				<div style={{position: 'relative'}}>
					<SwipeableViews
						axis='x'
						index={activeStep}
						onChangeIndex={this.handleStepChange}
						enableMouseEvents
					>
						{images.map((image, index) => (
						<div key={index}>
							{Math.abs(activeStep - index) <= 2 ? (
							<img 
								style={{
									overflow: 'hidden',
									display: 'block',
									width: '100%',                        
									padding: imgPadding, 
									borderRadius: imgBorderRadius, 
									border: imgBorder
								}} 
								src={image} 
								alt={index} 
							/>
							) : null}
						</div>
						))}
					</SwipeableViews> 
					{(this.props.deleteButton && images[activeStep] !== this.props.currentImage) ? (
						<Fab onClick={this.removeImageOpen} size='small' color='primary' style={{position: 'absolute', bottom: 10, right:60}}>
							<IconRemove />
						</Fab>
					) : ( null )}
					<Fab onClick={this.addImageOpen} size='small' color='secondary' style={{position: 'absolute', bottom: 10, right:10}}>
						<IconAdd />
					</Fab>
				</div>
				{(!this.props.hideSelector) ? (
					<div>
					{(maxSteps > 1) ? (
						<MobileStepper
							steps={maxSteps}
							position='static'
							variant='text'
							activeStep={activeStep}
							nextButton={
							<Button size="small" onClick={this.handleNext} disabled={activeStep === maxSteps - 1}>
								Next <KeyboardArrowRight />
							</Button>
							}
							backButton={
							<Button size="small" onClick={this.handleBack} disabled={activeStep === 0}>
								<KeyboardArrowLeft /> Back
							</Button>
							}
						/>
					) : (null)}            
					</div>
				) : (null)}        
			</div>
			</React.Fragment>
		)
	}
}
export default ImageSelector