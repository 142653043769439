import React from 'react'

import Paper from '@material-ui/core/Paper'
import Text from '@material-ui/core/Typography'

function Donations() {
	return (
		<Paper style={{ padding: 20, minHeight:800 }} >
			<Text variant='h5' gutterBottom>
				Donations
			</Text>
			<Text variant='caption' gutterBottom>	
					- last 30days chart<br/>
					- filter to select campaign (default-All)<br/>
					- sort order button <br/>
					- list of donations (name, date, campaign, amount)<br/>
			</Text>
		</Paper>
	)
}
export default Donations
