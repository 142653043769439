import React from 'react'

import Paper from '@material-ui/core/Paper'
import Text from '@material-ui/core/Typography'

function Current() {
	return (
		<Paper style={{ padding: 20, minHeight:800 }} >
			<Text variant='h5' gutterBottom>
				Current
			</Text>
			<Text variant='caption' gutterBottom>
				Current
			</Text>
		</Paper>
	)
}
export default Current
