import React from 'react'
import { connect } from 'react-redux'
import { loadBoardInfo } from '../../../redux/public/actions'
import { addEditCampaign } from '../../../redux/member/actions'

import PageMessage from '../../components/PageMessage'
import SubNav from '../../components/SubNav'
import Index from './Index'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Text from '@material-ui/core/Typography'

class StepByStepContainer extends React.Component {
	mounted = true
	state = {
		pageLoading: true,
		pageTitle: undefined,	// Not Found',
		pageText: undefined,		// The campaign you are looking for was not found',
		boardId: (this.props.match.params.id) ? this.props.match.params.id : undefined,
		board: {}
	}
	componentDidMount = () => {
		if (!this.state.boardId) {
			return this.setState({pageLoading:false, pageTitle:'Processing Error', pageText:'Sorry, board Id required.'})
		}
		this.handleLoadBoard()
	}
	componentWillUnmount = () => {
		this.mounted = false
	}
	handleLoadBoard = () => {
		let _this = this
 		let boardId = this.state.boardId
	 	this.props.loadBoardInfo(boardId)
	 	.then((data) => {
	 		if(_this.mounted) {
	 			_this.setState({pageLoading:false, board:data})
	 		}
	 	})	
	 	.catch((e) => {
	 		if(_this.mounted) {
	 			_this.setState({pageLoading:false, pageTitle:e.title, pageText:e.message})
	 		}
	 	})	
	}
  	render() {
  		const {pageLoading, pageTitle, pageText, boardId, board} = this.state
		const {loggedIn, userInfo, addEditCampaign} = this.props

		if(pageLoading || pageTitle) {
			return (<PageMessage pageLoading={pageLoading} pageTitle={pageTitle} pageText={pageText} />)
		}
		if (!board) { 
			return (
				<Container maxWidth='lg' style={{ padding: 10, marginBottom: 150, minHeight:700  }}>
					<div align='center' style={{paddingTop:100}}><Text variant='h6' color='textSecondary'>Board Not Found</Text></div>
				</Container>
			) 
		}

    	return (
   		<React.Fragment>
   		<Container maxWidth='lg' style={{ padding: 10, marginBottom: 50 }}>
				<Grid container justify='center' spacing={2}>
					<Grid item xs={12} sm={12} md={10} >
						<SubNav text='Step by Step' buttonText='return to board' buttonLink={'/board/' + boardId}/>
						<Index
							loggedIn={loggedIn}
							userInfo={userInfo}
							boardId={boardId}
							board={board}
							addEditCampaign={addEditCampaign}
						/>
					</Grid>
				</Grid>
			</Container>
   		</React.Fragment>
    	)
  	}
}
const mapStateToProps = (state, ownProps) => ({
	loggedIn: state.auth.loggedIn || false,
	userInfo: state.auth.userInfo || {}
})
const mapDispatchToProps = {
	loadBoardInfo,
	addEditCampaign
}
export default connect(mapStateToProps, mapDispatchToProps)(StepByStepContainer)
