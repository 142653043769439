import React from 'react'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Divider from '@material-ui/core/Divider'
import Text from '@material-ui/core/Typography'
import withMobileDialog from '@material-ui/core/withMobileDialog'

function DonationGuidelines({fullScreen, open, showGuidelinesToggle}){
	return (
		<Dialog fullScreen={fullScreen} open={open} onClose={showGuidelinesToggle}>
			<DialogTitle>Donation Guidelines</DialogTitle>
        	<Divider/>
			<DialogContent>
				<div>
					<Text variant='body2'>
						LetsFundIt is a crowdfunding platform that allows individuals and organizations to raise 
						funding for projects and causes they believe in. All donations made (less our service fee), 
						go directly to the beneficiary.
					</Text>

					<Text variant='body1' style={{marginTop:15}}><b>APPRECIATION</b></Text>
				   <Text variant='body2'>
				   	We applaud your willingness to donate to this or any other campaign on LetsFundIt. 
				   	We trust that the beneficiary of your donation would be thankful and use it as intended. 
				   	Please keep in mind that a donation is a charity you give to someone without any expectations 
				   	of anything in return. Although we all want to help each other in these times, please only give 
				   	what you can afford to. We appreciate you and your contribution.
				   </Text>

					<Text variant='body1' style={{marginTop:15}}><b>DONATION CURRENCY</b></Text>
				   <Text variant='body2'>
				   	All donations are listed and processed in USD and depending on the beneficiary withdrawal 
				   	method, withdrawn in the beneficiary local currency.
				   </Text>

					<Text variant='body1' style={{marginTop:15}}><b>DONATION FEES</b></Text>
				   <Text variant='body2'>
				   	There is a small processing fee based on the payment method you select. 
				   	This fee is calculated and displayed when you select your payment method.
				   </Text>

					<Text variant='body1' style={{marginTop:15}}><b>NON REFUNDABLE</b></Text>
				   <Text variant='body2'>
				   	Donations made through LetsFundIt are NON-REFUNDABLE, however if there 
				   	was an error made by LetsFundIt or one of our payment processors, full refunds will be made upon notification.
				   </Text>

					<Text variant='body1' style={{marginTop:15}}><b>NOT TAX DEDUCTIBLE</b></Text>
				   <Text variant='body2'>
				   	This statement may differ from the laws of your Country, but generally, 
				   	donations made to individual campaigns on LetsFundIt are not tax deductible. 
				   	However donations made to registered charities may be. Please consult a local Accountant or Tax Attorney. 
				   </Text>

			   </div>
			</DialogContent>
			<Divider/>
			<DialogActions>
			   <Button onClick={showGuidelinesToggle} color='primary' autoFocus>Close</Button>
			</DialogActions>
		</Dialog>
	)
}
export default withMobileDialog()(DonationGuidelines)
