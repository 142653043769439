import React from 'react'

import Paper from '@material-ui/core/Paper'
import Text from '@material-ui/core/Typography'

function Donors() {
	return (
		<Paper style={{ padding: 20, minHeight:800 }} >
			<Text variant='h5' gutterBottom>
				Donors
			</Text>
			<Text variant='caption' gutterBottom>
				- filter to select date range (default - last 30days)<br/>
				- list of donors and the amount they've contributed
			</Text>
		</Paper>
	)
}
export default Donors
