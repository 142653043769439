import React from 'react'
import moment from 'moment'
import {money} from '../../../includes/functions'

import Button from '@material-ui/core/Button'
import Text from '@material-ui/core/Typography'

function Step4 ({boardInfo, handleGoTo, setBoardInfo}) {
	return (
		<div style={{maxWidth:500, margin:'0 auto'}}>
			<div align='center' style={{ maxWidth:200, margin: '0 auto', marginBottom: 30, borderBottom: '1px solid #ccc' }}>
				<img src={boardInfo.promoImage} style={{ width: '100%' }} alt='' /><br />
				<Text variant='caption' style={{color: '#d1d1d1'}}>
				  	<i>Feature Board Promo Image.</i>
				</Text>  
			</div>	

			<div align='center' style={{ maxWidth:440, margin: '0 auto', marginBottom: 30, borderBottom: '1px solid #ccc' }}>
				<img src={boardInfo.headerImage} style={{ width: '100%' }} alt='' /><br />
				<Text variant='caption' style={{color: '#d1d1d1'}}>
				  	<i>Feature Board Header Image.</i>
				</Text>  
			</div>	

			<div style={{ maxWidth:440, margin: '0 auto'}}>
				<Text variant='h5'>About</Text>
				<div style={{ padding:5, borderTop: '1px solid #ccc', borderBottom: '2px solid #ccc' }}>
					<Text variant='body1'>
					  	<b>Name:</b> {boardInfo.name}<br/>
					  	<b>Intro:</b><br/> {boardInfo.intro}<br/>
					  	<b>Signature:</b> {boardInfo.signature}<br/>
					  	<b>Date Start:</b> {moment.unix(boardInfo.dateStart).format('MMM Do YYYY - h:mm a')}<br/>
					  	<b>Date End:</b> {moment.unix(boardInfo.dateEnd).format('MMM Do YYYY - h:mm a')}<br/>
					  	<b>Thankyou Message:</b><br/> {boardInfo.thankyouMessage}<br/>
					  	<b>Thankyou Email:</b><br/> {boardInfo.thankyouEmail}<br/>
					</Text>
				</div>  
				<div align='right'><Button size='small' color='primary' onClick={() => { handleGoTo(0) }}>edit</Button></div>
			</div>

			<div style={{ maxWidth:440, margin: '0 auto'}}>
				<Text variant='h5'>Settings</Text>
				<div style={{ padding:5, borderTop: '1px solid #ccc', borderBottom: '2px solid #ccc' }}>
					<Text variant='body1'>
					  	<b>Donation Target:</b> {money(boardInfo.donationTarget,0)}<br/>
					  	<b>Donation Requirement:</b> {money(boardInfo.donationRequirement,0)}<br/>
						<b>Donation Default:</b> {money(boardInfo.donationDefault,0)}<br/>
					  	<b>Board Fee:</b> {money(boardInfo.boardFee,0)}<br/>
					  	<b>Board Spots Allowed:</b> {boardInfo.boardSpotsAllowed}<br/>
					</Text>
				</div>  
				<div align='right'><Button size='small' color='primary' onClick={() => { handleGoTo(1) }}>edit</Button></div>
			</div>

			<div align='right' style={{ marginTop:50, paddingTop:30, borderTop: '2px solid #ddd' }}>
				<Button variant='contained' color='secondary' onClick={setBoardInfo}>Create and save Feature Board</Button>
		  	</div>

		</div>
	)
}
export default Step4
