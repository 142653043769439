import React from 'react'
import { withRouter } from 'react-router-dom'
import {money, progress, truncate} from '../../includes/functions'

import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import IconFolder from '@material-ui/icons/FolderOpen'
import LinearProgress from '@material-ui/core/LinearProgress'
import Text from '@material-ui/core/Typography'

const useStyles = makeStyles((theme) => ({
  	root: {
    	maxWidth: 450,
    	minHeight:370,
    	margin: '0px auto',
      height: '100%'
  	},
  	media: {
    	//height: 140
    	height: 0,
    	paddingTop: '60%'//'35%'
  	},
  	categoryButton: {
  		padding:0, 
  		color:'#ccc',
  		textTransform: 'none !important'
  	}
}))

function CampaignCard({history, campaign}){
	const classes = useStyles()

	const goToCategory = () => {
		history.push({ pathname: '/campaigns/category/'+campaign.category })
	}

	const goToCampaign = () => {
		history.push({ pathname: '/view/'+campaign.id })
	}

	return (
	   <Card className={classes.root}>
	      <CardActionArea onClick={goToCampaign}>
	      	<CardMedia className={classes.media} image={campaign.promoImage} title={campaign.title} />
	      </CardActionArea>
        	<CardContent>
				    <Button onClick={goToCategory} size='small' startIcon={<IconFolder style={{marginBottom:4}} />} className={classes.categoryButton}>
            	{campaign.category}
          	</Button>		          
          	<Text gutterBottom variant='h5' component='h2'>
            	{truncate(campaign.title, 25)}
          	</Text>
          	<Text variant='body2' color='textSecondary' style={{marginBottom:20}}>
            	{truncate(campaign.about, 110)}
          	</Text>
          	<Text component='span' variant='body1' color='primary'><b>{money(campaign.totalDonations,0)} </b></Text>
          	<Text component='span' variant='caption' color='textSecondary'> raised of </Text>
          	<Text component='span' variant='body1' color='textSecondary' ><b>{money(campaign.target,0)} </b></Text>
          	<Text component='span' variant='caption' color='textSecondary'> target. </Text>
          	<LinearProgress variant="determinate" value={progress(campaign.totalDonations, campaign.target)} style={{marginBottom:20}}/>
          	<Button onClick={goToCampaign} variant='contained' color='secondary' fullWidth>Learn More / Donate</Button>
        	</CardContent>
	   </Card>
	)
}
export default withRouter(CampaignCard)
