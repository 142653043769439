import React from 'react'
import { connect } from 'react-redux'
import { fetchGroupCampaigns, fetchDonorDetails } from '../../../redux/group/actions'

import PageMessage from '../../components/PageMessage'
import NextUpdate from '../../components/NextUpdate'
import SubNav from '../../components/SubNav'
import Campaigns from './Campaigns'
import Donations from './Donations'
import Donors from './Donors'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'

function TabPanel(props) {
  	const { children, value, index, ...other } = props;
  	return (
    	<div role='tabpanel' hidden={value !== index} id={`nav-tabpanel-${index}`} {...other} >
      	{value === index && ( children )}
    	</div>
  	)
}

class GroupCampaignsContainer extends React.Component {
	mounted = true
	state = {
		activeTab: 0,
		pageLoading: true,
		pageTitle: undefined,	//Not Found',
		pageText: undefined		//The campaign you are looking for was not found',
  	}
	componentDidMount = () => {
		this.handleFetchGroupCampaigns(false)
	}
  	componentWillUnmount = () => {
		this.mounted = false
	}
	handleChange = (event, newValue) => {
  		this.setState({activeTab:newValue})
  	}
	handleFetchGroupCampaigns = (force) => {
	 	return new Promise((resolve, reject) => {
		 	let _this = this
		 	this.props.fetchGroupCampaigns(force)
		 	.then(() => {
		 		if(_this.mounted) {
		 			_this.setState({ pageLoading:false })
		 		}
		 	})	
		 	.catch((e) => {
		 		if(_this.mounted) {
		 			_this.setState({ pageLoading:false, pageTitle:e.title, pageText:e.message })
		 		}
		 	})	
		 	.finally(() => {
		 		resolve()
		 	})		
	 	})
	}
  	render() {
  		const {activeTab, pageLoading, pageTitle, pageText} = this.state
  		const {lastUpdated, campaigns, fetchDonorDetails} = this.props

  		if(pageLoading || pageTitle) {
			return (<PageMessage pageLoading={pageLoading} pageTitle={pageTitle} pageText={pageText} />)
		}

    	return (
   		<React.Fragment>
   		<Container maxWidth='lg' style={{ padding: 10, marginBottom: 20 }}>
				<Grid container justify='center' spacing={2}>
					<Grid item xs={12} sm={12} md={10} >
						<SubNav text='Campaigns' buttonText='New Campaign' buttonLink='/group/campaign/'/>
				      <div style={{borderBottom: '2px solid #eee'}}>  
						  	<Tabs value={activeTab} onChange={this.handleChange} variant='fullWidth' indicatorColor='primary' textColor='primary'>
						    	<Tab label='Campaigns' />
						    	<Tab label='Donations' />
						    	<Tab label='Donors' />
						  	</Tabs>
						</div>
						<TabPanel value={activeTab} index={0}>
				        	<Campaigns campaigns={campaigns} fetchDonorDetails={fetchDonorDetails}/>
				      </TabPanel>
				      <TabPanel value={activeTab} index={1}>
				        	<Donations />
				      </TabPanel>
				      <TabPanel value={activeTab} index={2}>
				        	<Donors />
				      </TabPanel>						      						
					</Grid>
				</Grid>
				<NextUpdate lastUpdated={lastUpdated} updateNow={this.handleFetchGroupCampaigns}/>
			</Container>
   		</React.Fragment>
    	)
  	}
}
const mapStateToProps = (state) => ({
	lastUpdated: state.app.lastUpdated.groupCampaigns,
	campaigns: state.group.campaigns
})
const mapDispatchToProps = {
	fetchGroupCampaigns,
	fetchDonorDetails
}
export default connect(mapStateToProps, mapDispatchToProps)(GroupCampaignsContainer)
