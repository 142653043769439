import React from 'react'
import { withRouter } from 'react-router-dom'

import Button from '@material-ui/core/Button'
import Text from '@material-ui/core/Typography'

function Step4 ({history, campaignId}) {
	const goTo = (path) => {
		history.push({ pathname: path })
	}
	return (
		<div style={{maxWidth:600, margin:'0px auto'}}>
			<Text variant='body1'>
				Congratulations your fundraising campaign was created. It is still
				pending review, but has been posted to our site. To view your
				public campaign page <Button onClick={() => { goTo('/view/' + campaignId) }} style={{padding:0}}>Click Here</Button>.
			</Text>
			<Text variant='body1' style={{marginTop:10}}>
				 To <b>Manage Your Campaigns</b> including edits, view progress and share, use the
				 <b> Top Left Menu</b> to go to <b>Campaigns</b>. 
			</Text>
			<div style={{textAlign:'center', margin:20}}><Button onClick={() => { goTo('/group/campaigns') }} variant='contained' color='secondary'>Manage my campaigns</Button></div>	
		</div>
	)
}
export default withRouter(Step4)
