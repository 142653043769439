export const selectorLoadGroupTickets = (tickets, status) => {
	let filteredTickets = {}
	if (status === 'assigned') {
		Object.keys(tickets).forEach(function (i) {
			if (tickets[i].status === 'open' || tickets[i].status === 'closed') {
				filteredTickets[i] = tickets[i]
			}
		})
	} else {
		Object.keys(tickets).forEach(function (i) {
			if (tickets[i].status === status) {
				filteredTickets[i] = tickets[i]
			}
		})
	}
	return filteredTickets
}