import { GROUP_LOAD_CAMPAIGNS, GROUP_CREATE_EDIT_CAMPAIGN, GROUP_LOAD_BOARDS, GROUP_CREATE_EDIT_BOARD, GROUP_RESET_DATA, GROUP_LOAD_SUPPORT_TICKETS, GROUP_UPDATE_SUPPORT_TICKET, GROUP_LOAD_TEAM } from '../types'

const initialState = {
	lastUpdated: 0,
	campaigns: {},
	boards: {},
	supportTickets: {},
	supportNotes: {},
	team: {}
}
export default function reducer(state = initialState, action) {
	switch (action.type) {	
		case GROUP_LOAD_CAMPAIGNS:
		return { 
			...state, 
			lastUpdated: action.payload.timestamp,
			campaigns: action.payload.campaigns
		}
		case GROUP_CREATE_EDIT_CAMPAIGN:
			return { 
				...state, 
				lastUpdated: action.payload.timestamp,
				campaigns: {
               ...state.campaigns,
               [action.payload.id]: action.payload.info
            }
			}
		case GROUP_LOAD_BOARDS:
			return { 
				...state, 
				lastUpdated: action.payload.timestamp,
				boards: action.payload.boards
			}
		case GROUP_CREATE_EDIT_BOARD:
			return { 
				...state, 
				lastUpdated: action.payload.timestamp,
				boards: {
               ...state.boards,
               [action.payload.id]: action.payload.info
            }
			}
		case GROUP_LOAD_SUPPORT_TICKETS:
			return { 
				...state, 
				lastUpdated: action.payload.timestamp,
				supportTickets: action.payload.tickets,
				supportNotes: action.payload.notes,
				team: action.payload.team
			}
		case GROUP_UPDATE_SUPPORT_TICKET:
			return { 
				...state, 
				lastUpdated: action.payload.timestamp,
				supportTickets: {
               ...state.supportTickets,
               [action.payload.id]: action.payload.info
            }
			}
		case GROUP_LOAD_TEAM:
			return { 
				...state, 
				lastUpdated: action.payload.timestamp,
				team: action.payload.team
			}
		case GROUP_RESET_DATA:
			return { 
				...initialState           
			}
		default:
			return state
	}
}
