import React from 'react'
import moment from 'moment'
import {money} from '../../../includes/functions'

import Avatar from '@material-ui/core/Avatar'
import CircularProgress from '@material-ui/core/CircularProgress'
import Divider from '@material-ui/core/Divider'
import Hidden from '@material-ui/core/Hidden'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import Text from '@material-ui/core/Typography'

function DetailsDonations({campaignId, donationCount, donations, loadCampaignDonations}) {
	const mounted = React.useRef(true)
	const [loading, setLoading] = React.useState(true)
	const [loadingError, setLoadingError] = React.useState(false)

	// [START]: Track if component is mounted
   React.useEffect(() => {
	   if (donationCount) {
			loadCampaignDonations(campaignId)
			.then(() => {
				if (mounted.current) {
					setLoading(false)	
				}
			})
			.catch(() => {
				if (mounted.current) {
					setLoading(false)	
					setLoadingError(true)
				}	
			})
		} else{
			setLoading(false)
		}

      return () => {
         mounted.current = false
      }
   },[campaignId, donationCount, loadCampaignDonations])
   // [END]: Track if component is mounted
	
	if (loading) { 
		return (
			<div align='center' style={{marginTop:20}}>
				<CircularProgress color='secondary' style={{ margin: '5px auto'}} />
				<Text variant='h6'>Loading Donations</Text>
				<Text variant='subtitle1'>Checking server for donations.</Text>
			</div>
		) 
	}

	if (loadingError) { 
		return (
			<div align='center' style={{marginTop:20}}>
				<Text variant='h6'>Server Error</Text>
				<Text variant='subtitle1'>Sorry, there was an error retreving records.</Text>
			</div>
		) 
	}

	if (!donations || !Object.keys(donations).length) { 
		return (
			<div align='center' style={{marginTop:20}}>
				<Text variant='body1' color='textSecondary'>No Donations Recorded</Text>
			</div>
		) 
	}	

	return (
		<List style={{padding:5}}>
			{Object.keys(donations).map((i, key) => {
				return (
				   <div key={key}>
						<ListItem alignItems='flex-start'>
							<ListItemAvatar>
								<Avatar alt={donations[i].donorName} src={donations[i].donorImage} style={{borderRadius:5}} />
							</ListItemAvatar>
							<ListItemText>
								<Text variant='h6' style={{ lineHeight:1.1}}>{donations[i].donorName}</Text>
								<Text variant='body2'>{donations[i].donorMessage}</Text>
								<Hidden smUp>
									<Text variant='h6' component='span' color='primary'><b>{ money(donations[i].donationAmount,0) } </b></Text>
									<Text variant='caption' component='span' color='textSecondary'><b> USD</b></Text>
								</Hidden>	
								<Text variant='caption' display='block' color='textSecondary'>
									On {moment.unix(donations[i].timestamp).format('MMM Do YYYY - h:mm a')}
								</Text>
							</ListItemText>
							<Hidden xsDown>
								<ListItemSecondaryAction>
									<Text variant='h6' component='span' color='primary'><b>{ money(donations[i].donationAmount,0) } </b></Text>
									<Text variant='caption' component='span' color='textSecondary'><b> USD</b></Text>
								</ListItemSecondaryAction>
							</Hidden>
						</ListItem>
						<Divider/>                
				   </div>
				)
			})}
		</List>		
	)
}
export default DetailsDonations
