import React from 'react'
import DetailsAbout from './DetailsAbout'
import DetailsActivity from './DetailsActivity'
import DetailsDonations from './DetailsDonations'

import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import Text from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'

function TabPanel(props) {
  	const { children, value, index, ...other } = props;
  	return (
    	<div role='tabpanel' hidden={value !== index} id={`nav-tabpanel-${index}`} {...other} >
      	{value === index && ( children )}
    	</div>
  	)
}

function FundraiserDetails({details, campaignId, donations, loadCampaignDonations}){
	const [activeTab, setActiveTab] = React.useState(0)
	
	const handleTabChange = (event, newValue) => {
    	setActiveTab(newValue)
  	}

  	if(!details || Object.keys(details).length === 0){ return (null) }

	return (
		<Paper elevation={1} style={{padding: 5, minHeight:400}} >
			<Paper><img alt={details.title} src={details.promoImage} style={{display:'block',width:'100%', borderRadius:3}} /></Paper>
			<div style={{padding:10}}>
				<Text variant='h4'>{details.title}</Text>
			</div>
			<div style={{borderBottom: '2px solid #eee', backgroundColor:'white'}}>  
			  	<Tabs value={activeTab} onChange={handleTabChange} variant='fullWidth' indicatorColor='primary' textColor='primary'>
			    	<Tab label='About' />
			    	<Tab label='Activity' />
			    	<Tab label='Donations' />
			  	</Tabs>
			</div>
			<div style={{padding:20}}>
				<TabPanel value={activeTab} index={0}>
		        	<DetailsAbout about={details.about} category={details.category} location={details.location}/>
		      </TabPanel>
		      <TabPanel value={activeTab} index={1}>		        	
		        	<DetailsActivity activity={details.activity}/>
		      </TabPanel>
		      <TabPanel value={activeTab} index={2}>
		        	<DetailsDonations campaignId={campaignId} donationCount={details.totalDonors} donations={donations} loadCampaignDonations={loadCampaignDonations}/>
		      </TabPanel>
		   </div>		
		</Paper>
	)
}
export default FundraiserDetails
