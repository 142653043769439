import React from 'react'
import moment from 'moment'

import Button from '@material-ui/core/Button'
import Text from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'

function Step1 ({boardInfo, updateBoardAbout}) {
	const [name, setName] = React.useState(boardInfo.name)
	const [intro, setIntro] = React.useState(boardInfo.intro)
	const [signature, setSignature] = React.useState(boardInfo.signature)
	const [dateStart, setDateStart] = React.useState(boardInfo.dateStart)
	const [dateEnd, setDateEnd] = React.useState(boardInfo.dateEnd)
	const [thankyouMessage, setThankyouMessage] = React.useState(boardInfo.thankyouMessage)
	const [thankyouEmail, setThankyouEmail] = React.useState(boardInfo.thankyouEmail)
	const [message, setMessage] = React.useState('')

	const handleChange = (f,value) => {
		f(value)
		setMessage('')
	}
	const handleNext = () => {
		let i = {
			name: name,
			intro: intro,
			signature: signature,
			dateStart: dateStart,
			dateEnd: dateEnd,
			thankyouMessage: thankyouMessage,
			thankyouEmail: thankyouEmail
		}	
		if (!i.name) {
			return setMessage('Sorry, please give us the name of your board')
		}
		if (!i.intro) {
			return setMessage('Sorry, please tell us about your board')
		}
		updateBoardAbout(i)
	}

	return (
		<div style={{maxWidth:500, margin:'0 auto'}}>

			<div style={{marginTop:20}}>
				<Text variant='subtitle2' color='primary'>What is the Name of your Feature Board?</Text>
				<TextField 
					placeholder='Feature Board Name'
					style={{maxWidth:300}}
					fullWidth 
					onChange={(e) => { handleChange(setName, e.target.value) }} 
					value={name} 
					size='small' 
					variant='outlined' 
				/>
			</div>

			<div style={{marginTop:20}}>
				<Text variant='subtitle2' color='primary'>Write a one paragraph intro about your Feature Board.</Text>
				<TextField 
					placeholder='About FeatureBoard'
					multiline
          		rows={4}
					fullWidth 
					onChange={(e) => { handleChange(setIntro, e.target.value) }} 
					value={intro} 
					size='small' 
					variant='outlined' 
				/>
			</div>

			<div style={{marginTop:20}}>
				<Text variant='subtitle2' color='primary'>Author of Intro</Text>
				<TextField 
					placeholder='Board Signature'
					style={{maxWidth:300}} 
					fullWidth 
					onChange={(e) => { handleChange(setSignature, e.target.value) }} 
					value={signature} 
					size='small' 
					variant='outlined' 
				/>
			</div>

			<div style={{marginTop:20}}>
				<Text variant='subtitle2' color='primary'>When should this board start?</Text>
				<TextField 
					fullWidth
					style={{maxWidth:300}}
					variant='outlined' 
					size='small'
					onChange={(e) => { handleChange(setDateStart, moment(e.target.value).format('X')) }} 
					type='date'
					value={moment.unix(dateStart).format('yyyy-MM-DD')}
				/>
			</div>

			<div style={{marginTop:20}}>
				<Text variant='subtitle2' color='primary'>When should this board end?</Text>
				<TextField 
					fullWidth
					style={{maxWidth:300}}
					variant='outlined' 
					size='small'
					onChange={(e) => { handleChange(setDateEnd, moment(e.target.value).format('X')) }} 
					type='date'
					value={moment.unix(dateEnd).format('yyyy-MM-DD')}
				/>
			</div>

			<div style={{marginTop:20}}>
				<Text variant='subtitle2' color='primary'>Leave a Thankyou message for your donors.</Text>
				<TextField 
					placeholder='Thankyou Message'
					multiline
          		rows={4}
					fullWidth 
					onChange={(e) => { handleChange(setThankyouMessage, e.target.value) }} 
					value={thankyouMessage} 
					size='small' 
					variant='outlined' 
				/>
			</div>

			<div style={{marginTop:20}}>
				<Text variant='subtitle2' color='primary'>What email message would you like to send to your donors.</Text>
				<TextField 
					placeholder='Thankyou Email'
					multiline
          		rows={4}
					fullWidth 
					onChange={(e) => { handleChange(setThankyouEmail, e.target.value) }} 
					value={thankyouEmail} 
					size='small' 
					variant='outlined' 
				/>
			</div>

			<div align='center' style={{ height: 60, padding: 10, borderBottom: '2px solid #ddd' }}>
            <Text variant='body2' style={{ color: 'red' }}>{message}</Text>
         </div>
		  	<div align='right' style={{ marginTop:50 }}>
				<Button variant='contained' color='primary' onClick={handleNext}>Next</Button>
		  	</div>
		</div>
	)
}
export default Step1
