import React from 'react'
import { connect } from 'react-redux'
import { createGroup } from '../../../redux/member/actions'

import SubNav from '../../components/SubNav'
import Index from './Index'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'

class MemberAffilateContainer extends React.Component {
  	render() {
    	return (
   		<Container maxWidth='lg' style={{ padding: 10, marginBottom: 50 }}>
				<Grid container justify='center' spacing={2}>
					<Grid item xs={12} sm={12} md={10} >
						<SubNav text='Register as Affilate'/>
			        	<div style={{marginTop:20}}>
			        		<Index createGroup={this.props.createGroup}/>
			        	</div>
					</Grid>
				</Grid>
			</Container>
    	)
  	}
}
const mapDispatchToProps = {
	createGroup
}
export default connect(null, mapDispatchToProps)(MemberAffilateContainer)
