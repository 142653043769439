import React from 'react'

import Paper from '@material-ui/core/Paper'
import Text from '@material-ui/core/Typography'

function Intro({image, name, intro, signature}) {
	if (!name) return null
	return (
		<Paper elevation={1} style={{padding: 5, maxWidth:650, margin:'0px auto'}} >
			<img alt={name} src={image} style={{display:'block',width:'100%', borderRadius:2}} />
			<div style={{padding:10}}>
				<Text variant='h6'>{name}</Text>
				<Text variant='body2'>{intro}</Text>
				<div align='right' style={{marginTop:4, borderTop:'1px solid gray'}}><Text variant='caption'><i>- {signature}</i></Text></div>
			</div>
		</Paper>
	)
}
export default Intro
