import React from 'react'
import {money} from '../../../includes/functions'
import ViewTransactionsList from './ViewTransactionsList'

import AppBar from '@material-ui/core/AppBar'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import IconAssignment from '@material-ui/icons/Assignment'
import IconButton from '@material-ui/core/IconButton'
import IconClose from '@material-ui/icons/Close'
import Paper from '@material-ui/core/Paper'
import Slide from '@material-ui/core/Slide'
import Text from '@material-ui/core/Typography'
import Toolbar from '@material-ui/core/Toolbar'
import withMobileDialog from '@material-ui/core/withMobileDialog'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction='up' ref={ref} {...props} />
})

function Funds({fullScreen, credits, allActivity}) {
	const [viewTransactions, setViewTransactions] = React.useState(false)
	const funds = allActivity

  	const toggleViewAllTransactions = () => {
  		setViewTransactions(!viewTransactions)
  	}

	return (
		<React.Fragment>
			<Paper style={{ padding: 20, minHeight:800 }} >
				<div align='center' style={{width:'100%', marginBottom:20, paddingTop:100, paddingBottom:100, backgroundColor:'#f1f1f1'}}><Text variant='body1' color='textSecondary'>Graph of Last 30 Days Transactions<br/>(Coming Soon)</Text></div>
				
				<Grid container>
	     			<Grid item xs={12} sm={6} style={{textAlign:'left'}} >
	     				<Text variant='h4' color='primary' style={{lineHeight:1}}><b>Credits</b></Text>
	     				<Text variant='overline' color='textSecondary'><b>current balance</b></Text>
	     			</Grid>
	     			<Grid item xs={12} sm={6} style={{textAlign:'right'}} >
	     				<Text variant='h4' component='span' color='primary'><b>{ money(credits,0) } </b></Text>
						<Text variant='caption' component='span' color='textSecondary'><b> USD</b></Text>
	     			</Grid>
	     			<Grid item xs={12}><Divider/></Grid>
				</Grid>

				<div style={{marginTop:30}}>
					<Text variant='h5' color='textSecondary'>Transaction History</Text>
					<ViewTransactionsList funds={funds} count={10}/>
					{(Object.keys(funds).length) ? (
						<div align='right'><Button size='small' color='secondary' onClick={toggleViewAllTransactions}>View All Transactions</Button></div>
					) : ( null )}
				</div>
			</Paper>

			<Dialog keepMounted fullScreen={fullScreen} open={viewTransactions} TransitionComponent={Transition} onClose={toggleViewAllTransactions}>
				<AppBar style={{ position: 'relative' }} >
               <Toolbar>
                   <IconAssignment style={{ marginRight: 5 }} />
                   <Text variant='subtitle2' color='inherit' style={{ flex: 1 }}>
                      	All Transactions
                   </Text>
                   <IconButton color='inherit' onClick={toggleViewAllTransactions}>
                       <IconClose />
                   </IconButton>
               </Toolbar>
           	</AppBar>
           	<DialogContent>
           		<ViewTransactionsList funds={funds}/>
           	</DialogContent>
            <DialogActions>
               <Button onClick={toggleViewAllTransactions} color='primary'>Close</Button>
            </DialogActions>
			</Dialog>			
		</React.Fragment>
	)
}
export default withMobileDialog()(Funds)
