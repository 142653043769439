import React from 'react'
import {money, formattedNumber} from '../../../includes/functions'

import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Text from '@material-ui/core/Typography'

function Stats({name, raised, featured, donations}) {
	return (
		<div style={{maxWidth:650, margin:'0px auto', marginTop:15 }}>
			<Text variant='h5'>{name}</Text>
			<Paper elevation={1} style={{marginBottom:25, paddingTop:20, paddingLeft:20, paddingRight:20, paddingBottom:0, minHeight:100}} >
				<Grid container justify='center' spacing={2}>
					<Grid item xs={12} sm={4}>
						<div style={{ padding:0, margin: '0 auto', textAlign:'center' }}>
							<Text variant='h4'>{money(raised,0)}</Text>
							<Text variant='button' color='textSecondary'>USD RAISED</Text>
						</div>
					</Grid>
					<Grid item xs={12} sm={4}>
						<div style={{ padding:0, margin: '0 auto', textAlign:'center' }}>
							<Text variant='h4'>{formattedNumber(featured,0)}</Text>
							<Text variant='button' color='textSecondary'>FEATURED</Text>
						</div>
					</Grid>
					<Grid item xs={12} sm={4}>
						<div style={{ padding:0, margin: '0 auto', textAlign:'center' }}>
							<Text variant='h4'>{formattedNumber(donations,0)}</Text>
							<Text variant='button' color='textSecondary'>DONATIONS</Text>
						</div>
					</Grid>
				</Grid>
			</Paper>
		</div>
	)
}
export default Stats
