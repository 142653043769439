import React from 'react'
import Loader from '../../components/Loader'
import ImageSelector from '../../components/ImageSelector'
import countryList from '../../../includes/countryList'

import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Paper from '@material-ui/core/Paper'
import Select from '@material-ui/core/Select'
import Text from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'

function Profile({memberInfo, updateMemberInfo}) {
	const [loading, setLoading] = React.useState(false)
	const [message, setMessage] = React.useState('')
	const [disableButton, setDisableButton] = React.useState(true)
	const [firstName, setFirstName] = React.useState((memberInfo.firstName) ? memberInfo.firstName : '')
	const [lastName, setLastName] = React.useState((memberInfo.lastName) ? memberInfo.lastName : '')
	const [gender, setGender] = React.useState((memberInfo.gender) ? memberInfo.gender : '')
	const [dob, setDob] = React.useState((memberInfo.dob) ? memberInfo.dob : '')
	const [cell, setCell] = React.useState((memberInfo.cell) ? memberInfo.cell : '')
	const [location, setLocation] = React.useState((memberInfo.location) ? memberInfo.location : 'TT')

	//const [currentImage, setCurrentImage] = React.useState(memberInfo.image) // ImageSelector variable
	const [selectedImage, setSelectedImage] = React.useState((memberInfo.image) ? memberInfo.image :null) // ImageSelector variable
	const images = [ '/images/profile/default.jpg' ] // ImageSelector variable

	// [START]: ImageSelector Function :::::::::
	const handleSelectImage = (src) => {
		if (src !== memberInfo.image) {
			setDisableButton(false)
		} else {
			setDisableButton(true)
		} 
		setSelectedImage(src)
	}
	// [END]: ImageSelector Function :::::::::::

	React.useEffect(() => {
		let m = memberInfo
		if (m.firstName === firstName && m.lastName === lastName && m.dob === dob && m.gender === gender && m.cell === cell && m.location === location) {
			setDisableButton(true)
		} else {
			setDisableButton(false)
		}
	}, [memberInfo, firstName, lastName, dob, gender, cell, location]);

	const handleChange = (f,v) => {
		f(v)
		setMessage('')
	}

	const	updateUser = () => {
		// firstName and lastName cannot be blank
		if (firstName.length < 1 || lastName.length < 1) {
			return setMessage('Sorry, both First and Last names are required.')
		}
		// set user variables
		let user = {
			thumb: selectedImage,
			firstName: firstName,
			lastName: lastName,
			dob: dob,
			gender: gender,
			cell: cell,
			location: location,
		}
		setLoading(true)
		updateMemberInfo(user)
		.then(() => {
			setDisableButton(true)
			setLoading(false)
		})
		.catch((e) => {
			setMessage(e)
			setLoading(false)
		})
	}

	return (
		<Paper style={{ padding: 20, minHeight:800 }} >
			<Loader loading={loading}/>
			<Grid container spacing={2} style={{marginTop:50}}>
				<Grid item xs={12} sm={6}>
					{/** ImageSelector Starts Here */}
					<div align='center' style={{ maxWidth: 300, margin:'0 auto'}}>
						<ImageSelector 
							imgBorder='1px solid #ccc'
							imgBorderRadius={10}
							imgPadding={5}
							imgRatio='1:1' 
							images={images}
							currentImage={selectedImage}
							selectImage={handleSelectImage}
						/>
					</div>
					{/** ImageSelector Ends Here */} 
				</Grid>
				<Grid item xs={12} sm={6}>
					<TextField fullWidth
						label='First Name (required)'
						name='firstName'
						value={firstName}
						onChange={(e) => {handleChange(setFirstName, e.target.value) }}
						margin='normal'
					/>
					<TextField fullWidth
						label='Last Name (required)'
						name='lastName'
						value={lastName}
						onChange={(e) => {handleChange(setLastName, e.target.value) }}
						margin='normal'
					/>
					<FormControl fullWidth>
					<InputLabel htmlFor='gender'>Gender</InputLabel>
					<Select
						value={gender}
						onChange={(e) => {handleChange(setGender, e.target.value) }}
						inputProps={{
							name: 'gender',
							id: 'gender'
						}}
					>
						<MenuItem value=''><em>Gender</em></MenuItem>
						<MenuItem value='Male'>Male</MenuItem>
						<MenuItem value='Female'>Female</MenuItem>
					</Select>
					</FormControl>
					<TextField fullWidth
						name='dob'
						onChange={(e) => {handleChange(setDob, e.target.value) }}
						label='Date of Birth'
						type='date'
						value={dob}
						InputLabelProps={{
							shrink: true,
							placeholder: 'Select Date of Birth'
						}}
					/>
					<TextField fullWidth
						label='Cell'
						name='cell'
						type='number'
						value={cell}
						onChange={(e) => {handleChange(setCell, e.target.value) }}
						margin='normal'
					/>
					<FormControl fullWidth>
					<InputLabel htmlFor='location'>Current Location</InputLabel>
					<Select
						value={location}
						onChange={(e) => {handleChange(setLocation, e.target.value) }}
						inputProps={{
						name: 'location',
						id: 'location'
						}}
					>
						<MenuItem value=''><em>Location</em></MenuItem>
						{countryList.map((c, i) => {
							return(<MenuItem key={i} value={c.code}>{c.name}</MenuItem>)
						})}
					</Select>
					</FormControl>					
				</Grid>
				<Grid item xs={12}>
					<div align='center' style={{ height: 35, padding:5 }}>
					<Text variant='body1' style={{ color: 'red' }}>
						<b>{message}</b>
					</Text>
				</div>
					<div style={{margin:'0px auto', maxWidth:400}}><Button onClick={updateUser} fullWidth variant='outlined' color='secondary' disabled={disableButton}>Update Profile</Button></div>
				</Grid>
			</Grid>
		</Paper>
	)
}
export default Profile
