
const initialState = {
	processingFees: {
		facFixedFee: 2, 		// $2 dollars
		facPercentage: .035,	// 3.5 percent
		creditsFixedFee: 5	// $5 dollars
	}
}

export default function reducer(state = initialState, action) {
	return state
}

