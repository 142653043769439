import React from 'react'
import { connect } from 'react-redux'
import { loadBoards  } from '../../redux/public/actions'
import {money, formattedNumber} from '../../includes/functions'

import PageMessage from '../components/PageMessage'
import SubNav from '../components/SubNav'
import NextUpdate from '../components/NextUpdate'

import Container from '@material-ui/core/Container'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Text from '@material-ui/core/Typography'

class Boards extends React.Component {
	mounted = true
	state = {
		pageLoading: true,
		pageTitle: undefined,	// Not Found',
		pageText: undefined		// The Boards',
	}
	componentDidMount = () => {
		this.handleLoadBoards(false)
	}
	componentWillUnmount = () => {
		this.mounted = false
	}
	handleLoadBoards = (force) => {
	 	return new Promise((resolve, reject) => {
	 		let _this = this
			_this.setState({ pageLoading:true, pageTitle:'Loading Feature Boards', pageText:'Checking server for updated list of Feature Boards.' })
		 	_this.props.loadBoards(force)
		 	.then(() => {
		 		if(_this.mounted) {
		 			_this.setState({ pageLoading:false, pageTitle:undefined, pageText:undefined })
		 		}
		 	})	
		 	.catch((e) => {
		 		if(_this.mounted) {
		 			_this.setState({pageLoading:false, pageTitle:e.title, pageText:e.message})
		 		}
		 	})
		})		
	}
	goTo =(id) => {
		this.props.history.push({ pathname: '/board/' + id })
	}
	render() {
		const { pageLoading, pageTitle, pageText } = this.state
		const { lastUpdated, boards } = this.props

		if(pageLoading || pageTitle) {
			return (<PageMessage pageLoading={pageLoading} pageTitle={pageTitle} pageText={pageText} />)
		}
		return (
			<React.Fragment>
				<Container maxWidth='lg' style={{ padding: 10, marginBottom: 50 }}>
				<Grid container justify='center' spacing={2}>
					<Grid item xs={12} sm={12} md={10} >
						<SubNav text='Feature Boards'/>
						<Paper style={{ marginTop:20, padding: 20, minHeight:800 }} >
							{(Object.keys(boards).length) ? (
								<div>
									{Object.keys(boards).map((i) => {
										return ( 
											<Grid key={i} container spacing={2} style={{marginTop:50, borderBottom:'2px solid #ccc'}}>
												<Grid item xs={12} sm={6}>
													<img onClick={() => { this.goTo(boards[i].id) }} src={boards[i].promoImage} alt={boards[i].name} style={{width:'100%', borderRadius:5}}/> 
												</Grid>
												<Grid item xs={12} sm={6}>
													<Button onClick={() => { this.goTo(boards[i].id) }} style={{padding:0, textTransform: 'none'}} ><Text variant='h5'>{boards[i].name}</Text></Button>
													<Text variant='body2'>{boards[i].intro}</Text>
													<div align='right' style={{marginTop:4, borderTop:'1px solid gray'}}><Text variant='caption'><i>- {boards[i].signature}</i></Text></div>
			
													<Grid container justify='center' spacing={2} style={{marginTop:20}}>
														<Grid item xs={4} sm={4}>
															<div style={{ padding:0, margin: '0 auto', textAlign:'center' }}>
																<Text variant='h6'>{money(boards[i].totalDonations,0)}</Text>
																<Text variant='caption' color='textSecondary'>USD RAISED</Text>
															</div>
														</Grid>
														<Grid item xs={4} sm={4}>
															<div style={{ padding:0, margin: '0 auto', textAlign:'center' }}>
																<Text variant='h6'>{formattedNumber(boards[i].boardCurrentSpot,0)}</Text>
																<Text variant='caption' color='textSecondary'>FEATURED</Text>
															</div>
														</Grid>
														<Grid item xs={4} sm={4}>
															<div style={{ padding:0, margin: '0 auto', textAlign:'center' }}>
																<Text variant='h6'>{formattedNumber(boards[i].totalDonors,0)}</Text>
																<Text variant='caption' color='textSecondary'>DONATIONS</Text>
															</div>
														</Grid>
													</Grid>

													<div align='center' style={{padding:20}}> 
													<Button variant='outlined' color='secondary' size='small' onClick={() => { this.goTo(boards[i].id) }}>Go to this feature board</Button>
													</div>

												</Grid>
											</Grid>
										)
									})}
								</div>
							) : (
								<div style={{padding:20, textAlign:'center'}}>
									<Text variant='body1' color='textSecondary'>
										No Active Feature Boards
									</Text>
								</div>
							)}
						</Paper>
					</Grid>
				</Grid>
				<NextUpdate lastUpdated={lastUpdated} updateNow={this.handleLoadBoards}/>
				</Container>
			</React.Fragment>
		)
	}
}
const mapStateToProps = (state) => ({
	boards: state.public.boards,
	lastUpdated: state.app.lastUpdated['publicBoards']
})
const mapDispatchToProps = {
	loadBoards
}
export default connect(mapStateToProps, mapDispatchToProps)(Boards)
