import React from 'react'
import moment from 'moment'
import swal from 'sweetalert2'
import { withRouter } from 'react-router-dom'
import {money, formattedNumber, progress, truncate} from '../../../includes/functions'
import CampaignDonors from '../../components/CampaignDonors'
import Loader from '../../components/Loader'

import { green } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles'
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionActions from '@material-ui/core/AccordionActions'
import Alert from '@material-ui/lab/Alert'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import IconDot from '@material-ui/icons/FiberManualRecord'
import IconExpandMore from '@material-ui/icons/ExpandMore'
import LinearProgress from '@material-ui/core/LinearProgress'
import Paper from '@material-ui/core/Paper'
import Text from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'

const useStyles = makeStyles(theme => ({
  	thumb: {
		width: 75,
		height: 75, 
		verticalAlign: 'top', 
		marginRight: 10,
		border: '1px solid #ccc', 
		borderRadius: 7
  	},
  		cardContent: {
		display: 'flex',
		padding: 5		
  	}
}))

function Boards({history, boards, defaultCampaigns, updateDefaultCampaign}) {
	const classes = useStyles()
	const [loading, setLoading] = React.useState(false)
	const [expanded, setExpanded] = React.useState(false)
	const [showShare, toggleShare] = React.useState(false)
	const [defaultId, setDefaultId] = React.useState('')
	const [message, setMessage] = React.useState('')

  	const handleChange = (panel) => (event, isExpanded) => {
    	setExpanded(isExpanded ? panel : false);
  		setDefaultId('')
  	}
  	const handleUpdateDefaultCampaign = (boardId) => {
  		if (!defaultId) {
  			return setMessage('Please select a Default Campaign.')
  		}
  		setMessage('')
  		setLoading(true)
  		updateDefaultCampaign(boardId, defaultId)
  		.then(() => {
  			setLoading(false)
  			setDefaultId('')
  		})
  		.catch((e) => {
  			setLoading(false)
			swal.fire({
				position: 'top-end',
				title: e.title,
				text: e.message,
				icon: 'error',
				showConfirmButton: true,
			  	confirmButtonText: 'Ok'
			})
  		})
  	}
	const goTo = (path) => {
      history.push({ pathname: path })
	}
	
	if (!boards || !Object.keys(boards).length) { 
		return (
			<Paper style={{ marginTop:20, padding: 20, minHeight:800 }} >
				<div align='center' style={{marginTop:20}}>
					<Text variant='body1' color='textSecondary'>No Boards Listed</Text>
				</div>
			</Paper>
		) 
	}

	return (
		<React.Fragment>
		<Loader loading={loading}/>
		<Paper style={{ marginTop:20, padding: 10, minHeight:800 }} >
			{Object.keys(boards).map((i, key) => {
				return (
					<Accordion key={key} expanded={expanded === i} onChange={handleChange(i)}>
			        	<AccordionSummary	expandIcon={<IconExpandMore />}>
			        		<Avatar alt={boards[i].name} src={boards[i].promoImage} style={{borderRadius:5, margin:5, marginRight:10}} />
			        		<Grid container>
			        			<Grid item xs={12} sm={6} md={6} >
									<Text variant='h6' style={{marginTop:5, lineHeight:1.1}}>{truncate(boards[i].name, 25)}</Text>
										<Text variant='caption' display='block' color='textSecondary'>
											Listed on: {moment.unix(boards[i].dateStart).format('MMM Do YYYY - h:mm a')}
										</Text>
			        			</Grid>
			        			<Grid item xs={4} sm={2} md={2} style={{textAlign:'center'}} >
			        				<Text variant='overline'>donors</Text><br/>
			        				<Text variant='body1' color='primary'><b>{formattedNumber(boards[i].totalDonors,0)}</b></Text>
			        			</Grid>
			        			<Grid item xs={4} sm={2} md={2} style={{textAlign:'center'}} >
			        				<Text variant='overline'>donations</Text><br/>
			        				<Text variant='body1' color='primary'><b>{money(boards[i].totalDonations,0)}</b></Text>
			        			</Grid>
			        			<Grid item xs={4} sm={2} md={2} style={{textAlign:'center'}} >
			        				<Text variant='overline'>live</Text><br/>
			        				<IconDot style={{color:(boards[i].live) ? green[500] : '#E8E8E8'}} fontSize='small'/>
			        			</Grid>
			        		</Grid>
			        	</AccordionSummary>
			        	<AccordionDetails>
			        		<Grid container spacing={2} style={{textAlign:'center'}}>
			        			<Grid item xs={12} sm={6}>
									<div align='center' style={{ maxWidth:200, margin: '0 auto', marginBottom: 30, borderBottom: '1px solid #B0B0B0' }}>
										<img src={boards[i].promoImage} style={{ width: '100%' }} alt='' /><br />
										<Text variant='caption' style={{color: '#B0B0B0'}}>
										  	<i>Campaign Promo Image.</i>
										</Text>  
									</div>
									<div align='center' style={{ maxWidth:440, margin: '0 auto', marginBottom: 50, borderBottom: '1px solid #B0B0B0' }}>
										<img src={boards[i].headerImage} style={{ width: '100%' }} alt='' /><br />
										<Text variant='caption' style={{color: '#B0B0B0'}}>
										  	<i>Page Header Image.</i>
										</Text>  
									</div>
									<div style={{ textAlign:'left', maxWidth:440, margin: '0 auto', marginBottom: 50}}>
										<Text variant='h5'>{boards[i].name}</Text>
										<div style={{ padding:5, borderTop: '1px solid #ccc', borderBottom: '2px solid #ccc' }}>
										<Text variant='body1'>{boards[i].intro}</Text>
										</div>  
										<div align='right'><i>{boards[i].signature}</i></div>
									</div>
									<div style={{ textAlign:'left', maxWidth:440, margin: '0 auto', marginBottom: 50}}>
										<Text variant='h5'>About</Text>
										<div style={{ padding:5, borderTop: '1px solid #ccc', borderBottom: '2px solid #ccc' }}>
											<Text variant='body1'>
											  	<b>Date Start:</b> {moment.unix(boards[i].dateStart).format('MMM Do YYYY - h:mm a')}<br/>
											  	<b>Date End:</b> {moment.unix(boards[i].dateEnd).format('MMM Do YYYY - h:mm a')}<br/>
											  	<b>Thankyou Message:</b><br/> {boards[i].thankyouMessage}<br/>
											  	<b>Thankyou Email:</b><br/> {boards[i].thankyouEmail}<br/>
											  	
											</Text>
										</div>  
									</div>
			        				<div style={{ textAlign:'left', maxWidth:440, margin: '0 auto', marginBottom: 50}}>
										<Text variant='h5'>Settings</Text>
										<div style={{ padding:5, borderTop: '1px solid #ccc', borderBottom: '2px solid #ccc' }}>
											<Text variant='body1'>
											  	<b>Donation Target:</b> {money(boards[i].donationTarget,0)}<br/>
											  	<b>Donation Requirement:</b> {money(boards[i].donationRequirement,0)}<br/>
												<b>Donation Default:</b> {money(boards[i].donationDefault,0)}<br/>
											  	<b>Board Fee:</b> {money(boards[i].boardFee,0)}<br/>
											  	<b>Board Spots Allowed:</b> {boards[i].boardSpotsAllowed}<br/>											  	
											</Text>
										</div>  
									</div>

			        			</Grid>
			        			<Grid item xs={12} sm={6}>
									<div style={{ maxWidth:440, margin: '0 auto', marginBottom: 60}}>
										<Text variant='h5'>Default Campaign</Text>
										<div style={{ padding:5, borderTop: '1px solid #ccc', margin:10 }}>
										{(boards[i].defaultCampaignId) ? (
											<Card>
												<CardContent className={classes.cardContent}>
													<span><img src={boards[i].defaultCampaignImage} className={classes.thumb} alt={boards[i].defaultCampaignName} /></span>
													<span>
														<Text variant="h6" display="block"><b>{boards[i].defaultCampaignTitle}</b></Text>
														<Text variant="body1" display="block" color="textSecondary" ><b>Beneficiary:</b> {boards[i].defaultCampaignOwnerName}</Text>
														<Text variant="body1" display="block" color="textSecondary" ><b>Funds Raised:</b> {boards[i].defaultCampaignDonations}</Text>
													</span>
												</CardContent>
											</Card>										
										) : (
											<Alert severity="error">You must set a <b>Default Campaign</b> before you <b>Go Live</b>.</Alert>
										)}
										</div>	
										<Text variant='body2' color='textSecondary'><b>Set Default Campaign</b></Text>
										<div style={{ padding:5, borderTop: '1px solid #ccc', borderBottom: '2px solid #ccc' }}>
											<div align='center'>
												<TextField select fullWidth style={{maxWidth:200}} onChange={(e) => { setDefaultId(e.target.value) }} value={defaultId} size='small' variant='outlined' id='defaultId'>
												  	{defaultCampaigns.map((campaign) => {
												  		return (<MenuItem key={campaign.id} value={campaign.id}>{campaign.name}</MenuItem>)
												  	})}
												</TextField>											
												<Button onClick={() => { handleUpdateDefaultCampaign(i) }} variant='contained' color='secondary' size='small' style={{margin:5}}>submit</Button>
											</div>
										</div>  
				        				<div align='center' style={{ height: 60, padding: 10 }}>
							            <Text variant='body2' style={{ color: 'red' }}>{message}</Text>
							         </div>
									</div>
			        				<Button onClick={() => { goTo('/board'+i) }} size='small' variant='outlined' color='secondary'>View Donations Details</Button>
			        				<Button onClick={() => {toggleShare(!showShare)}} size='small' variant='contained' color='secondary'>Share Campaign</Button>
			        				
			        			</Grid>
			        		</Grid>

			        	</AccordionDetails>
			        	<Divider />
			        	<AccordionActions>
			        		<Button onClick={() => {goTo('/group/board/'+i)}} size="small" variant='outlined'>edit board</Button>
				         <Button onClick={() => {setExpanded(false)}} size='small' variant='contained' color='secondary' style={{marginLeft:20}}>close</Button>
			        	</AccordionActions>
			      </Accordion>
				)
			})}
		</Paper>
		</React.Fragment>
	)
}
export default withRouter(Boards)
