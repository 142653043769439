import React from 'react'
import moment from 'moment'
import {formattedNumber} from '../../../includes/functions'

import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import Text from '@material-ui/core/Typography'

function Step3 ({ campaignInfo, handleGoTo, handleSetCampaignInfo }) {
	return (
		<div style={{maxWidth:500, margin:'0 auto'}}>
			<div style={{marginTop:20, maxWidth: 500}}>
				<img alt={campaignInfo.title} src={campaignInfo.promoImage} style={{width:'100%', borderRadius:3}} />
				<Text variant='h6'>{campaignInfo.title}</Text>
				<Text variant='body1'>{campaignInfo.about}</Text>
				<Divider style={{margin:5}} />
				<Text variant='body1' color='textSecondary'><b>Category:</b> {campaignInfo.category}</Text>
				<Text variant='body1' color='textSecondary'><b>Target:</b> {formattedNumber(campaignInfo.target,0)}</Text>
				<Divider style={{color:'black', height:3, marginTop:5}} />
				<div align='right'><Button onClick={() => { handleGoTo(0) }} size='small' color='secondary'>Edit</Button></div>
			</div>

			<div style={{marginTop:30}}>
				<Text variant='h5'>Settings</Text>
				<Divider />
				<Text variant='body1'><b>Listed on Site:</b> {(campaignInfo.listed) ? 'Yes - Listed on LetsFundIt' : 'No - Only people I share it with'}</Text>					
			  	<Text variant='body1'><b>Stop At Target:</b> {(campaignInfo.stopAtTarget) ? 'Yes - Stop when target is reached.' : 'No - Do not end when target is reached.'}</Text>
			  	<Text variant='body1'><b>Campaign End Date:</b> {(campaignInfo.deadline) ? moment.unix(campaignInfo.dateEnd).format('MMM Do YYYY - h:mm a') : 'Ongoing, no end.'}</Text>	
			  	<Text variant='body1'><b>Thankyou Message</b></Text>	
			  	<Text variant='body1'>{campaignInfo.thankyouMessage}</Text>
				<Divider style={{color:'black', height:3, marginTop:5}} />
				<div align='right'><Button onClick={() => { handleGoTo(1) }} size='small' color='secondary'>Edit</Button></div>
			</div>

		  	<div align='right' style={{ marginTop:50 }}>
				<Button variant='contained' color='secondary' onClick={handleSetCampaignInfo}>Save & Publish Campaign</Button>
		  	</div>
		</div>
	)
}
export default Step3
