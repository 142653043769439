import React from 'react'
import { withRouter } from 'react-router-dom'

import {money, formattedNumber} from '../../../includes/functions'
import Payments from '../../components/Payments/'
import ServiceAndPolicies from '../../components/ServiceAndPolicies'

import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import Collapse from '@material-ui/core/Collapse'
import Divider from '@material-ui/core/Divider'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Grid from '@material-ui/core/Grid'
import IconHand from '@material-ui/icons/PanToolRounded'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import MenuItem from '@material-ui/core/MenuItem'
import Paper from '@material-ui/core/Paper'
import Text from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import { pink, green } from '@material-ui/core/colors'
import IconDot from '@material-ui/icons/FiberManualRecord'

const Hand = ({color}) => {
	color = (color) ? color : '#f0f0f0'
	return <Avatar style={{margin:'4px 2px', backgroundColor:color, color:'white'}}><IconHand style={{marginLeft:-5, marginTop:-2}} /></Avatar>
}

function Join({history, loggedIn, isMember, boardId, boardName, boardOwner, boardFee, boardSpotsAllowed, donationRequirement, memberTotalContribution, memberTotalSpotsUsed, memberTotalSpotsCompleted, memberCampaigns, termsOfService }) {
	const [showContributionDetails, toggleContributionDetails] = React.useState(false)
	const [memberCampaignId, setMemberCampaignId] = React.useState(0)
	const [checkedTos, setCheckedTos] = React.useState(false)
	const [errorMessage, setErrorMessage] = React.useState('')
	
	// [START]: 2 - Contribution Variables ::::::::::::::::::::::::::::::::::::::::::::::
		const qualifiedSpots = parseInt(memberTotalContribution / donationRequirement)
		const usedSpots = memberTotalSpotsUsed || 0
		const activeSpots = memberTotalSpotsUsed - memberTotalSpotsCompleted
		const availableSpots = (qualifiedSpots - usedSpots >= 0) ? qualifiedSpots - usedSpots : 0
		const graySpots = (memberTotalContribution % donationRequirement) ? 1 : 0
		const totalSpots = (Math.ceil(qualifiedSpots / 5) * 5) || 5
		const remainder = totalSpots - qualifiedSpots - graySpots
		const lightGraySpots = (remainder >= 0) ? remainder : 0
	// [END]: 2 - Contribution Variables ::::::::::::::::::::::::::::::::::::::::::::::::

	const handleClickLink = (link) => {
   	let loginReturnLink= 'rURL=/board/' + boardId
      history.push({ pathname:link, search:loginReturnLink })
   }
   const handleNewCampaign = () => {
      history.push({ pathname:'/new/'+ boardId  })
   }
   const handleSelectCampaign = (value) => {
   	setCheckedTos(false)
		setErrorMessage('')
   	setMemberCampaignId(value)
   }
   const handlePayFee = () => {
   	setCheckedTos(!checkedTos)
   	if (!availableSpots) {
   		return setErrorMessage('please review step 1')
   	}
   	if (memberCampaignId < 1) {
   		return setErrorMessage('please complete step 2')
   	}
   	// changed memberSpots to memberTotalSpotsUsed
   	if (activeSpots >= boardSpotsAllowed) {
   		return setErrorMessage('you currently have ' + activeSpots + ' active spots on this board, which is the limit.')
   	}
   	return null
   }

	if (!loggedIn) {
		return (
			<Paper elevation={1} style={{padding:10, maxWidth:650, margin:'0px auto', marginTop:15}} >
				<Text variant='h5' color='secondary'>Join This Feature Board</Text>
				<div style={{padding:10}}>
					You must be a member of <b>LetsFundIt</b> to join this Feature Board.
					It takes <b>one minute</b> and you would be returned right here when complete.<br/>
					Please <b>Login</b> / <b>Register</b> to continue.<br/>
					<div align='center' style={{padding:10}}>
						<Button onClick={() => {handleClickLink('/login')}} variant='contained' color='secondary' style={{margin:10}}>Login</Button>
						<Button onClick={() => {handleClickLink('/email/register')}} variant='contained' color='secondary' style={{margin:10}}>Register</Button>
					</div>
				</div>
			</Paper>
		)
	}

	return (
		<div style={{maxWidth:650, margin:'0px auto', marginTop:15 }}>
			<Text variant='h5' color='secondary'>Join This Feature Board</Text>
			<Paper elevation={1} style={{padding:10}} >
				<List>
			      <ListItem alignItems='flex-start' style={{paddingLeft:0}}>
			        <ListItemAvatar>
			          <Avatar style={{backgroundColor: pink[500]}}><b>1</b></Avatar>
			        </ListItemAvatar>
			        <div>
			        		<Text variant='body1'><b>Make Qualifying Contribution</b></Text>
			        		<Text variant='body2'>Your must make a collective contribution to this board of at least <b>{money(donationRequirement,0)}</b>. Each collective <b>{money(donationRequirement,0)}</b> contribution would allow you one spot on the feature board.</Text>
			        		<div style={{height:10}} />
			        		{(donationRequirement > memberTotalContribution) ? (
			        			<div align='center'><Text variant='button' style={{color:'red'}}>Contribution Required</Text></div>
			        		) : ( null )}

			        		<div style={{display:'flex', justifyContent:'center', flexWrap:'wrap', maxWidth:250, margin:'0 auto'}}>
		        				{[...Array(usedSpots)].map((i, key) => {
		        					return <Hand key={key} color='red'/>
		        				})}
		        				{[...Array(availableSpots)].map((i, key) => {
		        					return <Hand key={key} color='#ffd333'/>
		        				})}
		        				{(graySpots) ? (
		        					<Hand color='#ccc'/>
		        				) : ( null )}
		        				{[...Array(lightGraySpots)].map((i, key) => {
		        					return <Hand key={key}/>
		        				})}
			        		</div>
			        		<div style={{borderTop: '2px solid pink'}}>
				        		<Collapse in={showContributionDetails} timeout='auto' unmountOnExit>
									<div align='center' style={{backgroundColor:'#fafafa', padding:10, borderRadius:3}}>
										<Text component='span' style={{borderBottom: '2px solid gray', paddingBottom:3}}>Total Contribution: <b>{money(memberTotalContribution,0)}</b></Text>
										<div style={{margin:'0 auto', maxWidth:200, marginTop:10}}>
										<Grid container justify='center' spacing={0}>
											<Grid item xs={9} style={{textAlign:'right'}}>Qualified Spots - </Grid>
											<Grid item xs={3} style={{textAlign:'center'}}>{formattedNumber(qualifiedSpots,0)}</Grid>
											<Grid item xs={9} style={{textAlign:'right'}}>Used Spots - </Grid>
											<Grid item xs={3} style={{textAlign:'center'}}>{formattedNumber(usedSpots,0)}</Grid>
											<Grid item xs={9} style={{textAlign:'right'}}><b>Available Spots - </b></Grid>
											<Grid item xs={3} style={{textAlign:'center'}}><b>{formattedNumber(availableSpots,0)}</b></Grid>
											<Grid item xs={12}>
												<IconDot style={{margin:2, color:'red', marginBottom:-3, fontSize:16}}/>
												<Text variant='caption' color='textSecondary'>Used</Text>
												<IconDot style={{margin:2, color:'#ffd333', marginBottom:-3, fontSize:16}}/>
												<Text variant='caption' color='textSecondary'>Available</Text>
												<IconDot style={{margin:2, color:'#ccc', marginBottom:-3, fontSize:16}}/>
												<Text variant='caption' color='textSecondary'>Partial</Text>
											</Grid>
										</Grid>
										</div>
									</div>
								</Collapse>
				        		<div align='right'><Button size='small' color='secondary' onClick={() => { toggleContributionDetails(!showContributionDetails) }}>{(showContributionDetails) ? 'hide details' : 'view details'}</Button></div>
			        		</div>
			        </div>
			      </ListItem>

			      <Divider variant='inset' component='li' style={{height:3, marginTop:10, marginBottom:10}} />

			      <ListItem alignItems='flex-start' style={{paddingLeft:0}}>
			        <ListItemAvatar>
			          <Avatar style={{backgroundColor: pink[500]}}><b>2</b></Avatar>
			        </ListItemAvatar>
			        <div>
			        		<Text variant='body1'><b>Submit Your Campaign</b></Text>
			        		<Text variant='body2'>Submit the campaign you would like to feature on this board. When your spot in the queue is reached, your campaign would be automatically featured.</Text>
			        		<div style={{height:10}} />
			      
			        		{(Array.isArray(memberCampaigns) && memberCampaigns.length) ? (
				        		<div>
					        		<div style={{textAlign:'center', borderTop: '2px solid pink', borderBottom: '2px solid pink', margin: '0px auto', padding:10}}>
					        			<Text variant='button' color='secondary'>select campaign</Text>
					        			<TextField select fullWidth onChange={(e) => { handleSelectCampaign(e.target.value) }} value={memberCampaignId} size='small' variant='outlined' id='category'>
										  	<MenuItem value='0' >Campaigns</MenuItem>
										  	{memberCampaigns.map((c) => {
										  		return <MenuItem key={c.id} value={c.id}>{c.title}</MenuItem>
										  	})}
										</TextField>
					        			<Text variant='caption' color='textSecondary'><i>Please Note: This Board's settings will over-ride any conflicting Campaign settings. (eg: Target, Exp Date, etc)</i></Text>
					        		</div>
					        		<div align='right'><Button onClick={handleNewCampaign} size='small' color='secondary'>Create New Campagn</Button></div>
				        		</div>
			        		) : (
				        		<div style={{textAlign:'center', borderTop: '2px solid pink', borderBottom: '2px solid pink', margin: '20px auto', padding:10}}>
				        			<Text variant='button' color='secondary'>no active campaign</Text>
				        			<Text variant='body2'>You do not have any active campaigns on letsFundIt, please create one.</Text>
				        			<Button onClick={handleNewCampaign} variant='contained' size='small' style={{ backgroundColor: green[500], color:'#fff', margin:'10px auto' }}>Click to Create Campaign</Button>
				        		</div>
				        	)}

			        </div>
			      </ListItem>

			      <Divider variant='inset' component='li' style={{height:3, marginTop:10, marginBottom:10}} />

			      <ListItem alignItems='flex-start' style={{paddingLeft:0}}>
			        <ListItemAvatar>
			          <Avatar style={{backgroundColor: pink[500]}}><b>3</b></Avatar>
			        </ListItemAvatar>
			        <div>
			        		<Text variant='body1'><b>Pay Fee & Get Listed</b></Text>
			        		<Text variant='body2'>
			        			This Feature Board is owned, marketed and managed by <b>{boardOwner}.</b> There is a minimal fee of <b>{money(boardFee,0)}</b> to process your submission and list your campaign.
			        			Please review our <ServiceAndPolicies tab={4} color='secondary' size='medium' /> to view all other fees and guidlines.
			        		</Text>

			        		<FormControlLabel
					        control={<Checkbox checked={checkedTos} onChange={handlePayFee} />}
					        //label='I Agree to the Fees & Terms of Service.'
					        label={
					        		<label>
					        			<Text variant='caption'>I Agree to the Fees & Terms of Service.</Text>
					        		</label>
					        	}
					        style={{marginLeft:0, marginTop:10}}
					      />
			        		<div style={{borderBottom:'1px solid pink'}} />

			        </div>
			      </ListItem>

			   </List>
      		<Collapse in={checkedTos} timeout='auto' unmountOnExit>
	      		{(errorMessage) ? (
	      			<div style={{textAlign:'center', marginTop:10, marginBottom:10}}>
	      				<Text variant='button' color='secondary'>{errorMessage}</Text>
	      			</div>
	      		) : (
	      			<div style={{textAlign:'center', marginTop:10, marginBottom:10}}>
	      				<Text variant='button' color='secondary'>Make Payment</Text>
	      				<div align='center'>
								<Text component='span' variant='h2' style={{color:'#d4d4d4'}}>$</Text>
								<Text component='span' variant='h2' color='secondary'>{boardFee}</Text>
								<Text component='span' variant='h5' style={{color:'#d4d4d4'}}> USD</Text>
							</div>
							<div align='center' style={{backgroundColor:'#f1f1f1', padding:10, borderRadius:5, paddingBottom:20}}>
								<Text variant='h6' color='secondary'>Select Payment Method</Text>
								<Payments 
									type='registration' 
									id={boardId}
									amount={boardFee}
									message={memberCampaignId}
									checkedAnonymous=''
									checkedHideMessage=''
									paymentDescription='Registration'
									buttonText='make payment'
								/>
							</div>
	      			</div>
	      		)}
      		</Collapse>
			</Paper>
		</div>
	)
}
export default withRouter(Join)
