export const selectorLoadTickets = (tickets, status) => {
    let filteredTickets = {}
    Object.keys(tickets).forEach(function (i) {
        if (tickets[i].closed === status) {
            filteredTickets[i] = tickets[i]
        }
    })
    return filteredTickets
}

export const selectorLoadMemberActivity = (transactions, filter) => {
	let filteredData = {}

	switch(filter) {
		case 'payIns':
			Object.keys(transactions).forEach(function (i) {
				if (transactions[i].type === 'payIn') {
					filteredData[i] = transactions[i]
				}
			})
			break
		case 'payOuts':
			Object.keys(transactions).forEach(function (i) {
				if (transactions[i].type === 'payOut') {
					filteredData[i] = transactions[i]
				}
			})
			break
		default:
			filteredData = {}
	  	}

	return filteredData
}

/*
export const selectorFilterCampaigns = (campaigns, status) => {
	let filteredCampaigns = {}
	let currentTime = Math.floor(Date.now() / 1000)
	switch(status) {
		case 'Active':
			Object.keys(campaigns).forEach(function (i) {
				if (campaigns[i].live && campaigns[i].dateTimeEnd._seconds > currentTime) {
					filteredCampaigns[i] = campaigns[i]
				}
			})
			break
		case 'Inactive':
			Object.keys(campaigns).forEach(function (i) {
				if (!campaigns[i].live) {
					filteredCampaigns[i] = campaigns[i]
				}
			})
			break
		default:
			filteredCampaigns = {}
	  }
	return filteredCampaigns
}
*/
