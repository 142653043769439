import React from 'react'
import { Link } from 'react-router-dom'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Text from '@material-ui/core/Typography'

function List() {
    return (
        <Container maxWidth='lg' style={{ padding: 10, minHeight:700  }}>
        <Grid container justify='center' spacing={2}>
            <Grid item xs={10} sm={8} md={6} >
                <Paper style={{ padding: 20 }} >
                    <Text variant='caption' gutterBottom>
                        <ul>
                            <li><Link to='/templates/login-register'>Login / Register</Link></li>
                            <li><Link to='/templates/credits-payment'>Confirm Credits Payment</Link></li>
                            <li><Link to='/templates/credits-transfer'>Confirm Credits Transfer</Link></li>
                            <li>------------</li>
                            <li><Link to='/templates/purchase-receipt'>Purchase Receipt</Link></li>
                            <li><Link to='/templates/phuket-event-text'>Phuket Event Text</Link></li>
                        </ul>
                    </Text>
                </Paper>
            </Grid>
        </Grid>
        </Container>
    )
}

export default List
