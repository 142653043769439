import React from 'react'

import Paper from '@material-ui/core/Paper'
import Text from '@material-ui/core/Typography'

function Statements() {
	return (
		<Paper style={{ padding: 20, minHeight:800 }} >
			<Text variant='h5' gutterBottom>
				Statements
			</Text>
			<Text variant='caption' gutterBottom>
				- Monthly Statements<br/>
				- REQUEST A WITHDRAWL<br/>
				- NOTICE OF OUTSTANDING DUES<br/>
			</Text>
		</Paper>
	)
}
export default Statements
