import React from 'react'
import moment from 'moment'

import Avatar from '@material-ui/core/Avatar'
import Paper from '@material-ui/core/Paper'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemText from '@material-ui/core/ListItemText'
import Text from '@material-ui/core/Typography'

function Profile({profile}) {
	if(!profile || Object.keys(profile).length === 0){ return (null) }
		
	return (
		<Paper elevation={1} style={{padding: 5, marginTop:20}} >
			<List>
				<ListItem>
					<ListItemAvatar>
						<Avatar alt={profile.ownerName} src={profile.ownerImage} style={{borderRadius:5}} />
					</ListItemAvatar>
					<ListItemText>
						<Text style={{lineHeight:.5}} variant='caption' display='block' color='textSecondary'>
							Campaign by:
						</Text>
						<Text variant='h6'>{profile.ownerName}</Text>
						<Text style={{lineHeight:.8}} variant='caption' display='block' color='textSecondary'>
							Listed on {moment.unix(profile.dateStart).format('MMM Do YYYY - h:mm a')}
						</Text>
					</ListItemText>
				</ListItem>
			</List>
		</Paper>
	)
}
export default Profile
