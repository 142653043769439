import React from 'react'
import swal from 'sweetalert2'
import { connect } from 'react-redux'
import { addEditFundraiser, fetchCampaignInfo } from '../../../redux/group/actions'

import PageMessage from '../../components/PageMessage'
import SubNav from '../../components/SubNav'
import CampaignType from './CampaignType'
import Donation from './Donation'
import Fundraiser from './Fundraiser'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'

class GroupCampaignsContainer extends React.Component {
	mounted = true
	state = {
		pageLoading: false,
		pageTitle: undefined,	//Not Found',
		pageText: undefined,		//The campaign you are looking for was not found',
		campaignType: null,
		id: undefined,
		info: undefined
  	}
	componentDidMount = () => {
		let id = this.props.match.params.id
		if(id) {
			this.setState({ id:id })
			this.handleFetchCampaign(id)
		}
	}
	componentWillUnmount = () => {
		this.mounted = false
	}
	handleFetchCampaign = (id) => {
		let _this = this
		this.setState({ pageLoading: true, pageTitle: 'Loading Campaign', pageText: 'fetching campaign info from server'})
		this.props.fetchCampaignInfo(id)
		.then((info) => {
			if (_this.mounted) {
				_this.setState({ pageLoading:false, pageTitle:'', pageText:'', info:info, campaignType:info.type.toUpperCase() })
			}
		})
		.catch((error) => {
			if (_this.mounted) {
				swal.fire({
					  title: error.title,
					  text: error.message,
					  icon: 'warning',
					  confirmButtonColor: '#4caf50',
					  confirmButtonText: 'ok'
				}).then((result) => {
					if (result.isConfirmed) {
					   _this.props.history.push({ pathname:'/group/campaigns' })
					}
				})	
			}
		})
	}


  	handleCampaignType = (type) => {
  		this.setState({ campaignType:type })
  	}
  	render() {
  		const { pageLoading, pageTitle, pageText, campaignType, id, info } = this.state
 		const { addEditFundraiser } = this.props

  		if(pageLoading || pageTitle) {
			return (<PageMessage pageLoading={pageLoading} pageTitle={pageTitle} pageText={pageText} />)
		}

    	return (
   		<React.Fragment>
   		<Container maxWidth='lg' style={{ padding: 10, marginBottom: 50 }}>
				<Grid container justify='center' spacing={2}>
					<Grid item xs={12} sm={12} md={10} >
						<SubNav text='Campaign' buttonText='Campaign List' buttonLink='/group/campaigns/'/>
						{(!campaignType) ? (
							<CampaignType handleCampaignType={this.handleCampaignType} />
						) : ( null )}											

						{(campaignType === 'DONATION') ? (
							<Donation/>
						) : ( null )}

						{(campaignType === 'FUNDRAISER') ? (
							<Fundraiser id={id} info={info} addEditFundraiser={addEditFundraiser} />
						) : ( null )}
					</Grid>
				</Grid>
			</Container>
   		</React.Fragment>
    	)
  	}
}
const mapDispatchToProps = {
	addEditFundraiser,
	fetchCampaignInfo
}
export default connect(null, mapDispatchToProps)(GroupCampaignsContainer)
