import React from 'react'
import { connect } from 'react-redux'
import { updateMemberInfo, updateMemberPassword } from '../../../redux/member/actions'

import Loader from '../../components/Loader'
import SubNav from '../../components/SubNav'
import Profile from './Profile'
import Verification from './Verification'
import Security from './Security'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'

const tabNames = {'profile':0,'verification':1,'security':2}
function TabPanel(props) {
  	const { children, value, index, ...other } = props;
  	return (
    	<div role='tabpanel' hidden={value !== index} id={`nav-tabpanel-${index}`} {...other} >
      	{value === index && ( children )}
    	</div>
  	)
}

class MemberProfileContainer extends React.Component {
	state = {
	 	loading:false,
		activeTab: (this.props.match.params.page && tabNames[this.props.match.params.page]) ? tabNames[this.props.match.params.page] : 0
  	}
  	handleChange = (event, newValue) => {
  		this.setState({activeTab:newValue})
  	}
  	render() {
  		const {loading, activeTab} = this.state
  		const {memberInfo, updateMemberInfo, updateMemberPassword} = this.props
    	return (
   		<React.Fragment>
   		<Loader loading={loading}/>
   		<Container maxWidth='lg' style={{ padding: 10, marginBottom: 50 }}>
				<Grid container justify='center' spacing={2}>
					<Grid item xs={12} sm={12} md={10} >
						<SubNav text='Profile Settings'/>
					
						<div style={{borderBottom: '2px solid #eee'}}>  
						  	<Tabs
						    	value={activeTab}
						    	variant="fullWidth"
						    	onChange={this.handleChange}
						    	indicatorColor="primary"
						    	textColor="primary"							    
						  	>
						    	<Tab label='Profile' />
						    	<Tab label='Verification' />
						    	<Tab label='Security' />
						  	</Tabs>
						</div>

						<TabPanel value={activeTab} index={0}>
				        	<Profile memberInfo={memberInfo} updateMemberInfo={updateMemberInfo}/>
				      </TabPanel>
				      <TabPanel value={activeTab} index={1}>
				        	<Verification/>
				      </TabPanel>
				      <TabPanel value={activeTab} index={2}>
				        	<Security updateMemberPassword={updateMemberPassword}/>
				      </TabPanel>

					</Grid>
				</Grid>
			</Container>
   		</React.Fragment>
    	)
  	}
}
const mapStateToProps = (state) => ({
	memberInfo: state.auth.userInfo
})
const mapDispatchToProps = {
	updateMemberInfo,
	updateMemberPassword
}
export default connect(mapStateToProps, mapDispatchToProps)(MemberProfileContainer)
