import React from 'react'
import moment from 'moment'

import Avatar from '@material-ui/core/Avatar'
import Divider from '@material-ui/core/Divider'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemText from '@material-ui/core/ListItemText'
import Text from '@material-ui/core/Typography'

const icons = {
	newCampaign: '/images/profile/default.jpg'
}

function DetailsActivity({activity}) {
	
	if (!activity || !activity.length) { 
		return (
			<div align='center' style={{marginTop:20}}>
				<Text variant='body1' color='textSecondary'>No Activity Recorded</Text>
			</div>
		) 
	}

	return (
		<List style={{padding:5}}>
			{activity.map((a) => {
				return (
				   <div key={a.date}>
						<ListItem alignItems='flex-start'>
							<ListItemAvatar>
								<Avatar alt={a.title} src={icons[a.icon]} style={{borderRadius:5}} />
							</ListItemAvatar>
							<ListItemText>
								<Text variant='h6' style={{ lineHeight:1.1}}>{a.title}</Text>
								<Text variant='body2'>{a.description}</Text>
								<Text variant='caption' display='block' color='textSecondary'>
									On {moment.unix(a.date).format('MMM Do YYYY - h:mm a')}
								</Text>
							</ListItemText>
						</ListItem>
						<Divider/>                
				   </div>
				)
			})}
		</List>		
	)
}
export default DetailsActivity
