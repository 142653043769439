import React from 'react'
import moment from 'moment'
import { withRouter } from 'react-router-dom'

import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemText from '@material-ui/core/ListItemText'
import Text from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'

function NotificationsList({history, notifications, loadMoreMemberNotifications}) {
	
	const goTo = (path) => {
      history.push({ pathname: path })
	}

	if (!notifications || !Object.keys(notifications).length) { 
		return (
			<Paper style={{ marginTop:20, padding: 40, minHeight:800 }} >
				<div align='center'><Text variant='body1' color='textSecondary'>No Notifications Recorded</Text></div>
			</Paper>
		) 
	}

	return (
		<Paper style={{ marginTop:20, padding: 20, minHeight:800 }} >
			<List style={{padding:5, maxWidth:600, margin:'0 auto'}}>
				{Object.keys(notifications).map((i, key) => {
					return (
					   <Paper key={key} style={{marginBottom:20}}>
							<ListItem alignItems='flex-start'>
								<ListItemAvatar>
									<Avatar alt={notifications[i].subject} src={notifications[i].image} style={{borderRadius:5}} />
								</ListItemAvatar>
								<ListItemText>

									{notifications[i].senderName}
									<Text variant='h6' style={{ lineHeight:1.1}}>{notifications[i].subject}</Text>
									<Text variant='body2'>{notifications[i].message}</Text>
									<Text variant='caption' display='block' color='textSecondary'>
										On {moment.unix(notifications[i].timestamp).format('MMM Do YYYY - h:mm a')}
									</Text>
									{(notifications[i].actionText) ? (
										<div align='center' style={{padding:10}}><Button onClick={() => { goTo(notifications[i].actionLink) }} size='small' variant='contained' color='secondary'>{notifications[i].actionText}</Button></div>
									) : ( null)}
								</ListItemText>
							</ListItem>
							<Divider/>                
					   </Paper>
					)
				})}
			</List>	
		</Paper>
	)
}
export default withRouter(NotificationsList)
