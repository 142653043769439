import React from 'react'
import moment from 'moment'
import queryString from 'query-string'
import { connect } from 'react-redux'
import { processFacPayment } from '../../../redux/payments/actions'
import PageMessage from '../../components/PageMessage'
import { money, formattedNumber } from '../../../includes/functions'

import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import Paper from '@material-ui/core/Paper'
import Text from '@material-ui/core/Typography'

class PaymentCard extends React.Component {
	mounted = true
	state = {
		pageLoading: true,
		pageTitle: undefined,	// Not Found',
		pageText: undefined,		// The campaign you are looking for was not found',
		info: {}
	}
	componentDidMount = () => {
      this.handleSubmitFac()
   }   
   handleSubmitFac = () => {
   	const _this = this
      const qs = queryString.parse(this.props.location.search)    
      const ID = (qs.ID) ? qs.ID : ''     
      this.props.processFacPayment(ID)
      .then((data) => {
      	if (_this.mounted) {
      		this.setState({pageLoading:false, info:data})  
      	}
      })
      .catch((e) => {
      	if (_this.mounted) {
         	this.setState({pageLoading:false, pageTitle:e.title, pageText:e.message})
      	}
      })
   }
   goTo = (link) => {
   	this.props.history.push({ pathname: link })
   }

	render() {
		const {pageLoading, pageTitle, pageText, info} = this.state

		if(pageLoading || pageTitle) {
			return (<PageMessage pageLoading={pageLoading} pageTitle={pageTitle} pageText={pageText} />)
		}

		return (
			<div  style={{ padding: 10, marginBottom: 150, minHeight:700  }}>
				<Paper style={{ padding: 10, maxWidth:500, margin:'0 auto', marginTop:50, marginBottom:50 }} >
					<Text variant='h5' gutterBottom>Transaction Complete</Text>
					<Divider/>
					<div align='center' style={{marginTop:30}}>
						<Text component='span' variant='h2' style={{color:'#d4d4d4'}}>$</Text>
						<Text component='span' variant='h2' color='primary'>{formattedNumber(info.paymentTotal,2)}</Text>
						<Text component='span' variant='h5' style={{color:'#d4d4d4'}}> USD</Text>
					</div>
					<Divider style={{height:3, backgroundColor:'#d4d4d4'}}/>

					<div style={{paddingTop:20, margin:'0px auto', maxWidth:300, textAlign:'center'}}>
						<div style={{display: 'flex', lineHeight:1.5}}>
		               <Text variant='h6'>{info.transCategory}:</Text>
		               <div style={{ marginLeft:'auto', marginRight:0, textAlign:'right'}}>
		                  <Text variant='h6'><i>{money(info.paymentAmount,2)}</i></Text>
		               </div>
		            </div>
		            <div style={{display: 'flex', lineHeight:1.5}}>
		               <Text variant='body1' color='textSecondary'>Processing Fee:</Text>
		               <div style={{ marginLeft:'auto', marginRight:0, textAlign:'right'}}>
		                  <Text variant='body1' color='textSecondary'><i>{money(info.paymentFee,2)}</i></Text>
		               </div>
		            </div>
		            <div style={{display: 'flex', lineHeight:1.5}}>
		               <Text variant='h6' color='textSecondary'>Total:</Text>
		               <div style={{ marginLeft:'auto', marginRight:0, textAlign:'right'}}>
		                  <Text variant='h6' color='textSecondary'><i>{money(info.paymentTotal,2)}</i></Text>
		               </div>
		            </div>
				   </div>

					<div align='center' style={{marginTop:10}}><Text variant='caption' color='textSecondary'>{info.transCategory}:</Text></div>
					<div align='center' style={{backgroundColor:'#f1f1f1'}}><Text variant='h5' color='primary'>{info.transTitle}</Text></div>
					<div align='center' style={{marginTop:10}}>
						<Text component='span' variant='body1' color='textSecondary'><b>Date: </b></Text>
						<Text component='span' variant='body1'>{moment.unix(info.timestamp).format('MMM Do YYYY')}</Text>
					</div>
					<div align='center'>
						<Text component='span' variant='body1' color='textSecondary'><b>Recipient: </b></Text>
						<Text component='span' variant='body1'>{info.payeeName}</Text>
					</div>
					{(info.payeeMessage) ? (
						<div style={{marginTop:25, borderTop:'1px solid #ccc', borderBottom:'2px solid #ccc', padding:10}}>
							<Text variant='body1'><i>{info.payeeMessage}</i></Text>
							<div align='right'><Text variant='caption'><i>- {info.payeeName}</i></Text></div>
						</div>
					) : ( null)}
					<div align='center' style={{padding:5}}>
						<div style={{padding:20}}>
							{(info.transType === 'campaign') ? (
								<Button fullWidth variant='contained' color='secondary' style={{margin:5}} onClick={() => { this.goTo('/view/'+info.transId) }}>Go Back to campaign</Button>
							) : (
								<Button fullWidth variant='contained' color='secondary' style={{margin:5}} onClick={() => { this.goTo('/board/'+info.transId) }}>Go Back to feature board</Button>
							)}
						</div>
						<div style={{marginTop:10}}>
							<Text variant='body1' color='textSecondary'>All your donation records are kept in your account.</Text>
							<Button size='small' onClick={() => { this.goTo('/member/donations') }} style={{padding:0}}>Click Here to go to your Account</Button>
						</div>						
					</div>
				</Paper>
			</div>
		)
	}
}
const mapDispatchToProps = {
  processFacPayment
}
export default connect(null,mapDispatchToProps)(PaymentCard)
