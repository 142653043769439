import { 
	APP_LAST_UPDATED,
	PUBLIC_LOAD_BOARDS, 
	PUBLIC_LOAD_CAMPAIGNS,  
	PUBLIC_LOAD_CAMPAIGN_INFO, 
	PUBLIC_LOAD_CAMPAIGN_DONATIONS,
	PUBLIC_FOLLOW_CAMPAIGN,
	PUBLIC_LOAD_CHARITIES
} from '../types'
import { chkExpiredData } from '../app/actions'

// [START]: SECTION - Public Campaigns ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
// ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
	export function loadCampaigns(type='list', filter='all') {
		return (dispatch, getstate, firebase) => new Promise(async function (resolve, reject) {
			const fetchCampaigns = firebase.functions().httpsCallable('fetchCampaigns')
	      fetchCampaigns({ type:type, filter:filter })
	      .then((returned) => {
				let payload = {
	         	timestamp: Math.floor(Date.now() / 1000),
					campaigns: returned.data
				}				
				dispatch({ type: PUBLIC_LOAD_CAMPAIGNS, payload: payload })
				resolve()
	      })
	      .catch((e) => {
	      	let error = {title:'Error',message:''}
	      	try { error = JSON.parse(e.message) } 
	      	catch (err) { error.message = (e.message === 'internal') ? 'Error connecting to server.' : e.message }
	      	reject(error)
	      })
		})
	}

	export function loadCampaignInfo(campaignId) {
		return (dispatch, getstate, firebase) => new Promise(async function (resolve, reject) {
			const memberId = (getstate().auth.userInfo) ? getstate().auth.userInfo.id : undefined
			const fetchInfo = firebase.functions().httpsCallable('fetchCampaignInfo')
	      fetchInfo({campaignId:campaignId, memberId:memberId})
	      .then((returned) => {
	         
	      	let info = getstate().public.campaignsInfo
	      	if (Object.keys(info).length > 5) {
	      		info = {}
	      	}
	      	info[campaignId] = returned.data

	         let payload = {
	         	timestamp: Math.floor(Date.now() / 1000),
					info: info
				}
				dispatch({type: PUBLIC_LOAD_CAMPAIGN_INFO, payload: payload})
				resolve()
	      })
	      .catch((e) => {
	      	let error = {title:'Error',message:''}
	      	try { error = JSON.parse(e.message) } 
	      	catch (err) { error.message = (e.message === 'internal') ? 'Error connecting to server.' : e.message }
	      	reject(error)
	      }) 
		})
	}

	export function loadCampaignDonations(campaignId, force=false) {
		return (dispatch, getstate, firebase) => new Promise(async function (resolve, reject) {
	     	const loadId = 'donations-'+campaignId
	     	const chkIfExpired = dispatch(chkExpiredData(force, loadId))
	     	chkIfExpired
	     	.then((currentTime) => {
	     	// ---------------------------------------------------------------------------    
				const fetchInfo = firebase.functions().httpsCallable('fetchCampaignDonations')
		      fetchInfo({campaignId:campaignId})
		      .then((returned) => {
		         
		      	let donations = getstate().public.campaignsDonations
		      	if (Object.keys(donations).length > 5) {
		      		donations = {}
		      	}
		      	donations[campaignId] = returned.data

		         let payload = {
		         	timestamp: currentTime,
						donations: donations
					}
					dispatch({type: PUBLIC_LOAD_CAMPAIGN_DONATIONS, payload: payload})
					dispatch({ type: APP_LAST_UPDATED, payload:{key:loadId, value:currentTime} })
					resolve()
		      })
		      .catch((e) => {
		      	let error = {title:'Error',message:''}
		      	try { error = JSON.parse(e.message) } 
		      	catch (err) { error.message = (e.message === 'internal') ? 'Error connecting to server.' : e.message }
		      	reject(error)
		      }) 
	     	// ---------------------------------------------------------------------------
	     	})
	     	.catch(() => {
	         // Current data still good so do nothing.
	         resolve()
	     	})
		})
	}

	export function followCampaign(id) {
		return (dispatch, getstate, firebase) => new Promise(async function (resolve, reject) {
			const memberId = (getstate().auth.userInfo) ? getstate().auth.userInfo.id : undefined
			let cInfo = getstate().public.campaignsInfo[id]
			let status = !cInfo.following
			cInfo.following = status 

			let payload = {
				timestamp: Math.floor(Date.now() / 1000),
				id: id,
				info: cInfo
			}
			dispatch({type: PUBLIC_FOLLOW_CAMPAIGN, payload: payload})
			
			// :::::::::::::::::::::::::::::::::::::::::

			const setFollowers = firebase.functions().httpsCallable('setCampaignFollowing')
	      setFollowers({ memberId:memberId, campaignId:id, status:status })
	      .then((returned) => {
	      	resolve()
	      })
	      .catch((err) => {
	      	resolve()
	      })
		})
	}
// ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
// [END]: SECTION - Public Campaigns ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::


// [START]: Public Charities >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
// >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
	export function loadCharities(force=false) {
		return (dispatch, getstate, firebase) => new Promise(async function (resolve, reject) {
	     	const chkIfExpired = dispatch(chkExpiredData(force, 'publicCharities'))
	     	chkIfExpired
	     	.then((currentTime) => {
	     	// ---------------------------------------------------------------------------    
	       	const fetchCharities = firebase.functions().httpsCallable('fetchCharities')
		      fetchCharities()
		      .then((returned) => {
					let payload = {
		         	timestamp: currentTime,
						charities: returned.data
					}
					dispatch({ type: PUBLIC_LOAD_CHARITIES, payload: payload })
					dispatch({ type: APP_LAST_UPDATED, payload:{key:'publicCharities', value:currentTime} })
					resolve()
		      })
		      .catch((e) => {
		      	let error = {title:'Error',message:''}
		      	try { error = JSON.parse(e.message) } 
		      	catch (err) { error.message = (e.message === 'internal') ? 'Error connecting to server.' : e.message }
		      	reject(error)
		      })  
	     	// ---------------------------------------------------------------------------
	     	})
	     	.catch(() => {
	         // Current data still good so do nothing.
	         resolve()
	     	})
		})
	}
// >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
// [END]: Public Charities >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>


// [START]: Public Board :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
// :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
	export function loadBoards(force=false) {
		return (dispatch, getstate, firebase) => new Promise(async function (resolve, reject) {
	     	const chkIfExpired = dispatch(chkExpiredData(force, 'publicBoards'))
	     	chkIfExpired
	     	.then((currentTime) => {
	     	// ---------------------------------------------------------------------------    
	       	const fetchBoards = firebase.functions().httpsCallable('fetchBoards')
		      fetchBoards()
		      .then((returned) => {
					let payload = {
		         	timestamp: currentTime,
						boards: returned.data
					}
					dispatch({ type: PUBLIC_LOAD_BOARDS, payload: payload })
					dispatch({ type: APP_LAST_UPDATED, payload:{key:'publicBoards', value:currentTime} })
					resolve()
		      })
		      .catch((e) => {
		      	let error = {title:'Error',message:''}
		      	try { error = JSON.parse(e.message) } 
		      	catch (err) { error.message = (e.message === 'internal') ? 'Error connecting to server.' : e.message }
		      	reject(error)
		      })  
	     	// ---------------------------------------------------------------------------
	     	})
	     	.catch(() => {
	         // Current data still good so do nothing.
	         resolve()
	     	})
		})
	}

	export function loadBoardInfo(boardId) {
		return (dispatch, getstate, firebase) => new Promise(async function (resolve, reject) {
			const fetchBoard = firebase.functions().httpsCallable('fetchBoardInfo')
	      fetchBoard({boardId:boardId})
	      .then((returned) => {	
				resolve(returned.data)
	      })
	      .catch((e) => {
	      	let error = {title:'Error',message:''}
	      	try { error = JSON.parse(e.message) } 
	      	catch (err) { error.message = (e.message === 'internal') ? 'Error connecting to server.' : e.message }
	      	reject(error)
	      }) 

		})
	}
// :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
// [END]: Public Board :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::