import React from 'react'
import moment from 'moment'
import { withRouter } from 'react-router-dom'
import {money, truncate} from '../../../includes/functions'

import Avatar from '@material-ui/core/Avatar'
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionActions from '@material-ui/core/AccordionActions'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import IconExpandMore from '@material-ui/icons/ExpandMore'
import Paper from '@material-ui/core/Paper'
import Text from '@material-ui/core/Typography'

function Donations({history, donations}) {
	const [expanded, setExpanded] = React.useState(false)

  	const handleChange = (panel) => (event, isExpanded) => {
    	setExpanded(isExpanded ? panel : false);
  	}

	const goTo = (path) => {
      history.push({ pathname: path })
	}

	if (!donations || !Object.keys(donations).length) { 
		return (
			<Paper style={{ padding: 20, minHeight:800 }} >
				<div align='center' style={{marginTop:20}}>
					<Text variant='body1' color='textSecondary'>No Donations Listed</Text>
				</div>
			</Paper>
		) 
	}

	return (
		<Paper style={{ padding: 10, minHeight:800 }} >
			{Object.keys(donations).map((i, key) => {
				return (
					<Accordion key={key} expanded={expanded === i} onChange={handleChange(i)}>
			        	<AccordionSummary	expandIcon={<IconExpandMore />}>
			        		<Avatar alt={donations[i].campaignTitle} src={donations[i].campaignImage} style={{borderRadius:5, margin:5, marginRight:10}} />
			        		<Grid container alignItems="center">
			        			<Grid item xs={12} sm={9} md={10} >
									<Text variant='h6' style={{marginTop:5, lineHeight:1.1}}>{truncate(donations[i].campaignTitle, 25)}</Text>
									<Text variant='caption' display='block' color='textSecondary'>
										On: {moment.unix(donations[i].timestamp).format('MMM Do YYYY - h:mm a')}
									</Text>
			        			</Grid>
			        			<Grid item xs={12} sm={3} md={2}>
			        				<Text variant='h6' component='span' color='primary'><b>{ money(donations[i].donationAmount,0) } </b></Text>
									<Text variant='caption' component='span' color='textSecondary'><b> USD</b></Text>
			        			</Grid>
			        		</Grid>
			        	</AccordionSummary>
			        	<AccordionDetails>
			        		<div style={{margin:'0 auto', width:'100%', maxWidth:600, marginBottom:50}}>
								<Grid container>
				        			<Grid item xs={12} sm={3} md={3} style={{textAlign:'left'}} >
				        				<Text variant='overline'><b>payment method</b></Text>
				        				<Text variant='h6' color='primary' style={{lineHeight:1}}><b>{donations[i].paymentMethod}</b></Text>				        				
				        			</Grid>
				        			<Grid item xs={4} sm={3} md={3} style={{textAlign:'center'}} >
				        				<Text variant='overline'><b>donation</b></Text><br/>
				        				<Text variant='h6' component='span' color='primary' style={{lineHeight:1}}><b>{ money(donations[i].paymentAmount,2) } </b></Text>
				        			</Grid>
				        			<Grid item xs={4} sm={3} md={3} style={{textAlign:'center'}} >
				        				<Text variant='overline'><b>fees</b></Text><br/>
				        				<Text variant='h6' component='span' color='primary' style={{lineHeight:1}}><b>{ money(donations[i].paymentFee,2) } </b></Text>
				        			</Grid>
				        			<Grid item xs={4} sm={3} md={3} style={{textAlign:'center'}} >
				        				<Text variant='overline'><b>total paid</b></Text><br/>
				        				<Text variant='h6' component='span' color='primary' style={{lineHeight:1}}><b>{ money(donations[i].paymentTotal,2) } </b></Text>
				        			</Grid>
				        			<Grid item xs={12} sm={12} md={12} style={{textAlign:'center', paddingTop:10}}>
			        					<Text variant='body1' display='block' color='textSecondary' style={{marginTop:-5}}><i>{donations[i].paymentNote}</i></Text>
				        			</Grid>
			        			</Grid>

							</div>
			        	</AccordionDetails>
			        	<Divider />
			        	<AccordionActions>
			        		<Button onClick={() => {goTo('/view/'+donations[i].campaignId)}} size='small' variant='outlined'>go to campaign</Button>
				         <Button onClick={() => {setExpanded(false)}} size='small' variant='contained' color='secondary' style={{marginLeft:20}}>Close</Button>
			        	</AccordionActions>
			      </Accordion>
				)
			})}
		</Paper>
	)

}
export default withRouter(Donations)
