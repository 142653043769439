import React from 'react'
import { withRouter } from 'react-router-dom'

import Loader from '../../components/Loader'
import Step1 from './Step1'
import Step2 from './Step2'
import Step3 from './Step3'
import Step4 from './Step4'
import Step5 from './Step5'

import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Step from '@material-ui/core/Step'
import StepContent from '@material-ui/core/StepContent'
import StepLabel from '@material-ui/core/StepLabel'
import Stepper from '@material-ui/core/Stepper'
import Text from '@material-ui/core/Typography'

class New extends React.Component {
	mounted = true
	state = { 
		loading: false,
		campaignId: null,
		boardId: this.props.boardId,
		board: this.props.board,	
		activeStep: 0
	}
	componentWillUnmount = () => {
		this.mounted = false
	}
	handleSetCampaignId = (id) => {
		this.setState({ campaignId:id })
		this.handleGoTo(4)
	}

// ...................................................................
	handleGoTo = (step) => {
	   this.setState({activeStep: step })
	}
	goTo = (link) => {
      this.props.history.push({ pathname:link })
   }
	render() {
		const { loading, campaignId, boardId, board, activeStep } = this.state
		const { loggedIn, userInfo } = this.props

		return (
			<React.Fragment>
				<Loader loading={loading} />

				<Paper elevation={1} style={{padding: 5, margin:'20px auto'}} >
					<img alt={board.boardName} src={board.boardImage} style={{display:'block',width:'100%', borderRadius:2}} />
					<div style={{padding:10}}>
						<Text variant='h6'>{board.boardName}</Text>
						<Text variant='body1'>{board.boardIntro}</Text>
						<div align='right' style={{marginTop:4, borderTop:'1px solid gray'}}><Text variant='caption'><i>- {board.boardSignature}</i></Text></div>
					</div>
				</Paper>

				<Paper square  style={{padding:20, marginBottom:20}}>
					<Text variant='body1'>
						This Step by Step guide is meant to help guide you through the Feature Board registration process.
						Some features were omitted and default settings used to help simplify and speed up the process.
						 You must complete each step before moving onto the next.
					</Text>
				</Paper>

			   <Paper square  style={{padding:0, minHeight:600, marginTop:20}}>
				   <Stepper activeStep={activeStep} orientation='vertical'>

					   {/* [START]: STEP 1 - Review Profile ::::::::::::::::::::: */}    
							<Step key={1}>
							<StepLabel><Text variant='h6'>Review Profile</Text></StepLabel>
							<StepContent>
								<Step1
									loggedIn={loggedIn}
									userInfo={userInfo}
									boardId={boardId}
									handleGoTo={this.handleGoTo}
								/>
							</StepContent>
							</Step>
					   {/* [END]: STEP 1 - Review Profile ::::::::::::::::::::::: */}

					   {/* [START]: STEP 2 - Acknowledge ::::::::::::::::::::: */}    
							<Step key={2}>
							<StepLabel><Text variant='h6'>Acknowledge</Text></StepLabel>
							<StepContent>
								<Step2
									loggedIn={loggedIn}
									handleGoTo={this.handleGoTo}
								/>
							</StepContent>
							</Step>
					   {/* [END]: STEP 2 - Acknowledge ::::::::::::::::::::::: */}

						{/* [START]: STEP 3 - Make Qualifying Contribution ::::::::::::::::::::: */}    
							<Step key={3}>
							<StepLabel><Text variant='h6'>Make Qualifying Contribution</Text></StepLabel>
							<StepContent>
								<Step3 
									loggedIn={loggedIn}
									boardId={boardId}
									boardName={board.boardName}
									boardSpotsAllowed={board.boardSpotsAllowed}
									donationRequirement={board.donationRequirement}
									memberTotalContribution={board.memberTotalContribution}
									memberTotalSpotsUsed={board.memberTotalSpotsUsed}
									memberTotalSpotsCompleted={board.memberTotalSpotsCompleted}
									handleGoTo={this.handleGoTo}
								/> 
							</StepContent>
							</Step>
						{/* [END]: STEP 3 - Make Qualifying Contribution ::::::::::::::::::::::: */}

						{/* [START]: STEP 4 - Submit Your Campaign ::::::::::::::::::::: */}    
						  <Step key={4}>
								<StepLabel><Text variant='h6'>Submit Your Campaign</Text></StepLabel>
								<StepContent>
									<Step4
										loggedIn={loggedIn}
										boardId={boardId}
										memberCampaigns={board.memberCampaigns}
										addEditCampaign={this.props.addEditCampaign}
										handleSetCampaignId={this.handleSetCampaignId}
									/>
								</StepContent>
							</Step>
						{/* [END]: STEP 4 - Submit Your Campaign ::::::::::::::::::::::: */}

						{/* [START]: STEP 5 - Pay Fee & Get Listed ::::::::::::::::::::: */}    
						  <Step key={5}>
								<StepLabel><Text variant='h6'>Pay Fee & Get Listed</Text></StepLabel>
								<StepContent>
									<Step5
										loggedIn={loggedIn}
										boardId={boardId}
										boardName={board.boardName}
										boardOwner={board.boardOwner}
										boardFee={board.boardFee}
										donationRequirement={board.donationRequirement}
										campaignId={campaignId}
									/>
								</StepContent>
							</Step>
						{/* [END]: STEP 5 - Pay Fee & Get Listed ::::::::::::::::::::::: */}
					</Stepper>
				</Paper>
				<div align='right'><Button onClick={() => { this.goTo('/board/'+boardId) }} size='small' style={{color:'#999'}}>Return to Feature Board</Button></div>
			</React.Fragment>
		)
	}
}
export default withRouter(New)
