import React from 'react'
import Loader from '../../../components/Loader'

import Button from '@material-ui/core/Button'
import Text from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'

function TicketPost({ticketId, updateTicket}){
	const [loading, setLoading] = React.useState(false)
	const [post, setPost] = React.useState('')
	const [message, setMessage] = React.useState('')
	const [disabled, setDisabled] = React.useState(false)
	
	const handleChange = (e) => {
		setMessage('')
		setPost(e.target.value)
	}

	const handleUpdateTicket = () => {
		if(post === ''){
			return setMessage('Please enter your post.')
		}
		setLoading(true)
		setDisabled(true)
		setMessage('Processing...')
		updateTicket(ticketId, post)
		.then(() => {
			setLoading(false)
			setDisabled(false)
			setMessage('')
			setPost('')
		})
		.catch((error) => {
			setLoading(false)
			setDisabled(false)
			setMessage(error)
		})
	}

   return (
    	<React.Fragment>
    		<Loader loading={loading} />
			<div style={{padding:20, textAlign:'center'}}>
				<TextField
					style={{width:'100%', margin:'20px auto'}}
					id="post"
					label="Post"
					multiline
					rows={4}
					variant="outlined"
					value={post}
          		onChange={handleChange}
				/>
				<div style={{textAlign:'center'}}><Text variant="overline" style={{color:'red'}}>{message}</Text></div>
				<Button disabled={disabled} onClick={handleUpdateTicket} variant="contained" color="secondary" style={{marginBottom:20}}>Submit</Button>
			</div>			
		</React.Fragment>
   )
}
export default TicketPost
