import React from 'react'
import moment from 'moment'
import swal from 'sweetalert2'
import {money, progress} from '../../../includes/functions'

import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import LinearProgress from '@material-ui/core/LinearProgress'
import Paper from '@material-ui/core/Paper'
import Text from '@material-ui/core/Typography'

const getDaysLeft = (day) => {
	let a = moment.unix(day);
	let b = moment().startOf('day')
	return a.diff(b, 'days')
}
const getDaysActive = (day) => {
	let a = moment().startOf('day')
	let b = moment.unix(day);
	return a.diff(b, 'days')
}
function Stats({loggedIn, stats, followCampaign}) {
	const follow = (id) => {
		if (loggedIn) {
			followCampaign(id)
		} else {
			swal.fire({
            position: 'top-end',
            icon: 'info',
            title: 'hi',
            text: 'Please login to follow campaign.',
            showConfirmButton: true,
            timer: 6000
         })  
		}
	}
	if(!stats || Object.keys(stats).length === 0){ return (null) }

	return (
		<Paper elevation={1} style={{paddingTop:20, paddingLeft:20, paddingRight:20, paddingBottom:0, minHeight:100}} >
			<Grid container justify='center' spacing={2}>
				<Grid item xs={12} sm={4}>
					<div style={{ padding:0, margin: '0 auto', textAlign:'center' }}>
						<Text variant='h4'>{money(stats.totalDonations,0)}</Text>
						<Text variant='button' color='textSecondary'>USD RAISED</Text>
					</div>
				</Grid>
				<Grid item xs={12} sm={4}>
					<div style={{ padding:0, margin: '0 auto', textAlign:'center' }}>
						<Text variant='h4'>{stats.totalDonors}</Text>
						<Text variant='button' color='textSecondary'>{(stats.totalDonors === 1) ? 'DONATION' : 'DONATIONS'}</Text>
					</div>
				</Grid>
				<Grid item xs={12} sm={4}>
					{(stats.deadline) ? (
						<div style={{ padding:0, margin: '0 auto', textAlign:'center' }}>
							<Text variant='h4'>{getDaysLeft(stats.dateEnd)}</Text>
							<Text variant='button' color='textSecondary'>{(getDaysLeft(stats.dateEnd) === 1) ? 'DAY LEFT' : 'DAYS LEFT'}</Text>
						</div>
					) : (
						<div style={{ padding:0, margin: '0 auto', textAlign:'center' }}>
							<Text variant='h4'>{getDaysActive(stats.dateStart)}</Text>
							<Text variant='button' color='textSecondary'>{(getDaysActive(stats.dateStart) === 1) ? 'DAY ACTIVE' : 'DAYS ACTIVE'}</Text>
						</div>
					)}
				</Grid>
				<Grid item xs={12}>
					<LinearProgress variant='determinate' value={progress(stats.totalDonations,stats.target)} />
					<Text variant='overline' color='textSecondary'>{progress(stats.totalDonations,stats.target)}% funded</Text>
				</Grid>
				<Grid item xs={6} style={{padding:0, margin:0}}>
					<Button color='primary'>Target: {money(stats.target,0)}</Button>
				</Grid>
				<Grid item xs={6} style={{padding:0, margin:0, textAlign:'right'}}>
					<Button color='primary' onClick={() => { follow(stats.id) }}>{(stats.following) ? 'Following' : 'Follow'}</Button>
				</Grid>
			</Grid>
		</Paper>
	)
}
export default Stats
