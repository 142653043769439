import React from 'react'

import Button from '@material-ui/core/Button'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Paper from '@material-ui/core/Paper'
import Text from '@material-ui/core/Typography'

const options = {
  	DONATION: 'This is the Default Campaign for groups that collect donations.',
  	FUNDRAISER: 'Fundraiser for a spacific cause.'
}

function CampaignType({handleCampaignType}) {
	const [typeEl, setTypeEl] = React.useState(null)
	const [campaignType, setCampaignType] = React.useState('DONATION')

	return (
		<Paper style={{ marginTop:20, padding: 20, minHeight:800 }} >
			<div style={{ maxWidth:600, margin:'0 auto' }}>
				<div style={{marginTop:20, padding:3}}><Text variant='body1' display='block' color='primary'><b>Select the type of campaign you would like to create.</b></Text></div>
			  	<List component='nav' style={{backgroundColor:'#f1f1f1', padding:2}}>
				 	<ListItem button onClick={(event) => { setTypeEl(event.currentTarget) }} style={{padding:10}}>
						<ListItemText primary={'Type of Campaign: ' + campaignType} secondary={options[campaignType]} />
				 	</ListItem>
			  	</List>             
			  	<Menu dense='true' anchorEl={typeEl} open={Boolean(typeEl)} onClose={() => {setTypeEl(null) }}
			  	>
				 	<MenuItem value='NGO' selected={campaignType === 'DONATION' } onClick={() => { setCampaignType('DONATION'); setTypeEl(null) }}>
						<ListItemText style={{whiteSpace: 'normal'}} primary='Donation' secondary={options['DONATION']} />
				 	</MenuItem>
				 	<MenuItem value='SCHOOL' selected={campaignType === 'FUNDRAISER' } onClick={() => { setCampaignType('FUNDRAISER'); setTypeEl(null) }}>
						<ListItemText style={{whiteSpace: 'normal'}} primary='Fundraiser' secondary={options['FUNDRAISER']} />
				 	</MenuItem>
			  	</Menu>
				<div align='right' style={{ marginTop:10 }}>
				 	<Button variant='contained' color='secondary' onClick={() => {handleCampaignType(campaignType)}}>
						Create {campaignType}
				 	</Button>
				</div>
			</div>							
		</Paper>
	)
}
export default CampaignType
