import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { getUserCredits } from '../../../redux/auth/actions'
import { payWithFac, payWithCredits } from '../../../redux/payments/actions'

import PaymentCreditCard from './PaymentCreditCard'
import PaymentStripe from './PaymentStripe'
import PaymentCredits from './PaymentCredits'

import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'

function TabPanel(props) {
  	const { children, value, index, ...other } = props;
  	return (
    	<div role='tabpanel' hidden={value !== index} id={`nav-tabpanel-${index}`} {...other} >
      	{value === index && ( children )}
    	</div>
  	)
}

function Payments({history, loggedIn, userCredits, processingFees, getUserCredits, payWithFac, payWithCredits, type, id, amount, message, checkedAnonymous, checkedHideMessage, paymentDescription='Donation', buttonText='confirm donation'}) {
	const [activeTab, setActiveTab] = React.useState(0)

	const handleTabChange = (event, newValue) => {
    	setActiveTab(newValue)
  	}

  	if (!loggedIn) { return null }
	return (
		<div style={{minHeight:200}}>					
			<Tabs variant='fullWidth' value={activeTab} onChange={handleTabChange} indicatorColor='secondary' textColor='secondary'>
			   <Tab style={{ minWidth: 50 }} label='Card'/>
			   <Tab style={{ minWidth: 50 }} label='Stripe'/>
			   <Tab style={{ minWidth: 50 }} label='Credits'/>
			</Tabs>

			<TabPanel value={activeTab} index={0}>
	        	<PaymentCreditCard 
	        		processingFees={processingFees} 
	        		payWithFac={payWithFac}
	        		type={type} 
	        		id={id} 
	        		amount={amount} 
	        		message={message} 
	        		checkedAnonymous={checkedAnonymous} 
	        		checkedHideMessage={checkedHideMessage}
	        		paymentDescription={paymentDescription}
	        		buttonText={buttonText}
	        	/>	
		   </TabPanel>
	      <TabPanel value={activeTab} index={1}>
	        	<PaymentStripe
	        		type={type} 
	        		id={id} 
	        		amount={amount} 
	        		message={message} 
	        		checkedAnonymous={checkedAnonymous} 
	        		checkedHideMessage={checkedHideMessage}
	        		paymentDescription={paymentDescription}
	        		buttonText={buttonText}
	        	/>
	      </TabPanel>
		   <TabPanel value={activeTab} index={2}>
	        	<PaymentCredits 
	        		processingFees={processingFees}
	        		getUserCredits={getUserCredits}
	        		userCredits={userCredits}
	        		payWithCredits={payWithCredits}
	        		type={type} 
	        		id={id} 
	        		amount={amount} 
	        		message={message} 
	        		checkedAnonymous={checkedAnonymous} 
	        		checkedHideMessage={checkedHideMessage}
	        		paymentDescription={paymentDescription}
	        		buttonText={buttonText}
	        	/>
	      </TabPanel>		
		</div>
	)
}
const mapStateToProps = (state) => ({
	loggedIn: state.auth.loggedIn,
	userCredits: state.auth.userInfo.credits,
	processingFees: state.payments.processingFees
})
const mapDispatchToProps = {
	getUserCredits,
	payWithFac,
	payWithCredits
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Payments))
