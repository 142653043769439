import React from 'react'
import moment from 'moment'
import { withRouter } from 'react-router-dom'
import {money, progress, truncate} from '../../../includes/functions'
import CampaignDonors from '../../components/CampaignDonors'

import Avatar from '@material-ui/core/Avatar'
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionActions from '@material-ui/core/AccordionActions'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import IconDot from '@material-ui/icons/FiberManualRecord'
import IconExpandMore from '@material-ui/icons/ExpandMore'
import LinearProgress from '@material-ui/core/LinearProgress'
import Paper from '@material-ui/core/Paper'
import Text from '@material-ui/core/Typography'

const getDaysActive = (day) => {
	let a = moment().startOf('day')
	let b = moment.unix(day);
	return a.diff(b, 'days')
}

function Campaigns({history, campaigns, fetchDonorDetails}) {
	const [expanded, setExpanded] = React.useState(false)
	const [showDonors, toggleDonors] = React.useState(false)
	const [showShare, toggleShare] = React.useState(false)
	const [campaignId, setCampaignId] = React.useState('')

  	const handleChange = (panel) => (event, isExpanded) => {
    	setExpanded(isExpanded ? panel : false);
  	}
  	const handleToggleDonors = (id) => {
  		setCampaignId(id)
  		toggleDonors(!showDonors)
  	}
	const goTo = (path) => {
      history.push({ pathname: path })
	}

	if (!campaigns || !Object.keys(campaigns).length) { 
		return (
			<Paper style={{ marginTop:0, padding: 20, minHeight:800 }} >
				<div align='center' style={{marginTop:20}}>
					<Text variant='body1' color='textSecondary'>No Campaigns Listed</Text>
				</div>
			</Paper>
		) 
	}

	return (
		<Paper style={{ marginTop:0, padding: 10, minHeight:800 }} >
			{(showDonors) ? (
				<CampaignDonors showDonors={showDonors} toggleDonors={toggleDonors} fetchDonorDetails={fetchDonorDetails} campaignId={campaignId}/>
			) : ( null )}
			{Object.keys(campaigns).map((i, key) => {
				return (
					<Accordion key={key} expanded={expanded === i} onChange={handleChange(i)}>
			        	<AccordionSummary	expandIcon={<IconExpandMore />}>
			        		<Avatar alt={campaigns[i].title} src={campaigns[i].promoImage} style={{borderRadius:5, margin:5, marginRight:10}} />
			        		<Grid container>
			        			<Grid item xs={12} sm={6} md={6} >
									<Text variant='h6' style={{marginTop:5, lineHeight:1.1}}>{truncate(campaigns[i].title, 25)}</Text>
										<Text variant='caption' display='block' color='textSecondary'>
											Listed on: {moment.unix(campaigns[i].dateStart).format('MMM Do YYYY - h:mm a')}
										</Text>
			        			</Grid>
			        			<Grid item xs={4} sm={2} md={2} style={{textAlign:'center'}} >
			        				<Text variant='overline'>donations</Text><br/>
			        				<Text variant='body1' color='primary'><b>{campaigns[i].totalDonors}</b></Text>
			        			</Grid>
			        			<Grid item xs={4} sm={2} md={2} style={{textAlign:'center'}} >
			        				<Text variant='overline'>days</Text><br/>
			        				<Text variant='body1' color='primary'><b>{getDaysActive(campaigns[i].dateStart)}</b></Text>
			        			</Grid>
			        			<Grid item xs={4} sm={2} md={2} style={{textAlign:'center'}} >
			        				<Text variant='overline'>live</Text><br/>
			        				<IconDot style={{color:(campaigns[i].live) ? '#28DD11' : '#E8E8E8'}} fontSize='small'/>
			        			</Grid>
			        			<Grid item xs={12}>
									<Text component='span' variant='body1' color='primary'><b>{money(campaigns[i].totalDonations,0)} </b></Text>
					          	<Text component='span' variant='caption' color='textSecondary'> raised of </Text>
					          	<Text component='span' variant='body1' color='textSecondary' ><b>{money(campaigns[i].target,0)} </b></Text>
					          	<Text component='span' variant='caption' color='textSecondary'> target. </Text>
					          	<LinearProgress variant="determinate" value={progress(campaigns[i].totalDonations, campaigns[i].target)} style={{marginBottom:20}}/>
			        			</Grid>
			        		</Grid>
			        	</AccordionSummary>
			        	<AccordionDetails>
			        		<div style={{maxWidth:500, margin:'0 auto', marginBottom: 50}}>
								<div align='center' style={{ maxWidth:200, margin: '0 auto', marginBottom: 30, borderBottom: '1px solid #B0B0B0' }}>
									<img src={campaigns[i].promoImage} style={{ width: '100%' }} alt='' /><br />
									<Text variant='caption' style={{color: '#B0B0B0'}}>
									  	<i>Campaign Promo Image.</i>
									</Text>  
								</div>
								<div align='center' style={{ maxWidth:440, margin: '0 auto', marginBottom: 50, borderBottom: '1px solid #B0B0B0' }}>
									<img src={campaigns[i].headerImage} style={{ width: '100%' }} alt='' /><br />
									<Text variant='caption' style={{color: '#B0B0B0'}}>
									  	<i>Page Header Image.</i>
									</Text>  
								</div>
								<div style={{ maxWidth:440, margin: '0 auto', marginBottom: 50}}>
									<Text variant='h5'>{campaigns[i].title}</Text>
									<div style={{ padding:5, borderTop: '1px solid #ccc', borderBottom: '2px solid #ccc' }}>
									<Text variant='body1'>{campaigns[i].about}</Text>
									</div>  
								</div>
								<div style={{ maxWidth:440, margin: '0 auto', marginBottom: 50}}>
									<Text variant='h5'>Details</Text>
									<div style={{ padding:5, borderTop: '1px solid #ccc', borderBottom: '2px solid #ccc' }}>
										<Text variant='body1'>
										  	<b>Category:</b> {campaigns[i].category}<br/>
										  	<b>Campaign Target:</b> {campaigns[i].target}<br/>
										  	<b>Stop At Target:</b> {(campaigns[i].stopAtTarget) ? 'Yes' : 'No'}<br/>
										  	{(campaigns[i].donationType === 'any') ? (
										  		<React.Fragment><b>Donation Type:</b> Any Amount ($5 minimum)<br/></React.Fragment>
											) : (
												<React.Fragment><b>Donation Type:</b> Fixed At: ${campaigns[i].donationFixedAmount}<br/></React.Fragment>
											)}
											{(campaigns[i].deadline) ? (
										  		<React.Fragment><b>Campaign End:</b> {campaigns[i].dateEnd}</React.Fragment>
											) : (
												<React.Fragment><b>Campaign End:</b> Ongoing, no end.</React.Fragment>
											)}
										</Text>
									</div>  
								</div>
								<Grid container>
			        				<Grid item xs={12} sm={6} style={{textAlign:'center', padding:10}}>
			        					<Button onClick={() => { handleToggleDonors(i) }} size='small' variant='outlined' color='secondary'>View Donations Details</Button>
			        				</Grid>
			        				<Grid item xs={12} sm={6} style={{textAlign:'center', padding:10}}>
			        					<Button onClick={() => {toggleShare(!showShare)}} size='small' variant='contained' color='secondary'>Share Campaign</Button>
			        				</Grid>
			        			</Grid>

							</div>


			        	</AccordionDetails>
			        	<Divider />
			        	<AccordionActions>
			        		<Button onClick={() => {goTo('/view/'+i)}} size="small" variant='outlined'>go to campaign</Button>
				         <Button onClick={() => {goTo('/group/campaign/'+i)}} size='small' variant='contained' color='secondary' style={{marginLeft:20}}>Edit Campaign</Button>
			        	</AccordionActions>
			      </Accordion>
				)
			})}
		</Paper>
	)
}
export default withRouter(Campaigns)
