import React from 'react'
import swal from 'sweetalert2'
import {money} from '../../../includes/functions'
import Loader from '../../components/Loader'

import Button from '@material-ui/core/Button'
import Text from '@material-ui/core/Typography'

function CreditCard({processingFees, payWithFac, type, id, amount, message, checkedAnonymous, checkedHideMessage, paymentDescription, buttonText}) {
	const [loading, setLoading] = React.useState(false)

	const donation = Number(amount)
	const fees = processingFees.facFixedFee + ((donation + processingFees.facFixedFee) * processingFees.facPercentage)
	const totalDue = donation + fees

	const handlePayNow = () => {
		setLoading(true)
		payWithFac(type, id, donation, message, checkedHideMessage, checkedAnonymous)	
		.then(paymentUrl => {
			setLoading(false)
         window.location.assign(paymentUrl)
      })       
      .catch(error => {
        	setLoading(false)
         swal.fire({
            position: 'top-end',
            icon: 'error',
            title: error.title,
            text: error.message,
            showConfirmButton: false,
            timer: 3000
         })                    
      }) 
	}
	return (
		<div>
			<Loader loading={loading}/>
			<div align='center' style={{maxWidth:300, marginLeft:20, marginRight:10, paddingTop:20}}>
				<div style={{display: 'flex', lineHeight:1.5}}>
               <Text variant='h6' color='secondary'>{paymentDescription}:</Text>
               <div style={{ marginLeft:'auto', marginRight:0, textAlign:'right'}}>
                  <Text variant='h6' color='secondary'><i>{money(donation,2)}</i></Text>
               </div>
            </div>
            <div style={{display: 'flex', lineHeight:1.5}}>
               <Text variant='body1' color='textSecondary'>Processing Fee:</Text>
               <div style={{ marginLeft:'auto', marginRight:0, textAlign:'right'}}>
                  <Text variant='body1' color='textSecondary'><i>{money(fees,2)}</i></Text>
               </div>
            </div>
            <div style={{display: 'flex', lineHeight:1.5}}>
               <Text variant='h6' color='textSecondary'>Total:</Text>
               <div style={{ marginLeft:'auto', marginRight:0, textAlign:'right'}}>
                  <Text variant='h6' color='textSecondary'><i>{money(totalDue,2)}</i></Text>
               </div>
            </div>
            <div style={{paddingTop: 10}}>
                <Button style={{backgroundColor:'#4caf50', color:'#fff'}} fullWidth variant='contained' color='primary' onClick={handlePayNow}>{buttonText}</Button>
            </div>
		   </div>
		</div>
	)
}
export default CreditCard
