import React from 'react'

import Paper from '@material-ui/core/Paper'
import Text from '@material-ui/core/Typography'

function Outstanding() {
	return (
		<Paper style={{ padding: 20, minHeight:800 }} >
			<Text variant='h5' gutterBottom>
				Outstanding
			</Text>
			<Text variant='caption' gutterBottom>
				funds team members collected and have outstanding for organization
			</Text>
		</Paper>
	)
}
export default Outstanding
