import React from 'react'
import moment from 'moment'
import { withRouter } from 'react-router-dom'
import {money, progress, truncate} from '../../../includes/functions'

import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import LinearProgress from '@material-ui/core/LinearProgress'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Paper from '@material-ui/core/Paper'
import Text from '@material-ui/core/Typography'

function Bookmarked({history, bookmarks}) {
	
	const goTo = (path) => {
      history.push({ pathname: path })
	}
	
	if (!bookmarks || !Object.keys(bookmarks).length) { 
		return (
			<Paper style={{ padding: 20, minHeight:800 }} >
				<div align='center' style={{marginTop:20}}>
					<Text variant='body1' color='textSecondary'>No Bookmarks Listed</Text>
				</div>
			</Paper>
		) 
	}

	return (
		<Paper style={{ padding: 20, minHeight:800 }} >
			<List>
				{Object.keys(bookmarks).map((i, key) => {
					return (
						<div key={key}>
						<ListItem alignItems='flex-start'>
							<ListItemAvatar>
								<Avatar alt={bookmarks[i].title} src={bookmarks[i].promoImage} style={{borderRadius:5, margin:5, marginRight:10}} />
							</ListItemAvatar>
							<Grid container>
					      	<Grid item xs={12} sm={8} md={9}>
					      		<Text variant='h6' style={{marginTop:5, lineHeight:1.1}}>{truncate(bookmarks[i].title, 25)}</Text>
									<Text variant='caption' display='block' color='textSecondary'>
										Listed on: {moment.unix(bookmarks[i].dateStart).format('MMM Do YYYY - h:mm a')}
									</Text>

									<Text component='span' variant='body1' color='primary'><b>{money(bookmarks[i].totalDonations,0)} </b></Text>
					          	<Text component='span' variant='caption' color='textSecondary'> raised of </Text>
					          	<Text component='span' variant='body1' color='textSecondary' ><b>{money(bookmarks[i].target,0)} </b></Text>
					          	<Text component='span' variant='caption' color='textSecondary'> target. </Text>
					          	<LinearProgress variant="determinate" value={progress(bookmarks[i].totalDonations, bookmarks[i].target)} style={{marginBottom:20}}/>
					      	</Grid>
					      	<Grid item xs={12} sm={4} md={3} style={{textAlign:'right'}}>
					      		<Button onClick={() => {goTo('/view/'+i)}} variant='outlined' color='secondary'>Go to Campaign</Button>
					      	</Grid>
					      </Grid>
						</ListItem>
						<Divider style={{margin:10}}/>
						</div>
					)
				})}
			</List>
		</Paper>
	)
}
export default withRouter(Bookmarked)
