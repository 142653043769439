import React from 'react'
import { Route, Redirect, Switch } from 'react-router-dom'
 
import Account from './views-member/account/_Container'
import Affiliate from './views-member/affiliate/_Container'
import Campaign from './views-member/campaign/_Container'
import Campaigns from './views-member/campaigns/_Container'
import Donations from './views-member/donations/_Container'
import Notifications from './views-member/notifications/_Container'
import Profile from './views-member/profile/_Container'
import Settings from './views-member/Settings'
import Setup from './views-member/account-setup/_Container'
import Support from './views-member/support/_Container'
import Transfer from './views-member/transfer/_Container'

function MemberRoutes() {
	return (
		<Switch>
			<Route path='/member/affiliate' component={Affiliate}/>
			<Route path='/member/account' component={Account}/>
			<Route path='/member/campaign/:id?' component={Campaign}/>
			<Route path='/member/campaigns' component={Campaigns}/>
			<Route path='/member/donations' component={Donations}/>
			<Route path='/member/notifications' component={Notifications}/>
			<Route path='/member/profile/:page?' component={Profile}/>
			<Route path='/member/settings' component={Settings}/>
			<Route path='/member/setup' component={Setup}/>
			<Route path='/member/support/:page?' component={Support}/>
			<Route path='/member/transfer/:transferId?/:tokenId?' component={Transfer}/>
			<Redirect from='/member' to='/member/account' />
		</Switch>
	)
}
export default MemberRoutes
