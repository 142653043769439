import React from 'react'
import moment from 'moment'
import swal from 'sweetalert2'
import { withRouter } from 'react-router-dom'

import Loader from '../../components/Loader'
import Step1 from './Step1'
import Step2 from './Step2'
import Step3 from './Step3'
import Step4 from './Step4'
import Step5 from './Step5'

import Paper from '@material-ui/core/Paper'
import Step from '@material-ui/core/Step'
import StepContent from '@material-ui/core/StepContent'
import StepLabel from '@material-ui/core/StepLabel'
import Stepper from '@material-ui/core/Stepper'
import Text from '@material-ui/core/Typography'

class New extends React.Component {
	mounted = true
	state = { 
		loading: false,	
		boardInfo: {
			id: (this.props.id) ? this.props.id : null,
			name: (this.props.info) ? this.props.info.name : '',
			intro: (this.props.info) ? this.props.info.intro : '',
			signature: (this.props.info) ? this.props.info.signature : '',
			headerImage: (this.props.info) ? this.props.info.headerImage : '/images/boards/boardHeader.jpg', //null,
			promoImage: (this.props.info) ? this.props.info.promoImage : '/images/boards/boardProfile.jpg', //null,
			thankyouMessage: (this.props.info) ? this.props.info.thankyouMessage : '',
			thankyouEmail: (this.props.info) ? this.props.info.thankyouEmail : '',
			donationTarget: (this.props.info) ? this.props.info.donationTarget : 500,
			donationRequirement: (this.props.info) ? this.props.info.donationRequirement : 100,
			donationDefault: (this.props.info) ? this.props.info.donationDefault : 25,
			boardFee: (this.props.info) ? this.props.info.boardFee : 25,
			boardSpotsAllowed: (this.props.info) ? this.props.info.boardSpotsAllowed : 5,
			dateStart: (this.props.info) ? this.props.info.dateStart : moment(Date.now()).format('X'), // current date in seconds
			dateEnd: (this.props.info) ? this.props.info.dateEnd : moment(Date.now()).add(365, 'd').format('X') // current date plus 180 days in seconds			
		}, 
		headerImages: [ // ImageSelector variable,
			'/images/boards/boardHeader.jpg',
			'/images/boards/boardHeader-1.jpg',
			'/images/boards/boardHeader-2.jpg',
			'/images/boards/boardHeader-3.jpg'
		],
		promoImages: [ // ImageSelector variable,
			'/images/boards/boardProfile.jpg',
			'/images/boards/boardProfile-1.jpg',
			'/images/boards/boardProfile-2.jpg',
			'/images/boards/boardProfile-3.jpg'
		],
		activeStep: 0
	}
	
	componentWillUnmount = () => {
		this.mounted = false
	}

// Step #1 - handleUpdateBoardAbout ................................
	handleUpdateBoardAbout = (i) => {
		let board = this.state.boardInfo
		board.name = i.name
		board.intro = i.intro
		board.signature = i.signature
		board.thankyouMessage = i.thankyouMessage
		board.thankyouEmail = i.thankyouEmail
		board.dateStart = i.dateStart
		board.dateEnd = i.dateEnd
		this.setState({ boardInfo:board })
		this.handleNext()
	}

// Step #2 - handleUpdateBoardSetup ................................
	handleUpdateBoardSetup = (donationTarget, donationRequirement, donationDefault, boardFee, boardSpotsAllowed) => {
		let board = this.state.boardInfo
		board.donationTarget = donationTarget
		board.donationRequirement = donationRequirement
		board.donationDefault = donationDefault
		board.boardFee = boardFee
		board.boardSpotsAllowed = boardSpotsAllowed
		this.setState({ boardInfo:board })
		this.handleNext()
	}

// Step #3 - handleUpdateBoardImages ...............................
	handleUpdateBoardImages = (promos, headers, selectedPromo, selectedHeader, step) => {
		let board = this.state.boardInfo
		board.promoImage = selectedPromo
		board.headerImage = selectedHeader

		this.setState({ boardInfo:board, promoImages:promos, headerImages:headers })
		if (step === 'next') {
			this.handleNext()
		} else {
			this.handleBack()
		}
	}

// Step #4 - handleSetBoardInfo ....................................
	handleSetBoardInfo = () => {
		let _this = this
		this.setState({loading:true})
	   this.props.addEditBoard(this.state.boardInfo)
		.then((id) => {
			if (_this.mounted) {
				_this.setState({loading:false})
				swal.fire({
					position: 'top-end',
					icon: 'success',
					title: 'Your feature board was created',
					showConfirmButton: true
			  	})
				_this.handleNext()
			}
		})
		.catch((error) => {
			if (_this.mounted) {
				_this.setState({loading:false})
				swal.fire({
					title: error.title,
					text: error.message,
					position: 'top-end',
					icon: 'error',
					showConfirmButton: true
			  	})
			}
		})
	}

// ...................................................................
	handleNext = () => { 
	   this.setState({activeStep: this.state.activeStep + 1 })
	}	 
	handleBack = () => {
	   this.setState({activeStep: this.state.activeStep - 1 })
	}
	handleGoTo = (step) => {
	   this.setState({activeStep: step })
	}

	render() {
		const { loading, boardInfo, headerImages, promoImages, activeStep } = this.state
		return (
			<React.Fragment>
				<Loader loading={loading} />
			   <Paper square  style={{padding:0, minHeight:800}}>
				   <div style={{padding:20}}>
				   	<Text>
				   		Create/Edit Feature Board.
				   	</Text>
				   </div>
				   <Stepper activeStep={activeStep} orientation='vertical'>
					   {/* [START]: STEP 1 - About ::::::::::::::::::::: */}    
							<Step key={1}>
							<StepLabel>About</StepLabel>
							<StepContent>
								<Step1 
									boardInfo={boardInfo}
									updateBoardAbout={this.handleUpdateBoardAbout}
								/>
							</StepContent>
							</Step>
					   {/* [END]: STEP 1 - About ::::::::::::::::::::::: */}

						{/* [START]: STEP 2 - Setup ::::::::::::::::::::: */}    
							<Step key={2}>
							<StepLabel>Setup</StepLabel>
							<StepContent>
								<Step2 
									boardInfo={boardInfo}
									updateBoardSetup={this.handleUpdateBoardSetup}
									handleBack={this.handleBack}
								/> 
							</StepContent>
							</Step>
						{/* [END]: STEP 2 - Setup ::::::::::::::::::::::: */}

						{/* [START]: STEP 3 - Images ::::::::::::::::::::: */}    
						  <Step key={3}>
								<StepLabel>Images</StepLabel>
								<StepContent>
									<Step3
									boardInfo={boardInfo}
								 	headerImages ={headerImages} 
								 	promoImages={promoImages}
									updateBoardImages = {this.handleUpdateBoardImages}
								/>
								</StepContent>
							</Step>
						{/* [END]: STEP 3 - Images ::::::::::::::::::::::: */}

						{/* [START]: STEP 4 - Review & Save ::::::::::::::::::::: */}    
						  <Step key={4}>
								<StepLabel>Review & Save</StepLabel>
								<StepContent>
									<Step4
									boardInfo={boardInfo}
								 	handleGoTo={this.handleGoTo}
								 	setBoardInfo={this.handleSetBoardInfo}
								/>
								</StepContent>
							</Step>
						{/* [END]: STEP 4 - Review & Save ::::::::::::::::::::::: */}

					   {/* [START]: STEP 5 - Go Live ::::::::::::::::::::: */}    
							
							{/* make sure user agrees to structure and fees (checkbox like terms of service) */}
							<Step key={5}>
								<StepLabel>What's Next</StepLabel>
								<StepContent>
								 	<Step5 />
								</StepContent>
							</Step>
					   {/* [END]: STEP 5 - Go Live ::::::::::::::::::::::: */}
					</Stepper>
				</Paper>
			</React.Fragment>
		)
	}
}
export default withRouter(New)
