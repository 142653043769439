import React from 'react'
import ImageSelector from '../../components/ImageSelector'

import Button from '@material-ui/core/Button'
import Text from '@material-ui/core/Typography'

function Step3 ({boardInfo, headerImages, promoImages, updateBoardImages}) {
	const [selectedPromo, setSelectedPromo] = React.useState(boardInfo.promoImage)
	const [selectedHeader, setSelectedHeader] = React.useState(boardInfo.headerImage)
	let promos = promoImages
	let headers = headerImages

	const handleSelectPromo = (src) => {
		setSelectedPromo(src)
		if (!promos.includes(src)) {
			promos.push(src)
		}
	}
	const handleSelectHeader = (src) => {
		setSelectedHeader(src)
		if (!headers.includes(src)) {
			headers.push(src)
		}
	}
	const handleAction = (step) => {
		updateBoardImages(promos, headers, selectedPromo, selectedHeader, step)
	}
	
	return (
		<div style={{maxWidth:500, margin:'0 auto'}}>
			<div style={{marginTop:20}}>
				<Text variant='subtitle2' color='primary'>Set the Promo Image that would be used to promote your fundraiser across the site.</Text>
				{/** ImageSelector Starts Here */}
				<div align='center' style={{ maxWidth: 250, margin:'0 auto', marginTop:20}}>
					<ImageSelector 
						imgBorder='1px solid #ccc'
						imgBorderRadius={10}
						imgPadding={5}
						imgRatio='16:9' 
						images={promos}
						currentImage={boardInfo.promoImage}
						selectImage={handleSelectPromo}
					/>
				</div>
				{/** ImageSelector Ends Here */}
			</div>

			<div style={{marginTop:20}}>
				<Text variant='subtitle2' color='primary'>Set the Header Image that would be used at the top of your fundraiser page.</Text>
				{/** ImageSelector Starts Here */}
				<div align='center' style={{ maxWidth: 450, margin:'0 auto', marginTop:20}}>
					<ImageSelector 
						imgBorder='1px solid #ccc'
						imgBorderRadius={10}
						imgPadding={5}
						imgRatio='32:9' 
						images={headers}
						currentImage={boardInfo.headerImage}
						selectImage={handleSelectHeader}
					/>
				</div>
				{/** ImageSelector Ends Here */}
			</div>

		  	<div align='right' style={{ marginTop:50 }}>
				<Button variant='outlined' color='primary' style={{ marginRight: 10 }} onClick={() => { handleAction('back') }}>Back</Button>
				<Button variant='contained' color='primary' onClick={() => { handleAction('next') }}>
					 Next
				</Button>
		  	</div>

		</div>
	)
}
export default Step3
